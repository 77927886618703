import { User } from 'sections/Users/types';
import { SUPER_ADMINISTRATOR } from '../../constants';

export const filterRolesByUserPrivileges = (
  roles: User['roles'],
  userPrivileges: Array<string>
) => {
  const superAdminRole = roles.find(
    (role) => role.name === SUPER_ADMINISTRATOR
  );

  if (
    !superAdminRole?.privileges?.every((privilege) =>
      userPrivileges.includes(privilege)
    )
  ) {
    return roles.filter((role) => role.name !== SUPER_ADMINISTRATOR);
  }

  return roles;
};
