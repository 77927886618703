import React from 'react';
import { TargetActions } from '../GoalSection';
import { Goal, Guideline } from '../../types';
import { StyledGuidelineLabel } from './styles';
import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';

export function GuidelineToggleActive({
  disabled = false,
  goal,
  guideline,
  guidelineIsActive,
  languageIdShort,
  openWarningModal,
  presetWarningModalShouldAppear,
  setWarningModalOKCallback,
  targetActions,
}: {
  disabled?: boolean;
  goal: Goal;
  guideline: Guideline;
  guidelineIsActive: boolean;
  languageIdShort: string;
  openWarningModal: () => void;
  presetWarningModalShouldAppear: boolean;
  setWarningModalOKCallback: (callback: () => void) => void;
  targetActions: TargetActions;
}): JSX.Element {
  return (
    <CommonCheckboxWithLabel
      disabled={disabled}
      disableRipple={true}
      checked={guidelineIsActive}
      lang={languageIdShort}
      label={
        <StyledGuidelineLabel variant="body2">
          {' '}
          {guideline.displayName}{' '}
        </StyledGuidelineLabel>
      }
      onChange={(_, checked) => {
        function onChangeHandler() {
          targetActions.setGuidelineActive(goal, guideline.identifier, checked);
        }

        if (presetWarningModalShouldAppear) {
          setWarningModalOKCallback(() => onChangeHandler);
          openWarningModal();
          return;
        }

        onChangeHandler();
      }}
    />
  );
}

export default GuidelineToggleActive;
