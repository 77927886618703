import Box from '@mui/material/Box';
import Button, { buttonClasses } from '@mui/material/Button';
import InputBase, { inputBaseClasses } from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
export const IconButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  color: theme.palette.primary.main,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.custom.grey95,
  },
  '&$disabled': {
    color: '#AFADAC',
  },
  [`&.${buttonClasses.text}`]: {
    padding: theme.spacing(4 / 5),
  },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.28),
  boxSizing: 'border-box',
  backgroundColor: theme.palette.custom.grey95,
  border: `1px solid ${theme.palette.custom.grey95}`,
  borderRadius: theme.shape.borderRadius,
  [`& .${inputBaseClasses.input}`]: {
    textAlign: 'center',
    padding: theme.spacing(1),
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    // Hide the HTML5 number input’s spin box
    // https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      MozAppearance: 'textfield' /* Firefox */,
    },
  },
  [`&:focus-within, &:hover:focus-within, &:focus:focus-within`]: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    [`& .${inputBaseClasses.input}:focus, & .${inputBaseClasses.input}:active`]:
      {
        padding: theme.spacing(4 / 5),
      },
  },
  [`&:hover, &:focus`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const StyledTotalBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  paddingLeft: theme.spacing(2),
}));
