import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { styled } from '@mui/material/styles';

import AuthContext from 'contexts/AuthContext';
import { fetchJsonFromAPI, FetchJsonReturnType } from 'utils/fetch';
import CommonDrawer, { DrawerContent } from 'components/CommonDrawer';
import InnerCreateUserDrawer from '../InnerCreateUserDrawer';
import { generatePassword } from 'sections/Users/utils';
import {
  PASSWORD_VALIDATION_PATTERN,
  USERNAME_VALIDATION_PATTERN,
} from 'sections/Users/constants';
import { User } from 'sections/Users/types';
import { isReadonlyArray } from 'utils/utils';

import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ApiError } from '../../../../../../../../errors/ApiError';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { useGetLicenses } from 'sections/Users/pages/UserDetails/components/LicenseTypeInput/utils';
import {
  useAddNewUserMutation,
  useGetCustomFields,
  useRolesQuery,
} from 'sections/Users/pages/UsersIndexPage/queries';

const DEFAULT_FORM_VALUES = {
  username: '',
  fullName: '',
  password: '',
};

type UsersResponse = FetchJsonReturnType & {
  data: ReadonlyArray<User>;
};

export type IFormInput = {
  username: string;
  fullName: string;
  password: string;
  roles: User['roles'];
  customFields: Record<string, string>;
  licenseType: string;
};

const StyledDrawerContent = styled(DrawerContent)(() => ({
  margin: 0,
}));

interface UserFormInDrawerProps {
  onClose?: (user?: User) => void;
  open: boolean;
}

export default function UserFormInDrawer({
  onClose,
  open,
}: UserFormInDrawerProps): ReactElement {
  const { t } = useTranslation();
  const { open: openErrorDialog } = useErrorNotificationDialog();
  const [isOpen, setIsOpen] = useState(false);
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );

  const { mutateAsync: addUser } = useAddNewUserMutation();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const [authToken] = useContext(AuthContext);

  const FETCH_CACHE = useRef<Record<string, UsersResponse>>({});

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        username: yup
          .string()
          .matches(/^\S+/, { message: 'startsWithSpace' })
          .matches(USERNAME_VALIDATION_PATTERN, {
            message: 'usernameInvalidCharacters',
          })
          .required()
          .test(
            'unique',
            (d) => `${d.path}`,
            async (value) => {
              if (value) {
                const url = `/api/v1/user?username=${value}&page=1&per_page=1&excludeProperties=true&excludeCustomFields=true`;

                // Every time any input value change the "React Hook Form" is running validation for all form inputs. This is a little help to keep submit button not blinking on every change between states disabled and not disabled.
                if (!FETCH_CACHE.current[url]) {
                  FETCH_CACHE.current[url] = await fetchJsonFromAPI<
                    never,
                    UsersResponse
                  >(authToken, url);
                }

                if (
                  FETCH_CACHE.current[url]?.data &&
                  Array.isArray(FETCH_CACHE.current[url].data)
                ) {
                  return (
                    FETCH_CACHE.current[url].data.filter(
                      ({ username }) =>
                        username.toUpperCase() === `${value}`.toUpperCase()
                    ).length === 0
                  );
                }
              }

              return true;
            }
          )
          .required(),
        fullName: yup.string(),
        password: yup.string().matches(PASSWORD_VALIDATION_PATTERN).required(),
        roles: yup.array().min(1).required(),
      })
      .required();
  }, [authToken]);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isValidating },
    setValue,
    reset,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { data: customFieldsData, refetch: mutateCustomFields } =
    useGetCustomFields();

  const setInitialCustomFieldsFormValue = useCallback(() => {
    if (customFieldsData && isReadonlyArray(customFieldsData?.data)) {
      setValue(
        'customFields',
        customFieldsData.data.reduce((previousValue, { key }) => {
          // Set default values
          return {
            ...previousValue,
            [key]: '',
          };
        }, {}),
        { shouldValidate: true }
      );
    }
  }, [customFieldsData, setValue]);

  useEffect(() => {
    const prevValue = getValues('customFields');

    // Run on mount only
    if (!prevValue) {
      setInitialCustomFieldsFormValue();
    }
  }, [customFieldsData, getValues, setInitialCustomFieldsFormValue, setValue]);

  const { data: rolesData, refetch: mutateRoles } = useRolesQuery();

  useEffect(() => {
    const prevValue = getValues('roles');

    // Run on mount only
    if (!prevValue || !isOpen) {
      if (rolesData && Array.isArray(rolesData.data)) {
        setValue(
          'roles',
          rolesData.data.filter(
            ({ default: isSetByDefault }) => !!isSetByDefault
          ),
          { shouldValidate: true }
        );
      }
    }
  }, [getValues, isOpen, rolesData, setValue]);

  const { data: licenseData } = useGetLicenses();

  const [onSubmitResult, setOnSubmitResult] = useState<User | null>(null);

  const onSubmit: SubmitHandler<IFormInput> = async ({
    roles,
    customFields,
    licenseType,
    ...data
  }) => {
    try {
      // Protect submitting while async validation is running
      if (isValidating || !isReadonlyArray(customFieldsData?.data)) {
        return false;
      }

      setOnSubmitResult(null);

      const response = await addUser({
        ...data,
        roles: roles.map(({ id, name }) => ({ id, name })),
        customFields: Object.keys(customFields)
          .map((key) => ({
            key,
            value: customFields[key],
          }))
          .filter(({ key, value }) => {
            const { inputType } =
              customFieldsData?.data.find(
                ({ key: defKey }) => key === defKey
              ) || {};

            // If required it can't have "" value and should be removed from output
            if (inputType === 'required' && !value) {
              return false;
            }

            // If read-only it can't be in output
            return inputType !== 'externallyProvided';
          }),
        // This is only added when the user has both license types available
        ...(licenseData &&
          licenseData?.data?.named?.licensed > 0 &&
          licenseData?.data?.concurrent?.licensed > 0 && { licenseType }),
      });

      if (response) {
        setOnSubmitResult(response.data);
      }

      // Close drawer
      setIsOpen(false);
    } catch (error: unknown) {
      openErrorDialog({
        ...apiErrorToNotification(error),
        onClose: () => {
          if (
            error instanceof ApiError &&
            (error.status === 403 || error.status === 404)
          ) {
            window.location.reload();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setOnSubmitResult(null);
      setValue('password', generatePassword());
      setInitialCustomFieldsFormValue();
    }
  }, [isOpen, reset, setInitialCustomFieldsFormValue, setValue]);

  const handleFormClose = () => {
    // Reset all input values when drawer is closed
    mutateCustomFields();
    mutateRoles();

    reset({ ...DEFAULT_FORM_VALUES });

    // Propagate outside
    if (onClose) {
      onClose(onSubmitResult ?? undefined);
    }
  };

  const values = getValues();

  return (
    <CommonDrawer
      drawerIsOpen={isOpen}
      submitButtonClickHandler={handleSubmit(onSubmit)}
      cancelAndCloseHandler={handleFormClose}
      drawerTitle={t('users.headers.addUser')}
      submitButtonLabel={t('shared.actions.add')}
      submitDisabled={!isValid}
      submitErrorText={!isValid ? t('users.hints.disabledSubmitButton') : ''}
      setFooterRect={setDrawerFooterRect}
      handleExit={() => handleFormClose()}
    >
      <StyledDrawerContent>
        {values?.roles !== undefined && (
          <InnerCreateUserDrawer
            isOpen
            control={control}
            errors={errors}
            customFieldsData={customFieldsData}
            licenseData={licenseData}
            footerRect={drawerFooterRect}
          />
        )}
      </StyledDrawerContent>
    </CommonDrawer>
  );
}
