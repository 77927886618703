import React, { memo, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import GuidelineSection from '../GuidelineSection';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Goal,
  GoalConfig,
  GoalGroup,
  Guideline,
  GuidelineValue,
} from '../../types';
import { TargetActions } from '../GoalSection';
import { WORDS_AND_PHRASES_GUIDELINE_TYPE } from '../../constants';
import TerminologyGuideline from '../TerminologyGuideline';
import { useTerminologyGuideline } from '../TerminologyGuideline/hooks';
import {
  GoalGroupContainer,
  GuidelineAccordionSummaryLabel,
  GuidelineWrapper,
  StyledAccordion,
  StyledAccordionSummaryGuidelineGroup,
} from './components';

interface GoalGroupSectionProps {
  expandedGoals: boolean;
  goal: Goal;
  goalConfig: GoalConfig;
  goalGroup: GoalGroup;
  guidelineConfig: GuidelineValue[] | undefined;
  languageIdShort: string;
  modified: boolean;
  readOnly: boolean;
  targetActions: TargetActions;
  targetGoal: GoalConfig;
}

/* eslint-disable react/prop-types */
export function GoalGroupSection(props: GoalGroupSectionProps): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (props.expandedGoals) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [props.expandedGoals]);

  return (
    <GoalGroupContainer>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        onClick={(ev) => ev.stopPropagation()}
      >
        <StyledAccordionSummaryGuidelineGroup expandIcon={<ExpandMoreIcon />}>
          <GuidelineAccordionSummaryLabel
            variant="h4"
            noWrap={true}
            lang={props.languageIdShort}
          >
            {props.goalGroup.displayName}
          </GuidelineAccordionSummaryLabel>
        </StyledAccordionSummaryGuidelineGroup>
        <AccordionDetails
          sx={(theme) => ({
            paddingLeft: theme.spacing(1.6),
            paddingRight: theme.spacing(1.6),
          })}
        >
          <GuidelineWrapper>
            {props.goalGroup.guidelines.map((guideline) =>
              guideline.guidelineType.toLowerCase() ===
              WORDS_AND_PHRASES_GUIDELINE_TYPE ? (
                <TerminologyGuidelineWrapper
                  key={guideline.identifier}
                  {...props}
                  guideline={guideline}
                  goalGroup={props.goalGroup}
                  readOnly={props.readOnly}
                />
              ) : (
                <GuidelineSection
                  goal={props.goal}
                  guideline={guideline}
                  guidelineConfig={props.guidelineConfig?.find(
                    (x) => x.guidelineId === guideline.identifier
                  )}
                  key={guideline.identifier}
                  languageIdShort={props.languageIdShort}
                  modified={props.modified}
                  readOnly={props.readOnly}
                  targetActions={props.targetActions}
                  targetGoal={props.targetGoal}
                />
              )
            )}
          </GuidelineWrapper>
        </AccordionDetails>
      </StyledAccordion>
    </GoalGroupContainer>
  );
}

export default memo(GoalGroupSection, isEqual);

function TerminologyGuidelineWrapper(
  props: GoalGroupSectionProps & { guideline: Guideline; goalGroup: GoalGroup }
) {
  const state = useTerminologyGuideline({
    goal: props.goal,
    goalConfig: props.goalConfig,
    targetActions: props.targetActions,
    guidelineObj: props.guideline,
  });

  return (
    <TerminologyGuideline
      {...state}
      {...props}
      expandedGoals={props.expandedGoals}
      guidelineConfig={props.guidelineConfig?.find(
        (x) => x.guidelineId === props.guideline.identifier
      )}
    />
  );
}
