import * as React from 'react';
import ErrorNotificationDialog from 'sections/Users/components/ErrorNotificationDialog';
import { ApiErrorDetails, ApiErrorResponse } from 'types/types';
import { ApiError } from './ApiError';
import TransWrapper from 'components/TransWrapper';
import { Box, Typography } from '@mui/material';
import theme from 'theme';

export const isApiErrorDetails = (details: any): details is ApiErrorDetails =>
  typeof details === 'object' &&
  'detail' in details &&
  'status' in details &&
  'title' in details &&
  'type' in details;

export const isApiErrorResponse = (
  response: any
): response is ApiErrorResponse =>
  typeof response === 'object' &&
  'error' in response &&
  isApiErrorDetails(response.error);

export const apiErrorToNotification = (
  error: unknown
): React.ComponentProps<typeof ErrorNotificationDialog> => {
  if (error instanceof ApiError) {
    return {
      title: error.title,
      description: error.description,
      error: (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <TransWrapper
            i18nKey="shared.apiError.details"
            values={error}
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
              a: <a style={{ color: theme.palette.primary.main }} />,
            }}
          />
          <Box sx={{ overflowY: 'auto' }}>
            <Typography
              component="pre"
              variant="body2"
              marginTop={2}
              sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap',
                fontFamily:
                  'ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace',
              }}
            >
              {error.toString()}
            </Typography>
          </Box>
        </Box>
      ),
      clipboardText: error.toString(),
    };
  } else if (error instanceof Error) {
    return {
      title: error.name,
      description: error.toString(),
    };
  }

  // Reaching this has 0 chance, handles the "unknown" type case (caught errors has an unknown type by default in ts)
  /* istanbul ignore next */
  return {
    title: 'Unknown Error',
    description: 'Something went wrong. Please try again later.',
  };
};
