import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import {
  ReplacementsImportResult,
  ReuseIndexDomain,
} from 'sections/Reuse/types';
import { useIsOpen, UseIsOpenResult } from 'hooks/useIsOpen';
import {
  REUSE_EXPORT_PATH,
  REUSE_IMPORTS_PATH,
} from 'sections/Reuse/constants';
import {
  fetchPlainResponse,
  fetchWrapperWithToken,
  HttpMethod,
} from 'utils/fetch';
import AuthContext from 'contexts/AuthContext';
import useRequestStatus, {
  ActionType,
  UseRequestStatusResult,
} from 'hooks/useRequestStatus';
import { UseReuseDomainsResult } from '../../hooks/useReuseDomains';
import { REUSE_BASE_PATH, REUSE_DOMAINS_PATH } from 'constants/constants';

export interface UseCreateDomainResult {
  closeDrawerCreateDomain: () => void;
  drawerCreateDomainIsOpen: boolean;
  setDrawerCreateDomainIsOpen: (arg: boolean) => void;
}

export function useCreateDomain(
  refreshIndexDomains: UseReuseDomainsResult['refreshIndexDomains']
): UseCreateDomainResult {
  const {
    elementIsOpen: drawerCreateDomainIsOpen,
    setElementIsOpen: setDrawerCreateDomainIsOpen,
  } = useIsOpen();

  async function closeDrawerCreateDomain() {
    await refreshIndexDomains();
    setDrawerCreateDomainIsOpen(false);
  }

  return {
    closeDrawerCreateDomain,
    drawerCreateDomainIsOpen,
    setDrawerCreateDomainIsOpen,
  };
}

export interface UseDeleteDomainResult {
  closeDeleteConfirmationDialog: (
    deleteIt: boolean,
    domain: ReuseIndexDomain
  ) => Promise<void>;
  domainForDeleteConfirmation: ReuseIndexDomain | undefined;
  setDomainForDeleteConfirmation: Dispatch<
    SetStateAction<ReuseIndexDomain | undefined>
  >;
}

export function useDeleteDomain(
  refreshIndexDomains: UseReuseDomainsResult['refreshIndexDomains']
): UseDeleteDomainResult {
  const [authToken] = useContext(AuthContext);
  const [domainForDeleteConfirmation, setDomainForDeleteConfirmation] =
    useState<ReuseIndexDomain | undefined>();

  async function closeDeleteConfirmationDialog(
    deleteIt: boolean,
    domain: ReuseIndexDomain
  ) {
    setDomainForDeleteConfirmation(undefined);
    if (deleteIt) {
      await fetchPlainResponse(
        authToken,
        `${REUSE_BASE_PATH}/${REUSE_DOMAINS_PATH}/${domain.uuid}`,
        { method: HttpMethod.DELETE }
      );

      await refreshIndexDomains();
    }
  }

  return {
    closeDeleteConfirmationDialog,
    domainForDeleteConfirmation,
    setDomainForDeleteConfirmation,
  };
}

export interface UseUploadReplacementsResult {
  closeUploadReplacementsDialog: (arg: boolean) => void;
  openUploadDialog: UseIsOpenResult['open'];
  uploadDialogIsOpen: UseIsOpenResult['elementIsOpen'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFileName: string;
  importState: UseRequestStatusResult<ReplacementsImportResult>['state'];
  resetImportState: UseRequestStatusResult<ReplacementsImportResult>['resetRequestState'];
}

export function useUploadReplacements(
  refreshIndexDomains: UseReuseDomainsResult['refreshIndexDomains']
): UseUploadReplacementsResult {
  const { open, close, elementIsOpen } = useIsOpen();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const { dispatch, resetRequestState, state } =
    useRequestStatus<ReplacementsImportResult>();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event?.target) {
      const file: File = (event?.target?.files as FileList)[0];
      setSelectedFile(file);
      setSelectedFileName(file.name);
    }
  }

  const [authToken] = useContext(AuthContext);

  async function closeUploadReplacementsDialog(upload: boolean) {
    if (upload) {
      const formData = new FormData();

      formData.append('file', selectedFile as Blob);
      formData.append('filename', selectedFile?.name as string);

      dispatch({ type: ActionType.InProgress });
      fetch(`${REUSE_BASE_PATH}/${REUSE_IMPORTS_PATH}`, {
        headers: {
          'X-Acrolinx-Auth': authToken as string,
        },
        method: HttpMethod.POST,
        body: formData,
      })
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            dispatch({ type: ActionType.Success, data });
            await refreshIndexDomains();

            return;
          }

          throw Error(response.status + ' ' + response.statusText);
        })
        .catch((error) => {
          console.log(error);
          return dispatch({ type: ActionType.Failure, error });
        })
        .finally(() => closeDialog());
    } else {
      closeDialog();
    }
  }

  function closeDialog() {
    setSelectedFile(undefined);
    setSelectedFileName('');
    close();
  }

  return {
    closeUploadReplacementsDialog,
    openUploadDialog: open,
    uploadDialogIsOpen: elementIsOpen,
    handleChange,
    selectedFileName,
    importState: state,
    resetImportState: resetRequestState,
  };
}

export interface UseDownloadReplacementsResult {
  actionHandler: (ok: boolean) => void;
  exportDialogIsOpen: UseIsOpenResult['elementIsOpen'];
  closeExportDialog: UseIsOpenResult['close'];
  openExportDialog: UseIsOpenResult['open'];
}

export function useExportReplacements(): UseDownloadReplacementsResult {
  const [authToken] = useContext(AuthContext);
  const {
    open: openExportDialog,
    close: closeExportDialog,
    elementIsOpen: exportDialogIsOpen,
  } = useIsOpen();

  async function actionHandler(download: boolean) {
    if (download) {
      const response = await fetchWrapperWithToken(authToken)(
        `${REUSE_BASE_PATH}/${REUSE_EXPORT_PATH}`
      );

      if (response.ok) {
        const originalBlob = await response.blob();
        const blob = originalBlob.slice(0, originalBlob.size, 'text/csv');
        const datetime = dayjs().format('YYYY-MM-DD');
        const fileName = `replacements-export-${datetime}.csv`;
        saveAs(blob, fileName);
      }
    }

    closeExportDialog();
  }

  return {
    actionHandler,
    closeExportDialog,
    exportDialogIsOpen,
    openExportDialog,
  };
}
