import React, { memo, useEffect, useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';

import {
  ContextConfig,
  Goal,
  GoalConfig,
  Guideline,
  GuidelineId,
  Preset,
  PresetId,
  Target,
} from '../../types';
import GoalAccordion from '../GoalAccordion';
import { CUSTOM } from '../../constants';
import { useTargetGoal } from '../../hooks';
import CustomPreset from './CustomPreset';
import GoalGroupSection from '../GoalGroupSection';
import {
  CustomPresetContainer,
  PresetContainer,
  PresetName,
  PresetText,
  StyledGoalColorSvg,
} from './styles';

export interface TargetActions {
  setRecommended(goal: Goal, recommended: boolean): void;
  setPreset(goal: Goal, presetId: PresetId): void;
  setGuidelineActive(
    goal: Goal,
    guidelineId: GuidelineId,
    active: boolean
  ): void;
  setGuidelineParameterValue(
    goal: Goal,
    guideline: Guideline,
    parameterValue: string
  ): void;
  setContextConfig(
    goal: Goal,
    guidelineId: GuidelineId,
    contextConfig: ContextConfig
  ): void;

  openEditContextsDrawer(props: {
    goal: Goal;
    contextConfig: ContextConfig;
    guideline: Guideline;
    warningModal?: {
      presetWarningModalShouldAppear: boolean;
      openWarningModal: () => void;
      setWarningModalCancelCallback: (callback: () => void) => void;
      setWarningModalOKCallback: (callback: () => void) => void;
    };
  }): void;
  setCustomPresetDescription(goal: Goal, description: string): void;
}

interface GoalSectionProps {
  goal: Goal;
  goalConfig: GoalConfig;
  expandedGoals: boolean;
  guidelinesWithWarning: Record<string, string[]>;
  languageIdShort: string;
  modified: boolean;
  readOnly: boolean;
  target: Target;
  targetActions: TargetActions;
  warningTranslationString: string;
}

function GoalSection(props: GoalSectionProps) {
  const {
    expandedGoals,
    goal,
    goalConfig,
    languageIdShort,
    modified,
    readOnly,
    target,
  } = props;
  const presetsSelectOptions: Preset[] = [...goal.predefinedGoalPresets];
  if (goalConfig.customPreset?.edited) {
    presetsSelectOptions.push(goalConfig.customPreset);
  }

  const selectedPreset = presetsSelectOptions.find(
    (x) => x.identifier === goalConfig.selectedPresetId
  );

  const [displayFormToEditPreset, setDisplayFormToEditPreset] =
    useState<boolean>(false);
  const targetGoal = useTargetGoal(target, goal);

  const [customPresetDescription, setCustomPresetDescription] =
    useState<string>(goalConfig.customPreset?.description || '');

  useEffect(() => {
    setCustomPresetDescription(goalConfig.customPreset?.description || '');
  }, [goalConfig]);

  return (
    <GoalAccordion
      goalColor={<StyledGoalColorSvg color={goal.color.toUpperCase()} />}
      expandedGoals={expandedGoals}
      presets={true}
      recommended={goalConfig.recommended}
      toggleRequiredRecommended={() => {
        props.targetActions.setRecommended(goal, !goalConfig.recommended);
      }}
      presetId={goalConfig.selectedPresetId}
      goal={goal}
      presetsSelectOptions={presetsSelectOptions}
      languageIdShort={languageIdShort}
      readOnly={readOnly}
      goalWithWarning={props.guidelinesWithWarning[goal.identifier]}
      setPreset={props.targetActions.setPreset}
      warningTranslationString={props.warningTranslationString}
    >
      <AccordionDetails onClick={(ev) => ev.stopPropagation()}>
        <Box width={1}>
          {goalConfig.selectedPresetId?.toLowerCase() === CUSTOM ? (
            <CustomPresetContainer lang={languageIdShort}>
              <CustomPreset
                customPresetDescription={customPresetDescription}
                displayFormToEditPreset={displayFormToEditPreset}
                goal={goal}
                readOnly={readOnly}
                setCustomPresetDescription={setCustomPresetDescription}
                setDisplayFormToEditPreset={setDisplayFormToEditPreset}
                targetActions={props.targetActions}
              />
            </CustomPresetContainer>
          ) : (
            <PresetContainer lang={languageIdShort}>
              <PresetName variant="body2" component="h4">
                {selectedPreset?.displayName}:
              </PresetName>
              <PresetText variant="body2">
                {selectedPreset?.description}
              </PresetText>
            </PresetContainer>
          )}
          {goal.goalGroups.map((goalGroup) => {
            return (
              <GoalGroupSection
                expandedGoals={props.expandedGoals}
                goal={goal}
                goalConfig={goalConfig}
                goalGroup={goalGroup}
                guidelineConfig={selectedPreset?.guidelineValues}
                key={goalGroup.displayName}
                languageIdShort={languageIdShort}
                modified={modified}
                targetActions={props.targetActions}
                targetGoal={targetGoal as GoalConfig}
                readOnly={readOnly}
              />
            );
          })}
        </Box>
      </AccordionDetails>
    </GoalAccordion>
  );
}

export default memo(GoalSection);
