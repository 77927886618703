import { styled } from '@mui/material/styles';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    top: '-10%',
    width: '460px',
    boxShadow: '0 5px 49px rgba(85, 85, 85, 0.2)',
    padding: theme.spacing(6),
    paddingTop: theme.spacing(26 / 5),
    borderRadius: theme.typography.pxToRem(6),
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(4),
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(32 / 5),
}));

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
  })
);

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: 0,
  '& > :not(:first-of-type)': {
    marginLeft: theme.spacing(2),
  },
}));
