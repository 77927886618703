import { useContext } from 'react';
import {
  FetchJsonReturnType,
  requestResponseOrThrowError,
  useFetch,
} from 'utils/fetch';

import AuthContext from 'contexts/AuthContext';
import {
  CUSTOM_FIELDS_API_PATH,
  ROLES_API_PATH,
  USER_API_PATH,
} from 'constants/constants';
import { User } from 'sections/Users/types';

import { useMutation, useQuery } from '@tanstack/react-query';

export const useRolesQuery = () => {
  const [authToken] = useContext(AuthContext);

  return useQuery<
    FetchJsonReturnType & {
      data: User['roles'];
    },
    Error
  >({
    queryKey: ['roles'],
    queryFn: () => requestResponseOrThrowError(authToken, ROLES_API_PATH),
  });
};

export const useGetCustomFields = () => {
  const [authToken] = useContext(AuthContext);

  return useQuery<
    {
      data: User['customFields'];
    },
    Error
  >({
    queryKey: ['custom-fields', 'user'],
    queryFn: () =>
      requestResponseOrThrowError(authToken, CUSTOM_FIELDS_API_PATH),
  });
};

type AddNewUserMutationData = {
  username: string;
  fullName: string;
  password: string;
  roles: ReadonlyArray<{ id: string; name: string }>;
  customFields: ReadonlyArray<{ key: string; value: string }>;
};

export const useAddNewUserMutation = () => {
  const http = useFetch();

  return useMutation<{ data: User }, Error, AddNewUserMutationData>({
    mutationFn: (data) => http.post(USER_API_PATH, data),
  });
};
