import React, { ReactElement, ElementType, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import Typography, { TypographyProps } from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import FormHelperText from '@mui/material/FormHelperText';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

import { YellowTooltip, BlackTooltip } from 'components/CommonTooltip';
import ConditionalWrapper from 'components/ConditionalWrapper';

const StyledSelect = styled(Select)(({ theme }) => ({
  '&:before': {
    borderBottomColor: theme.palette.custom.midGrey,
    borderBottomWidth: '1px',
    transition: 'none',
    transform: 'none',
  },
  '&:after': {
    borderBottomColor: theme.palette.custom.midGrey,
    borderBottomWidth: '1px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    transform: 'none',
  },
  '&:hover:after': {
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: '2px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    transform: 'none',
  },
  '& .MuiSelect-select': {
    paddingTop: theme.spacing(4.4),
    paddingBottom: theme.spacing(1.2),
  },
  '& svg': {
    right: theme.typography.pxToRem(3),
  },
}));

const StyledLabel = styled(Typography)<
  TypographyProps<ElementType, { component?: ElementType }>
>(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1.8, 1.8, 2, 6),
  letterSpacing: theme.typography.pxToRem(0.28),
  '&.Mui-selected': {
    backgroundColor: grey[100],
  },

  '&:hover': {
    backgroundColor: grey[100],
  },
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

const OverflowingValueStyledTooltip = styled(YellowTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    margin: 0,
    marginTop: `${theme.spacing(2)} !important`,
    marginLeft: theme.spacing(2),
    maxWidth: '290px',
  },
}));

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
} as Partial<MenuProps>;

export interface CommonSelectStandardProps extends SelectProps {
  helperText?: string;
  displayTooltipForLongLabels?: boolean;
}
export default function CommonSelectStandard({
  children,
  id,
  label,
  helperText,
  onOpen,
  onClose,
  // This switch is here because AC was limited to "the User Management section", task: https://acrolinx.atlassian.net/browse/DEV-31377
  displayTooltipForLongLabels = false,
  ...props
}: CommonSelectStandardProps) {
  const labelId = `${id}-standard-select-label`;

  const [isLabelTooltipVisible, setIsLabelTooltipVisible] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
  const [isLabelOverflowing, setIsLabelOverflowing] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <OverflowingValueStyledTooltip
      title={
        (!isLabelOverflowing && props?.value !== '' && <>{props.value}</>) || ''
      }
      open={!isDropdownOpen && visibleTooltip}
      onOpen={(event: React.SyntheticEvent) => {
        const target = event.target as HTMLElement;
        setVisibleTooltip(
          /* istanbul ignore next */
          target.scrollWidth > target.clientWidth
        );

        setIsLabelOverflowing(target.tagName === 'LABEL');
      }}
      onClose={() => {
        setVisibleTooltip(false);
      }}
      placement="bottom-start"
    >
      <FormControl fullWidth>
        <ConditionalWrapper
          condition={displayTooltipForLongLabels}
          wrapper={(children: ReactElement) => (
            <BlackTooltip
              title={`${label}`}
              open={isLabelTooltipVisible}
              onOpen={(event: React.SyntheticEvent) => {
                const target = event.target as HTMLElement;
                setIsLabelTooltipVisible(
                  /* istanbul ignore next */
                  target.scrollWidth > target.clientWidth
                );
              }}
              onClose={() => {
                setIsLabelTooltipVisible(false);
              }}
              placement="top"
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [25, -8],
                      },
                    },
                  ],
                },
              }}
            >
              {children}
            </BlackTooltip>
          )}
        >
          <InputLabel
            sx={{
              top: '-4px',
              maxWidth: 'calc(100% - 35px)',
            }}
            id={labelId}
          >
            <StyledLabel component="span" variant="body2">
              {label}
            </StyledLabel>
          </InputLabel>
        </ConditionalWrapper>
        <StyledSelect
          MenuProps={menuProps}
          id={`${id}-standard-select`}
          label={label}
          variant="filled"
          labelId={labelId}
          {...props}
          onOpen={(event) => {
            setIsDropdownOpen(true);

            if (onOpen) {
              onOpen(event);
            }
          }}
          onClose={(event) => {
            setIsDropdownOpen(false);

            if (onClose) {
              onClose(event);
            }
          }}
        >
          {children}
        </StyledSelect>
        {helperText && (
          <StyledFormHelperText>{helperText}</StyledFormHelperText>
        )}
      </FormControl>
    </OverflowingValueStyledTooltip>
  );
}
