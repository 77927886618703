import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { withLDProvider } from 'launchdarkly-react-client-sdk';

declare global {
  interface Window {
    Appcues: any;
  }
}

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
/* commenting out to save LaunchDarkly incurred costs, while Appcues is still not rolled out
const LDProvider = withLDProvider({
  clientSideID: '65e9d03a818c5a102d65b679',
  context: {
    kind: 'tenant',
    key: window.location.hostname.split('.')[0],
  },
  options: {
    streaming: false,
  },
})(App);
root.render(<LDProvider />);
*/
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
