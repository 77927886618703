import { useContext } from 'react';
import { LICENSE_STATISTICS_API_PATH } from 'constants/constants';
import AuthContext from 'contexts/AuthContext';
import { requestResponseOrThrowError } from 'utils/fetch';
import { LicenseTypeResponse } from 'sections/Users/types';
import { useQuery } from '@tanstack/react-query';

export const useGetLicenses = () => {
  const [authToken] = useContext(AuthContext);

  return useQuery<LicenseTypeResponse, Error>({
    queryKey: ['licenses', 'statistics'],
    queryFn: () =>
      requestResponseOrThrowError(authToken, LICENSE_STATISTICS_API_PATH),
  });
};
