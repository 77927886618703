import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import { NewAddedText } from 'sections/Targets/pages/TargetPage/types';
import { ALL_VALUE } from './constants';
import { DataForCheckboxes, Options } from './types';
import { handleChangeForCustomFields } from './helpers';
import OptionCheckbox from './OptionCheckbox';
import AllOption from './AllOption';
import { TargetAssignment } from '../../../../types';
import { InnerEmptyUserListErrorPageContent } from 'pages/EmptyUsersListErrorPage';

export interface OptionListProps {
  data: DataForCheckboxes;
  target: TargetAssignment;
  setNewTarget: (target: TargetAssignment) => void;
  newAddedTextList: NewAddedText[];
  deleteText: (text: string) => void;
  assignedTexts: Set<string>;
}

export function OptionList({
  data,
  target,
  setNewTarget,
  newAddedTextList,
  deleteText,
  assignedTexts,
}: Readonly<OptionListProps>): JSX.Element {
  const { t } = useTranslation();

  function calculateDisplayedChange(
    arrayWithOptions: string[],
    checked: boolean
  ) {
    if (data.propertyName) {
      const newTarget = { ...target };
      let targetPropertySet = new Set(newTarget[data.propertyName] as string[]);

      checked
        ? arrayWithOptions.forEach((value) => targetPropertySet.add(value))
        : arrayWithOptions.forEach((value) => targetPropertySet.delete(value));

      setNewTarget({
        ...newTarget,
        [data.propertyName]: Array.from(targetPropertySet),
      });
    }
  }

  const emptyResult =
    !data.options.length && data.propertyName === 'userUuids' ? (
      <InnerEmptyUserListErrorPageContent
        sx={(theme) => ({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        })}
      />
    ) : null;

  return (
    <FormControl
      required
      component="fieldset"
      id="chosen-criteria-selector"
      sx={{ width: '100%', marginLeft: '-4px' }}
    >
      {data.options.length ? (
        <>
          <AllOption
            element={{ id: ALL_VALUE, label: t('capture.labels.all') }}
            data={data}
            target={target}
            setNewTarget={setNewTarget}
            newAddedTextList={newAddedTextList}
            calculateDisplayedChange={calculateDisplayedChange}
          />
          {!!data.propertyName &&
            !!(target[data.propertyName] as string[]) &&
            data.options.map((element: Options): JSX.Element => {
              let checked =
                (typeof data.getPropertyValue === 'function' &&
                  data.optionsFieldName &&
                  (data.getPropertyValue(target) || {})[
                    data.optionsFieldName
                  ]?.includes(element.id)) ||
                (!!data.propertyName &&
                  (target[data.propertyName] as string[]).includes(element.id));

              if (data.allPropertyName && target[data.allPropertyName]) {
                checked = true;
              }

              return (
                <OptionCheckbox
                  key={element.id}
                  element={element}
                  checked={checked}
                  showDeleteIcon={!!data.type}
                  deleteText={deleteText}
                  deletable={!assignedTexts.has(element.id)}
                  onChange={(
                    event: ChangeEvent<{ value?: string }>,
                    checked: boolean
                  ) => {
                    const value = event.target?.value as string;

                    if (data.customFieldName && data.propertyName) {
                      handleChangeForCustomFields({
                        checked,
                        data,
                        target,
                        setNewTarget,
                        all: false,
                        value,
                      });
                      return;
                    }

                    calculateDisplayedChange([value], checked);
                  }}
                />
              );
            })}
        </>
      ) : (
        emptyResult
      )}
    </FormControl>
  );
}

export default OptionList;
