import cloneDeep from 'lodash/cloneDeep';
import {
  buildStructure,
  createTemporalStructureObject,
  updateChecked,
} from '../../sections/Targets/pages/TargetPage/components/TerminologyGuideline/helpers';
import { EnumContentObject } from '../../types/types';
import { DomainStructureObj } from './types';

export function addAllParents(
  domainObj: DomainStructureObj | undefined,
  structure: DomainStructureObj[],
  affectedDomains: string[]
) {
  const parent = structure.find(
    (d) => d.internalId === domainObj?.internalParentId
  );

  if (!domainObj?.internalParentId || !parent) {
    return;
  }

  affectedDomains.push(domainObj.internalParentId);
  addAllParents(parent, structure, affectedDomains);
}

export function update(
  structure: DomainStructureObj[],
  selectedDomains: string[]
) {
  const newStructure = cloneDeep(structure);
  const leaves = newStructure.filter(
    (d) => d.level && !d.childrenIndexes.length
  );
  const roots = newStructure.filter((d) => !d.level);

  leaves.forEach((domainObj) => {
    updateChecked(domainObj, selectedDomains, newStructure);
  });

  roots.forEach((domainObj) => {
    updateChecked(domainObj, selectedDomains, newStructure);
  });

  return newStructure;
}

export function createMainDomainStructure(domainData: EnumContentObject[]) {
  const nestStructure = domainData.reduce(createTemporalStructureObject, {});

  return domainData.reduce(
    (domainArr: DomainStructureObj[], domain): DomainStructureObj[] => {
      if (!domain.internalParentId) {
        domainArr.push({
          ...domain,
          level: 0,
          checked: false,
          indeterminate: false,
          childrenIndexes: [],
        });

        buildStructure(domainArr, nestStructure, domain.internalId, 1);
      }

      return domainArr;
    },
    []
  );
}
