import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { green } from '@mui/material/colors';
import LanguageIcon from '@mui/icons-material/Language';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { buttonGroupClasses } from '@mui/material';
import isEqual from 'lodash/isEqual';

import CreateOrEditTargetDrawer from '../../components/CreateOrEditTargetDrawer';
import EditContextsDrawer from './components/EditContextsDrawer';
import GoalSection from './components/GoalSection';
import StyledTooltip from 'components/CommonTooltip';
import {
  GoalsSection,
  StyledIconButton,
  RedResetButton,
  StyledDivider,
  GoalName,
  headerIconsSx,
} from './styles';
import { useTargetPage } from './hooks';
import WordsPhrasesGoal from './components/WordsPhrasesGoal';
import ReuseGoal from './components/ReuseGoal';
import { WORDS_AND_PHRASES_GOAL_NAME } from './constants';
import ReloadLSDialog from './components/ReloadLSDialog';
import WarningIcon from 'components/WarningIcon';
import { DetailsPageLayout } from 'components/PageLayout';
import ButtonPrimary from 'components/ButtonPrimary';
import useUnsavedChangesOnCloseWarning from 'hooks/useUnsavedChangesOnCloseWarning';
import { StyledEditButton } from 'components/PageLayout/styles';
import WarningText from 'components/WarningText/WarningText';
import { useFetchLanguages } from 'hooks/useFetchLanguages';
import { LanguageFull } from 'types/types';
import { StyledButtonGroup } from 'components/CommonDrawer';
import EditTargetWithComment from './EditTargetWithComment';
import CommonLockIconButton from 'components/CommonLockIconButton';
import InformationOutlinedRoundedIcon from 'icons/InformationOutlinedRoundedIcon';

export function TargetPage(): JSX.Element {
  const { t } = useTranslation();
  const dataFromHook = useTargetPage();
  const {
    actions,
    buttonsDimensions,
    commentData,
    configuredGoals,
    dialogWithLSReloadControl,
    dialogWithReloadLSData,
    dialogWithLSReloadIsActive,
    drawerEditTargetIsOpen,
    expandedGoals,
    getGoalConfig,
    handleClose,
    headerDimensions,
    languageDisplayName,
    languageIdShort,
    openContextDrawerFor,
    patchTarget,
    privileges: { editRights, readRights },
    reloadLS,
    reset,
    save,
    setDialogWithLSReloadIsActive,
    setDialogWithReloadLSData,
    setDrawerEditTargetIsOpen,
    setExpandedGoals,
    setOpenContextDrawerFor,
    snackbarMessage,
    target,
    targetResult,
    targetsOverviewResult,
    guidelinesWithWarning,
  } = dataFromHook;

  const { data: languages } = useFetchLanguages<LanguageFull>('full');

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const isModified =
    editRights && target && targetResult.data
      ? !isEqual(
          {
            ...target,
            // comment comes always as null because it can be set only for the current action
            // set it as null only if comment wasn't already set
            ...(!target?.comment && { comment: null }),
          },
          targetResult.data
        )
      : false;

  const [setIsModified] = useUnsavedChangesOnCloseWarning();

  useEffect(() => {
    if (isModified) {
      setIsSaved(false);
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [isModified, setIsSaved, setIsModified]);

  const closeReloadLSDialog = useCallback(() => {
    setDialogWithLSReloadIsActive(false);
    setDialogWithReloadLSData([]);
    dialogWithLSReloadControl.close();
  }, [
    dialogWithLSReloadControl,
    setDialogWithLSReloadIsActive,
    setDialogWithReloadLSData,
  ]);

  if (targetResult.error) {
    return <p>{targetResult.error.message}</p>;
  }

  // Access control based on privileges
  // shouldn't happen but better be safe than sorry :D
  if (!editRights && !readRights) {
    return <Redirect to="/403" />;
  }

  const height =
    window.innerHeight -
    buttonsDimensions.resizedHeight -
    headerDimensions.resizedHeight -
    // TODO: find a way to calculate this number
    // this number is needed as not all element dimensions (margins, borders and so on) are taken by JS
    210;

  return (
    <DetailsPageLayout
      backLink="/targets"
      backIconText={t('capture.a11y.backIcon')}
      heading={target?.displayName as string}
      headerDimensions={headerDimensions}
      lang={languageIdShort}
      rightSideHeaderComponent={
        editRights && (
          <StyledTooltip title={t('capture.actions.tooltips.editTarget')}>
            <StyledEditButton
              color="primary"
              onClick={() => setDrawerEditTargetIsOpen(true)}
              aria-label={t('capture.actions.tooltips.editTarget')}
              disableFocusRipple={true}
            >
              <EditIcon />
            </StyledEditButton>
          </StyledTooltip>
        )
      }
    >
      <Box my={2}>
        <>
          {openContextDrawerFor && target && (
            <EditContextsDrawer
              close={() => setOpenContextDrawerFor(undefined)}
              disabled={!editRights}
              openFor={openContextDrawerFor}
              setOpenFor={setOpenContextDrawerFor}
              setContextConfig={actions.setContextConfig}
            />
          )}
          {editRights && targetsOverviewResult?.data && (
            <CreateOrEditTargetDrawer
              submit={patchTarget}
              target={target}
              drawerIsOpen={drawerEditTargetIsOpen}
              close={() => setDrawerEditTargetIsOpen(false)}
              targetsNames={targetsOverviewResult.data
                .filter((x) => x.displayName !== target?.displayName)
                .map((x) => x.displayName)}
              drawerTitle={t('capture.titles.editTarget')}
              submitButtonLabel={t('capture.actions.viewChanges')}
              languages={languages}
            />
          )}
          {editRights &&
            dialogWithLSReloadIsActive &&
            dialogWithReloadLSData && (
              <ReloadLSDialog
                open={dialogWithLSReloadControl.elementIsOpen}
                closeFn={closeReloadLSDialog}
                okFn={reloadLS}
                data={dialogWithReloadLSData}
                configuredGoals={configuredGoals}
                lang={languageIdShort}
              />
            )}
        </>

        <Grid
          container
          ref={buttonsDimensions.setResizeElement}
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
          })}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="row"
            flexWrap="nowrap"
            aria-label={`${target?.displayName} ${t(
              'capture.a11y.targetInfo'
            )}`}
          >
            <Grid
              sx={(theme) => ({
                display: 'inline-flex',
                paddingTop: theme.spacing(0.4),
              })}
              alignItems="flex-start"
            >
              <LanguageIcon sx={headerIconsSx} />
              <Typography
                variant="body2"
                sx={(theme) => ({
                  minWidth: theme.spacing(35),
                  marginRight: theme.spacing(3),
                  paddingTop: theme.spacing(0.4),
                })}
                lang={languageIdShort}
              >
                {languageDisplayName}
              </Typography>

              {target?.description && <InformationOutlinedRoundedIcon />}
              <Typography
                sx={(theme) => ({
                  maxWidth: theme.spacing(74),
                })}
                variant="body2"
                lang={languageIdShort}
              >
                {target?.description}
              </Typography>
            </Grid>
            <Grid display="flex" sx={{ height: '100%' }} alignItems="center">
              <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
              >
                {editRights ? (
                  <>
                    <Grid
                      display="inline-flex"
                      justifyContent="flex-end"
                      flexDirection="row"
                    >
                      <RedResetButton
                        onClick={reset}
                        variant="outlined"
                        disabled={!isModified}
                        color="error"
                      >
                        <>{t('capture.actions.reset')}</>
                      </RedResetButton>
                      <StyledButtonGroup
                        variant="contained"
                        sx={(theme) => ({
                          [`&.${buttonGroupClasses.root} .${buttonGroupClasses.grouped}:not(:last-of-type)`]:
                            {
                              borderRight: 'none',
                              borderColor: 'unset',
                              marginRight: theme.spacing(1 / 5),
                            },
                        })}
                      >
                        <ButtonPrimary
                          className="save"
                          onClick={async () => {
                            if (dialogWithLSReloadIsActive) {
                              dialogWithLSReloadControl.open();
                            }

                            await save();
                            setIsSaved(true);
                          }}
                          variant="contained"
                          disabled={!isModified}
                          {...(isSaved && {
                            sx: () => ({
                              '&:disabled.save': {
                                backgroundColor: green['A700'],
                                cursor: 'not-allowed',
                                pointerEvents: 'auto',
                                '&:hover, &:focus, &:focus-within': {
                                  backgroundColor: green['A700'],
                                },
                              },
                            }),
                          })}
                        >
                          <>
                            <Collapse
                              in={isSaved}
                              orientation="horizontal"
                              timeout={500}
                            >
                              <CheckIcon fontSize="small" />
                            </Collapse>
                            {isSaved
                              ? t('capture.actions.saved')
                              : t('capture.actions.save')}
                          </>
                        </ButtonPrimary>
                        <EditTargetWithComment
                          commentData={commentData}
                          disabled={!isModified}
                          submitAction={async () => {
                            if (dialogWithLSReloadIsActive) {
                              dialogWithLSReloadControl.open();
                            }

                            await save();
                            setIsSaved(true);
                          }}
                        />
                      </StyledButtonGroup>
                    </Grid>
                    {isModified && (
                      <Grid
                        container
                        flexDirection="row"
                        justifyContent="flex-end"
                        flexWrap="nowrap"
                        sx={(theme) => ({
                          marginTop: theme.spacing(1),
                        })}
                      >
                        <WarningIcon />
                        <WarningText
                          sx={(theme) => ({
                            lineHeight: theme.typography.pxToRem(23),
                          })}
                        >
                          {t('capture.descriptions.unsavedChangesWarning')}
                        </WarningText>
                      </Grid>
                    )}
                  </>
                ) : (
                  <CommonLockIconButton
                    tooltipText={t('reuse.tooltips.readonly')}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledDivider />
        <Grid>
          <GoalsSection
            style={{
              height: `${height}px`,
              overflowY: 'auto',
              overflowX: 'hidden',
              width: '100%',
              scrollbarWidth: 'thin',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignContent="flex-start"
            >
              <GoalName component="h2">{t('capture.titles.goals')}</GoalName>
              <StyledTooltip
                placement="bottom-end"
                title={
                  expandedGoals
                    ? t('capture.actions.tooltips.collapseAll')
                    : t('capture.actions.tooltips.expandAll')
                }
              >
                <StyledIconButton
                  className="expand-all"
                  onClick={async () => {
                    setExpandedGoals(!expandedGoals);
                  }}
                  aria-label={
                    expandedGoals
                      ? t('capture.actions.tooltips.collapseAll')
                      : t('capture.actions.tooltips.expandAll')
                  }
                >
                  {!expandedGoals && <UnfoldMoreIcon color="primary" />}
                  {expandedGoals && <UnfoldLessIcon color="primary" />}
                </StyledIconButton>
              </StyledTooltip>
            </Grid>
            {target &&
              !!configuredGoals.length &&
              configuredGoals.map((goal) => {
                if (
                  goal.identifier.toLowerCase() === WORDS_AND_PHRASES_GOAL_NAME
                ) {
                  return (
                    <WordsPhrasesGoal
                      key={goal.identifier}
                      expandedGoals={expandedGoals}
                      goal={goal}
                      goalConfig={getGoalConfig(goal.identifier)}
                      languageIdShort={languageIdShort}
                      targetActions={actions}
                      target={target}
                      guidelinesWithWarning={guidelinesWithWarning}
                      warningTranslationString="capture.tooltips.noTerminologyForGoalWarning"
                      modified={isModified}
                      readOnly={!editRights}
                    />
                  );
                }
                if (goal.identifier.toLowerCase() === 'reuse') {
                  return (
                    <ReuseGoal
                      key={goal.identifier}
                      expandedGoals={expandedGoals}
                      goal={goal}
                      goalConfig={getGoalConfig(goal.identifier)}
                      languageIdShort={languageIdShort}
                      targetActions={actions}
                      target={target}
                      guidelinesWithWarning={guidelinesWithWarning}
                      modified={isModified}
                      readOnly={!editRights}
                    />
                  );
                }

                return (
                  <GoalSection
                    expandedGoals={expandedGoals}
                    goal={goal}
                    goalConfig={getGoalConfig(goal.identifier)}
                    guidelinesWithWarning={guidelinesWithWarning}
                    key={goal.identifier}
                    modified={isModified}
                    readOnly={!editRights}
                    target={target}
                    targetActions={actions}
                    warningTranslationString="capture.tooltips.noTerminologyForGuidelineWarning"
                    languageIdShort={languageIdShort}
                  />
                );
              })}
          </GoalsSection>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!!snackbarMessage}
          autoHideDuration={6000}
          onClose={handleClose}
          message={snackbarMessage}
        >
          <Alert onClose={handleClose} severity="info">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </DetailsPageLayout>
  );
}

export default TargetPage;
