import { styled } from '@mui/material/styles';

export const UploadReplacementDescription = styled('span')(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.common.black,
  marginBottom: theme.spacing(4),
}));

export const UploadFileName = styled('span')(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.common.black,
  marginTop: theme.spacing(4),
  '& span': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
