import { styled } from '@mui/material/styles';
import InputBase, {
  inputBaseClasses,
  InputBaseProps,
} from '@mui/material/InputBase';
import { selectClasses } from '@mui/material/Select';
import { blueGrey, grey } from '@mui/material/colors';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import InputLabel, {
  inputLabelClasses,
  InputLabelProps,
} from '@mui/material/InputLabel';
import { formLabelClasses } from '@mui/material/FormLabel';

export const BootstrapInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'hovered',
})<InputBaseProps & { width: number | undefined; hovered: boolean }>(
  ({ theme, hovered, width }) => ({
    [`&.Mui-focused .${inputBaseClasses.input}.${selectClasses.standard}.${selectClasses.select}`]:
      {
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.primary.main,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
      },
    [`& .${inputBaseClasses.input}.${selectClasses.standard}.${selectClasses.select}`]:
      {
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.custom.linkWater}`,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(22),
        letterSpacing: theme.typography.pxToRem(0.14),
        padding: theme.spacing(1.6, 6, 1.6, 2),
        minWidth: theme.spacing(((width || 110) + 10) / 5),
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        borderColor: hovered ? theme.palette.primary.main : blueGrey[100],
        '&:focus, &:hover, &:active': {
          borderRadius: theme.shape.borderRadius,
          borderColor: theme.palette.primary.main,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
        '&.Mui-disabled': {
          borderColor: grey[400],
          color: grey[400],
        },
      },
    [`& svg.${selectClasses.icon}`]: {
      fill: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(28),
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
  })
);

export const StyledMenuItemRaw = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  margin: 0,
  // , &.${menuItemClasses.root}.Mui-focusVisible
  [`&.${menuItemClasses.root}.Mui-selected, &:hover`]: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(22),
  padding: theme.spacing(1.8, 2.6),
  [`&.${menuItemClasses.root}.Mui-selected`]: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.grey98,
  },
}));

export const StyledEmptyMenuItem = styled(StyledMenuItem)({
  fontStyle: 'italic',
});

export const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'hovered',
})<InputLabelProps & { hovered: boolean; value: number }>(
  ({ theme, hovered, value }) => {
    const labelTransform = 'translate(0, 0) scale(1)';
    const labelTransformScale = 'translate(0, -9px) scale(0.86)';

    const hoveredStyles = {
      letterSpacing: theme.typography.pxToRem(0.12),
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 1),
      color: theme.palette.primary.main,
      transform: labelTransformScale,
      transition: 'all 300ms',
    };

    const normalStyles = {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      cursor: 'pointer',
      zIndex: 1,
      paddingTop: theme.spacing(1.6),
      marginLeft: theme.spacing(2),
      transition: 'all 300ms',
      transform: labelTransform,
      color: theme.palette.text.secondary,
    };

    return {
      [`&.${formLabelClasses.root}.smallOutlinedSelectLabel`]: {
        ...normalStyles,
        '&.Mui-focused': hoveredStyles,
        [`&.${inputLabelClasses.shrink}`]: {
          // to override MuiInputLabel.styleOverrides.shrink from theme.ts
          transform: `${
            value || hovered ? labelTransformScale : labelTransform
          }!important`,
          '&.Mui-focused': {
            transform: `${labelTransformScale}!important`,
          },
        },
        ...(!value ? normalStyles : hoveredStyles),
        ...(hovered ? hoveredStyles : undefined),
        color: hovered
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
        '&.Mui-disabled': {
          color: grey[400],
          cursor: 'auto',
        },
      },
    };
  }
);
