import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '0.28px',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(18),
  padding: theme.spacing(2, 2, 1, 2),
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.custom.lighterGrey}`,
  },
}));
