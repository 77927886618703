import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ButtonPrimary from 'components/ButtonPrimary';
import CommonTooltip from 'components/CommonTooltip';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { USER_API_PATH } from 'constants/constants';
import { USER_AND_ROLES_DELETE_SESSIONS } from 'constants/privileges';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { useFetch } from 'utils/fetch';
import { User } from 'sections/Users/types';
import { useLicenseTypeOptions } from 'sections/Users/utils';
import { LicenseInfoTitle, StyledHelpOutlineOutlinedIcon } from './styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ApiError } from 'errors/ApiError';
import { useError } from '../../../../components/ErrorNotificationDialog/ErrorContext';
import { useGetLicenses } from './utils';
import { FormAction } from '../../utils';

interface LicenseTypeInputProps {
  user: User;
  isEditingAllowed?: boolean;
  dispatch: React.Dispatch<FormAction>;
}

const LicenseTypeInput = ({
  user,
  isEditingAllowed = true,
  dispatch,
}: LicenseTypeInputProps) => {
  const { t } = useTranslation();
  const licenseTypeOptions = useLicenseTypeOptions(isEditingAllowed);
  const userPrivileges = useContext(PrivilegesContext);

  const http = useFetch();
  const { setError } = useError();

  const { id: userId, licenseType, licenseStatus } = user;

  const { data: licenseData } = useGetLicenses();

  const { enqueueSnackbar } = useSnackbar();

  // Request license release on server
  const handleRequestLicenseRelease = async () => {
    try {
      await http.put(`${USER_API_PATH}/${userId}/_release`);

      dispatch({
        type: 'SET_FIELD_VALUE',
        payload: { fieldName: 'licenseStatus', fieldValue: 'inactive' },
      });
    } catch (error: unknown) {
      throw error;
    }
  };

  const handleChangeLicenseType = async (
    event: React.ChangeEvent<{ value?: unknown }>
  ) => {
    const newValue = event.target.value as User['licenseType'];

    try {
      if (newValue === 'concurrent') {
        await handleRequestLicenseRelease();
      }
    } catch (error: unknown) {
      setError({ value: error as ApiError, origin: 'releaseLicense' });
      return;
    }

    try {
      await http.put(`${USER_API_PATH}/${userId}`, {
        licenseType: newValue,
      });

      dispatch({
        type: 'SET_FIELD_VALUE',
        payload: { fieldName: 'licenseType', fieldValue: newValue },
      });
    } catch (error: unknown) {
      setError({ value: error as ApiError, origin: 'changeLicenseType' });
    }
  };

  const handleLicenseRelease = async () => {
    try {
      await handleRequestLicenseRelease();

      // Display Notification
      enqueueSnackbar(t('user.notification.releaseLicense'));
    } catch (error) {
      setError({ value: error as ApiError, origin: 'releaseLicense' });
    }
  };

  if (!licenseType) {
    return null;
  }

  return (
    <>
      <LicenseInfoTitle variant="h2">
        {(licenseData?.data?.concurrent?.licensed === 0 &&
          licenseType === 'named' && (
            <>{t('users.columns.licenseStatus')}</>
          )) || (
          <>
            {t('users.columns.licenseType')}
            {['named', 'concurrent'].includes(licenseType) && (
              <CommonTooltip
                type="yellow"
                title={<>{t('users.hints.licenseType')}</>}
                placement="left-start"
              >
                <IconButton
                  disableRipple
                  aria-label={t('users.columns.licenseType')}
                >
                  <StyledHelpOutlineOutlinedIcon
                    fontSize="small"
                    width={18}
                    height={18}
                  />
                </IconButton>
              </CommonTooltip>
            )}
          </>
        )}
      </LicenseInfoTitle>

      {(licenseType === 'builtin' && (
        <Typography variant="body2" tabIndex={0}>
          {t('users.info.status.integratedLicense')}
        </Typography>
      )) || (
        <>
          {licenseData && licenseData?.data?.concurrent?.licensed > 0 && (
            <Box pb={5}>
              <FormControl component="fieldset">
                <CommonRadioGroup
                  data={licenseTypeOptions}
                  value={licenseType}
                  aria-label={t('users.columns.userLicense')}
                  changeHandler={handleChangeLicenseType}
                />
              </FormControl>
            </Box>
          )}
          {licenseType === 'named' && (
            <Box>
              {licenseData && licenseData?.data?.concurrent?.licensed > 0 && (
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: 'custom.gray36',
                    fontSize: 13,
                    lineSpacing: 19,
                  }}
                >
                  {t('users.columns.licenseStatus').toUpperCase()}
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  color: `${
                    licenseStatus === 'active'
                      ? 'custom.green'
                      : 'custom.bluishGrey'
                  }`,
                  lineSpacing: 19,
                  mt: 2,
                }}
              >
                {t(
                  `users.info.status.${
                    licenseStatus === 'active'
                      ? 'activeLicense'
                      : 'inactiveLicense'
                  }`
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  lineSpacing: 19,
                }}
              >
                {t('users.descriptions.licenseStatusDescription')}
              </Typography>
              {[USER_AND_ROLES_DELETE_SESSIONS].find((p) =>
                userPrivileges.includes(p)
              ) && (
                <>
                  {licenseStatus === 'inactive' && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        lineSpacing: 19,
                      }}
                    >
                      {t('users.descriptions.releaseLicense')}
                    </Typography>
                  )}
                  <Box mt={32 / 5}>
                    <ButtonPrimary
                      variant="contained"
                      aria-disabled={licenseStatus === 'inactive'}
                      {...(licenseStatus === 'active' && {
                        onClick: handleLicenseRelease,
                      })}
                    >
                      {t('users.actions.releaseLicense')}
                    </ButtonPrimary>
                  </Box>
                </>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default LicenseTypeInput;
