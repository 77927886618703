import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import SuggestionAddButton from './SuggestionAddButton';
import { SuggestionTypeText } from './components';
import {
  ReuseSuggestion,
  SuggestionDataType,
  SuggestionStatusType,
} from 'sections/Reuse/types';
import { UseReuseInboxResult, useUnDismissSuggestionDialog } from '../../hooks';
import DismissSuggestionDialog from '../DismissSuggestionDialog';
import SuggestionUnDismissButton from './SuggestionUnDismissButton';

export interface SuggestionActionButtonPanelProps {
  data: ReuseSuggestion;
  handleOnClick: UseReuseInboxResult['openReplacementDrawer'];
  refreshSuggestionList: UseReuseInboxResult['refreshSuggestionList'];
}

export default function SuggestionActionButtonPanel({
  data,
  handleOnClick,
  refreshSuggestionList,
}: SuggestionActionButtonPanelProps) {
  const { t } = useTranslation();
  const dismissed = data.status === SuggestionStatusType.DISMISSED;

  const {
    unDismissSuggestionDialogIsOpen,
    unDismissSuggestionOrCloseDialog,
    unDismissSuggestionOrOpenDialog,
    onCheck,
  } = useUnDismissSuggestionDialog({
    dismissed,
    uuid: data.uuid,
    refreshSuggestionList,
  });

  const text = t(
    data.type === SuggestionDataType.NEW
      ? 'reuseInbox.titles.addSuggestion'
      : 'reuseInbox.titles.updateSuggestion'
  );
  const tooltip = t(
    data.type === SuggestionDataType.NEW
      ? 'reuseInbox.tooltips.addSuggestion'
      : 'reuseInbox.tooltips.updateSuggestion'
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <div>
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({ padding: theme.spacing(1, 0, 1, 1 / 5) })}
        >
          <SuggestionAddButton
            handleOnClick={() =>
              handleOnClick(data.cluster, data.deprecatedPhrases, data.uuid)
            }
            tooltip={tooltip}
            disabled={dismissed}
          />
          <SuggestionTypeText
            dismissed={dismissed}
            {...(dismissed ? { 'aria-hidden': true } : {})}
          >
            {text}
          </SuggestionTypeText>
        </Grid>
      </div>
      <SuggestionUnDismissButton
        dismissed={dismissed}
        handleOnClick={unDismissSuggestionOrOpenDialog}
      />
      {unDismissSuggestionDialogIsOpen && (
        <DismissSuggestionDialog
          check={onCheck}
          dismissed={dismissed}
          open={unDismissSuggestionDialogIsOpen}
          onClose={unDismissSuggestionOrCloseDialog}
        />
      )}
    </Grid>
  );
}
