import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import ActiveCheckbox from './ActiveCheckbox';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import CommonTooltip from 'components/CommonTooltip';
import { ClusterRow, DeleteButton, EditButton } from './components';
import { ReuseDomainCluster } from '../../types';
import { UpdateActiveFn } from './hooks';

export interface EditableClusterRowProps {
  cluster: ReuseDomainCluster;
  updateActive: UpdateActiveFn;
  openReplacementDrawer: () => void;
  openDeleteDialog: () => void;
}

export default function EditableClusterRow({
  cluster,
  updateActive,
  openReplacementDrawer,
  openDeleteDialog,
}: EditableClusterRowProps) {
  const { t } = useTranslation();

  return (
    <ClusterRow>
      <Grid container justifyContent="space-between" flexWrap="nowrap">
        <ActiveCheckbox updateActive={updateActive} cluster={cluster} />
        <Grid
          flexWrap="nowrap"
          display="inline-flex"
          sx={(theme) => ({
            marginLeft: theme.spacing(0.8),
          })}
        >
          <CopyToClipboardButton
            text={cluster.preferredPhrase.phrase}
            copyTooltip={t('reuse.tooltips.copyPreferredWording')}
          />
          <CommonTooltip
            title={t('reuse.tooltips.editReplacement') || ''}
            placement="bottom"
          >
            <EditButton onClick={openReplacementDrawer}>
              <EditRoundedIcon color="primary" />
            </EditButton>
          </CommonTooltip>
          <CommonTooltip title={t('shared.actions.delete')}>
            <DeleteButton onClick={openDeleteDialog}>
              <DeleteOutlineIcon />
            </DeleteButton>
          </CommonTooltip>
        </Grid>
      </Grid>
    </ClusterRow>
  );
}
