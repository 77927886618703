import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

import CommonTooltip from 'components/CommonTooltip';
import { ActionIconsWrapper } from 'components/IndexTable/components';
import { ReuseIndexDomain } from 'sections/Reuse/types';

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  '& svg:hover': {
    fill: theme.palette.error.main,
  },
}));

interface DomainIconsProps {
  actions: {
    setDomainForDeleteConfirmation: (domain: ReuseIndexDomain) => void;
  };
}

export default function createDomainIcons({ actions }: DomainIconsProps) {
  return function ({ element: domain }: { element: ReuseIndexDomain }) {
    const { t } = useTranslation();

    return (
      <ActionIconsWrapper>
        <CommonTooltip title={<>{t('reuse.tooltips.deleteDomain')}</>}>
          <StyledDeleteButton
            aria-label={t('reuse.tooltips.deleteDomain')}
            onClick={() => actions.setDomainForDeleteConfirmation(domain)}
            disableRipple
            size="large"
          >
            <DeleteOutlineIcon />
          </StyledDeleteButton>
        </CommonTooltip>
      </ActionIconsWrapper>
    );
  };
}
