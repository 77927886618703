import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';

import useResize from 'hooks/useResize';
import AuthContext from 'contexts/AuthContext';
import { requestResponseOrThrowError } from 'utils/fetch';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import {
  TARGET_EVENT_PER_PAGE,
  TARGETS_RECENT_ACTIVITIES_PATH,
  TARGETS_RECENT_ACTIVITIES_QUERY_KEY,
} from 'sections/Targets/constants';
import { RecentActivityData } from './types';
import { useDebounce } from 'utils/utils';

export interface UseScrollableListResult {
  listHeight: number;
  resizeElement: HTMLDivElement | null;
  scrollStyles: CSSProperties | undefined;
  setResizeElement: (resizeTarget: HTMLDivElement | null) => void;
}

/* istanbul ignore next */
export function useScrollableList(
  numberToBeSubtracted: number = 0
): UseScrollableListResult {
  const { setResizeElement, resizeElement, resizedHeight } =
    useResize<HTMLDivElement>();
  const [scrollStyles, setScrollStyles] = useState<CSSProperties | undefined>(
    undefined
  );

  useEffect(() => {
    const rect = resizeElement?.getBoundingClientRect();

    if (rect?.bottom && rect.bottom > window.innerHeight) {
      setScrollStyles({
        maxHeight: window.innerHeight - rect.top - numberToBeSubtracted + 'px',
        overflow: 'scroll',
      });
    }
  }, [numberToBeSubtracted, resizeElement, resizedHeight]);

  const listHeight =
    window.innerHeight -
    (resizeElement?.getBoundingClientRect()?.top || 0) -
    20;

  return {
    listHeight,
    resizeElement,
    scrollStyles,
    setResizeElement,
  };
}

export interface RecentActivitiesPaginated {
  activities: RecentActivityData[];
  pageNumber: number;
  totalPages: number;
}

export interface UseRecentActivitiesResult
  extends Omit<RecentActivitiesPaginated, 'activities' | 'pageNumber'> {
  page: number;
  recentActivitiesList: RecentActivityData[];
  setPage: Dispatch<SetStateAction<number>>;
}

export function useRecentActivities(): UseRecentActivitiesResult {
  const [page, setPage] = useState(1);
  const [authToken] = useContext(AuthContext);
  const [searchParams] = useDebounce([page], 1000);

  async function fetchTargetsRecentActivities() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGETS_RECENT_ACTIVITIES_PATH}?pageSize=${TARGET_EVENT_PER_PAGE}&pageNumber=${page}`
    );
  }

  const { data } = useQuery<RecentActivitiesPaginated, Error>({
    queryKey: [TARGETS_RECENT_ACTIVITIES_QUERY_KEY, searchParams],
    queryFn: fetchTargetsRecentActivities,
    refetchOnMount: true,
    cacheTime: 0,
    keepPreviousData: true,
    staleTime: 1000,
  });

  return {
    recentActivitiesList: data?.activities ?? [],
    page,
    setPage,
    totalPages: data?.totalPages ?? 1,
  };
}
