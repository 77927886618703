import React, { ChangeEvent, useEffect, useState } from 'react';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { grey, blueGrey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import CommonRadioGroup from 'components/CommonRadioGroup';
import { TerminologyGuidelineParameterValueObject } from './hooks';
import { StyledAccordionSummary as AccordionSummary } from 'components/FixedAccordion';
import TransWrapper from 'components/TransWrapper';
import { calculateAdmittedTermsValue } from './helpers';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  paddingTop: theme.spacing(2),
  marginTop: theme.spacing(0),
  '&.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    marginRight: theme.spacing(-2),
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: grey[50],
  borderTop: `2px solid ${grey[200]}`,
  boxShadow: 'none',
  width: '100%',
  borderTopLeftRadius: theme.spacing(0),
  borderTopRightRadius: theme.spacing(0),
  '&.MuiPaper-root': {
    borderTopLeftRadius: theme.spacing(0),
    borderTopRightRadius: theme.spacing(0),
  },
  '&.MuiAccordion-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  [`& .${accordionClasses.disabled}`]: {
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
    '& svg': {
      opacity: 0.4,
    },
  },
}));

const StyledAdvancedSettingsHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.32),
}));

const StyledAdmittedTerms = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.28),
  marginRight: theme.spacing(1),
  padding: theme.spacing(3, 0, 6),
}));

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: blueGrey[400],
  borderRadius: theme.typography.pxToRem(25),
  '&$expanded': {
    marginRight: theme.spacing(-2),
  },
  '&:hover': {
    backgroundColor: 'white',
  },
  '&.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    marginRight: theme.spacing(-2),
  },
}));

interface AdvancedSettingsProps {
  disabled: boolean;
  expandedGoals: boolean;
  parameterValueObj: TerminologyGuidelineParameterValueObject;
  preserveChanges: (
    evt: ChangeEvent<{ value?: unknown }>,
    checked: boolean
  ) => void;
  readOnly: boolean;
}

export function AdvancedSettings({
  disabled,
  expandedGoals,
  parameterValueObj,
  preserveChanges,
  readOnly,
}: AdvancedSettingsProps): JSX.Element {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const data = [
    // first row
    {
      label: (
        <TransWrapper i18nKey="capture.descriptions.requiredAndHighlighted" />
      ),
      value: '101',
      key: '1',
      ...(readOnly && { disabled: readOnly }),
    },
    {
      label: (
        <TransWrapper i18nKey="capture.descriptions.recommendedAndSuggested" />
      ),
      value: '010',
      key: '3',
      ...(readOnly && { disabled: readOnly }),
    },
    // second row
    {
      label: (
        <TransWrapper i18nKey="capture.descriptions.recommendedAndHighlighted" />
      ),
      value: '001',
      key: '2',
      ...(readOnly && { disabled: readOnly }),
    },
    {
      label: <TransWrapper i18nKey="capture.descriptions.recommendedOnly" />,
      value: '000',
      key: '4',
      ...(readOnly && { disabled: readOnly }),
    },
  ];

  useEffect(() => {
    if (expandedGoals) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandedGoals]);

  return (
    <StyledAccordion
      TransitionProps={{ unmountOnExit: true }}
      disabled={disabled}
      expanded={expanded}
      onChange={(e, open) => {
        setExpanded(open);
      }}
    >
      <StyledAccordionSummary
        sx={(theme) => ({ paddingRight: theme.spacing(0) })}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls="advanced-settings-content"
        id="advanced-settings-summary"
      >
        <StyledAdvancedSettingsHeading variant="h4">
          {t('capture.titles.advancedSettings')}
        </StyledAdvancedSettingsHeading>
      </StyledAccordionSummary>
      <AccordionDetails sx={(theme) => ({ padding: theme.spacing(0) })}>
        <Grid container direction="column">
          <Grid item container direction="row" alignItems="center">
            <StyledAdmittedTerms variant="body2">
              {t('capture.descriptions.admittedTerms')}
            </StyledAdmittedTerms>
          </Grid>
          <Grid sx={(theme) => ({ marginLeft: theme.spacing(-1.6) })} item>
            <CommonRadioGroup
              data={data}
              changeHandler={preserveChanges}
              value={calculateAdmittedTermsValue(parameterValueObj)}
              numberOfColumns={2}
              sx={(theme) => ({
                marginRight: theme.spacing(4),
              })}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export default AdvancedSettings;
