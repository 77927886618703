export const TERM_FILTER_PREFIX = 'filter_';
export const DOMAIN_PREFIX = 'domain_';

export const CUSTOM = 'custom';
export const PREDEFINED_CUSTOM = 'PREDEFINED_CUSTOM';
export const PRESET_TYPES = {
  predefinedDefault: 'predefined_default',
  predefined: 'predefined',
  custom: CUSTOM,
};

export const WORDS_AND_PHRASES_GOAL_NAME = 'words_and_phrases';
export const WORDS_AND_PHRASES_GUIDELINE_TYPE = 'words_and_phrases';
export const REUSE_GUIDELINE_TYPE = 'reuse_sentence';
export const TEXT_TYPE = 'text';
export const REUSE_GL = 'reuse';
export const WAP_GL = 'wap';

export const TARGET_ASSIGNMENT_INDEX_PATH = 'target-assignments';
export const DOCUMENT_CUSTOM_FIELDS_PATH = 'document-custom-fields';
export const USER_CUSTOM_FIELDS_PATH = 'custom-fields';
export const TARGET_PATH = 'targets/:id';
export const TARGETS_PATH = 'targets';
export const USERS_PATH = 'users';
export const GOALS_BY_LANGUAGE_PATH = 'goals/:languageId';
export const ROLES_PATH = 'roles';
export const MAX_TARGET_DESCRIPTION_LENGTH = 150;
export const DOCUMENT_CUSTOM_FIELD_CRITERIA_PATH =
  'document-custom-field-criteria';
export const DOCUMENT_REFERENCE_CRITERIA_PATH = 'document-reference-criteria';
export const RELOAD_LS_PATH = 'reload-language-servers';

export const TARGET_QUERY_KEY = 'fetch target';
export const GOALS_QUERY_KEY = 'fetch goals';
