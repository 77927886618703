import { TargetIndexItem } from 'types/types';

export function getUnassignedTargets(targets: TargetIndexItem[]) {
  return targets
    .filter((it) => !it.assigned)
    .map((target) => ({
      targetUuid: target.uuid,
      targetName: target.displayName,
      assignedToAll: false,
      userUuids: [],
      roleUuids: [],
      customFields: [],
      documentCustomFieldCriteria: [],
      documentReferenceCriteria: [],
      enableLanguageDetection: false,
      priority: 0,
      targetLanguageAbbreviation: target.languageId.split(/[:-]/)[0],
    }));
}
