import React, { useCallback } from 'react';
import CommonTooltip from 'components/CommonTooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCopyToCliboardButton } from './hooks';
import { StyledIconButton } from './styles';
import { t } from 'i18next';

export interface CopyToClipboardButtonProps {
  text: string;
  copyTooltip?: string;
  copiedTooltip?: string;
}

export default function CopyToClipboardButton({
  text,
  copyTooltip = t('shared.tooltips.copyToClipboard'),
  copiedTooltip = t('shared.tooltips.copiedToClipboard'),
}: CopyToClipboardButtonProps) {
  const { copied, copyText } = useCopyToCliboardButton();

  const clickHandler = useCallback(() => {
    copyText(text);
  }, [copyText, text]);

  return (
    <CommonTooltip
      title={copied ? copiedTooltip : copyTooltip}
      placement="bottom-start"
    >
      <StyledIconButton onClick={clickHandler}>
        {copied ? (
          <CheckCircleIcon
            sx={(theme) => ({
              fill: '#00C853',
              height: theme.spacing(6.4),
              width: theme.spacing(6.4),
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(5),
            })}
          />
        ) : (
          <ContentCopyIcon
            sx={(theme) => ({ padding: theme.spacing(0.4) })}
            color="primary"
          ></ContentCopyIcon>
        )}
      </StyledIconButton>
    </CommonTooltip>
  );
}
