import React, { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CommonTooltip from 'components/CommonTooltip';
import ConditionalWrapper from 'components/ConditionalWrapper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&:focus-within': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export interface CustomTableCellProps extends TableCellProps {
  width?: number | string;
  maxWidth?: number | string;
  value?: string;
  tooltipContent?: string | boolean | ReactElement;
  tooltipTypeYellow?: boolean;
}

const CustomTableCell = React.forwardRef(
  (
    {
      children,
      width,
      maxWidth,
      tooltipContent,
      tooltipTypeYellow,
      sx,
      ...props
    }: CustomTableCellProps,
    ref
  ) => {
    const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);

    return (
      <StyledTableCell ref={ref} {...props} sx={{ px: 2, py: 13 / 5, ...sx }}>
        <ConditionalWrapper
          condition={!!tooltipContent}
          wrapper={(wrapperChildren: ReactElement) => (
            <CommonTooltip
              {...(tooltipTypeYellow && { type: 'yellow' })}
              title={tooltipContent || ''}
              placement="bottom-start"
              open={hasOverflowingChildren}
              onOpen={(event) => {
                const target = event.target as HTMLElement;

                setHasOverflowingChildren(
                  target.scrollWidth > target.clientWidth
                );
              }}
              onClose={() => {
                setHasOverflowingChildren(false);
              }}
              disableFocusListener
              disableTouchListener
              describeChild
            >
              {wrapperChildren}
            </CommonTooltip>
          )}
        >
          <Box
            {...(width && { width })}
            {...(maxWidth && { maxWidth })}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {children}
          </Box>
        </ConditionalWrapper>
      </StyledTableCell>
    );
  }
);

export default CustomTableCell;
