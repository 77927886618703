export const LANGUAGE_COOKIE_NAME = 'acrolinx_user_locale';
export const FALLBACK_LANGUAGE = 'en';

export const ANALYTICS_EDIT_PRIVILEGE = 'Reporting.create';
export const ANALYTICS_READ_PRIVILEGE = 'Reporting.read';
export const TARGETS_EDIT_PRIVILEGE = 'LinguisticConfiguration.editTargets';
export const TARGETS_READ_PRIVILEGE = 'LinguisticConfiguration.viewTargets';
export const USERS_EDIT_PRIVILEGE = 'UserAndRoles.editUser';
export const USERS_READ_PRIVILEGE = 'UserAndRoles.read';
export const TERMINOLOGY_EDIT_PRIVILEGE = 'Terminology.edit';
export const TERMINOLOGY_READ_PRIVILEGE = 'Terminology.read';
