import {
  ClusterRow,
  ReplacementText,
  StyledReadonlyReplacement,
} from './components';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton';
import React, { KeyboardEvent } from 'react';
import { ReuseDomainCluster } from '../../types';
import { useTranslation } from 'react-i18next';

export interface ReadonlyClusterRowProps {
  cluster: ReuseDomainCluster;
  openReplacementDrawer: () => void;
  openOnKeyboardEvent: (event: KeyboardEvent<HTMLDivElement>) => void;
}

export default function ReadonlyClusterRow({
  cluster,
  openReplacementDrawer,
  openOnKeyboardEvent,
}: ReadonlyClusterRowProps) {
  const { t } = useTranslation();
  return cluster.active ? (
    <ClusterRow key={cluster.uuid}>
      <StyledReadonlyReplacement
        tabIndex={0}
        direction="row"
        container
        wrap="nowrap"
        justifyContent="space-between"
      >
        <ReplacementText
          onClick={openReplacementDrawer}
          onKeyDown={openOnKeyboardEvent}
          tabIndex={0}
          role="button"
        >
          {cluster.preferredPhrase.phrase}
        </ReplacementText>
        <CopyToClipboardButton
          text={cluster.preferredPhrase.phrase}
          copyTooltip={t('reuse.tooltips.copyPreferredWording')}
        />
      </StyledReadonlyReplacement>
    </ClusterRow>
  ) : (
    <></>
  );
}
