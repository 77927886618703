import React from 'react';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { DataForRadioGroup } from './types';
import { TargetAssignment } from '../../../../types';

export default function OptionRadioGroup({
  data,
  target,
  setNewTarget,
}: {
  data: DataForRadioGroup;
  target: TargetAssignment;
  setNewTarget: (target: TargetAssignment) => void;
}): JSX.Element {
  const value = target.enableLanguageDetection ? 'on' : 'off';

  return (
    <CommonRadioGroup
      data={data.options}
      value={value}
      changeHandler={(ev) =>
        setNewTarget({
          ...target,
          enableLanguageDetection: ev.target.value === 'on',
        })
      }
    ></CommonRadioGroup>
  );
}
