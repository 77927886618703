import React, { ChangeEvent } from 'react';

import { StyledAllLabel } from './components';
import { CheckboxValue } from 'types/types';
import { CheckboxProps } from '@mui/material/Checkbox';
import CheckboxWithLabel from '../CommonCheckboxWithLabel';

interface AllCheckboxBaseProps {
  allChecked: boolean;
  allIndeterminate: boolean;
  amountChecked?: number;
  disabled: boolean;
  filteredValues?: CheckboxValue[];
  label: string;
}

interface AllCheckboxInListProps extends AllCheckboxBaseProps {
  localValues: Set<CheckboxValue>;
  setLocalValues: (v: Set<CheckboxValue>) => void;
}

interface AllCheckboxStandaloneProps extends AllCheckboxBaseProps {
  handleChangeFn: CheckboxProps['onChange'];
}

export default function AllCheckbox({
  allChecked,
  allIndeterminate,
  amountChecked,
  disabled,
  filteredValues,
  label,
  ...rest
}: AllCheckboxInListProps | AllCheckboxStandaloneProps) {
  const filteredNumber =
    filteredValues?.length ||
    (rest as AllCheckboxInListProps).localValues?.size;
  const checkedString =
    typeof amountChecked === 'undefined'
      ? ''
      : `(${amountChecked}/${filteredNumber})`;

  const handleChange =
    (rest as AllCheckboxStandaloneProps).handleChangeFn ||
    function (event: ChangeEvent<HTMLInputElement>, checked: boolean) {
      (rest as AllCheckboxInListProps)?.localValues &&
        (rest as AllCheckboxInListProps)?.setLocalValues(
          new Set(
            Array.from((rest as AllCheckboxInListProps).localValues)?.map(
              (v) => {
                const matchingValue = filteredValues
                  ? filteredValues.find((fv) => fv.id === v.id)
                  : v;

                const newChecked = matchingValue ? checked : v.checked;

                return {
                  ...v,
                  checked: v.disabled ? v.checked : newChecked,
                };
              }
            )
          )
        );
    };

  return (
    <>
      <CheckboxWithLabel
        label={<StyledAllLabel>{`${label} ${checkedString}`}</StyledAllLabel>}
        disabled={disabled}
        checked={allChecked}
        indeterminate={allIndeterminate}
        onChange={handleChange}
      />
      <div style={{ marginBottom: '3px' }} />
    </>
  );
}
