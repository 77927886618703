import React from 'react';
import { ReuseIndexDomain } from '../../types';
import { UseClusterDomainsResult } from './hooks';
import DomainChip from 'components/DomainChip';

export interface DomainChipsProps {
  clusterDomains: UseClusterDomainsResult;
  domainList: ReuseIndexDomain[];
  readOnly?: boolean;
}

export default function DomainChips({
  clusterDomains,
  domainList,
  readOnly = false,
}: DomainChipsProps) {
  const sortedClusterDomains = domainList.filter(({ uuid }) =>
    clusterDomains.domains.find((domainId) => uuid === domainId)
  );

  return (
    <>
      {sortedClusterDomains.map(({ uuid, displayName }) => (
        <DomainChip
          key={uuid}
          domainName={displayName}
          label={displayName}
          onDelete={
            readOnly ? undefined : () => clusterDomains.removeDomain(uuid)
          }
          {...(readOnly
            ? {
                sx: (theme) => ({
                  marginLeft: 0,
                  marginBottom: 0,
                  marginRight: theme.spacing(1),
                }),
              }
            : {})}
        />
      ))}
    </>
  );
}
