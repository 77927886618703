import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: theme.typography.pxToRem(29),
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.custom.lighterGrey,
  fontSize: theme.typography.pxToRem(13),
  letterSpacing: theme.typography.pxToRem(0.26),
  color: theme.palette.custom.grey94,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.custom.grey96,
  },
}));
