import React from 'react';

import SuggestionActionButtonPanel from './SuggestionActionButtonPanel';
import { StyledSuggestionCard, SuggestionDetailLabel } from './components';
import SuggestionDetails from './SuggestionDetails';
import {
  ReuseIndexDomain,
  ReuseSuggestion,
  SuggestionDataType,
} from 'sections/Reuse/types';
import SuggestionPreferredPhrase from './SuggestionPreferredPhrase';
import SuggestionUpdateDetails from './SuggestionUpdateDetails';
import { UseReuseInboxResult } from '../../hooks';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import { LanguageFull } from 'types/types';

export interface SuggestionCardProps {
  data: ReuseSuggestion;
  handleOnClick: UseReuseInboxResult['openReplacementDrawer'];
  languages: LanguageFull[];
  showLanguageLabelOnSuggestionCard: boolean;
  sortedDomains?: ReuseIndexDomain[];
  refreshSuggestionList: UseReuseInboxResult['refreshSuggestionList'];
}

export default function SuggestionCard({
  data,
  handleOnClick,
  languages,
  showLanguageLabelOnSuggestionCard,
  sortedDomains,
  refreshSuggestionList,
}: SuggestionCardProps) {
  const { t } = useTranslation();

  return (
    <StyledSuggestionCard>
      <SuggestionActionButtonPanel
        data={data}
        handleOnClick={handleOnClick}
        refreshSuggestionList={refreshSuggestionList}
      />
      {data.type === SuggestionDataType.AMEND ? (
        <SuggestionUpdateDetails
          data={data.cluster}
          deprecatedPhrases={data.deprecatedPhrases}
        />
      ) : (
        <Grid>
          <SuggestionPreferredPhrase data={data.cluster} />
          <Grid>
            {data.deprecatedPhrases.length ? (
              <SuggestionDetailLabel
                sx={(theme) => ({ marginTop: theme.spacing(2) })}
              >
                {t('reuseInbox.descriptions.unsuitableWording', {
                  count: data.deprecatedPhrases.length,
                })}
              </SuggestionDetailLabel>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      )}

      <SuggestionDetails
        data={data}
        languages={languages}
        sortedDomains={sortedDomains}
        showLanguageLabelOnSuggestionCard={showLanguageLabelOnSuggestionCard}
      />
    </StyledSuggestionCard>
  );
}
