import Box, { BoxProps } from '@mui/material/Box';
import React, { ReactElement, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Typography, { typographyClasses } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle';

const CustomTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.24),
  lineHeight: theme.typography.pxToRem(36),
  [`&.${typographyClasses.gutterBottom}`]: {
    marginBottom: theme.spacing(3),
  },
}));

export interface PageWrapperProps extends BoxProps {
  pageTitle: string;
  children: React.ReactNode;
  displayPageMainHeading?: boolean;
}

const PageContentWrapper = ({
  pageTitle,
  children,
  displayPageMainHeading = true,
  ...props
}: PageWrapperProps): ReactElement => {
  const { t } = useTranslation();
  useTitle(`${pageTitle} - ${t('shared.app.name')}`);

  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  return (
    <Box
      {...props}
      sx={(theme) => ({
        padding: theme.spacing(8),
        paddingTop: theme.spacing(19 / 5),
      })}
    >
      {displayPageMainHeading && (
        <CustomTypography
          variant="h1"
          gutterBottom
          ref={headingRef}
          tabIndex={-1}
          sx={{ '&:focus': { outline: 'none' } }}
        >
          {pageTitle}
        </CustomTypography>
      )}
      {children}
    </Box>
  );
};

export default PageContentWrapper;
