import React, { useEffect, useState } from 'react';
import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import { ReuseDomainCluster } from '../../types';
import { UpdateActiveFn } from './hooks';

export interface ActiveCheckboxProps {
  cluster: ReuseDomainCluster;
  updateActive: UpdateActiveFn;
}

export default function ActiveCheckbox({
  cluster,
  updateActive,
}: ActiveCheckboxProps) {
  const [checked, setChecked] = useState<boolean>(cluster.active);

  useEffect(() => {
    setChecked(cluster.active);
  }, [cluster.active]);

  return (
    <CommonCheckboxWithLabel
      lang={cluster.language}
      checked={checked}
      label={cluster.preferredPhrase.phrase}
      onClick={(evt) => {
        const checked = (evt.target as HTMLInputElement).checked;
        updateActive(cluster.uuid as string, checked);
        setChecked(checked);
      }}
    />
  );
}
