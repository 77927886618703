import { useTranslation } from 'react-i18next';

import { IndexTableConfig } from 'components/IndexTable';
import { UseSortResult } from 'hooks/useSort';

export interface UseDomainTableResult {
  reuseTableConfig: IndexTableConfig[];
}

export function useDomainTable(sortState: UseSortResult): UseDomainTableResult {
  const { t } = useTranslation();
  const { sortField, sortOrder, setSorting } = sortState;

  const reuseTableConfig = [
    {
      title: t('reuse.columns.domainName'),
      active: sortField === 'displayName',
      direction: sortOrder,
      onClick: () => setSorting('displayName'),
      renderConfig: {
        idPropertyName: 'uuid',
        valuePropertyName: 'displayName',
        urlBase: './reuse/',
      },
    },
    // {
    //   title: t('reuse.columns.activeIn'),
    //   renderConfig: {
    //     valuePropertyName: '',
    //   }
    // },
    {
      title: t('reuse.columns.description'),
      renderConfig: {
        valuePropertyName: 'description',
      },
    },
    {
      renderConfig: {
        icons: true,
      },
    },
  ];

  return {
    reuseTableConfig,
  };
}
