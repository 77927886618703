import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';

import AuthContext from 'contexts/AuthContext';
import { UseReuseInboxResult } from '../../hooks';
import { ReuseTargetSettings } from 'sections/Reuse/types';
import { fetchJsonFromAPI, HttpMethod } from 'utils/fetch';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import { REUSE_HARVESTING_TARGETS_PATH } from 'sections/Reuse/constants';
import { CheckboxValue } from 'types/types';
import { TargetSettingsDrawerProps } from './TargetSettingsDrawer';

export interface UseTargetSettingsResult {
  checkboxesHeight: number;
  filterByText: (evt: ChangeEvent<HTMLInputElement>) => void;
  filteredTargets: CheckboxValue[];
  handleChange: (values: Set<CheckboxValue>) => void;
  refCheckboxes: MutableRefObject<HTMLFieldSetElement | null>;
  setDrawerFooterRect: Dispatch<SetStateAction<DOMRect | null>>;
  settingsWereModified: boolean;
  submitButtonClickHandler: () => Promise<void>;
  targetsForHarvesting: CheckboxValue[];
}

export function useTargetSettings({
  closeDrawer,
  refreshTargets,
  targetValues,
}: TargetSettingsDrawerProps): UseTargetSettingsResult {
  const [authToken] = useContext(AuthContext);
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );
  const refCheckboxes = useRef<HTMLFieldSetElement | null>(null);
  const [targetsForHarvesting, setTargetsForHarvesting] =
    useState<UseReuseInboxResult['targetValues']>(targetValues);
  const [filteredTargets, setFilteredTargets] =
    useState<UseReuseInboxResult['targetValues']>(targetValues);
  const [checkboxesHeight, setCheckboxesHeight] = useState<number>(0);

  useEffect(() => {
    if (refCheckboxes.current && drawerFooterRect) {
      setCheckboxesHeight(
        drawerFooterRect?.top -
          refCheckboxes.current?.getBoundingClientRect()?.top
      );
    }
  }, [drawerFooterRect]);

  useEffect(() => {
    setTargetsForHarvesting(targetValues);
    setFilteredTargets(targetValues);
  }, [targetValues]);

  const saveTargetSettings = useCallback(
    async (data: CheckboxValue[]) => {
      const body: ReuseTargetSettings[] = data.map((v) => ({
        uuid: v.id,
        allowHarvesting: v.checked,
      }));

      await fetchJsonFromAPI(
        authToken,
        `${TARGET_SERVICE_PATH}/${REUSE_HARVESTING_TARGETS_PATH}`,
        { method: HttpMethod.PUT, body: JSON.stringify(body) }
      );

      refreshTargets();
    },
    [authToken, refreshTargets]
  );

  const handleChange = useCallback(
    (values: Set<CheckboxValue>) => {
      setTargetsForHarvesting(Array.from(values));
    },
    [setTargetsForHarvesting]
  );

  const submitButtonClickHandler = useCallback(async () => {
    await saveTargetSettings(targetsForHarvesting);
    closeDrawer();
  }, [closeDrawer, saveTargetSettings, targetsForHarvesting]);

  const settingsWereModified = !isEqual(
    targetValues,
    targetsForHarvesting.map(({ originalIndex, ...obj }) => obj)
  );

  const filterByText = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setFilteredTargets(
        evt.target.value
          ? targetsForHarvesting.filter(({ label }) =>
              label.toLowerCase().includes(evt.target.value.toLowerCase())
            )
          : targetsForHarvesting
      );
    },
    [targetsForHarvesting]
  );

  return {
    checkboxesHeight,
    filterByText,
    filteredTargets,
    handleChange,
    refCheckboxes,
    setDrawerFooterRect,
    settingsWereModified,
    submitButtonClickHandler,
    targetsForHarvesting,
  };
}
