import { useContext, useState } from 'react';
import PrivilegesContext from 'contexts/PrivilegesContext';

import {
  ANALYTICS_READ_PRIVILEGE,
  ANALYTICS_EDIT_PRIVILEGE,
  TARGETS_EDIT_PRIVILEGE,
  TARGETS_READ_PRIVILEGE,
  TERMINOLOGY_READ_PRIVILEGE,
  TERMINOLOGY_EDIT_PRIVILEGE,
  USERS_EDIT_PRIVILEGE,
  USERS_READ_PRIVILEGE,
} from '../constants';

export interface PrivilegesStructure {
  editRights: boolean;
  readRights: boolean;
}

export function useTargetsPrivileges() {
  const privileges = useContext(PrivilegesContext);
  const [userRights] = useState<PrivilegesStructure>(() => ({
    editRights: privileges.includes(TARGETS_EDIT_PRIVILEGE),
    readRights: privileges.includes(TARGETS_READ_PRIVILEGE),
  }));

  return userRights;
}

export function useUserManagementPrivileges() {
  const privileges = useContext(PrivilegesContext);
  const [userRights] = useState<PrivilegesStructure>(() => ({
    editRights: privileges.includes(USERS_EDIT_PRIVILEGE),
    readRights: privileges.includes(USERS_READ_PRIVILEGE),
  }));

  return userRights;
}

export function useTerminologyPrivileges() {
  const privileges = useContext(PrivilegesContext);
  const [userRights] = useState<PrivilegesStructure>(() => ({
    editRights: privileges.includes(TERMINOLOGY_EDIT_PRIVILEGE),
    readRights: privileges.includes(TERMINOLOGY_READ_PRIVILEGE),
  }));

  return userRights;
}

export function useAnalyticsPrivileges() {
  const privileges = useContext(PrivilegesContext);
  const [userRights] = useState<PrivilegesStructure>(() => ({
    editRights: privileges.includes(ANALYTICS_EDIT_PRIVILEGE),
    readRights: privileges.includes(ANALYTICS_READ_PRIVILEGE),
  }));

  return userRights;
}
