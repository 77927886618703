import React from 'react';
import { SliderProps } from '@mui/material/Slider';
import Grid from '@mui/material/Grid';

import { GeneralParameterProps } from '../GuidelineSection/Parameter';
import { createValueText } from './helpers';
import {
  StyledParameterTextField,
  StyledSlider,
  StyledSpace,
} from './components';
import { useSliderWithTextFields } from './hooks';
import { StyledGuidelineParameterLabelReadOnlySlider } from '../GuidelineSection/styles';
import CommonTooltip from 'components/CommonTooltip';

export interface TextFields {
  tooltips: [string, string];
  labels: [string, string];
  values: [number, number];
}

export interface CustomSliderProps {
  parameterProps: GeneralParameterProps;
  textFields: TextFields;
}

export type SliderWithTextFieldsProps = CustomSliderProps & SliderProps;

export default function SliderWithTextFields({
  textFields,
  parameterProps,
  ...props
}: SliderWithTextFieldsProps) {
  const {
    getSliderAriaLabel,
    handleFirstFieldBlur,
    handleFirstFieldChange,
    handleSecondFieldBlur,
    handleSecondFieldChange,
    handleSliderChange,
    maxTextValue,
    minTextValue,
    parameterObj,
  } = useSliderWithTextFields({ textFields, parameterProps });
  const { tooltips, labels, values } = textFields;

  return (
    <Grid container flexWrap="nowrap">
      {parameterProps.readOnly ? (
        <CommonTooltip title={tooltips[0]} type="yellow">
          <StyledGuidelineParameterLabelReadOnlySlider>
            {minTextValue}
          </StyledGuidelineParameterLabelReadOnlySlider>
        </CommonTooltip>
      ) : (
        <StyledParameterTextField
          tooltipText={tooltips[0]}
          aria-label={labels[0]}
          placeholder=""
          value={minTextValue}
          required={true}
          variant="outlined"
          onChange={handleFirstFieldChange}
          onBlur={handleFirstFieldBlur}
        />
      )}
      <StyledSpace />
      <StyledSlider
        value={values}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        getAriaValueText={createValueText}
        min={parameterObj.rangeMin}
        max={parameterObj.rangeMax}
        getAriaLabel={getSliderAriaLabel}
        disabled={parameterProps.readOnly}
        {...props}
      />
      <StyledSpace />
      {parameterProps.readOnly ? (
        <CommonTooltip title={tooltips[1]} type="yellow">
          <StyledGuidelineParameterLabelReadOnlySlider>
            {maxTextValue}
          </StyledGuidelineParameterLabelReadOnlySlider>
        </CommonTooltip>
      ) : (
        <StyledParameterTextField
          tooltipText={tooltips[1]}
          aria-label={labels[1]}
          placeholder=""
          value={maxTextValue}
          required={true}
          variant="outlined"
          onChange={handleSecondFieldChange}
          onBlur={handleSecondFieldBlur}
        />
      )}
    </Grid>
  );
}
