import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GuidelineRangeVoiceParameter, GuidelineValue } from '../../types';
import {
  createHandleBlur,
  createHandleChange,
  isValueDuringEdition,
} from './helpers';
import { createUpdate } from '../GuidelineSection/helpers';
import { CustomSliderProps } from './SliderWithTextFields';

export interface UseSliderWithTextFieldsResult {
  getSliderAriaLabel: (index: number) => string;
  handleFirstFieldBlur: (ev: FocusEvent<HTMLInputElement>) => void;
  handleFirstFieldChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  handleSecondFieldBlur: (ev: FocusEvent<HTMLInputElement>) => void;
  handleSecondFieldChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  handleSliderChange: (event: Event, newValue: number | number[]) => void;
  maxTextValue: string;
  minTextValue: string;
  parameterObj: GuidelineRangeVoiceParameter;
}

export function useSliderWithTextFields({
  textFields: { values },
  parameterProps,
}: CustomSliderProps): UseSliderWithTextFieldsResult {
  const { t } = useTranslation();
  const lastValues = useRef<[number, number]>(values);
  const [minTextValue, setMinTextValue] = useState<string>(
    values[0].toString()
  );
  const [maxTextValue, setMaxTextValue] = useState<string>(
    values[1].toString()
  );
  const parameterObj = parameterProps.guideline
    .parameter as GuidelineRangeVoiceParameter;
  const parameterValue = (parameterProps.guidelineConfig as GuidelineValue)
    .parameterValue as string;

  useEffect(() => {
    if (
      (!isValueDuringEdition(
        minTextValue,
        lastValues.current[0],
        parameterObj
      ) &&
        !isValueDuringEdition(
          maxTextValue,
          lastValues.current[1],
          parameterObj
        )) ||
      !parameterProps.modified
    ) {
      setMinTextValue(values[0].toString());
      setMaxTextValue(values[1].toString());
    }

    if (!parameterProps.modified) {
      lastValues.current = values;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lastValues, parameterProps.modified]);

  useEffect(() => {
    setMinTextValue(values[0].toString());
    setMaxTextValue(values[1].toString());
    lastValues.current = values;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [parameterProps.targetGoal.selectedPresetId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFirstFieldChange = useCallback(
    createHandleChange(0, parameterProps, setMinTextValue, values),
    [parameterProps, values]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSecondFieldChange = useCallback(
    createHandleChange(1, parameterProps, setMaxTextValue, values),
    [parameterProps, values]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFirstFieldBlur = useCallback(
    createHandleBlur(
      0,
      lastValues,
      parameterObj,
      parameterProps,
      setMinTextValue,
      values
    ),
    [lastValues, parameterObj, parameterProps, values]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSecondFieldBlur = useCallback(
    createHandleBlur(
      1,
      lastValues,
      parameterObj,
      parameterProps,
      setMaxTextValue,
      values
    ),
    [lastValues, parameterObj, parameterProps, values]
  );
  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      if (parameterValue === JSON.stringify(newValue)) {
        return;
      }

      createUpdate(parameterProps, JSON.stringify(newValue));
      setMinTextValue((newValue as number[])[0].toString());
      setMaxTextValue((newValue as number[])[1].toString());
      lastValues.current = newValue as [number, number];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parameterProps]
  );
  const getSliderAriaLabel = useCallback(
    (index: number) =>
      index
        ? t('capture.a11y.parameterSliderIntegerrangeMax')
        : t('capture.a11y.parameterSliderIntegerrangeMin'),
    [t]
  );

  return {
    getSliderAriaLabel,
    handleFirstFieldBlur,
    handleFirstFieldChange,
    handleSecondFieldBlur,
    handleSecondFieldChange,
    handleSliderChange,
    maxTextValue,
    minTextValue,
    parameterObj,
  };
}
