import React, { ReactElement, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { pink } from '@mui/material/colors';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useFetch } from 'utils/fetch';
import { useTranslation } from 'react-i18next';

import type { UserTypeExtended } from '../UsersTable/components/UsersTableBodyRow';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';

interface ConfirmationDialogDeleteProps {
  open: boolean;
  item: UserTypeExtended | undefined;
  onClose: (ok: boolean) => void;
}

export default function ConfirmationDialogDelete({
  item,
  onClose,
  ...rest
}: ConfirmationDialogDeleteProps): ReactElement {
  const { t } = useTranslation();
  const [displayedValue, setDisplayedValue] = useState(item?.username);
  const { setError } = useError();
  const http = useFetch();

  // This is helper to keep the last value displayed during while dialog fades out
  useEffect(() => {
    if (item?.username !== '') {
      setDisplayedValue(item?.username);
    }
  }, [item?.username]);

  return (
    <ConfirmationDialog
      {...rest}
      onClose={async (ok) => {
        if (ok && item?.id) {
          try {
            await http.delete(`/api/v1/user/${item.id}`);
            onClose(true);
          } catch (error) {
            setError({
              value: error as ApiError,
              origin: 'userDelete.single',
            });
            onClose(false);
          }
        }
        onClose(false);
      }}
      title={t('users.actions.deleteUser')}
      buttonOKLabel={t('shared.actions.remove')}
      buttonCancelLabel={t('shared.actions.cancel')}
    >
      {t('users.descriptions.deleteUserConfirmation')}
      <br />
      <Box
        component={'span'}
        display={'inline-block'}
        sx={{
          py: 2 / 5,
          px: 8 / 5,
          mt: 4,
          borderRadius: 1,
          backgroundColor: pink[50],
          color: pink.A700,
        }}
      >
        {displayedValue}
      </Box>
    </ConfirmationDialog>
  );
}
