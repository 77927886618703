import React, { ReactElement, useEffect, useState } from 'react';
import InputBase, { inputBaseClasses } from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { UnderText } from 'components/CommonFilledDenseTextField/components';
import { useTranslation } from 'react-i18next';

// https://material-ui.com/customization/components/#shorthand
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  padding: theme.spacing(0.5),
  borderWidth: theme.typography.pxToRem(1),
  borderStyle: 'solid',
  borderColor: 'transparent',
  '&:hover, &:focus': {
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  [`& .${inputBaseClasses.input}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 173,
  },
}));

const ErrorText = styled(UnderText)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingLeft: 0,
}));

interface CustomFieldFreeTextProps {
  value: string;
  displayName: string;
  inputType: string;
  onSubmit: (value: string) => void;
}

const CustomFieldFreeText = ({
  value: inputValue,
  displayName,
  inputType,
  onSubmit,
}: CustomFieldFreeTextProps): ReactElement => {
  const [value, setValue] = useState(inputValue);
  const { t } = useTranslation();
  const [validate, setValidate] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  return (
    <>
      <StyledInputBase
        value={value}
        onChange={handleChange}
        onBlur={() => {
          if (inputType === 'required' && value === '') {
            setValue(inputValue);
          }
          inputValue !== value && onSubmit(value);
        }}
        onSelect={() => {
          setValidate(true);
        }}
        inputProps={{
          'aria-label': displayName,
        }}
        error={inputType === 'required' && value === '' && validate}
      />
      {inputType === 'required' && value === '' && validate && (
        <ErrorText>{t('users.hints.requiredInput')}</ErrorText>
      )}
    </>
  );
};

export default CustomFieldFreeText;
