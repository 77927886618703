import { styled } from '@mui/material/styles';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { pink } from '@mui/material/colors';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: pink[600],
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export const TargetNameBlock = styled('span')(({ theme }) => ({
  backgroundColor: pink[50],
  color: theme.palette.custom.redViolet,
  fontSize: theme.typography.pxToRem(14),
  padding: theme.spacing(1, 2, 1.2, 2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
  width: 'max-content',
  display: 'block',
  marginBottom: theme.spacing(1),
}));
