import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  API_LANGUAGES_SIMPLE_PATH,
  LANGUAGE_QUERY_KEY,
  LANGUAGES_PATH,
  TARGET_SERVICE_PATH,
} from 'constants/constants';
import { requestResponseOrThrowError } from '../utils/fetch';
import AuthContext from 'contexts/AuthContext';
import { useContext } from 'react';

const languageSimpleStructureAPIPath = `${TARGET_SERVICE_PATH}/${API_LANGUAGES_SIMPLE_PATH}`;
const languageFullStructureAPIPath = `${TARGET_SERVICE_PATH}/${LANGUAGES_PATH}`;

export function useFetchLanguages<T>(
  dataType: 'simple' | 'full' = 'simple'
): UseQueryResult<T[], Error> {
  const [authToken] = useContext(AuthContext);

  const languageAPIUrl =
    dataType === 'simple'
      ? languageSimpleStructureAPIPath
      : languageFullStructureAPIPath;

  async function fetchLanguages() {
    return await requestResponseOrThrowError(authToken, languageAPIUrl);
  }

  return useQuery<T[], Error>({
    queryKey: [LANGUAGE_QUERY_KEY, dataType],
    queryFn: fetchLanguages,
  });
}
