import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import CustomTableCell from '../../../CustomTableCell';

export const StyledCustomTableCell = styled(CustomTableCell)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(22),
}));

export const StyledCustomUsernameTableCell = styled(StyledCustomTableCell)(
  () => ({
    maxWidth: 0,
  })
);

export const StyledCellLink = styled(Link)({
  letterSpacing: '0.28px',
  '&:focus-visible': {
    outline: 'none',
  },
});
