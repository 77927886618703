import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledRoleName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  letterSpacing: 0.28,
  color: theme.palette.text.primary,
}));

export const StyledPrivilegeName = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.matterhorn,
  letterSpacing: 0.14,
}));
