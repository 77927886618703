import { useEffect, useRef, useState } from 'react';

export interface UseCalculateDrawerContentHeightResult {
  elementHeight: number;
  refElement: HTMLDivElement | null;
}

export function useCalculateDrawerContentHeight(footerRect: DOMRect | null) {
  const [elementHeight, setElementHeight] = useState<number>(0);
  const refElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refElement.current && footerRect) {
      setElementHeight(
        footerRect?.top -
          (
            refElement.current as unknown as HTMLFieldSetElement
          )?.getBoundingClientRect()?.top
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [refElement, footerRect]);

  return {
    elementHeight,
    refElement,
  };
}
