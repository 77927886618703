import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { ReactComponent as IconWarning } from 'icons/IconWarning.svg';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .MuiDialog-paper': {
    width: theme.typography.pxToRem(500),
    boxShadow: '0 3px 30px #00000034',
    borderRadius: theme.typography.pxToRem(8),
  },
  '.MuiDialogContentText-root': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.28),
    padding: '10px 0 0 30px',
    lineHeight: '21px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.32),
  lineHeight: theme.typography.pxToRem(20),
  padding: '30px 30px 0 30px',
}));

export const StyledIconWarning = styled(IconWarning)<SvgIconProps>(
  ({ theme }) => ({
    marginRight: theme.spacing(3),
    width: '20px',
    height: '20px',
    verticalAlign: 'text-bottom',
  })
);

export const StyledMoreButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  padding: 0,
  minWidth: 'fit-content',
  fontSize: theme.typography.pxToRem(13),
  '&:hover, &:focus': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

export const StyledMoreBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
}));
