import { useCallback, useContext, useState } from 'react';
import AuthContext from 'contexts/AuthContext';
import {
  PhraseEqualityCheckReplacement,
  PhraseEqualityCheckRequest,
  PhraseEqualityCheckResponse,
  PhraseEqualityCheckResults,
  PhraseSimilarityCheckRequest,
  PhraseSimilarityCheckResponse,
} from 'sections/Reuse/types';
import { fetchJsonFromAPI, HttpMethod } from 'utils/fetch';
import {
  REUSE_EQUALITY_CHECK,
  REUSE_SIMILARITY_CHECK,
} from 'sections/Reuse/constants';
import useDelayedAction from 'hooks/useDelayedAction';
import { REUSE_BASE_PATH } from '../../../../constants/constants';

export interface UseEqualityCheckProps {
  phrase: string;
  language: string;
  preferred: boolean;
  clusterId: string | undefined;
}

export interface UseEqualityCheckResult {
  equalityState: PhraseEqualityCheckReplacement[];
}

export function useEqualityCheck({
  phrase,
  language,
  preferred,
  clusterId,
}: UseEqualityCheckProps): UseEqualityCheckResult {
  const [authToken] = useContext(AuthContext);
  const [equalityState, setEqualityState] = useState<
    PhraseEqualityCheckReplacement[]
  >([]);

  function filterCurrentReplacementFromMatches(
    matchObj: PhraseEqualityCheckReplacement
  ): boolean {
    return matchObj.uuid !== clusterId;
  }

  const checkEquality = useCallback(async () => {
    // endpoint does not accept empty values
    if (!phrase || !language) {
      return;
    }
    const result = await fetchJsonFromAPI<
      PhraseEqualityCheckRequest,
      PhraseEqualityCheckResponse
    >(authToken, `${REUSE_BASE_PATH}/${REUSE_EQUALITY_CHECK}`, {
      method: HttpMethod.POST,
      body: { phrase: phrase.trim(), language },
    });

    const preferredMatches = result[
      PhraseEqualityCheckResults.preferredMatch
    ].filter(filterCurrentReplacementFromMatches);
    const deprecatedMatches = result[
      PhraseEqualityCheckResults.deprecatedMatch
    ].filter(filterCurrentReplacementFromMatches);

    setEqualityState(preferred ? preferredMatches : deprecatedMatches);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [phrase, language]);

  useDelayedAction(checkEquality, 1000);

  return {
    equalityState,
  };
}

export interface UseSimilarityCheckProps {
  duplicated: boolean;
  language: string;
  phrase: string;
  preferredPhrase: string;
}

export function useSimilarityCheck({
  duplicated,
  language,
  phrase,
  preferredPhrase,
}: UseSimilarityCheckProps): boolean {
  const [authToken] = useContext(AuthContext);
  const [similarityState, setSimilarityState] = useState<boolean>(false);

  const checkSimilarity = useCallback(async () => {
    if (!language || !phrase || duplicated || !preferredPhrase) {
      setSimilarityState(false);
      return;
    }

    const result = await fetchJsonFromAPI<
      PhraseSimilarityCheckRequest,
      PhraseSimilarityCheckResponse
    >(authToken, `${REUSE_BASE_PATH}/${REUSE_SIMILARITY_CHECK}`, {
      method: HttpMethod.POST,
      body: {
        phrase1: preferredPhrase,
        phrase2: phrase,
        language,
      },
    });

    setSimilarityState(result.similar);
  }, [authToken, duplicated, language, phrase, preferredPhrase]);

  useDelayedAction(checkSimilarity, 300);

  return similarityState;
}
