import { modalClasses } from '@mui/material/Modal';
import { tableRowClasses } from '@mui/material/TableRow';
import { SortOrder, User } from 'sections/Users/types';
import {
  DEFAULT_COLUMNS,
  LIMIT_COUNT_SELECTED_ITEMS,
  LS_USERS_TEXT_FILTER_KEY,
  PAGINATION_DEFAULT_LIMIT,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_COLUMN,
  LS_USERS_CURRENT_PAGE_KEY,
  LS_USERS_VISIBLE_COLUMNS_KEY,
} from './constants';
import { HeadCellType } from './components/UsersTable';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { FetchJsonReturnType, useFetch } from 'utils/fetch';
import { UserTypeExtended } from './components/UsersTable/components/UsersTableBodyRow';
import { ApiError } from 'errors/ApiError';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';

export const getAvailableUsers = (
  users?: ReadonlyArray<User>,
  currentUserId?: string
) => {
  if (users && currentUserId) {
    return users
      ?.filter(
        (user) =>
          user.licenseType !== 'builtin' && user.username !== currentUserId
      )
      .map((user) => user.id);
  }

  return [];
};

export const calculateNewSelection = (
  selectedUsers: ReadonlyArray<string>,
  availableUsers: ReadonlyArray<string>,
  isSelectAll: boolean
) => {
  if (isSelectAll) {
    const maxSelectionCount = Math.max(
      LIMIT_COUNT_SELECTED_ITEMS - selectedUsers.length,
      0
    );

    return [
      ...new Set([
        ...selectedUsers,
        ...availableUsers.slice(0, maxSelectionCount),
      ]),
    ];
  }

  return selectedUsers.filter(
    (selectedUser) => !availableUsers.includes(selectedUser)
  );
};

type BuildResourceUrlProps = {
  page: number;
  perPage: string;
  order: SortOrder;
  orderBy: keyof User;
  queryByText?: string;
  filteredRoles?: User['roles'];
  customFields?: string[];
};

export const buildResourceUrl = ({
  page,
  perPage,
  order,
  orderBy,
  queryByText,
  filteredRoles = [],
  customFields = [],
}: BuildResourceUrlProps) => {
  let storedDataCurrentPage = localStorage.getItem(LS_USERS_CURRENT_PAGE_KEY);

  const includeCustomField =
    customFields.length > 0
      ? customFields
          .map((cf) => `&includeCustomField=${encodeURIComponent(cf)}`)
          .join('')
      : '&excludeCustomFields=true';

  let resourceURL = `/api/v1/user?excludeProperties=true${includeCustomField}&page=${
    storedDataCurrentPage ?? page
  }&per_page=${perPage}&sort=${order === 'asc' ? '' : '-'}${orderBy}`;

  if (queryByText) {
    resourceURL += `&username=${encodeURIComponent(
      queryByText
    )}&fullName=${encodeURIComponent(queryByText)}`;
  }

  if (filteredRoles.length > 0) {
    const params = filteredRoles.map(({ id }) => `roles=${id}`);

    resourceURL += `&${params.join('&')}`;
  }

  return resourceURL;
};

export type UsersFilterState = {
  customFields: string[];
  page: number;
  perPage: string;
  order: SortOrder;
  orderBy: keyof User;
  filteredRoles: User['roles'];
  queryByText: string;
};

export type UsersFilterAction =
  | {
      type: 'setCustomFields';
      payload: string[];
    }
  | {
      type: 'setPage';
      payload: number;
    }
  | {
      type: 'setPerPage';
      payload: string;
    }
  | {
      type: 'setOrder';
      payload: Pick<UsersFilterState, 'order' | 'orderBy'>;
    }
  | {
      type: 'setFilteredRoles';
      payload: User['roles'];
    }
  | {
      type: 'setQueryByText';
      payload: string;
    };

export const usersFilterReducer = (
  state: UsersFilterState,
  action: UsersFilterAction
) => {
  switch (action.type) {
    case 'setCustomFields':
      return { ...state, page: 1, customFields: action.payload };
    case 'setPage':
      return { ...state, page: action.payload };
    case 'setPerPage':
      return { ...state, page: 1, perPage: action.payload };
    case 'setOrder':
      return { ...state, ...action.payload };
    case 'setFilteredRoles':
      return { ...state, page: 1, filteredRoles: action.payload };
    case 'setQueryByText':
      return { ...state, page: 1, queryByText: action.payload };
    default:
      return state;
  }
};

export const getInitialUsersFilter = (): UsersFilterState => {
  const [
    defaultSortColumn = DEFAULT_SORT_COLUMN,
    defaultSortDirection = DEFAULT_SORT_DIRECTION,
  ] = (localStorage.getItem('Users-Sorting')?.split('|') ?? []) as [
    keyof User,
    SortOrder
  ];

  const storedDataRolesFilter = localStorage.getItem('Users-RolesFilter');

  const storedVisibleColumns = localStorage.getItem(
    LS_USERS_VISIBLE_COLUMNS_KEY
  );

  let customFields: string[] = ['Department'];

  try {
    if (storedVisibleColumns !== null) {
      customFields = JSON.parse(storedVisibleColumns)
        .filter((cf: string) => cf.startsWith('customFields'))
        .map((cf: string) => cf.split('.')[1]);
    }
  } catch (error) {
    console.error('An error occurred while parsing JSON:', error);
  }

  return {
    customFields,
    page: 1,
    perPage:
      localStorage.getItem('Users-RowsPerPage') ??
      PAGINATION_DEFAULT_LIMIT.toString(),
    order: defaultSortDirection,
    orderBy: defaultSortColumn,
    filteredRoles: storedDataRolesFilter
      ? JSON.parse(storedDataRolesFilter)
      : [],
    queryByText: localStorage.getItem(LS_USERS_TEXT_FILTER_KEY) ?? '',
  };
};

export const generateColumns = (customFields?: User['customFields']) => {
  let newColumns = [
    ...DEFAULT_COLUMNS,
    ...(customFields?.map(({ key, displayName: label, value, ...rest }) => ({
      key: `customFields.${key}`,
      label,
      sortable: true,
      visible: key === 'Department',
      ...rest,
    })) ?? []),
  ] as HeadCellType[];

  try {
    const visibleColumns = JSON.parse(
      `${localStorage.getItem(LS_USERS_VISIBLE_COLUMNS_KEY)}`
    );

    if (Array.isArray(visibleColumns)) {
      newColumns = newColumns.map((column) => ({
        ...column,
        visible: visibleColumns.includes(column.key),
      }));
    }
  } catch (err) {}

  return newColumns;
};

const getUsersQueryKey = (usersFilterState: UsersFilterState) => [
  'users',
  ...Object.values(usersFilterState),
];

interface GetUsersServerResponse extends FetchJsonReturnType {
  data: ReadonlyArray<UserTypeExtended>;
}

export const useGetUsers = (usersFilterState: UsersFilterState) => {
  const http = useFetch();
  const resourceURL = buildResourceUrl(usersFilterState);
  const queryKey = getUsersQueryKey(usersFilterState);
  const { setError } = useError();

  return useQuery<GetUsersServerResponse, ApiError>(
    queryKey,
    async () => {
      try {
        return await http.get<GetUsersServerResponse>(resourceURL);
      } catch (error) {
        setError({ value: error as ApiError, origin: 'getUsers' });
        throw error;
      }
    },
    {
      keepPreviousData: true,
    }
  );
};

export const addUserToQueryCache = async (
  queryClient: QueryClient,
  usersFilterState: UsersFilterState,
  user: User
) => {
  const mouseDownAbortController = new AbortController();
  const queryKey = getUsersQueryKey(usersFilterState);
  await queryClient.cancelQueries(queryKey);
  const previousUsersResponse =
    queryClient.getQueryData<GetUsersServerResponse>(queryKey);

  const updatedUsersResponse = {
    ...previousUsersResponse,
    headers: {
      ...previousUsersResponse?.headers,
      totalItems: (previousUsersResponse?.headers?.totalItems ?? 0) + 1,
    },
  };

  queryClient.setQueryData(queryKey, {
    ...updatedUsersResponse,
    data: [
      {
        ...user,
        highlighted: true,
      },
      ...(updatedUsersResponse?.data ?? []).slice(
        0,
        parseInt(usersFilterState.perPage, 10) - 1
      ),
    ],
  });

  document.addEventListener(
    'mousedown',
    (event: MouseEvent) => {
      const addedRow = document.querySelector(
        `.${tableRowClasses.root}.${tableRowClasses.selected}`
      );
      const modals = Array.from(
        document.querySelectorAll(`.${modalClasses.root}`)
      );
      const target = event.target as HTMLElement;

      if (addedRow) {
        if (
          !addedRow.contains(target) &&
          !modals.some((container) => container.contains(target))
        ) {
          queryClient.refetchQueries(queryKey);
          mouseDownAbortController.abort();
        }
      } else {
        mouseDownAbortController.abort();
      }
    },
    { signal: mouseDownAbortController.signal }
  );
};
