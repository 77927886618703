import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import ConfirmationDialog from 'components/ConfirmationDialog';
import WarningIcon from 'components/WarningIcon';
import WarningText from 'components/WarningText';
import { ReuseDomainCluster } from 'sections/Reuse/types';
import { DeleteReplacementDescription } from './components';
import TransWrapper from 'components/TransWrapper';

export interface DeleteReplacementDialogProps {
  replacement: ReuseDomainCluster;
  onClose: (ok: boolean, replacement: ReuseDomainCluster) => void;
}

export default function DeleteReplacementDialog({
  replacement,
  onClose,
}: DeleteReplacementDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('reuse.titles.deleteReplacement')}
      buttonOKLabel={t('shared.actions.delete')}
      buttonCancelLabel={t('shared.actions.cancel')}
      open={!!replacement}
      onClose={(ok: boolean) => onClose(ok, replacement)}
      ContentComponent={Grid}
    >
      <DeleteReplacementDescription>
        <TransWrapper
          i18nKey="reuse.descriptions.deleteReplacement"
          values={{ replacement: replacement.preferredPhrase.phrase }}
          components={{
            span: (
              <span style={{ maxWidth: 'none' }} lang={replacement.language} />
            ),
          }}
        />
        {replacement.reuseDomains.length > 1 && (
          <Grid
            container
            justifyContent="flex-start"
            justifyItems="flex-start"
            flexWrap="nowrap"
            sx={(theme) => ({
              paddingTop: theme.spacing(2.6),
            })}
          >
            <WarningIcon
              sx={(theme) => ({ fontSize: theme.typography.pxToRem(18) })}
            />
            <WarningText component="div">
              <TransWrapper i18nKey="reuse.hints.deleteReplacementInManyDomains" />
            </WarningText>
          </Grid>
        )}
      </DeleteReplacementDescription>
    </ConfirmationDialog>
  );
}
