import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCalculateDrawerContentHeight,
  DrawerContent,
  InnerDivider,
} from 'components/CommonDrawer';
import { CompletePhrase, ReuseIndexDomain } from '../../types';
import { AdministrativeInformationAccordion } from 'components/AdministrativeInformation';
import AdvancedSettings from '../AdvancedSettings';
import { InnerDrawerPropsFromHook, UseClusterDomainsResult } from './hooks';
import {
  StyledInfoLabel,
  StyledInfoText,
} from 'components/AdministrativeInformation/components';
import DomainChips from './DomainChips';
import { useLanguages } from 'components/LanguageDropdown/hooks';
import { StyledEmptyField } from '../CreateOrEditDomainDrawer/styles';

import { LanguageSimple } from 'types/types';

export interface ReadOnlyInnerReplacementDrawerProps
  extends InnerDrawerPropsFromHook {
  clusterDomains: UseClusterDomainsResult;
  deprecatedPhrases: CompletePhrase[];
  domainList: ReuseIndexDomain[];
  footerRect: DOMRect | null;
  languages: LanguageSimple[];
}

export default function ReadOnlyInnerReplacementDrawer({
  clusterDomains,
  created,
  deprecatedPhrases,
  descriptionState,
  domainList,
  footerRect,
  languages,
  modified,
  originallyDeprecatedPhrasesExist,
  preferredPhraseState,
  selectedLanguageId,
}: ReadOnlyInnerReplacementDrawerProps) {
  const { t } = useTranslation();
  const { elementHeight, refElement } =
    useCalculateDrawerContentHeight(footerRect);
  const { selectedLanguage } = useLanguages({
    languages,
    selectedLanguageId,
    type: 'simple',
  });

  return (
    <DrawerContent
      ref={refElement}
      style={{ height: `${elementHeight - 5}px`, overflowY: 'scroll' }}
    >
      <StyledInfoLabel sx={(theme) => ({ paddingTop: theme.spacing(0) })}>
        {t('reuse.labels.preferredPhrase')}
      </StyledInfoLabel>
      <StyledInfoText>{preferredPhraseState.textValue}</StyledInfoText>
      <StyledInfoLabel>{t('reuse.labels.language')}</StyledInfoLabel>
      <StyledInfoText sx={(theme) => ({ paddingBottom: theme.spacing(6) })}>
        {selectedLanguage[0]?.languageVariantDisplayName}
      </StyledInfoText>
      <StyledInfoLabel>{t('reuse.labels.replacementDomains')}</StyledInfoLabel>
      <DomainChips
        clusterDomains={clusterDomains}
        domainList={domainList}
        readOnly={true}
      />
      <StyledInfoLabel sx={(theme) => ({ paddingTop: theme.spacing(4) })}>
        {t('reuse.labels.domainDescription')}
      </StyledInfoLabel>
      <StyledInfoText>{descriptionState.textValue}</StyledInfoText>
      {!descriptionState.textValue && (
        <StyledEmptyField>{t('reuse.fields.none')}</StyledEmptyField>
      )}
      <InnerDivider
        sx={(theme) => ({
          margin: theme.spacing(5, 0, 1, 0),
        })}
      />
      <AdvancedSettings
        deprecatedPhrases={deprecatedPhrases}
        defaultOpen={originallyDeprecatedPhrasesExist}
        readOnly={true}
      />
      <AdministrativeInformationAccordion
        created={created}
        modified={modified}
        readonly={true}
      />
    </DrawerContent>
  );
}
