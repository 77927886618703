import React, { ReactElement, memo, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

import CheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import { ReactComponent as IconWarning } from 'icons/IconWarning.svg';
import { User } from 'sections/Users/types';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { filterRolesByUserPrivileges } from './utils';

import { useTranslation } from 'react-i18next';
import { useRolesQuery } from '../../queries';

type Props = {
  selectedRoles: User['roles'];
  onChange: (selectedRoles: User['roles']) => void;
};

const StyledIconWarning = styled(IconWarning)<SvgIconProps>(({ theme }) => ({
  verticalAlign: 'middle',
  marginRight: theme.spacing(2),
}));

export const RequiredFieldWarning = () => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        fontSize: (theme) => theme.typography.pxToRem(12),
        fontWeight: 'bold',
      }}
    >
      <StyledIconWarning />
      {t('shared.notifications.warnings.lastRoleCannotBeUnchecked')}
    </Typography>
  );
};

const RolesInput = ({ selectedRoles = [], onChange }: Props): ReactElement => {
  const userPrivileges = useContext(PrivilegesContext);

  const { data, error } = useRolesQuery();

  if (error) {
    return <></>;
  }
  if (!data) {
    return <></>;
  }

  const filteredRoles = filterRolesByUserPrivileges(data.data, userPrivileges);

  return (
    <>
      {!selectedRoles.length && (
        <Box pb={3}>
          <RequiredFieldWarning />
        </Box>
      )}
      <FormControl required component="fieldset">
        <FormGroup>
          {filteredRoles.map(({ id: roleId, name: label }) => (
            <Box p={3 / 5} ml={-9 / 5} key={roleId}>
              <CheckboxWithLabel
                label={label}
                name={roleId}
                checked={!!selectedRoles.find(({ id }) => id === roleId)}
                onChange={(event) => {
                  let newSelectedRoles = [...selectedRoles];

                  // Remove role if already selected
                  newSelectedRoles = newSelectedRoles.filter(
                    ({ id }) => id !== event.target.name
                  );

                  // Add role
                  if (filteredRoles && event?.target?.checked === true) {
                    const role = filteredRoles.find(
                      ({ id }) => id === event.target.name
                    );

                    if (role) {
                      newSelectedRoles = [...newSelectedRoles, role];
                    }
                  }

                  onChange(newSelectedRoles);
                }}
              />
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default memo(RolesInput);
