import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
    color: theme.palette.primary.main,
    opacity: 0.4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof IconButton;
