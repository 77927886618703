import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import UserForm from './UserForm';
import RolesFilterStatePanel from './RolesFilterStatePanel';
import AugmentedIconButton from './AugmentedIconButton';
import CommonTooltip from 'components/CommonTooltip';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';

import {
  UsersFilterAction,
  UsersFilterState,
  addUserToQueryCache,
} from '../utils';
import { User } from 'sections/Users/types';
import {
  LS_USERS_CURRENT_PAGE_KEY,
  LS_USERS_VISIBLE_COLUMNS_KEY,
  PAGINATION_LIMIT_OPTIONS,
} from '../constants';
import { SearchField } from './SearchField';
import { HeadCellType } from './UsersTable';
import TransWrapper from 'components/TransWrapper';
import { useFetch } from 'utils/fetch';
import saveAs from 'file-saver';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';
import { ExportIcon, StyledSelectTypeA } from '../styles';

type Props = {
  usersCount: number;
  itemsCount: number;
  itemsSelected: number;
  columns: HeadCellType[];
  setColumns: React.Dispatch<React.SetStateAction<HeadCellType[]>>;
  usersFilterState: UsersFilterState;
  dispatch: React.Dispatch<UsersFilterAction>;
};

export const UsersTableFilters = ({
  usersCount,
  itemsCount,
  itemsSelected,
  columns,
  setColumns,
  dispatch,
  usersFilterState,
}: Props) => {
  const userPrivileges = React.useContext(PrivilegesContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const http = useFetch();
  const { setError } = useError();

  const onAddUser = (user?: User) => {
    if (user) {
      enqueueSnackbar(t('user.notification.newUserCreatedSuccessfully'), {
        snackbarContentProps: {
          role: 'status',
        },
      });
      addUserToQueryCache(queryClient, usersFilterState, user);
    }
  };

  const onSearchTextChange = React.useCallback(
    (value: string) => {
      dispatch({
        type: 'setQueryByText',
        payload: value,
      });
    },
    [dispatch]
  );

  const onExportUsers = async () => {
    try {
      const res = await http.get('/api/v1/user/_export', {
        returnResponse: true,
      });

      const contentDispositionHeader = res.headers.get('content-disposition');
      const filename = contentDispositionHeader
        ? contentDispositionHeader?.match(/"([^"]*)"/i)?.[1]
        : 'acrolinx_users_export.csv';

      saveAs(await res.blob(), filename);
    } catch (error: unknown) {
      setError({ value: error as ApiError, origin: 'exportUsers' });
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} pr={1} pb={9 / 5}>
        <Box mb={6}>
          <SearchField
            onChange={onSearchTextChange}
            userPrivileges={userPrivileges}
          />
        </Box>
        <Box>
          <Box display={'flex'}>
            {userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && (
              <Box alignSelf="center" whiteSpace="nowrap">
                <UserForm onClose={onAddUser} />
              </Box>
            )}
            <Box flexGrow={1}>
              <RolesFilterStatePanel
                activeRoles={usersFilterState.filteredRoles}
                onRemove={(roleId) => {
                  localStorage.removeItem(LS_USERS_CURRENT_PAGE_KEY);
                  localStorage.setItem(
                    'Users-RolesFilter',
                    JSON.stringify(
                      usersFilterState.filteredRoles.filter(
                        (role) => role.id !== roleId
                      )
                    )
                  );
                  dispatch({
                    type: 'setFilteredRoles',
                    payload: usersFilterState.filteredRoles.filter(
                      (role) => role.id !== roleId
                    ),
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pb={2}>
        <Box mb={9}>
          <Typography variant="body2">
            <TransWrapper
              i18nKey="users.descriptions.metaDataTotalUsers"
              values={{ count: usersCount }}
            />
            <TransWrapper
              i18nKey="users.descriptions.metaDataDisplayedUsers"
              values={{ count: itemsCount }}
            />
            <TransWrapper
              i18nKey="users.descriptions.metaDataSelectedUsers"
              values={{ count: itemsSelected }}
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
          <Box>
            <StyledSelectTypeA
              label={t('shared.baseComponents.pagination.rowsPerPage')}
              value={usersFilterState.perPage}
              onChange={(newPerPage) => {
                dispatch({ type: 'setPerPage', payload: newPerPage });
                localStorage.setItem('Users-RowsPerPage', newPerPage);
                localStorage.removeItem(LS_USERS_CURRENT_PAGE_KEY);
              }}
              options={PAGINATION_LIMIT_OPTIONS}
              sx={{ minWidth: 71 }}
            />
          </Box>
          <Box pl={3}>
            <ColumnSelector
              columns={columns}
              rolesColumnDisabled={!!usersFilterState.filteredRoles.length}
              onSubmit={(value) => {
                localStorage.setItem(
                  LS_USERS_VISIBLE_COLUMNS_KEY,
                  JSON.stringify(
                    value.filter(({ visible }) => visible).map(({ key }) => key)
                  )
                );

                setColumns(value);
              }}
            />
          </Box>
          {userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && (
            <Box ml={2}>
              <CommonTooltip title={<>{t('users.tooltips.exportUsers')}</>}>
                <AugmentedIconButton
                  color="primary"
                  aria-label={t('users.tooltips.exportUsers')}
                  onClick={onExportUsers}
                >
                  <ExportIcon />
                </AugmentedIconButton>
              </CommonTooltip>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
