import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  QueryObserverResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  ReuseDomain,
  ReuseDomainCluster,
  ReuseIndexDomain,
} from 'sections/Reuse/types';
import {
  REUSE_DOMAIN_DETAILS_PATH,
  REUSE_CLUSTERS_PATH,
  DOMAIN_QUERY_KEY,
} from 'sections/Reuse/constants';
import { REUSE_BASE_PATH } from 'constants/constants';
import {
  fetchPlainResponse,
  HttpMethod,
  requestResponseOrThrowError,
} from 'utils/fetch';
import AuthContext from 'contexts/AuthContext';
import useCreateEditReplacement from '../../hooks/useCreateEditReplacement';
import { useIsOpen } from 'hooks/useIsOpen';
import useFetchReuseDomains from 'hooks/useFetchReuseDomains';

interface UseDomainDetailPageResult {
  closePhraseDrawer: () => void;
  clusterForEdit: ReuseDomainCluster | null;
  domain: ReuseDomain | undefined;
  domainList: ReuseIndexDomain[];
  openPhraseDrawer: () => void;
  phraseDrawerIsOpen: boolean;
  refreshDomain: UseFetchDomainResult['refetch'];
  reuseDomainResult: UseFetchDomainResult;
  setClusterForEdit: (arg: ReuseDomainCluster | null) => void;
}

export function useDomainDetailPage(): UseDomainDetailPageResult {
  const {
    closePhraseDrawer,
    clusterForEdit,
    openPhraseDrawer,
    phraseDrawerIsOpen,
    setClusterForEdit,
  } = useCreateEditReplacement();

  const reuseDomainResult = useFetchDomain();
  const reuseDomainsResult = useFetchReuseDomains();

  function refreshDomain(): Promise<QueryObserverResult<ReuseDomain, Error>> {
    return reuseDomainResult.refetch();
  }

  return {
    closePhraseDrawer,
    clusterForEdit,
    domain: reuseDomainResult.data,
    domainList: reuseDomainsResult.data || [],
    openPhraseDrawer,
    phraseDrawerIsOpen,
    refreshDomain,
    reuseDomainResult,
    setClusterForEdit,
  };
}

export type UseFetchDomainResult = UseQueryResult<ReuseDomain, Error>;

export function useFetchDomain(): UseFetchDomainResult {
  const { id } = useParams<{ id: ReuseDomain['uuid'] }>();
  const [authToken] = useContext(AuthContext);

  async function fetchDomain() {
    return await requestResponseOrThrowError(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_DOMAIN_DETAILS_PATH}/${id}`
    );
  }

  return useQuery<ReuseDomain, Error>({
    queryKey: [DOMAIN_QUERY_KEY, id],
    queryFn: fetchDomain,
  });
}

export function useDeleteReplacement(
  refreshDomain: UseFetchDomainResult['refetch']
) {
  const [authToken] = useContext(AuthContext);
  const [
    replacementForDeleteConfirmation,
    setReplacementForDeleteConfirmation,
  ] = useState<ReuseDomainCluster | undefined>();

  async function closeDeleteReplacementConfirmationDialog(
    deleteIt: boolean,
    replacement: ReuseDomainCluster
  ) {
    setReplacementForDeleteConfirmation(undefined);
    if (deleteIt) {
      await fetchPlainResponse(
        authToken,
        `${REUSE_BASE_PATH}/${REUSE_CLUSTERS_PATH}/${replacement.uuid}`,
        { method: HttpMethod.DELETE }
      );
      await refreshDomain();
    }
  }
  return {
    closeDeleteReplacementConfirmationDialog,
    setReplacementForDeleteConfirmation,
    replacementForDeleteConfirmation,
  };
}

export interface UseEditDomainResult {
  closeDomainDrawer: () => void;
  drawerDomainIsOpen: boolean;
  openDomainDrawer: () => void;
}

export function useEditDomain(): UseEditDomainResult {
  const {
    elementIsOpen: drawerDomainIsOpen,
    setElementIsOpen: setDrawerDomainIsOpen,
  } = useIsOpen();

  function closeDomainDrawer() {
    setDrawerDomainIsOpen(false);
  }

  function openDomainDrawer() {
    setDrawerDomainIsOpen(true);
  }

  return {
    closeDomainDrawer,
    drawerDomainIsOpen,
    openDomainDrawer,
  };
}

export type UpdateActiveFn = (id: string, checked: boolean) => void;

export function useUpdateReplacementActive(
  refreshDomain: UseFetchDomainResult['refetch']
): { updateActive: UpdateActiveFn } {
  const [authToken] = useContext(AuthContext);

  async function updateActive(id: string, checked: boolean) {
    await fetchPlainResponse(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_CLUSTERS_PATH}`,
      {
        method: HttpMethod.PATCH,
        body: JSON.stringify({
          uuid: id,
          active: checked,
        }),
      }
    );
    if (typeof refreshDomain === 'function') {
      await refreshDomain();
    }
  }

  return {
    updateActive,
  };
}
