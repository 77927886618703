import { ButtonBase } from '@mui/material';
import { CommonFilledDenseTextFieldWithInformativeAdornment } from 'components/CommonFilledDenseTextField';
import CommonTooltip from 'components/CommonTooltip';
import {
  USER_AND_ROLES_READ_USER,
  USER_AND_ROLES_EDIT_USER,
} from 'constants/privileges';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  LS_USERS_CURRENT_PAGE_KEY,
  LS_USERS_TEXT_FILTER_KEY,
} from '../constants';
import { StyledCancelIcon, SearchByTextInputStyledClearIcon } from '../styles';
import { useDebounce } from 'utils/utils';

type Props = {
  onChange: (value: string) => void;
  userPrivileges: ReadonlyArray<string>;
};

export const SearchField = ({ onChange, userPrivileges }: Props) => {
  const [queryByText, setQueryByText] = useState<string>(
    localStorage.getItem(LS_USERS_TEXT_FILTER_KEY) ?? ''
  );

  const debouncedQueryByText = useDebounce(queryByText);

  useEffect(() => {
    onChange(debouncedQueryByText);
  }, [debouncedQueryByText, onChange]);

  return (
    <CommonFilledDenseTextFieldWithInformativeAdornment
      sx={{ width: 300, height: 42 }}
      placeholder={t('users.placeholders.search')}
      value={queryByText}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setQueryByText(event.target.value);
        if (event.target.value) {
          localStorage.removeItem(LS_USERS_CURRENT_PAGE_KEY);
          localStorage.setItem(LS_USERS_TEXT_FILTER_KEY, event.target.value);
        } else {
          localStorage.removeItem(LS_USERS_TEXT_FILTER_KEY);
        }
      }}
      disabled={
        ![USER_AND_ROLES_READ_USER, USER_AND_ROLES_EDIT_USER].some((p) =>
          userPrivileges.includes(p)
        )
      }
      adornmentComponent={
        queryByText ? (
          <CommonTooltip title={<>{t('shared.actions.clear')}</>}>
            <ButtonBase
              onClick={() => {
                setQueryByText('');
                localStorage.removeItem(LS_USERS_TEXT_FILTER_KEY);
              }}
              aria-label={t('shared.actions.clear')}
            >
              <StyledCancelIcon fontSize={'small'} />
            </ButtonBase>
          </CommonTooltip>
        ) : (
          <SearchByTextInputStyledClearIcon fontSize={'small'} />
        )
      }
    />
  );
};
