import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import TargetAssignmentCard from './TargetAssignmentCard';
import { TargetAssignment } from 'sections/Targets/types';
import { Roles } from './types';

export interface TargetAssignmentCardListProps {
  assignedTargetList: TargetAssignment[];
  footerTop?: number;
  onDragEnd: (result: DropResult) => Promise<void>;
  openDialogTargetAssignment: () => void;
  readOnly: boolean;
  roleList: Roles[];
  setTargetInEdit: (target: TargetAssignment) => void;
  setTargetInEditIsUnassigned: (value: boolean) => void;
  sortedTargetIds: string[];
}

export default function TargetAssignmentCardList({
  assignedTargetList,
  footerTop,
  onDragEnd,
  openDialogTargetAssignment,
  readOnly,
  roleList,
  setTargetInEdit,
  setTargetInEditIsUnassigned,
  sortedTargetIds,
}: TargetAssignmentCardListProps) {
  return readOnly ? (
    <>
      {sortedTargetIds?.map((targetId, index) => {
        const target = assignedTargetList.find(
          (x) => x.targetUuid === targetId
        );
        if (!target) {
          return <></>;
        }

        return (
          <TargetAssignmentCard
            key={target.targetUuid}
            target={target}
            roleList={roleList}
            setTargetInEdit={setTargetInEdit}
            setTargetInEditIsUnassigned={setTargetInEditIsUnassigned}
            openDialogTargetAssignment={openDialogTargetAssignment}
            readOnly={readOnly}
            footerTop={footerTop}
          />
        );
      })}
    </>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="target-assignments">
          {(prov) => (
            <div ref={prov.innerRef} {...prov.droppableProps}>
              {sortedTargetIds?.map((targetId, index) => {
                const target = assignedTargetList.find(
                  (x) => x.targetUuid === targetId
                );
                if (!target) {
                  return <></>;
                }
                return (
                  <Draggable
                    key={target.targetUuid}
                    draggableId={target.targetUuid}
                    index={index}
                  >
                    {(provA) => (
                      <TargetAssignmentCard
                        target={target}
                        prov={provA}
                        roleList={roleList}
                        setTargetInEdit={setTargetInEdit}
                        setTargetInEditIsUnassigned={
                          setTargetInEditIsUnassigned
                        }
                        openDialogTargetAssignment={openDialogTargetAssignment}
                        footerTop={footerTop}
                      />
                    )}
                  </Draggable>
                );
              })}
              {prov.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}
