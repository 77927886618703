import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledInlineSelectLabel } from 'components/CommonSelectDense';
import { ModernReuseGuidelineProps } from '../../types';
import ShowDomainMenuButton from '../TerminologyGuideline/ShowDomainMenuButton';
import ChosenDomainsChips from '../TerminologyGuideline/ChosenDomainsChips';
import { getModernReuseGuidelineDomainNames } from '../../helpers';
import { modernGuidelineDefaultValue } from './ReuseGoal';
import CommonWarningMessage from 'components/CommonWarningMessage';

export default function ReuseModernGuideline({
  newDomains,
  onChangeHandler,
  options,
  readOnly,
  setNewDomains,
  targetGoal,
  value,
}: ModernReuseGuidelineProps) {
  const { t } = useTranslation();

  const parameterValueObj = typeof value === 'string' ? JSON.parse(value) : {};

  useEffect(() => {
    setNewDomains(
      getModernReuseGuidelineDomainNames(
        targetGoal,
        modernGuidelineDefaultValue
      )
    );
  }, [setNewDomains, targetGoal]);

  function handleChangeDomainCheckbox(
    checked: boolean,
    affectedDomains: string[]
  ) {
    let domainNames = [...newDomains];

    if (checked) {
      domainNames = domainNames.concat(affectedDomains);
    } else {
      affectedDomains.forEach((id) => {
        const index = domainNames.findIndex((dName) => dName === id);
        if (index > -1) {
          domainNames.splice(index, 1);
        }
      });
    }

    onChangeHandler(
      JSON.stringify({
        domainNames: Array.from(new Set(domainNames)),
      })
    );
  }

  function handleDeleteDomainHandler(value: string) {
    onChangeHandler(value);
  }

  const warningTextId = `no-sets-for-${targetGoal.goalId}`;

  return (
    <>
      <StyledInlineSelectLabel id="repository-select-label" variant="body2">
        {t('capture.labels.modernReuseDomainsSelection')}
      </StyledInlineSelectLabel>
      {!readOnly && (
        <ShowDomainMenuButton
          aria-disabled={!options.length}
          aria-labelledby={!options.length ? warningTextId : ''}
          className={!options.length ? 'Mui-disabled' : ''}
          domainData={options}
          handleChangeDomainCheckbox={handleChangeDomainCheckbox}
          parameterValueObj={parameterValueObj}
          addIconTooltipText={t('reuse.tooltips.addSet')}
        />
      )}
      <ChosenDomainsChips
        domainData={options}
        onDelete={handleDeleteDomainHandler}
        parameterValueObj={parameterValueObj}
        readOnly={readOnly}
      />
      <br />
      {!options.length && (
        <CommonWarningMessage
          textContainerId={warningTextId}
          text={t('capture.descriptions.noSetsAvailable')}
        />
      )}
    </>
  );
}
