import React, { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import CommonFilledDenseTextField, {
  CommonFilledDenseTextFieldProps,
} from './CommonFilledDenseTextField';
import { ErrorText, StyledInputAdornment } from './components';

interface CommonFilledDenseTextFieldWithActionAdornmentProps
  extends CommonFilledDenseTextFieldProps {
  adornmentComponent: ReactElement<typeof IconButton | typeof Tooltip>;
  errorText?: string;
  uniqueAriaKey?: string;
}

export default function CommonFilledDenseTextFieldWithActionAdornment({
  adornmentComponent,
  errorText,
  uniqueAriaKey = '',
  ...props
}: CommonFilledDenseTextFieldWithActionAdornmentProps) {
  const ariaId = `field-description${uniqueAriaKey}`;

  return (
    <>
      <CommonFilledDenseTextField
        {...props}
        {...(props.error && {
          InputProps: {
            'aria-describedby': ariaId,
          },
        })}
        endAdornment={
          <StyledInputAdornment
            position="end"
            aria-disabled={!props.value || props.disabled}
          >
            {props.error ? (
              <ErrorOutlineIcon color="error" />
            ) : (
              adornmentComponent
            )}
          </StyledInputAdornment>
        }
      />
      {errorText && <ErrorText id={ariaId}>{errorText}</ErrorText>}
    </>
  );
}
