import { useContext, useMemo } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { TargetIndexItem } from 'types/types';
import {
  TARGET_INDEX_QUERY_KEY,
  TARGET_SERVICE_PATH,
  TARGETS_INDEX_PATH,
} from 'constants/constants';
import { requestResponseOrThrowError } from 'utils/fetch';
import useSort, { UseSortResult } from './useSort';
import AuthContext from 'contexts/AuthContext';

export interface UseFetchTargetsResult {
  fetchingTargets: boolean;
  setSorting: UseSortResult['setSorting'];
  sortedTargets: TargetIndexItem[];
  sortField: UseSortResult['sortField'];
  sortOrder: UseSortResult['sortOrder'];
  targetsResult: UseQueryResult<TargetIndexItem[], Error>;
}

export default function useFetchTargets(): UseFetchTargetsResult {
  const [authToken] = useContext(AuthContext);
  const { sortBy, sortField, sortOrder, setSorting } = useSort('displayName');

  async function fetchTargets() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGETS_INDEX_PATH}`
    );
  }

  const targetsResult = useQuery<TargetIndexItem[], Error>({
    queryKey: [TARGET_INDEX_QUERY_KEY],
    queryFn: fetchTargets,
  });
  const { data, isFetching } = targetsResult;

  const sortedTargets: TargetIndexItem[] = useMemo(
    () =>
      sortBy(
        data ?? [],
        sortField,
        sortOrder,
        sortField === 'displayName'
          ? 'languageVariantDisplayName'
          : 'displayName'
      ),
    [data, sortBy, sortField, sortOrder]
  );

  return {
    fetchingTargets: isFetching,
    setSorting,
    sortedTargets,
    sortField,
    sortOrder,
    targetsResult,
  };
}
