import { styled } from '@mui/material/styles';

export const FieldContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Title = styled('p')(({ theme }) => ({
  color: theme.palette.custom.gray36,
  letterSpacing: theme.typography.pxToRem(0.39),
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(18),
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
  margin: theme.spacing(0),
}));

export const Text = styled('p')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(18),
  marginBottom: theme.spacing(0),
  marginTop: theme.spacing(1),
  letterSpacing: theme.typography.pxToRem(0.31),
}));
