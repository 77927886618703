import BlockOutlined from '@mui/icons-material/BlockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import { pink, blueGrey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import Checkbox from 'components/CustomCheckbox/CustomCheckbox';

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  alignSelf: 'flex-start',
  paddingLeft: theme.spacing(1.6),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(0),
}));

export const StyledGuidelineLabel = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledGuidelineParameterLabel = styled(StyledGuidelineLabel)(
  ({ theme }) => ({
    color: theme.palette.custom.gray55,
    paddingLeft: theme.spacing(2),
  })
);

export const StyledGuidelineParameterLabelReadOnly = styled(
  StyledGuidelineLabel
)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(19),
  fontSize: theme.typography.pxToRem(14),
}));

export const StyledGuidelineParameterLabelReadOnlySlider = styled(
  StyledGuidelineLabel
)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(30),
  fontSize: theme.typography.pxToRem(14),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderColor: blueGrey[100],
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  width: 50,
  height: 30,
  paddingLeft: theme.spacing(2),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  height: theme.spacing(6),
  width: theme.spacing(6),
  backgroundColor: 'transparent',
  '&:hover, &:focus': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.MuiIconButton-colorPrimary.Mui-disabled': {
    color: theme.palette.disabled.primary,
  },
  '&.MuiIconButton-colorSecondary.Mui-disabled': {
    color: theme.palette.disabled.secondary,
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  margin: 0,
  width: theme.spacing(80),
}));

export const StyledWideDialogContent = styled(StyledDialogContent)(
  ({ theme }) => ({
    maxWidth: theme.spacing(160),
    width: 'auto',
  })
);

export const StyledDialogActionsContent = styled(DialogActions)(
  ({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
    margin: 0,
  })
);

export const StyledWarningDialogTitle = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.4),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const StyledWarningDialogDescription = styled(Typography)(
  ({ theme }) => ({
    letterSpacing: theme.typography.pxToRem(0.28),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(21),
    padding: theme.spacing(0),
    '& p': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      '&:last-of-type': {},
    },
    '& ul': {
      padding: 0,
      margin: theme.spacing(0, 0, -2, 0),
      '& li': {
        listStyle: 'none',
        lineHeight: theme.typography.pxToRem(21),
        '&:before': {
          content: '"·"',
          fontSize: theme.typography.pxToRem(20),
          verticalAlign: 'middle',
          lineHeight: theme.typography.pxToRem(21),
          width: theme.spacing(2),
          display: 'inline-block',
        },
      },
    },
    '& span': {
      display: 'inline-block',
      padding: theme.spacing(1.4, 2),
      lineHeight: theme.typography.pxToRem(20),
      borderRadius: theme.shape.borderRadius,
      '&:first-of-type': {
        backgroundColor: pink[50],
        color: pink['A700'],
      },
      '&:last-of-type': {
        backgroundColor: theme.palette.background.default,
        color: blueGrey[900],
      },
    },
  })
) as typeof Typography;

export const StyledBlockOutlined = styled(BlockOutlined)(({ theme }) => ({
  fill: theme.palette.custom.lightGrey,
}));

export const InfoIconContainer = styled('a')(({ theme }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.8),
  borderRadius: 25,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.background.paper,
  },
  '&:focus': {
    outline: 'none',
  },
  marginRight: theme.spacing(2.4),
}));

export const GuidelineInfoIcon = styled(InfoOutlined)(({ theme }) => ({
  fill: theme.palette.custom.lightGrey,
  '&:hover, &:focus': {
    fill: blueGrey[400],
  },
}));

export const GuidelineRow = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(1.4),
  borderRadius: 4,
  '& .guidelineIconsContainer': {
    visibility: 'hidden',
  },
  '&:hover, &:focus, &:focus-within': {
    backgroundColor: theme.palette.background.default,
    '& .guidelineIconsContainer': {
      visibility: 'visible',
    },
  },
}));
