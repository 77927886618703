import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreateOrEditDomainDrawerHooksProps,
  CreateOrEditDomainDrawerHooksReturn,
  NewReuseIndexDomain,
  ReuseIndexDomain,
} from 'sections/Reuse/types';
import {
  MAX_REUSE_DOMAIN_DESCRIPTION_LENGTH,
  MAX_REUSE_DOMAIN_NAME_LENGTH,
} from './constants';
import { fetchPlainResponse, HttpMethod } from 'utils/fetch';
import AuthContext from 'contexts/AuthContext';
import useText from 'hooks/useText';
import createStringErrorMessage from 'helpers/createStringErrorMessage';
import {
  REUSE_BASE_PATH,
  REUSE_DOMAINS_PATH,
} from '../../../../constants/constants';

export function useInnerDrawer({
  close,
  domain,
  drawerIsOpen,
  refresh,
  ...rest
}: CreateOrEditDomainDrawerHooksProps): CreateOrEditDomainDrawerHooksReturn {
  const { t } = useTranslation();
  const history = useHistory();
  const [authToken] = useContext(AuthContext);
  const [nameRequired, setNameRequired] = useState<boolean>(false);
  const [repeatedName, setRepeatedName] = useState<boolean>(false);
  const domainNameState = useText(domain?.displayName);
  const domainDescriptionState = useText(domain?.description);
  const domainNames = useRef(rest.domainsNames);

  useEffect(() => {
    if (drawerIsOpen) {
      domainNameState.setTextValue(domain?.displayName ?? '');
      domainDescriptionState.setTextValue(domain?.description ?? '');
      domainNames.current = rest.domainsNames;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [drawerIsOpen]);

  useEffect(() => {
    setNameRequired(!domainNameState.textValue);
    if (
      domainNames.current &&
      domain?.displayName !== domainNameState.textValue
    ) {
      setRepeatedName(domainNames.current.includes(domainNameState.textValue));
    }
  }, [domainNameState.textValue, domain?.displayName]);

  async function saveDomain(domain: NewReuseIndexDomain, id?: string) {
    const path = `${REUSE_BASE_PATH}/${REUSE_DOMAINS_PATH}`;
    await fetchPlainResponse<NewReuseIndexDomain>(authToken, path, {
      method: id ? HttpMethod.PUT : HttpMethod.POST,
      body: domain,
    });

    await refresh();
  }

  async function onSubmit() {
    const newDomain: NewReuseIndexDomain = {
      ...(domain?.uuid && { uuid: domain?.uuid }),
      displayName: domainNameState.textValue,
      description: domainDescriptionState.textValue,
    };
    await saveDomain(newDomain, domain?.uuid).then(
      (t: void | ReuseIndexDomain): void => {
        if (t) {
          history.push('/reuse');
        }
      }
    );

    close();
  }

  const repeatedNameText = repeatedName
    ? t('capture.hints.duplicatedName')
    : '';

  const domainNameLengthError = createStringErrorMessage({
    tooLongErrorText: t('reuse.errors.domainNameIsTooLong', {
      maxAllowedLength: MAX_REUSE_DOMAIN_NAME_LENGTH,
    }),
    maxLength: MAX_REUSE_DOMAIN_NAME_LENGTH,
    stringLength: domainNameState.textValueCount,
    minLength: 1,
    tooShortErrorText: t('reuse.hints.domainNameRequired'),
  });
  const domainNameErrorMessage = domainNameLengthError || repeatedNameText;
  const domainDescriptionErrorMessage = createStringErrorMessage({
    stringLength: domainDescriptionState.textValueCount,
    tooLongErrorText: t('reuse.errors.domainDescriptionIsTooLong', {
      maxAllowedLength: MAX_REUSE_DOMAIN_DESCRIPTION_LENGTH,
    }),
    maxLength: MAX_REUSE_DOMAIN_DESCRIPTION_LENGTH,
  });
  const domainIsValid =
    !domainNameErrorMessage && !domainDescriptionErrorMessage;
  const domainWasModified = !!(
    (domain &&
      (domain.description !== domainDescriptionState.textValue ||
        domain.displayName !== domainNameState.textValue)) ||
    (!domain && (domainDescriptionState.textValue || domainNameState.textValue))
  );

  return {
    created: domain?.created,
    domainDescriptionErrorMessage,
    domainDescriptionState,
    domainExists: !!domain,
    domainIsValid,
    domainNameErrorMessage,
    domainNameState,
    domainWasModified,
    modified: domain?.modified,
    nameRequired,
    onSubmit,
  };
}
