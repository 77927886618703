import { Button } from '@mui/material';
import { grey, amber } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import GoalColorSvg from '../GoalColorSvg';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const StyledGoalColorSvg = styled(GoalColorSvg)({
  display: 'flex',
  alignItems: 'flex-start',
});

export const EditCustomPresetButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  height: 'auto',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${grey[400]}`,
  boxShadow: 'none',
  padding: theme.spacing(3 / 5, 5),
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
  },
  '& span.label': {
    display: 'block',
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: theme.typography.pxToRem(0.28),
  },
}));

export const PresetContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2, 3),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const CustomPresetContainer = styled(PresetContainer)(({ theme }) => ({
  backgroundColor: amber[50],
  position: 'relative',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 3),
  '& h4': {
    letterSpacing: theme.typography.pxToRem(0.56),
  },
}));

export const PresetText = styled(Typography)<
  TypographyProps & { component?: React.ElementType }
>(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  alignSelf: 'start',
}));

export const PresetName = styled(PresetText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(4 / 5),
  alignSelf: 'start',
}));
