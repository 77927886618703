import * as React from 'react';
import ErrorNotificationDialog from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';

export const UserDeleteError = () => {
  const { error, setError } = useError();

  if (error) {
    const { value } = error;
    const onClose = () => {
      if (
        value instanceof ApiError &&
        (value.status === 403 || value.status === 404)
      ) {
        window.location.reload();
      }
      setError(null);
    };

    return (
      <ErrorNotificationDialog
        {...apiErrorToNotification(value)}
        onClose={onClose}
      />
    );
  }

  return null;
};
