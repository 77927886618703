import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StyledNoUsersFoundTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
}));

export const StyledNoUsersFoundDetail = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  fontWeight: 400,
  width: theme.typography.pxToRem(500),
}));
