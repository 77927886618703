import React, { PropsWithChildren } from 'react';
import { TooltipProps } from '@mui/material/Tooltip';
import CommonTooltip from '../CommonTooltip';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import ConditionalWrapper, {
  ConditionalWrapperWithProps,
} from '../ConditionalWrapper';
import { ButtonProps } from '@mui/material/Button';
import { StyledButtonGroup } from './styles';

export interface CommonDrawerSubmitButtonProps {
  SplitSaveButtonComponent?: JSX.Element;
  submitButtonLabel: string;
  submitButtonProps: ButtonProps;
  submitErrorText: string | undefined;
}

export default function CommonDrawerSubmitButton({
  SplitSaveButtonComponent,
  submitButtonLabel,
  submitButtonProps,
  submitErrorText,
}: CommonDrawerSubmitButtonProps) {
  return (
    <ConditionalWrapper
      condition={!!SplitSaveButtonComponent}
      wrapper={(children) => (
        <StyledButtonGroup variant="contained">{children}</StyledButtonGroup>
      )}
    >
      <>
        <ConditionalWrapperWithProps
          condition={!!submitErrorText}
          Wrapper={(props: PropsWithChildren<TooltipProps>): JSX.Element => (
            <CommonTooltip
              {...props}
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-8, 0],
                      },
                    },
                  ],
                },
              }}
              title={submitErrorText}
              placement="top-end"
              children={props.children}
            />
          )}
        >
          <ButtonPrimary variant="contained" {...submitButtonProps}>
            {submitButtonLabel}
          </ButtonPrimary>
        </ConditionalWrapperWithProps>
        {SplitSaveButtonComponent}
      </>
    </ConditionalWrapper>
  );
}
