import { useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import AuthContext from 'contexts/AuthContext';
import FeaturesContext from 'contexts/FeaturesContext';
import { ApiError, getErrorType } from 'errors/ApiError';

import { processFetchJSONResponse } from './fetch';
import { isApiErrorResponse } from '../../errors/utils';

interface ResponseWithData<T> extends Response {
  json(): Promise<T>;
}

type Options<B extends boolean> = {
  config?: RequestInit;
  returnResponse?: B;
};

type Method = 'get' | 'post' | 'put' | 'delete';

type RequestOptions<U, B extends boolean> = {
  path: string;
  body?: U;
  options?: Options<B>;
};

type ReturnValue<T, B extends boolean> = B extends true
  ? ResponseWithData<T>
  : T;

type ConvertError<T> = {
  data: T;
  url: string;
  method: string;
  status: number;
  t: TFunction;
  response: ResponseWithData<T>;
};

const convertError = async <T>({
  data,
  url,
  method,
  status,
  response,
  t,
}: ConvertError<T>) => {
  let title: string;
  let detail: string;
  let type: string;
  let reference: string | undefined;

  const request = { url, method };
  let resp: Record<string, any> = {};
  const browserLog = data instanceof Error ? data.toString() : undefined;

  if (isApiErrorResponse(data)) {
    title = data.error.title;
    detail = data.error.detail;
    type = data.error.type;
    reference = data.error.reference;

    resp = {
      status,
      type: data.error.type ?? `${getErrorType(status)}Error`,
      payload: data.error,
    };
  } else {
    title = t('shared.apiError.generalTitle');
    detail = t('shared.apiError.generalDescription');
    type = `${getErrorType(status)}Error`;
    resp = {
      status,
      type: `${getErrorType(status)}Error`,
      payload: data instanceof Error ? await response.clone().text() : data,
    };
  }

  return {
    title,
    detail,
    status,
    type,
    ...(reference ? { reference } : {}),
    log: {
      request,
      response: resp,
      browserLog,
    },
  };
};

export const useFetch = () => {
  const [authToken] = useContext(AuthContext);
  const { enableKeycloak } = useContext(FeaturesContext);
  const { t } = useTranslation();

  const request = async <T, U, B extends boolean>(
    path: string,
    method: Method,
    body?: U,
    options?: Options<B>
  ): Promise<ReturnValue<T, B>> => {
    const isBodyAvailable = method !== 'get' && body;
    const init = {
      method,
      ...(isBodyAvailable ? { body: JSON.stringify(body) } : {}),
      ...options?.config,
      headers: {
        ...(isBodyAvailable ? { 'Content-Type': 'application/json' } : {}),
        ...options?.config?.headers,
        ...(authToken ? { 'X-Acrolinx-Auth': authToken } : {}),
      },
    };

    let response: ResponseWithData<T>;

    try {
      response = await fetch(new Request(path, init));
    } catch (error: unknown) {
      if (error instanceof TypeError) {
        throw new TypeError(
          `${error.message}${navigator.onLine ? '' : ' (offline)'}`
        );
      }
      throw error;
    }

    let data: T;

    try {
      data =
        (response.headers.get('content-type')?.indexOf('application/json') ??
          -1) > -1
          ? await processFetchJSONResponse(response.clone())
          : ((await response.clone().text()) as T);
    } catch (error) {
      console.error(error);
      throw new ApiError(
        await convertError({
          data: error,
          url: path,
          method,
          status: response.status,
          t,
          response,
        })
      );
    }

    if (!response.ok) {
      if (response.status === 401) {
        window.location.assign(
          `${enableKeycloak ? 'sign-in' : 'logout'}?continue=` +
            window.location.pathname
        );
      } else if (response.status >= 400) {
        throw new ApiError(
          await convertError({
            data,
            url: path,
            method,
            status: response.status,
            t,
            response,
          })
        );
      }
    }

    return (options?.returnResponse ? response : data) as ReturnValue<T, B>;
  };

  return {
    get: <T, B extends boolean = false>(
      arg1: string | RequestOptions<never, B>,
      arg2?: Options<B>
    ): Promise<ReturnValue<T, B>> => {
      const { path, options } =
        typeof arg1 === 'string' ? { path: arg1, options: arg2 } : arg1;

      return request(path, 'get', undefined, options);
    },
    post: <T, U, B extends boolean = false>(
      arg1: string | RequestOptions<U, B>,
      arg2?: U,
      arg3?: Options<B>
    ): Promise<ReturnValue<T, B>> => {
      const { path, body, options } =
        typeof arg1 === 'string'
          ? { path: arg1, body: arg2, options: arg3 }
          : arg1;

      return request(path, 'post', body, options);
    },
    put: <T, U, B extends boolean = false>(
      arg1: string | RequestOptions<U, B>,
      arg2?: U,
      arg3?: Options<B>
    ): Promise<ReturnValue<T, B>> => {
      const { path, body, options } =
        typeof arg1 === 'string'
          ? { path: arg1, body: arg2, options: arg3 }
          : arg1;

      return request(path, 'put', body, options);
    },
    delete: <T, U, B extends boolean = false>(
      arg1: string | RequestOptions<U, B>,
      arg2?: U,
      arg3?: Options<B>
    ): Promise<ReturnValue<T, B>> => {
      const { path, body, options } =
        typeof arg1 === 'string'
          ? { path: arg1, body: arg2, options: arg3 }
          : arg1;

      return request(path, 'delete', body, options);
    },
  };
};
