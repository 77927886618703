import React, { ReactElement, useState, useMemo, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';

import UserContext from 'contexts/UserContext';
import UsersTableBodyRow from './components/UsersTableBodyRow';
import UsersTableHeadRow from './components/UsersTableHeadRow';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';
import ConfirmationDialogDelete from '../ConfirmationDialogDelete';
import ConfirmationDialogBulkDelete from '../ConfirmationDialogBulkDelete';
import ConfirmationDialogBulkRequestInformationUpdate from '../ConfirmationDialogBulkRequestInformationUpdate';
import { SortOrder, User } from 'sections/Users/types';
import type { UserTypeExtended } from './components/UsersTableBodyRow';
import NoUsersFoundInfo from './components/NoUsersFoundInfo';
import './transitions.css';

import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Loading } from '../Loading';
import {
  DeleteMenuItem,
  StyledMenu,
  StyledMenuItem,
  StyledTable,
  StyledTableWrapper,
} from './styles';
import ConfirmationDialogRequestInformationUpdate from '../ConfirmationDialogRequestInformationUpdate';

export const MAX_COLUMN_WIDTH = 180;

export interface HeadCellType {
  key: keyof User;
  label: string;
  sortable: boolean;
  visible: boolean;
  inputType?: string;
  type?: string;
  possibleValues?: string[];
}

export interface UsersTableProps {
  rows: ReadonlyArray<UserTypeExtended> | undefined;
  columns: HeadCellType[];
  isBusy: boolean;
  selected: ReadonlyArray<User['id']>;
  onSelect: (id: string, checked: boolean) => void;
  numRemainingCountForSelection: number;
  orderBy: keyof User;
  orderDirection: SortOrder;
  onChangeOrder: (by: keyof User, direction: SortOrder) => void;
  filteredRoles: User['roles'];
  onChangeFilteredRoles: (filteredRoles: User['roles']) => void;
  onAfterChange?: () => void;
  onSelectAll: (checked: boolean) => void;
}

const UsersTable = ({
  rows,
  columns = [],
  isBusy,
  selected = [],
  onSelect,
  orderBy,
  orderDirection,
  onChangeOrder,
  filteredRoles,
  onChangeFilteredRoles,
  onAfterChange,
  numRemainingCountForSelection,
  onSelectAll,
}: UsersTableProps): ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { id: currentUserId } = useContext(UserContext) || {};
  const userPrivileges = useContext(PrivilegesContext);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItemId, setSelectedItemId] = useState<null | string>(null);

  const [itemsToUpdate, setItemsToUpdate] = useState<ReadonlyArray<User['id']>>(
    []
  );
  const [itemsToDelete, setItemsToDelete] = useState<ReadonlyArray<User['id']>>(
    []
  );

  const visibleColumns = useMemo(
    () => columns.filter(({ visible }) => visible),
    [columns]
  );

  const allItemsPossibleToSelect = useMemo(
    () =>
      (Array.isArray(rows) &&
        rows.filter(
          ({ username, licenseType }) =>
            username !== currentUserId && licenseType !== 'builtin'
        )) ||
      [],
    [rows, currentUserId]
  );

  const isAllSelected =
    (allItemsPossibleToSelect || []).length === selected.length ||
    !numRemainingCountForSelection;

  const disableUnchecked = numRemainingCountForSelection <= 0 && isAllSelected;

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const onMenuMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id?: string) => {
      if (id) {
        setSelectedItemId(id);
      }
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const moreMenuOpen = Boolean(!selectedItemId && anchorEl ? anchorEl : null);

  return (
    <>
      <ConfirmationDialogRequestInformationUpdate
        open={itemsToUpdate.length === 1}
        user={rows?.find((row) => row.id === itemsToUpdate[0])}
        onClose={({ success, showNotification = false }) => {
          setItemsToUpdate([]);

          if (success) {
            onSelectAll(false);
          }

          if (showNotification) {
            setTimeout(() => {
              enqueueSnackbar(
                t('users.notification.requestForUpdateForOneSentSuccessfully')
              );
            }, 400);
          }
        }}
      />
      <ConfirmationDialogBulkRequestInformationUpdate
        open={itemsToUpdate.length > 1}
        items={
          (Array.isArray(rows) &&
            rows.filter(({ id }) => itemsToUpdate.includes(id))) ||
          undefined
        }
        onClose={({ success, showNotification = false }) => {
          setItemsToUpdate([]);

          if (success) {
            onSelectAll(false);
          }

          if (showNotification) {
            setTimeout(() => {
              enqueueSnackbar(
                t(
                  'users.notification.requestForUpdateForManySentSuccessfully',
                  {
                    count: selected.length,
                  }
                )
              );
            }, 400);
          }
        }}
      />
      <ConfirmationDialogDelete
        open={itemsToDelete.length === 1}
        item={
          (Array.isArray(rows) &&
            rows.find(({ id }) => itemsToDelete[0] === id)) ||
          undefined
        }
        onClose={(ok) => {
          setItemsToDelete([]);

          if (ok) {
            if (onAfterChange) {
              onAfterChange();
            }

            // Display notification
            enqueueSnackbar(t('users.notification.deleteOneSuccess'));
          }
        }}
      />
      <ConfirmationDialogBulkDelete
        open={itemsToDelete.length > 1}
        ids={selected}
        onClose={(showSnackbar, reloadUsers) => {
          setItemsToDelete([]);

          if (onAfterChange && reloadUsers) {
            onAfterChange();
          }

          if (showSnackbar) {
            // Display notification
            enqueueSnackbar(
              t('users.notification.deleteMoreSuccess', {
                count: selected.length,
              })
            );
          }
        }}
      />
      {userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && (
        <>
          <StyledMenu
            id="more-options-menu-header"
            anchorEl={!selectedItemId && anchorEl ? anchorEl : null}
            open={moreMenuOpen}
            onClose={handleCloseMoreMenu}
          >
            <StyledMenuItem
              onClick={() => {
                handleCloseMoreMenu();

                setItemsToUpdate(selected);
              }}
            >
              <Trans
                i18nKey="users.actions.requestUpdateMultiple"
                {...{
                  selectedUsersCount: selected.length,
                }}
              >
                Request User Information Update (
                {{ selectedUsersCount: selected.length }})
              </Trans>
            </StyledMenuItem>
            <DeleteMenuItem
              onClick={() => {
                handleCloseMoreMenu();

                setItemsToDelete(selected);
              }}
            >
              {t('users.actions.deleteMultiple', {
                selectedCount: selected.length,
                totalCount: rows?.length ?? 0,
              })}
            </DeleteMenuItem>
          </StyledMenu>
          <StyledMenu
            id="more-options-menu-item"
            anchorEl={selectedItemId && anchorEl ? anchorEl : null}
            keepMounted
            open={Boolean(selectedItemId && anchorEl ? anchorEl : null)}
            onClose={handleCloseMoreMenu}
            onClick={handleCloseMoreMenu}
          >
            <StyledMenuItem
              onClick={() => {
                if (selectedItemId) {
                  setItemsToUpdate([selectedItemId]);
                }
              }}
            >
              {t('users.actions.requestUpdate')}
            </StyledMenuItem>
            <DeleteMenuItem
              onClick={() => {
                if (selectedItemId) {
                  setItemsToDelete([selectedItemId]);
                }
              }}
            >
              {t('users.actions.delete')}
            </DeleteMenuItem>
          </StyledMenu>
        </>
      )}
      <StyledTableWrapper>
        <form noValidate autoComplete="off">
          <StyledTable aria-label={t('users.title')} stickyHeader>
            <TableHead>
              <UsersTableHeadRow
                columns={visibleColumns}
                selectedAmount={selected.length}
                isAllSelected={isAllSelected}
                isCheckboxToSelectAllDisabled={
                  (!isAllSelected && numRemainingCountForSelection <= 0) ||
                  (selected.length === 0 && numRemainingCountForSelection <= 0)
                }
                onClickSelectAll={onSelectAll}
                onMenuMoreClick={onMenuMoreClick}
                orderBy={orderBy}
                orderDirection={orderDirection}
                onChangeOrder={onChangeOrder}
                filteredRoles={filteredRoles}
                onChangeFilteredRoles={onChangeFilteredRoles}
                isMoreMenuOpen={moreMenuOpen}
              />
            </TableHead>
            {!isBusy && (
              <TableBody>
                {Array.isArray(rows) &&
                  rows.map((row, index) => (
                    <UsersTableBodyRow
                      key={row.id}
                      {...row}
                      columns={visibleColumns}
                      index={index}
                      isSelected={isSelected(row.id)}
                      onMenuMoreClick={onMenuMoreClick}
                      onSelect={onSelect}
                      isCheckboxToSelectDisabled={
                        !isSelected(row.id) && disableUnchecked
                      }
                      onAfterChange={onAfterChange}
                    />
                  ))}
              </TableBody>
            )}
          </StyledTable>
        </form>
        {isBusy && <Loading />}
        {!isBusy && Array.isArray(rows) && !rows.length && <NoUsersFoundInfo />}
      </StyledTableWrapper>
    </>
  );
};

export default UsersTable;
