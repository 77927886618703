import { useContext } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { requestResponseOrThrowError } from '../utils/fetch';
import { ReuseIndexDomain } from '../sections/Reuse/types';
import {
  DOMAINS_QUERY_KEY,
  REUSE_BASE_PATH,
  REUSE_DOMAINS_PATH,
} from 'constants/constants';
import AuthContext from 'contexts/AuthContext';

export type UseFetchReuseDomainsResult = UseQueryResult<
  ReuseIndexDomain[],
  Error
>;

export default function useFetchReuseDomains(): UseFetchReuseDomainsResult {
  const [authToken] = useContext(AuthContext);

  async function fetchDomains() {
    return await requestResponseOrThrowError(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_DOMAINS_PATH}`
    );
  }

  return useQuery<ReuseIndexDomain[], Error>({
    queryKey: [DOMAINS_QUERY_KEY],
    queryFn: fetchDomains,
  });
}
