import React, { MutableRefObject } from 'react';
import {
  createUpdate,
  parseNumberParameter,
  validateAgainstRange,
} from '../GuidelineSection/helpers';
import { GeneralParameterProps } from '../GuidelineSection/Parameter';
import { GuidelineRangeVoiceParameter } from '../../types';

export function createValueByIndex(
  index: number,
  parsedValue: number,
  values: [number, number]
): [number, number] {
  const newValue = [];
  const valueChecked = parsedValue ? parsedValue : 0;
  newValue[0] = index ? values[0] : valueChecked;
  newValue[1] = index ? valueChecked : values[1];

  return newValue as [number, number];
}

export function createHandleChange(
  index: number,
  parameterProps: GeneralParameterProps,
  updateFn: (arg: string) => void,
  values: [number, number]
) {
  return function (ev: React.ChangeEvent<HTMLInputElement>) {
    const parsedValue = parseNumberParameter(ev.target.value, values[index]);

    if (parsedValue === values[index]) {
      return;
    }

    const newValue = createValueByIndex(index, Number(parsedValue), values);

    const valueForUpdate =
      !parsedValue && !newValue[index] ? parsedValue : newValue[index];

    updateFn(valueForUpdate.toString());
    createUpdate(parameterProps, JSON.stringify(newValue));
  };
}

export function createHandleBlur(
  index: number,
  lastValue: MutableRefObject<[number, number]>,
  parameterObj: GuidelineRangeVoiceParameter,
  parameterProps: GeneralParameterProps,
  updateFn: (arg: string) => void,
  values: [number, number]
) {
  return (ev: React.FocusEvent<HTMLInputElement>) => {
    const parsedValue = parseNumberParameter(ev.target.value, values[index]);

    if (parsedValue === '') {
      createUpdate(
        parameterProps,
        JSON.stringify(
          createValueByIndex(index, lastValue.current[index], values)
        )
      );
      updateFn(lastValue.current[index].toString());
      return;
    }

    const newValue = createValueByIndex(index, parsedValue as number, values);

    if (!index && newValue[0] > newValue[1]) {
      newValue[0] = newValue[1];
    }

    if (index && newValue[1] < newValue[0]) {
      newValue[1] = newValue[0];
    }

    const validatedValue = validateAgainstRange(
      parameterObj,
      newValue as [number, number]
    ) as [number, number];

    lastValue.current = validatedValue;
    updateFn(validatedValue[index].toString());
    createUpdate(parameterProps, JSON.stringify(validatedValue));
  };
}

export function createValueText(value: number) {
  return `${value}`;
}

export function parseParameterValue(
  parameterValue: string
): string | [number, number] {
  try {
    const res = JSON.parse(parameterValue);
    if (Array.isArray(res)) {
      return res as [number, number];
    }
  } catch {}

  return parameterValue;
}

export function isValueDuringEdition(
  value: number | string,
  oldValue: number,
  parameterObj: GuidelineRangeVoiceParameter
) {
  return (
    value === '' ||
    (value !== oldValue &&
      (value < parameterObj?.rangeMin || value > parameterObj?.rangeMax))
  );
}
