import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Table, TableCell } from '@mui/material';

export const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
}));

export const TableHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.custom.lighterGrey}`,
  textAlign: 'left',
  padding: theme.spacing(0),
  '& p': {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.14px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
      marginLeft: theme.spacing(2),
    },
  },
  '&:focus-within': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .MuiTableSortLabel-root:hover, & .MuiTableSortLabel-root:focus': {
    color: theme.palette.text.primary,
  },
  // object name (target, set)
  '&:first-of-type': {
    width: theme.typography.pxToRem(250),
    minWidth: theme.typography.pxToRem(250),
  },
}));

export const DataRow = styled('tr')(({ theme }) => ({
  width: '100%',
  verticalAlign: 'top',
  borderBottom: `1px solid ${theme.palette.custom.lighterGrey}`,
  backgroundColor: theme.palette.common.white,
  '& a, & td, & th': {
    letterSpacing: '0.14px',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'left',
  },
  '& td p, & th p, & td > div': {
    margin: theme.spacing(2),
  },
  '& a:hover': {
    textDecoration: 'underline',
  },
  '& button': {
    padding: theme.spacing(0, 2.1),
    '& svg': {
      opacity: 0,
    },
  },
  '&:focus-within, &:hover': {
    backgroundColor: theme.palette.primary.light,
    '& button svg': {
      opacity: 1,
    },
  },
}));

export const TableLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 'normal',
  color: theme.palette.text.primary,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&.Mui-active .MuiTableSortLabel-icon': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export const ActionIconsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  '& svg': {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  '& button': {
    '&:hover, &:focus': {
      '& svg': {
        fill: theme.palette.custom.darkGrey,
        backgroundColor: 'transparent',
      },
    },
  },
}));
