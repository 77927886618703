import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid, { GridProps } from '@mui/material/Grid';

export const StyledSuggestionCard = styled(Card)(({ theme }) => ({
  boxShadow: '0px 1px 3px #00000080',
  borderRadius: '6px',
  padding: theme.spacing(0, 2, 2, 2),
}));
export const SuggestionTypeText = styled(
  (props: TypographyProps) => (
    <Typography component="span" variant="body1" {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'dismissed',
  }
)<TypographyProps & { dismissed: boolean }>(({ theme, dismissed }) => ({
  display: 'inline-block',
  color: theme.palette.common.black,
  opacity: dismissed ? 0.4 : 1,
  lineHeight: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.32),
}));
export const SuggestionSection = styled((props: GridProps) => (
  <Grid container wrap="nowrap" alignItems="flex-start" {...props} />
))(({ theme }) => ({
  padding: theme.spacing(2),
}));
export const SuggestedPhraseContainer = styled(SuggestionSection)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.oysterBay,
    borderRadius: '6px',
  })
);

export const DeprecatedPhraseContainer = styled(SuggestionSection)(
  ({ theme }) => ({
    backgroundColor: theme.palette.error.light,
    borderRadius: '6px',
  })
);
export const Phrase = styled((props: TypographyProps) => (
  <Typography variant="body2" component="span" {...props} />
))(({ theme }) => ({
  display: 'inline-block',
  paddingTop: theme.spacing(1 / 5),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const SuggestionDetailText = styled((props: { children: ReactNode }) => (
  <Typography {...props} />
))(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(19.5),
  letterSpacing: theme.typography.pxToRem(0.26),
}));

export const SuggestionDetailLabel = styled(SuggestionDetailText)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
  })
);

export const PhraseBreak = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));
