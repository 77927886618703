import React, { useEffect, useState } from 'react';

const useUnsavedChangesOnCloseWarning = () => {
  const [isModified, setIsModified] = useState(false);
  useEffect(() => {
    window.onbeforeunload = isModified ? () => '' : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [isModified]);
  return [setIsModified as React.Dispatch<boolean>] as const;
};
export default useUnsavedChangesOnCloseWarning;
