import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as Error40xAnd50xImage } from 'components/CommonErrorComponent/error-40x-50x.svg';

export const Main = styled(Box)(({ theme }) => ({
  width: 'auto',
  minHeight: 'fit-content',
  marginTop: theme.spacing(7),
  padding: theme.spacing(4, 6),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
}));

export const ErrorDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(33),
  marginBottom: theme.spacing(2),
}));

export const NextStepInstructionsTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  marginBottom: 0,
}));

export const RedirectImage = styled(Error40xAnd50xImage)(({ theme }) => ({
  height: theme.typography.pxToRem(200),
  width: theme.typography.pxToRem(200),
}));

export const NextStepInstructionListItem = styled('li')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(2),
}));
