import React, { forwardRef, Ref } from 'react';
import IconButton, {
  iconButtonClasses,
  IconButtonProps,
} from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${iconButtonClasses['root']}`]: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    padding: theme.spacing(1.2),
    '& svg': {
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      fill: theme.palette.primary.main,
    },
  },
}));

function TargetSettingsIcon(
  props: IconButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <StyledIconButton {...props} ref={ref} color="primary">
      <svg
        data-testid="TargetSettingsIcon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          id="Icon_Target_Configuration"
          data-name="Icon Target Configuration"
          d="M50.919,111.878a.783.783,0,0,0,.784-.782,6.774,6.774,0,1,0-6.775,6.757.782.782,0,1,0,0-1.564,5.194,5.194,0,1,1,5.207-5.194A.783.783,0,0,0,50.919,111.878Zm-4.01-.782a.784.784,0,0,0,1.567,0,3.551,3.551,0,0,0-6.057-2.5,3.54,3.54,0,0,0,2.509,6.043.782.782,0,1,0,0-1.564,2.026,2.026,0,0,1-1.981-1.976,1.979,1.979,0,0,1,1.981-1.975A1.98,1.98,0,0,1,46.909,111.1Zm-1.2,9.193a.784.784,0,0,1-.785.782,9.975,9.975,0,1,1,10-9.975.784.784,0,0,1-1.568,0,8.421,8.421,0,0,0-8.432-8.411,8.411,8.411,0,1,0,0,16.822A.784.784,0,0,1,45.713,120.289Zm8.172-3.023a2.093,2.093,0,0,0,.016-.282c0-.095,0-.185-.012-.281l.771-.584a.414.414,0,0,0,.108-.537l-.775-1.336a.411.411,0,0,0-.519-.174l-.891.376a3.148,3.148,0,0,0-.485-.281l-.12-.956a.416.416,0,0,0-.411-.364H50.019a.417.417,0,0,0-.415.364l-.12.956a3.148,3.148,0,0,0-.485.281l-.892-.376a.41.41,0,0,0-.518.174l-.775,1.34a.416.416,0,0,0,.108.538l.771.584c-.008.091-.012.182-.012.276s0,.186.012.282l-.771.583a.416.416,0,0,0-.108.538l.775,1.336a.41.41,0,0,0,.518.174l.892-.376a3.148,3.148,0,0,0,.485.281l.12.955a.416.416,0,0,0,.411.365h1.546a.416.416,0,0,0,.411-.365l.12-.955a3.148,3.148,0,0,0,.485-.281l.892.376a.41.41,0,0,0,.518-.174l.775-1.336a.417.417,0,0,0-.107-.538Zm-3.077,1.166a1.448,1.448,0,1,1,1.452-1.448A1.451,1.451,0,0,1,50.807,118.433Z"
          transform="translate(-34.928 -101.122)"
        />
      </svg>
    </StyledIconButton>
  );
}

export default forwardRef<HTMLButtonElement, IconButtonProps>(
  TargetSettingsIcon
);
