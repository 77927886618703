export enum SentencesReadyStatus {
  NOT_ENOUGH_CONTENT = 'notEnoughContentChecked',
  ENOUGH_FOR_SCHEDULED = 'enoughContentForScheduledHarvestingRun',
  ENOUGH_FOR_IMMEDIATE = 'enoughContentForImmediateHarvestingRun',
}

const BAR_VALUE_FOR_MISSING_DATA = 0;
const HALF_BAR_DEFAULT_RATIO = 6; // to make up a half of the flexbox

export function createProgressBarValues(
  sentencesReady: number | undefined,
  sentenceCountMid: number | undefined,
  sentenceCountMax: number | undefined
) {
  if (
    sentencesReady === undefined ||
    sentenceCountMid === undefined ||
    sentenceCountMax === undefined
  ) {
    return {
      leftProgressBarValue: BAR_VALUE_FOR_MISSING_DATA,
      rightProgressBarValue: BAR_VALUE_FOR_MISSING_DATA,
      leftProgressBarRatio: HALF_BAR_DEFAULT_RATIO,
      sentencesReadyStatus: SentencesReadyStatus.NOT_ENOUGH_CONTENT,
    };
  }
  sentencesReady =
    sentencesReady > sentenceCountMax ? sentenceCountMax : sentencesReady;
  const ratioOfCollectedSentencesToMid = sentencesReady / sentenceCountMid;
  const leftProgressBarValueRough =
    ratioOfCollectedSentencesToMid < 1
      ? ratioOfCollectedSentencesToMid * 100
      : 100;
  const leftProgressBarValue =
    leftProgressBarValueRough > 0
      ? Math.max(leftProgressBarValueRough, 5)
      : leftProgressBarValueRough;
  const rightProgressBarValueRough =
    ratioOfCollectedSentencesToMid <= 1
      ? 0
      : ((sentencesReady - sentenceCountMid) /
          (sentenceCountMax - sentenceCountMid)) *
        100;
  const rightProgressBarValue =
    rightProgressBarValueRough > 0
      ? Math.max(rightProgressBarValueRough, 5)
      : rightProgressBarValueRough;
  const leftProgressBarRatio = Math.ceil(
    (sentenceCountMid / sentenceCountMax) * 12
  );
  let sentencesReadyStatus = SentencesReadyStatus.NOT_ENOUGH_CONTENT;

  if (sentencesReady > sentenceCountMid && sentencesReady < sentenceCountMax) {
    sentencesReadyStatus = SentencesReadyStatus.ENOUGH_FOR_SCHEDULED;
  }
  if (sentencesReady >= sentenceCountMax) {
    sentencesReadyStatus = SentencesReadyStatus.ENOUGH_FOR_IMMEDIATE;
  }

  return {
    leftProgressBarValue,
    rightProgressBarValue,
    leftProgressBarRatio,
    sentencesReadyStatus,
  };
}
