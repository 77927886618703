import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { SmallOutlinedSelect, StyledMenuItem } from 'components/OutlinedSelect';
import CheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import { LanguageFull, TargetIndexItem } from 'types/types';
import { ReuseSuggestion } from 'sections/Reuse/types';
import { useLanguageTargetFilter } from './hooks';
import {
  generateLanguageSelectedValue,
  generateTargetSelectedValue,
} from './helpers';
import { StyledDivider } from './components';

export interface LanguageTargetFilterDropdownsProps {
  setFilteredSuggestionList: (list: ReuseSuggestion[]) => void;
  sortedTargets: TargetIndexItem[];
  suggestionList: ReuseSuggestion[];
  languages: LanguageFull[] | undefined;
}

export default function LanguageTargetFilterDropdowns(
  props: LanguageTargetFilterDropdownsProps
) {
  const { t } = useTranslation();
  const {
    allLanguagesCheckbox,
    allTargetsCheckbox,
    languageDropdownValue,
    languageList,
    targetDropdownValue,
    targetList,
    updateLanguage,
    updateTarget,
    updateAll,
  } = useLanguageTargetFilter(props);

  const languageGroups = useMemo(() => {
    return (
      props.languages?.reduce((acc, language) => {
        if (!acc.includes(language.abbreviation)) {
          acc.push(language.abbreviation);
        }

        return acc;
      }, [] as string[]) ?? []
    );
  }, [props.languages]);

  return (
    <Grid>
      {languageGroups.length > 1 && (
        <>
          <SmallOutlinedSelect
            fixedWidth={250}
            label={t('reuseInbox.labels.languageFilterDropdown')}
            multiple
            value={languageDropdownValue}
            renderValue={() => generateLanguageSelectedValue(t, languageList)}
            disabled={languageList.length <= 1}
          >
            <StyledMenuItem
              value={allLanguagesCheckbox.id}
              onClick={(ev) => {
                ev.preventDefault(); // preventing autonomous checkbox actions on click
                updateAll(!allLanguagesCheckbox.checked);
              }}
            >
              <CheckboxWithLabel {...allLanguagesCheckbox} />
            </StyledMenuItem>
            <StyledDivider aria-hidden="true" />
            {languageList.map((langObj, index) => (
              <StyledMenuItem
                value={langObj.id}
                key={langObj.id}
                onClick={(ev) => {
                  ev.preventDefault(); // preventing autonomous checkbox actions on click
                  updateLanguage(
                    { ...langObj, checked: !langObj.checked },
                    index
                  );
                }}
              >
                <CheckboxWithLabel
                  lang={langObj.id}
                  label={langObj.label}
                  checked={langObj.checked}
                />
              </StyledMenuItem>
            ))}
          </SmallOutlinedSelect>
          <Grid
            sx={(theme) => ({
              display: 'inline-block',
              padding: theme.spacing(2),
            })}
          />
        </>
      )}
      <SmallOutlinedSelect
        fixedWidth={250}
        label={t('reuseInbox.labels.targetFilterDropdown')}
        multiple
        value={targetDropdownValue}
        renderValue={() => generateTargetSelectedValue(t, targetList)}
        disabled={targetList.length <= 1}
      >
        <StyledMenuItem
          value={allTargetsCheckbox.id}
          onClick={(ev) => {
            ev.preventDefault(); // preventing autonomous checkbox actions on click
            updateAll(!allTargetsCheckbox.checked);
          }}
        >
          <CheckboxWithLabel {...allTargetsCheckbox} />
        </StyledMenuItem>
        <StyledDivider aria-hidden="true" />
        {targetList.map((targetObj, index) => (
          <StyledMenuItem
            value={targetObj.id}
            key={targetObj.id}
            onClick={(ev) => {
              ev.preventDefault(); // preventing autonomous checkbox actions on click
              updateTarget(
                { ...targetObj, checked: !targetObj.checked },
                index
              );
            }}
          >
            <CheckboxWithLabel
              lang={targetObj.lang}
              label={targetObj.label}
              checked={targetObj.checked}
            />
          </StyledMenuItem>
        ))}
      </SmallOutlinedSelect>
    </Grid>
  );
}
