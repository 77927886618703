import { useEffect, useState } from 'react';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const ResizeObserver =
  typeof window !== 'undefined' && 'ResizeObserver' in window
    ? (window as any).ResizeObserver // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    : Polyfill;

export interface UseResizeResult<T> {
  resizeElement: T | null;
  setResizeElement: (resizeTarget: T | null) => void;
  resizedHeight: number;
}

export default function useResize<T extends HTMLElement>(): UseResizeResult<T> {
  const [resizeElement, setResizeElement] = useState<T | null>(null);
  const [resizedHeight, setResizedHeight] = useState<number>(0);

  useEffect(() => {
    if (!resizeElement) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setResizedHeight(resizeElement?.offsetHeight || 0);
    });
    observer.observe(resizeElement);
    return () => observer.disconnect();
  }, [resizeElement]);

  return {
    resizeElement,
    setResizeElement,
    resizedHeight,
  };
}
