import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export const LicenseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.18),
  fontWeight: 400,
  marginBottom: theme.spacing(4),
}));

export const StyledHelpOutlineOutlinedIcon = styled(HelpOutlineOutlinedIcon)(
  ({ theme }) => ({
    color: theme.palette.custom.botticelli,
  })
);
