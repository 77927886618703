import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { useIsOpen } from 'hooks/useIsOpen';
import { State } from 'hooks/useRequestStatus';
import {
  ReplacementsImportFailedStatus,
  ReplacementsImportResult,
} from 'sections/Reuse/types';
import ImportFailed from './ImportFailed';
import ImportFullySuccessful from './ImportFullySuccessful';
import ImportPartiallySuccessful from './ImportPartiallySuccessful';

interface UploadReplacementResultDialogProps {
  importState: State<ReplacementsImportResult>;
  onClose: () => void;
}

export default function UploadReplacementResultDialog({
  importState,
  onClose,
}: UploadReplacementResultDialogProps) {
  const { t } = useTranslation();
  const { close, elementIsOpen } = useIsOpen(true);

  function closeHandler() {
    close();
    onClose();
  }

  const failure = !!(
    importState.error ||
    importState.data?.status === ReplacementsImportFailedStatus.Failure ||
    (importState.data && !importState.data?.replacements)
  );

  const fullSuccess = !!(
    !failure &&
    importState.data &&
    !importState.data?.failed.length
  );

  const partialSuccess = !!(!failure && importState.data?.failed.length);

  return (
    <ConfirmationDialog
      title={t('reuse.titles.importReplacements')}
      open={elementIsOpen}
      onClose={closeHandler}
      buttonOKLabel={t('shared.actions.close')}
      ContentComponent={Grid}
    >
      {failure && <ImportFailed />}
      {fullSuccess && (
        <ImportFullySuccessful
          numberOfReplacements={importState.data?.replacements as number}
        />
      )}
      {partialSuccess && (
        <ImportPartiallySuccessful importState={importState} />
      )}
      <Grid sx={(theme) => ({ marginTop: theme.spacing(2) })} />
    </ConfirmationDialog>
  );
}
