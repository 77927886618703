import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { pink } from '@mui/material/colors';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useFetch } from 'utils/fetch';
import { Trans, useTranslation } from 'react-i18next';
import { BulkOperationResponse, User } from 'sections/Users/types';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';
interface ConfirmationDialogBulkDeleteProps {
  open: boolean;
  ids: ReadonlyArray<User['id']>;
  onClose: (showSnackbar: boolean, reloadUsers: boolean) => void;
}

export default function ConfirmationDialogBulkDelete({
  ids,
  onClose,
  open,
}: Readonly<ConfirmationDialogBulkDeleteProps>): ReactElement {
  const { t } = useTranslation();
  const { setError } = useError();
  const http = useFetch();

  return (
    <ConfirmationDialog
      open={open}
      title={t('users.labels.deleteMultiple')}
      buttonOKLabel={t('shared.actions.remove')}
      buttonCancelLabel={t('shared.actions.cancel')}
      onClose={async (ok) => {
        if (ok && ids.length) {
          try {
            const response = await http.delete<
              BulkOperationResponse,
              ReadonlyArray<string>
            >('/api/v1/user/bulk', ids);
            if (response.errors.length) {
              setError({
                value: new ApiError({
                  title: t('users.apiError.bulkDeleteUsersTitle'),
                  detail:
                    response.errors.length === ids.length
                      ? t('users.apiError.bulkDeleteUsersDescriptionAllFailed')
                      : t('users.apiError.bulkDeleteUsersDescription', {
                          selection: ids.length,
                          success: response.results.length,
                          failure: response.errors.length,
                        }),
                  status: 207,
                  type: 'BulkDeleteError',
                  log: {
                    request: {
                      url: '/api/v1/user/bulk',
                      method: 'DELETE',
                    },
                    response: {
                      status: 207,
                      type: 'BulkDeleteError',
                      payload: response.errors,
                    },
                  },
                }),
                origin: 'userDelete.bulk',
              });
            }

            const allSuccessful = response.errors.length === 0;
            const allFailed = response.results.length === 0;

            onClose(allSuccessful, !allFailed);
          } catch (error) {
            setError({
              value: error as ApiError,
              origin: 'userDelete.bulk',
            });
            onClose(false, false);
          }
        }
        onClose(false, false);
      }}
    >
      <Trans
        i18nKey="users.descriptions.deleteMultipleUsersConfirmation"
        {...{
          usersCount: ids.length,
        }}
      >
        Permanently delete
        <Box
          component={'span'}
          display={'inline-block'}
          sx={{
            py: 2 / 5,
            px: 8 / 5,
            borderRadius: 1,
            backgroundColor: pink[50],
            color: pink.A700,
          }}
        >
          <>{{ usersCount: ids.length }}</>
        </Box>
        users?
      </Trans>
    </ConfirmationDialog>
  );
}
