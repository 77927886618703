import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import {
  NewPhrase,
  ReuseDomain,
  ReuseDomainCluster,
} from 'sections/Reuse/types';
import {
  checkIfReplacementIsValid,
  createNewClusterObject,
  doesPreferredPhraseExist,
  getClusterDomains,
  getDeprecatedPhrases,
  removeDuplicatedValues,
  removeEmptyDeprecatedPhrases,
  updateNewClusterObject,
} from './helpers';
import useText, { UseTextResult } from 'hooks/useText';
import {
  REUSE_CLUSTERS_PATH,
  REUSE_DEPRECATED_PHRASE_MAX_LENGTH,
  REUSE_PREFERRED_PHRASE_MAX_LENGTH,
  REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH,
} from 'sections/Reuse/constants';
import AuthContext from 'contexts/AuthContext';
import { fetchJsonFromAPI, HttpMethod } from 'utils/fetch';
import { useCalculateDrawerContentHeight } from 'components/CommonDrawer';
import { HandleChangeDomainCheckboxFn } from 'components/DomainMenu/types';
import { UpdateInformation } from 'types/types';
import { REUSE_BASE_PATH } from '../../../../constants/constants';

export const newPhrase: NewPhrase = {
  phrase: '',
};

export interface UseCreateOrEditReplacementProps {
  closeDrawer: () => void;
  cluster: ReuseDomainCluster | null;
  clusterDomains: UseClusterDomainsResult;
  newAddedDeprecatedPhrases?: string[];
  refresh?: () => Promise<ReuseDomain | undefined>;
  suggestionId?: string;
}

export interface UseCreateOrEditReplacementResult {
  created: UpdateInformation | undefined;
  deprecatedPhrases: ReuseDomainCluster['deprecatedPhrases'];
  descriptionErrorMessage: string;
  descriptionState: UseTextResult;
  duplicatedPhrases: number[];
  modified: UpdateInformation | undefined;
  newCluster: ReuseDomainCluster;
  originallyDeprecatedPhrasesExist: boolean;
  originalCluster: ReuseDomainCluster | null;
  preferredPhraseErrorMessage: string;
  preferredPhraseState: UseTextResult;
  replacementIsValid: boolean;
  replacementWasUpdated: boolean;
  saveCluster: () => Promise<void>;
  selectedLanguageId: string;
  setDeprecatedPhrases: (arg: ReuseDomainCluster['deprecatedPhrases']) => void;
  setSelectedLanguageId: (arg: string) => void;
}

export type InnerDrawerPropsFromHook = Omit<
  UseCreateOrEditReplacementResult,
  'replacementIsValid' | 'replacementWasUpdated' | 'saveCluster'
>;

export function useCreateOrEditReplacement({
  closeDrawer,
  cluster,
  clusterDomains,
  newAddedDeprecatedPhrases = [],
  refresh,
  suggestionId,
}: UseCreateOrEditReplacementProps): UseCreateOrEditReplacementResult {
  const { t } = useTranslation();
  const originallyDeprecatedPhrasesExist = useRef<boolean>(
    !!cluster?.deprecatedPhrases?.length
  );
  const [newCluster, setNewCluster] = useState<ReuseDomainCluster>(
    createNewClusterObject(cluster)
  );
  const originalNewCluster = useRef<ReuseDomainCluster>(
    createNewClusterObject(cluster)
  );
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>(
    cluster?.language || ''
  );
  const { preferredPhraseErrorMessage, preferredPhraseState } =
    usePreferredPhrase({ cluster, newCluster, setNewCluster });
  const descriptionState = useText(cluster?.description || '');
  const [deprecatedPhrases, setDeprecatedPhrases] = useState<
    ReuseDomainCluster['deprecatedPhrases']
  >(() => {
    return [
      ...getDeprecatedPhrases(cluster, newAddedDeprecatedPhrases),
      { phrase: '' },
    ];
  });
  const [authToken] = useContext(AuthContext);

  async function saveCluster() {
    const clusterToBeSaved = { ...newCluster };

    if (clusterToBeSaved.deprecatedPhrases) {
      // don't save empty or whitespace deprecated phrases
      clusterToBeSaved.deprecatedPhrases = removeEmptyDeprecatedPhrases(
        clusterToBeSaved.deprecatedPhrases
      );
    }

    const parameter = suggestionId ? `?suggestionUuid=${suggestionId}` : '';

    await fetchJsonFromAPI<any, any>(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_CLUSTERS_PATH}${parameter}`,
      {
        method: cluster?.uuid ? HttpMethod.PATCH : HttpMethod.POST,
        body: clusterToBeSaved,
      }
    );
    if (typeof refresh === 'function') {
      await refresh();
    }
    closeDrawer();
  }

  useEffect(() => {
    setNewCluster({
      ...newCluster,
      language: selectedLanguageId,
      preferredPhrase: {
        ...newCluster.preferredPhrase,
        phrase: preferredPhraseState.textValue,
      },
      description: descriptionState.textValue,
      deprecatedPhrases,
      reuseDomains: clusterDomains.domains,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    selectedLanguageId,
    preferredPhraseState.textValue,
    descriptionState.textValue,
    deprecatedPhrases,
    clusterDomains.domains,
  ]);

  const replacementWasUpdated = !isEqual(originalNewCluster.current, {
    ...newCluster,
    deprecatedPhrases: removeEmptyDeprecatedPhrases(
      newCluster.deprecatedPhrases
    ),
  });
  const descriptionIsTooLong =
    descriptionState.textValueCount > REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH;
  const deprecatedPhraseIsTooLong = deprecatedPhrases.reduce((acc, dPhrase) => {
    return acc || dPhrase.phrase.length > REUSE_DEPRECATED_PHRASE_MAX_LENGTH;
  }, false);
  const descriptionIsTooLongErrorMessage = descriptionIsTooLong
    ? t('reuse.errors.replacementDescriptionIsTooLong', {
        maxAllowedLength: REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH,
      })
    : '';
  const duplicatedPhrases = useMemo(() => {
    return deprecatedPhrases.length && preferredPhraseState.textValue
      ? deprecatedPhrases.reduce((indexes, deprecatedPhrase, i) => {
          const indexesCopy = [...indexes];
          if (
            deprecatedPhrase.phrase.trim() ===
            preferredPhraseState.textValue.trim()
          ) {
            indexesCopy.push(i);
          }

          return indexesCopy;
        }, [] as number[])
      : [];
  }, [deprecatedPhrases, preferredPhraseState.textValue]);
  const replacementIsValid =
    newCluster &&
    !duplicatedPhrases.length &&
    checkIfReplacementIsValid(cluster, newCluster, {
      deprecatedPhraseIsTooLong,
      descriptionIsTooLong,
      preferredPhraseHasError: !!preferredPhraseErrorMessage,
    });

  return {
    created: cluster?.created,
    deprecatedPhrases,
    descriptionErrorMessage: descriptionIsTooLongErrorMessage,
    descriptionState,
    duplicatedPhrases,
    modified: cluster?.modified,
    newCluster,
    originallyDeprecatedPhrasesExist: originallyDeprecatedPhrasesExist.current,
    originalCluster: cluster,
    preferredPhraseErrorMessage,
    preferredPhraseState,
    replacementIsValid,
    replacementWasUpdated,
    saveCluster,
    selectedLanguageId,
    setDeprecatedPhrases,
    setSelectedLanguageId,
  };
}

export interface UsePreferredPhraseResult {
  preferredPhraseErrorMessage: string;
  preferredPhraseState: UseTextResult;
}

export interface UsePreferredPhraseProps {
  cluster: ReuseDomainCluster | null;
  newCluster: ReuseDomainCluster;
  setNewCluster: Dispatch<SetStateAction<ReuseDomainCluster>>;
}

export function usePreferredPhrase({
  cluster,
  newCluster,
  setNewCluster,
}: UsePreferredPhraseProps): UsePreferredPhraseResult {
  const { t } = useTranslation();
  const preferredPhraseState = useText(cluster?.preferredPhrase?.phrase || '');

  useEffect(() => {
    setNewCluster(
      removeDuplicatedValues(
        cluster,
        {
          ...cluster?.preferredPhrase,
          phrase: preferredPhraseState.textValue,
        },
        'preferredPhrase',
        updateNewClusterObject({
          cluster,
          newCluster,
          preferredPhrase: {
            ...newCluster.preferredPhrase,
            phrase: preferredPhraseState.textValue,
          },
        })
      )
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [preferredPhraseState.textValue]);

  const preferredPhraseIsTooLong =
    preferredPhraseState.textValueCount > REUSE_PREFERRED_PHRASE_MAX_LENGTH;
  const preferredPhraseIsTooLongErrorMessage = preferredPhraseIsTooLong
    ? t('reuse.errors.preferredPhraseIsTooLong', {
        maxAllowedLength: REUSE_PREFERRED_PHRASE_MAX_LENGTH,
      })
    : '';
  const preferredPhraseDoesNotExistErrorMessage = !doesPreferredPhraseExist(
    cluster,
    newCluster
  )
    ? t('reuse.errors.preferredPhraseIsRequired')
    : '';
  const preferredPhraseErrorMessage =
    preferredPhraseIsTooLongErrorMessage ||
    preferredPhraseDoesNotExistErrorMessage;

  return {
    preferredPhraseErrorMessage,
    preferredPhraseState,
  };
}

export interface UseClusterDomainsResult {
  addDomain: (domainId: string) => void;
  domains: ReuseDomainCluster['reuseDomains'];
  // modified: boolean;
  removeDomain: (domainId: string) => void;
}

export function useClusterDomains(
  cluster: ReuseDomainCluster | null,
  domain?: ReuseDomain
): UseClusterDomainsResult {
  const [domains, setDomains] = useState<ReuseDomainCluster['reuseDomains']>(
    getClusterDomains(cluster, domain)
  );

  useEffect(() => {
    setDomains(getClusterDomains(cluster, domain));
  }, [cluster, domain]);

  function removeDomain(domainId: string) {
    const newDomains = [...domains];
    const index = newDomains.findIndex((d) => d === domainId);
    newDomains.splice(index, 1);

    setDomains(newDomains);
  }

  function addDomain(domainId: string) {
    const newDomains = [...domains];
    newDomains.push(domainId);

    setDomains(newDomains);
  }

  return {
    addDomain,
    domains,
    removeDomain,
  };
}

export interface UseInnerProps {
  clusterDomains: UseClusterDomainsResult;
  duplicatedPhrases: UseCreateOrEditReplacementResult['duplicatedPhrases'];
  footerRect: DOMRect | null;
  preferredPhraseErrorMessage: string;
}

export function useInner({
  clusterDomains,
  duplicatedPhrases,
  footerRect,
  preferredPhraseErrorMessage,
}: UseInnerProps) {
  const { t } = useTranslation();
  const [preferredPhraseIsPristine, setPreferredPhraseIsPristine] =
    useState(true);
  const { elementHeight, refElement } =
    useCalculateDrawerContentHeight(footerRect);

  const preferredPhraseIsDuplicatedErrorMessage = duplicatedPhrases.length
    ? t('reuse.errors.preferredPhraseIsTheSameAsDeprecatedPhrase')
    : '';

  const localPreferredPhraseErrorMessage =
    preferredPhraseIsDuplicatedErrorMessage ||
    (!preferredPhraseIsPristine && preferredPhraseErrorMessage);

  const handleChangeDomainCheckbox: HandleChangeDomainCheckboxFn = useCallback(
    (checked, affectedDomains) => {
      if (checked) {
        clusterDomains.addDomain(affectedDomains[0]);
        return;
      }

      clusterDomains.removeDomain(affectedDomains[0]);
    },
    [clusterDomains]
  );

  return {
    elementHeight,
    handleChangeDomainCheckbox,
    localPreferredPhraseErrorMessage,
    refElement,
    setPreferredPhraseIsPristine,
  };
}
