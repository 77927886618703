import React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayDate from 'components/DisplayDate';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(2.3),
  paddingBottom: theme.spacing(2.3),
}));

interface UsageInfoProps {
  createdOn: string | undefined;
  lastAccess: string | undefined;
  checkingFrequency: string | undefined;
}

const UsageInfo = ({
  createdOn,
  lastAccess,
  checkingFrequency,
}: UsageInfoProps) => {
  const { t } = useTranslation();

  return (
    <Box pt={6}>
      <Table sx={{ width: '600px' }}>
        <TableBody>
          <TableRow>
            <StyledTableCell width={250}>
              {t('users.labels.createdOn')}
            </StyledTableCell>
            <StyledTableCell>
              <DisplayDate date={createdOn} tooltipVisible />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>{t('users.columns.lastAccess')}</StyledTableCell>
            <StyledTableCell>
              <DisplayDate date={lastAccess} tooltipVisible />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>{t('users.columns.checks')}</StyledTableCell>
            <StyledTableCell>
              {checkingFrequency &&
                checkingFrequency.charAt(0).toUpperCase() +
                  checkingFrequency.slice(1)}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default UsageInfo;
