import React from 'react';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const StyledReadOnlyPhraseDivider = styled('div')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(3 / 5),
  backgroundColor: blueGrey[100],
  borderRadius: '2px',
  margin: theme.spacing(1, 0, 3, 0),
}));

export const NewDeprecatedPhraseIndicator = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  color: blueGrey[800],
  letterSpacing: theme.typography.pxToRem(0.24),
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(2.4),
  padding: theme.spacing(0, 1, 0, 1),
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(18),
  backgroundColor: theme.palette.primary.light,
  display: 'inline-block',
  borderRadius: '2px',
}));
