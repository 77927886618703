import { useState } from 'react';
import { ReuseDomainCluster } from '../types';
import { useIsOpen } from 'hooks/useIsOpen';

export interface UseCreateEditReplacementResult {
  closePhraseDrawer: () => void;
  clusterForEdit: ReuseDomainCluster | null;
  openPhraseDrawer: () => void;
  phraseDrawerIsOpen: boolean;
  setClusterForEdit: (arg: ReuseDomainCluster | null) => void;
}

export default function useCreateEditReplacement(): UseCreateEditReplacementResult {
  const {
    elementIsOpen: phraseDrawerIsOpen,
    open: openPhraseDrawer,
    close,
  } = useIsOpen();
  const [clusterForEdit, setClusterForEdit] =
    useState<ReuseDomainCluster | null>(null);

  function closePhraseDrawer() {
    setClusterForEdit(null);
    close();
  }

  return {
    closePhraseDrawer,
    clusterForEdit,
    openPhraseDrawer,
    phraseDrawerIsOpen,
    setClusterForEdit,
  };
}
