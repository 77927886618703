import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import FilledTextField from 'components/FilledTextField/FilledTextField';
import FilledTextFieldPassword from 'sections/Users/components/FilledTextFieldPassword/FilledTextFieldPassword';
import CommonTooltip from 'components/CommonTooltip';
import RolesInput, {
  RequiredFieldWarning,
} from '../../../RolesInput/RolesInput';
import Collapse from '@mui/material/Collapse';
import StandardDropdown from 'components/StandardDropdown/StandardDropdown';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import { blueGrey } from '@mui/material/colors';
import AccordionSummary, {
  accordionSummaryClasses,
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { useCalculateDrawerContentHeight } from 'components/CommonDrawer';
import { User } from 'sections/Users/types';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { useLicenseTypeOptions } from 'sections/Users/utils';
import {
  AccordionWrapperBox,
  CreateUserSectionContent,
  CreateUserSectionTitle,
  StyledAccordionDetails,
  StyledHelpOutlineOutlinedIcon,
  StyledDrawerContent,
  StyledExpandMoreIcon,
} from './styles';
import { ExternalAuthWarning } from './ExternalAuthWarning';
import FeaturesContext from 'contexts/FeaturesContext';
import TransWrapper from 'components/TransWrapper';
import DebouncedUsernameTextField from './DebouncedUsernameTextField';
import { StyledSelectMenuItem } from 'components/StandardDropdown/styles';

enum Panel {
  Roles,
  UserInformation,
  LicenseType,
}

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion
    square
    elevation={0}
    TransitionProps={{
      unmountOnExit: true,
    }}
    {...props}
  />
))({
  borderLeft: 'none',
  borderRight: 'none',
  '&:before': {
    backgroundColor: blueGrey[100],
  },
  '&.Mui-expanded': {
    margin: 0,
    '&:before': {
      opacity: 1,
    },
  },
  '&.Mui-expanded + &': {
    '&:before': {
      display: 'initial',
    },
  },
});

const StyledAccordionSummary = styled(function AccordionSummaryWithClasses({
  expanded = false,
  ...props
}: AccordionSummaryProps & {
  expanded?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <AccordionSummary
      expandIcon={
        <CommonTooltip
          type="black"
          title={
            <>
              {expanded
                ? t('capture.actions.tooltips.collapse')
                : t('capture.actions.tooltips.expand')}
            </>
          }
          placement="bottom-end"
        >
          <StyledExpandMoreIcon />
        </CommonTooltip>
      }
      {...props}
    />
  );
})(({ theme }) => ({
  height: theme.typography.pxToRem(48),
  color: theme.palette.custom.gray36,
  fontSize: theme.typography.pxToRem(13),
  lineHeight: '18px',
  letterSpacing: '0.39px',
  textTransform: 'uppercase',
  minHeight: 'auto !important',
  padding: theme.spacing(2, 0),
  '&$focusVisible': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
    '&$expanded': {
      margin: 'inherit',
    },
  },
}));

interface StyledAccordionSummaryProps {
  expanded: boolean;
  title: string;
  infoIconText?: string;
}

const CustomAccordionSummary = ({
  expanded = false,
  title,
  infoIconText,
}: StyledAccordionSummaryProps) => {
  return (
    <StyledAccordionSummary expanded={expanded}>
      <Box
        width={1}
        justifyContent="space-between"
        display="flex"
        paddingRight={2}
      >
        <Box flexGrow={1} alignSelf="center" fontWeight={500}>
          {title}
        </Box>
        {!!infoIconText && (
          <Box>
            <CommonTooltip
              type="yellow"
              title={<>{infoIconText}</>}
              placement="left-start"
            >
              <StyledHelpOutlineOutlinedIcon
                tabIndex={0}
                aria-hidden={undefined}
                fontSize="small"
              />
            </CommonTooltip>
          </Box>
        )}
      </Box>
    </StyledAccordionSummary>
  );
};

interface InnerCreateUserDrawerProps {
  isOpen: boolean;
  control: any;
  errors: any;
  customFieldsData: { data: User['customFields'] } | undefined;
  licenseData: any;
  footerRect: DOMRect | null;
}

const InnerCreateUserDrawer = ({
  isOpen,
  control,
  errors,
  customFieldsData,
  licenseData,
  footerRect,
}: InnerCreateUserDrawerProps) => {
  const { t } = useTranslation();
  const licenseTypeOptions = useLicenseTypeOptions();
  const features = useContext(FeaturesContext);

  const { elementHeight, refElement } =
    useCalculateDrawerContentHeight(footerRect);

  const [isFullnameReadOnly, setIsFullnameReadOnly] = useState(true);

  const [expanded, setExpanded] = useState<Panel | boolean>(Panel.Roles);

  useEffect(() => {
    if (isOpen) {
      setExpanded(Panel.Roles);
    }
  }, [isOpen]);

  const handleAccordionChange =
    (panel: Panel | boolean) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <StyledDrawerContent
      ref={refElement}
      style={{ height: `${elementHeight - 5}px` }}
    >
      {features.usesExternalAuth && <ExternalAuthWarning />}
      <Box mb={4}>
        <Controller
          control={control}
          name="username"
          render={(props) => (
            <DebouncedUsernameTextField
              controllerProps={props}
              onChange={props.field.onChange}
            />
          )}
        />
      </Box>
      <Box mb={4}>
        <Controller
          control={control}
          name="fullName"
          render={({ field }) => (
            <FilledTextField
              {...field}
              fullWidth
              id={'input-fullName'}
              label={t('users.labels.fullName')}
              helperText={
                (field.value.length === 255 &&
                  t('users.hints.maxCharacterLengthReached')) ||
                t('users.hints.optional')
              }
              inputProps={{
                maxLength: 255,
              }}
              hasCharacterCounter
              InputProps={{
                readOnly: isFullnameReadOnly,
              }}
              onFocus={() => {
                setIsFullnameReadOnly(false);
              }}
            />
          )}
        />
      </Box>
      {!features.usesExternalAuth && (
        <Box mb={4}>
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <FilledTextFieldPassword
                {...field}
                helperText={error?.type}
                error={
                  !!error?.type && ['required', 'matches'].includes(error?.type)
                }
                inputProps={{
                  maxLength: 64,
                }}
                hasCharacterCounter
              />
            )}
          />
        </Box>
      )}
      <AccordionWrapperBox>
        <StyledAccordion
          expanded={expanded === Panel.Roles}
          onChange={handleAccordionChange(Panel.Roles)}
        >
          <CustomAccordionSummary
            expanded={expanded === Panel.Roles}
            infoIconText={t('users.hints.defaultRole')}
            title={t('shared.navigation.roles')}
          />
          <StyledAccordionDetails>
            <Controller
              control={control}
              name="roles"
              render={({ field: { value, onChange } }) => (
                <Box>
                  <RolesInput selectedRoles={value} onChange={onChange} />
                </Box>
              )}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
        <Collapse
          in={expanded !== Panel.Roles && errors?.roles?.type === 'required'}
        >
          <Box px={0} pb={3}>
            <RequiredFieldWarning />
          </Box>
        </Collapse>
        <StyledAccordion
          expanded={expanded === Panel.UserInformation}
          onChange={handleAccordionChange(Panel.UserInformation)}
        >
          <CustomAccordionSummary
            expanded={expanded === Panel.UserInformation}
            title={t('users.columns.userInformation')}
            infoIconText={t('users.tooltips.userInformationOnCreation')}
          />
          <StyledAccordionDetails>
            {customFieldsData?.data.map(
              ({ key, displayName, inputType, value, ...rest }, index) => {
                const elementId = `input-customField-${encodeURIComponent(
                  key
                )}`;
                const marginBottom =
                  customFieldsData.data.length !== index + 1 ? 4 : 0;

                if (inputType === 'externallyProvided') {
                  return (
                    <Box key={elementId} mb={marginBottom}>
                      <FilledTextField
                        fullWidth
                        id={elementId}
                        label={displayName}
                        readOnly
                        defaultValue={value}
                        helperText={t('users.tooltips.readOnlyTextField')}
                      />
                    </Box>
                  );
                }

                if (!rest?.type) {
                  return <></>;
                }

                return (
                  <Box key={elementId} mb={marginBottom}>
                    <Controller
                      control={control}
                      name={`customFields.${key}`}
                      defaultValue={''}
                      render={({ field }) => {
                        if (rest.type === 'text') {
                          return (
                            <FilledTextField
                              {...field}
                              fullWidth
                              id={elementId}
                              label={displayName}
                              helperText={
                                (field.value.length === 255 &&
                                  t('users.hints.maxCharacterLengthReached')) ||
                                t('users.hints.userInformationOnCreation')
                              }
                              inputProps={{ maxLength: 255 }}
                              hasCharacterCounter
                            />
                          );
                        }

                        if (rest.type === 'list') {
                          const { possibleValues } = rest;
                          return (
                            <StandardDropdown
                              {...field}
                              fullWidth
                              id={elementId}
                              label={displayName}
                              helperText={t(
                                'users.hints.userInformationOnCreation'
                              )}
                            >
                              <StyledSelectMenuItem value="">
                                <em>{t('users.fields.none')}</em>
                              </StyledSelectMenuItem>
                              {possibleValues?.map((option) => (
                                <StyledSelectMenuItem
                                  value={option}
                                  key={option}
                                >
                                  {option}
                                </StyledSelectMenuItem>
                              ))}
                            </StandardDropdown>
                          );
                        }

                        return <></>;
                      }}
                    />
                  </Box>
                );
              }
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
        {licenseData &&
          licenseData?.data?.named?.licensed > 0 &&
          licenseData?.data?.concurrent?.licensed > 0 && (
            <StyledAccordion
              expanded={expanded === Panel.LicenseType}
              onChange={handleAccordionChange(Panel.LicenseType)}
            >
              <CustomAccordionSummary
                expanded={expanded === Panel.LicenseType}
                title={t('users.columns.userLicense')}
              />
              <StyledAccordionDetails>
                <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name={'licenseType'}
                    defaultValue={'named'}
                    render={({ field }) => (
                      <CommonRadioGroup
                        data={licenseTypeOptions}
                        value={field.value}
                        aria-label={t('users.columns.userLicense')}
                        changeHandler={field.onChange}
                      />
                    )}
                  />
                </FormControl>
              </StyledAccordionDetails>
            </StyledAccordion>
          )}
      </AccordionWrapperBox>
      <Box pt={14 / 5} tabIndex={0}>
        <CreateUserSectionTitle variant="h2">
          {t('users.labels.apiToken').toUpperCase()}
        </CreateUserSectionTitle>
        <CreateUserSectionContent variant="body2">
          <TransWrapper i18nKey="users.descriptions.createApiToken" />
        </CreateUserSectionContent>
      </Box>
    </StyledDrawerContent>
  );
};

export default InnerCreateUserDrawer;
