import getLanguageFromCookie from 'helpers/getLanguageFromCookie';

export function getFormattedDateTime(date: string | null | undefined) {
  const dateExists = !!(date && new Date(date).getMilliseconds());

  if (!dateExists) {
    return {
      formattedTime: '',
      formattedDate: '',
    };
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const locale = getLanguageFromCookie();
  const dateObj = new Date(date);
  const formattedTime = new Intl.DateTimeFormat(locale, {
    timeStyle: 'medium',
    timeZone,
  }).format(dateObj);
  const formattedDate = new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
    timeStyle: 'long',
    timeZone,
  }).format(dateObj);

  return {
    formattedTime,
    formattedDate,
  };
}
