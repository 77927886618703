import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import {
  DismissSuggestionContentText,
  StyledDismissSuggestionDialog,
  StyledGrid,
} from './components';
import CheckboxWithLabel from 'components/CommonCheckboxWithLabel/CheckboxWithLabel';

interface DismissSuggestionDialogProps {
  check: () => void;
  dismissed: boolean;
  onClose: (ok: boolean) => void;
  open: boolean;
}

export default function DismissSuggestionDialog({
  check,
  dismissed,
  onClose,
  open,
}: DismissSuggestionDialogProps) {
  const { t } = useTranslation();
  const dialogTitle = t(
    dismissed
      ? 'reuseInbox.titles.unDismissSuggestion'
      : 'reuseInbox.titles.dismissSuggestion'
  );
  const dialogButtonOKLabel = t(
    dismissed
      ? 'reuseInbox.labels.unDismissSuggestion'
      : 'reuseInbox.labels.dismissSuggestion'
  );

  return (
    <StyledDismissSuggestionDialog
      open={open}
      onClose={onClose}
      title={dialogTitle}
      buttonOKLabel={dialogButtonOKLabel}
      buttonCancelLabel={t('capture.actions.cancel')}
      ContentComponent={Grid}
    >
      <StyledGrid>
        <DismissSuggestionContentText
          sx={(theme) => ({
            marginBottom: theme.spacing(4),
            marginLeft: theme.spacing(1),
          })}
        >
          {t(
            dismissed
              ? 'reuseInbox.descriptions.unDismissSuggestion'
              : 'reuseInbox.descriptions.dismissSuggestion'
          )}
        </DismissSuggestionContentText>

        <CheckboxWithLabel
          label={
            <>{t('reuseInbox.labels.deactivateDismissSuggestionDialog')}</>
          }
          onChange={check}
        ></CheckboxWithLabel>
      </StyledGrid>
    </StyledDismissSuggestionDialog>
  );
}
