import React from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';

import { CustomField } from '../../../../types';
import { ChipWithMaxWidthProps } from './ChipWithMaxWidth';
import { useChipList } from './hooks';

export interface TargetAssignmentChipListProps {
  Chip: React.FunctionComponent<ChipWithMaxWidthProps>;
  customField: CustomField;
  footerTop: number | undefined;
  hoverColor: string;
}

export default function TargetAssignmentCustomFieldChipList({
  Chip,
  customField,
  hoverColor,
  footerTop,
}: Readonly<TargetAssignmentChipListProps>) {
  const { anchorRef, close, elementIsOpen, open, placement, setResizeElement } =
    useChipList(footerTop);

  return (
    <>
      <Chip
        labelText={`${customField.name} (${customField.options?.length})`}
        sx={{
          '&:hover, &:focus': {
            background: hoverColor,
          },
        }}
        onMouseEnter={open}
        ref={anchorRef}
      />
      {
        <Popper
          open={elementIsOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement={placement}
          transition
          disablePortal
        >
          {customField.options?.length &&
            (({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper
                  sx={(theme) => ({
                    maxHeight: theme.spacing(40),
                    overflowY: 'scroll',
                  })}
                >
                  <ClickAwayListener onClickAway={close}>
                    <List dense={true} ref={setResizeElement}>
                      {customField.options?.map((optionName) => (
                        <ListItem key={customField.name + optionName}>
                          <ListItemText primary={optionName} />
                        </ListItem>
                      ))}
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            ))}
        </Popper>
      }
    </>
  );
}
