import React, { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormGroup } from '@mui/material';
import isEqual from 'lodash/isEqual';

import { MAX_TARGET_DESCRIPTION_LENGTH } from '../../pages/TargetPage/constants';
import {
  StyledDrawerContent,
  LanguagesContainer,
  SelectGoalsTitle,
  GoalLabel,
  GoalDescription,
} from './styles';
import { InnerCreateOrEditTargetDrawerProps } from './types';
import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import FilledTextField from 'components/FilledTextField';
import LanguageDropdown from 'components/LanguageDropdown';
import { useCalculateDrawerContentHeight } from 'components/CommonDrawer';
import { MAX_TARGET_NAME_LENGTH } from 'sections/Targets/constants';
import { AdministrativeInformationAccordion } from 'components/AdministrativeInformation';
import { GoalInfo } from 'types/types';
import CommonOutlinedMultilineTextFieldWithInnerIcons from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextFieldWithInnerIcons';
import createStringErrorMessage from 'helpers/createStringErrorMessage';

function InnerCreateOrEditTargetDrawer({
  changeLanguage,
  created,
  description,
  descriptionIsValid,
  displayName,
  footerRect,
  goals,
  languages,
  languagesDisabled,
  modified,
  nameRequired,
  repeatedName,
  selectedGoals,
  selectedLanguageId,
  setDescription,
  setDisplayName,
  setSelectedLanguage,
  setSelectedLanguageId,
  setModifiedByUser,
  targetExists,
  tooLongName,
}: Readonly<InnerCreateOrEditTargetDrawerProps>): ReactElement {
  const { t } = useTranslation();

  const { elementHeight, refElement } =
    useCalculateDrawerContentHeight(footerRect);

  const repeatedNameText = repeatedName
    ? t('capture.hints.duplicatedName')
    : '';
  const tooLongNameText = tooLongName
    ? t('capture.hints.tooLongTargetName', {
        targetNameMaxLength: MAX_TARGET_NAME_LENGTH,
      })
    : '';
  const repeatedOrTooLongName = repeatedNameText || tooLongNameText || '';
  const helperText = nameRequired
    ? t('capture.hints.nameRequired')
    : repeatedOrTooLongName;
  const languagesLabel = languagesDisabled
    ? t('capture.labels.language')
    : t('capture.labels.selectLanguage');
  const targetDescriptionErrorMessage = createStringErrorMessage({
    stringLength: description.length,
    tooLongErrorText: t('capture.descriptions.targetDescriptionIsTooLong', {
      maxAllowedLength: MAX_TARGET_DESCRIPTION_LENGTH,
    }),
    maxLength: MAX_TARGET_DESCRIPTION_LENGTH,
  });
  const error = nameRequired || repeatedName || tooLongName;

  return (
    <StyledDrawerContent
      ref={refElement}
      style={{ height: `${elementHeight - 5}px` }}
    >
      <Box mb={4}>
        <FilledTextField
          data-testid="target-name"
          inputProps={{
            'aria-required': true,
            lang: selectedLanguageId.split(/[:-]/)[0],
          }}
          autoFocus
          id="name"
          label={t('capture.labels.name')}
          fullWidth
          value={displayName}
          error={error}
          aria-invalid={error}
          helperText={helperText}
          onChange={(ev) => {
            setModifiedByUser(true);
            setDisplayName(ev.target.value);
          }}
          onBlur={() => {
            setModifiedByUser(true);
          }}
        />
      </Box>

      <LanguagesContainer>
        <LanguageDropdown
          onChange={() => {
            setModifiedByUser(true);
            changeLanguage();
          }}
          languagesDisabled={languagesDisabled}
          languagesLabel={languagesLabel}
          selectedLanguageId={selectedLanguageId}
          setSelectedLanguage={setSelectedLanguage}
          setSelectedLanguageId={setSelectedLanguageId}
          readOnly={targetExists}
          readOnlyLabel={t('capture.labels.language')}
          type="structure"
          languages={languages || []}
        />
      </LanguagesContainer>

      <CommonOutlinedMultilineTextFieldWithInnerIcons
        id="target-description"
        label={t('capture.labels.description')}
        rows="4"
        fullWidth
        helperText={
          targetDescriptionErrorMessage || t('capture.hints.optional')
        }
        inputProps={{ lang: selectedLanguageId.split(/[:-]/)[0] }}
        margin="none"
        error={!descriptionIsValid}
        value={description}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          setModifiedByUser(true);
          setDescription(ev.target.value);
        }}
        onBlur={() => {
          setDescription(description.trim());
        }}
        charCount={description.length}
        maxCharCount={MAX_TARGET_DESCRIPTION_LENGTH}
      />

      <FormControl required component="fieldset" id="goals-selector">
        <legend>
          <SelectGoalsTitle
            aria-label={t('capture.tooltips.mustSelectAtLeastOne')}
          >
            {t('capture.descriptions.addGoalsToTarget')}
          </SelectGoalsTitle>
        </legend>
        <FormGroup lang={selectedLanguageId.split(/[:-]/)[0]}>
          {goals.map((goal: GoalInfo) => (
            <div key={goal.goalId}>
              <CommonCheckboxWithLabel
                checked={selectedGoals.set.has(goal.goalId)}
                color="secondary"
                value={goal.goalId}
                label={
                  <GoalLabel variant="body2">{goal.displayName}</GoalLabel>
                }
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setModifiedByUser(true);
                  selectedGoals.setKey(goal.goalId, ev.target.checked);
                }}
              />

              <GoalDescription>{goal.description}</GoalDescription>
            </div>
          ))}
        </FormGroup>
      </FormControl>
      {targetExists && (
        <AdministrativeInformationAccordion
          created={created}
          modified={modified}
        />
      )}
    </StyledDrawerContent>
  );
}

export default React.memo(InnerCreateOrEditTargetDrawer, isEqual);
