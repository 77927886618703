import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import CommonDrawer from 'components/CommonDrawer';
import InnerCreateOrEditReplacementDrawer from './InnerCreateOrEditReplacementDrawer';
import ReadOnlyInnerReplacementDrawer from './ReadOnlyInnerReplacementDrawer';
import { useClusterDomains, useCreateOrEditReplacement } from './hooks';
import {
  CompletePhrase,
  ReuseDomain,
  ReuseDomainCluster,
  ReuseIndexDomain,
} from 'sections/Reuse/types';

import { LanguageFull, LanguageSimple } from 'types/types';

export interface CreateOrEditReplacementDrawerProps {
  closeDrawer: () => void;
  cluster: ReuseDomainCluster | null;
  domain?: ReuseDomain;
  domainList: ReuseIndexDomain[];
  drawerIsOpen: boolean;
  editRights: boolean;
  languages: LanguageSimple[] | LanguageFull[] | undefined;
  newAddedDeprecatedPhrases?: string[];
  refresh?: () => any;
  suggestionId?: string;
}

export default function CreateOrEditReplacementDrawer({
  closeDrawer,
  cluster,
  domain,
  domainList,
  drawerIsOpen,
  editRights,
  languages = [],
  newAddedDeprecatedPhrases = [],
  refresh,
  suggestionId,
}: CreateOrEditReplacementDrawerProps) {
  const { t } = useTranslation();
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );
  const clusterDomains = useClusterDomains(cluster, domain);
  const { replacementIsValid, replacementWasUpdated, saveCluster, ...props } =
    useCreateOrEditReplacement({
      closeDrawer,
      cluster,
      refresh,
      clusterDomains,
      newAddedDeprecatedPhrases,
      suggestionId,
    });
  const sortedDomainList = useMemo(() => [...domainList].sort(), [domainList]);
  const domainsAreValid = !!clusterDomains.domains.length;
  const domainsChanged =
    (cluster && !isEqual(cluster?.reuseDomains, clusterDomains.domains)) ||
    (!cluster && domainsAreValid);
  const submitIsDisabled =
    !replacementIsValid ||
    (!replacementWasUpdated && !domainsChanged) ||
    !domainsAreValid;

  return editRights ? (
    <CommonDrawer
      cancelAndCloseHandler={closeDrawer}
      cancelButtonLabel={t('shared.actions.cancel')}
      drawerIsOpen={drawerIsOpen}
      drawerTitle={t(
        cluster?.uuid
          ? 'reuse.titles.editReplacement'
          : 'reuse.titles.addReplacement'
      )}
      drawerSubTitle={t(
        cluster?.uuid
          ? 'reuse.descriptions.editReplacement'
          : 'reuse.descriptions.addReplacement'
      )}
      submitButtonClickHandler={async () => {
        await saveCluster();
        closeDrawer();
      }}
      submitButtonLabel={t(
        cluster ? 'shared.actions.save' : 'shared.actions.add'
      )}
      submitDisabled={submitIsDisabled}
      submitErrorText={props.preferredPhraseErrorMessage}
      type="wide"
      setFooterRect={setDrawerFooterRect}
    >
      <InnerCreateOrEditReplacementDrawer
        footerRect={drawerFooterRect}
        domainList={sortedDomainList}
        clusterDomains={clusterDomains}
        clusterId={cluster?.uuid}
        newAddedDeprecatedPhrases={newAddedDeprecatedPhrases}
        languages={languages}
        {...props}
      />
    </CommonDrawer>
  ) : (
    <CommonDrawer
      cancelAndCloseHandler={closeDrawer}
      drawerIsOpen={drawerIsOpen}
      drawerTitle={t('reuse.titles.viewReplacement')}
      drawerSubTitle={t('reuse.descriptions.viewReplacement')}
      type="wide"
      setFooterRect={setDrawerFooterRect}
      submitButtonClickHandler={closeDrawer}
      submitButtonLabel={t('shared.actions.close')}
    >
      <ReadOnlyInnerReplacementDrawer
        footerRect={drawerFooterRect}
        domainList={sortedDomainList}
        clusterDomains={clusterDomains}
        {...props}
        deprecatedPhrases={props.deprecatedPhrases as CompletePhrase[]}
        languages={languages}
      />
    </CommonDrawer>
  );
}
