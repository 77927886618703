import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const TooltipRolesList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
}));

export const LongRolesBox = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const MoreRolesBox = styled(Box)(({ theme }) => ({
  cursor: 'default',
  height: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(20),
  border: `1px solid ${theme.palette.custom.darkBluishGrey}`,
  borderRadius: theme.typography.pxToRem(4),
  color: theme.palette.custom.darkBluishGrey,
  fontSize: theme.typography.pxToRem(13),
}));
