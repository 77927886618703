import * as React from 'react';
import { Redirect } from 'react-router-dom';

import ErrorNotificationDialog from 'sections/Users/components/ErrorNotificationDialog';
import { ApiError } from 'errors/ApiError';
import InternalServerErrorComponent from 'pages/InternalServerErrorComponent';
import { apiErrorToNotification } from 'errors/utils';

export const GetUsersError = ({ error }: { error: ApiError | Error }) => {
  if (error instanceof ApiError) {
    if (error.status === 403) {
      return <Redirect to="/403" />;
    }
    if (error.status === 404) {
      return <Redirect to="/404" />;
    }
  }

  return (
    <>
      <ErrorNotificationDialog {...apiErrorToNotification(error)} />
      <InternalServerErrorComponent />
    </>
  );
};
