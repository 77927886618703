import React, { useState, useEffect } from 'react';
import FilledTextField from 'components/FilledTextField';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'utils/utils';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';

interface DebouncedFilledTextFieldProps {
  controllerProps: {
    field: ControllerRenderProps<FieldValues, 'username'>;
    fieldState: ControllerFieldState;
  };
  onChange: (s: string) => void;
}

const DebouncedUsernameTextField = ({
  controllerProps,
  onChange,
  ...props
}: DebouncedFilledTextFieldProps) => {
  const { t } = useTranslation();

  const [isUsernameReadOnly, setIsUsernameReadOnly] = useState(true);

  const {
    field,
    fieldState: { error },
  } = controllerProps;
  const [usernameValue, setUsernameValue] = useState('');
  const [enableValidation, setEnableValidation] = useState(false);

  const debouncedUsernameValue = useDebounce(usernameValue);

  useEffect(() => {
    if (enableValidation) {
      onChange(debouncedUsernameValue);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedUsernameValue, onChange]);

  return (
    <FilledTextField
      {...props}
      fullWidth
      label={t('users.labels.username')}
      value={usernameValue}
      id={'input-username'}
      error={
        error?.type && ['required', 'unique', 'matches'].includes(error?.type)
      }
      helperText={
        (error?.type === 'required' && t('users.hints.required')) ||
        (error?.type === 'unique' && t('users.hints.usernameAlreadyExists')) ||
        (error?.type === 'matches' &&
          error?.message === 'startsWithSpace' &&
          t('users.hints.canNotStartWithSpace')) ||
        (error?.type === 'matches' &&
          error?.message === 'usernameInvalidCharacters' &&
          t('users.hints.usernameInvalidCharacters')) ||
        (field.value.length === 255 &&
          t('users.hints.maxCharacterLengthReached')) ||
        t('users.hints.required')
      }
      inputProps={{
        maxLength: 255,
      }}
      hasCharacterCounter
      InputProps={{
        readOnly: isUsernameReadOnly,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setUsernameValue(event.target.value);
        setEnableValidation(true);
      }}
      onFocus={() => {
        setIsUsernameReadOnly(false);
      }}
    />
  );
};

export default DebouncedUsernameTextField;
