import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { dialogClasses } from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

export const DismissSuggestionContentText = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.14),
  lineHeight: theme.typography.pxToRem(21),
  fontSize: theme.typography.pxToRem(14),
}));

export const StyledDismissSuggestionDialog = styled(ConfirmationDialog)(
  ({ theme }) => ({
    [`& .${dialogClasses.paper}`]: {
      paddingLeft: theme.spacing(5),
    },
    h2: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  width: theme.spacing(68),
}));
