import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import {
  CUSTOM,
  GOALS_BY_LANGUAGE_PATH,
  GOALS_QUERY_KEY,
  RELOAD_LS_PATH,
  TARGET_PATH,
  TARGET_QUERY_KEY,
} from './constants';
import {
  Goal,
  GoalConfig,
  Target,
  TargetNew,
  DialogReloadLSData,
} from './types';

import useTitle from 'hooks/useTitle';
import { TargetActions } from './components/GoalSection';
import { createNewGoalConfig, findGuidelinesWithWarnings } from './helpers';
import AuthContext from 'contexts/AuthContext';
import {
  fetchJsonFromAPI,
  fetchPlainResponse,
  HttpMethod,
  requestResponseOrThrowError,
} from 'utils/fetch';
import { GuidelineSectionProps } from './components/GuidelineSection/GuidelineSection';
import { useTargetActions } from './targetActions';
import { useIsOpen, UseIsOpenResult } from 'hooks/useIsOpen';
import useResize, { UseResizeResult } from 'hooks/useResize';
import useFetchTargets from 'hooks/useFetchTargets';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { TargetIndexItem } from 'types/types';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import { useComment, UseCommentResult } from '../../hooks/useComment';
import { EditContextsDrawerForProps } from './components/EditContextsDrawer';
import { useTargetsPrivileges, PrivilegesStructure } from 'hooks/usePrivileges';

export interface UseTargetPageResult {
  actions: TargetActions;
  buttonsDimensions: UseResizeResult<HTMLDivElement>;
  commentData: UseCommentResult;
  configuredGoals: Goal[];
  dialogWithLSReloadControl: UseIsOpenResult;
  dialogWithReloadLSData: DialogReloadLSData[];
  dialogWithLSReloadIsActive: boolean;
  drawerEditTargetIsOpen: boolean;
  expandedGoals: boolean;
  getGoalConfig: (goalId: string) => GoalConfig;
  handleClose: () => void;
  headerDimensions: UseResizeResult<HTMLDivElement>;
  languageDisplayName: string;
  languageIdShort: string;
  openContextDrawerFor: EditContextsDrawerForProps | undefined;
  patchTarget: (targetPatch: TargetNew) => Promise<void>;
  privileges: PrivilegesStructure;
  reloadLS: () => Promise<void>;
  reset: () => void;
  save: () => Promise<void>;
  setDialogWithLSReloadIsActive: (value: boolean) => void;
  setDialogWithReloadLSData: (value: DialogReloadLSData[]) => void;
  setDrawerEditTargetIsOpen: (args: boolean) => void;
  setExpandedGoals: (args: boolean) => void;
  setOpenContextDrawerFor: (
    args: EditContextsDrawerForProps | undefined
  ) => void;
  snackbarMessage: string;
  target: Target | undefined;
  targetResult: UseQueryResult<Target, Error>;
  targetsOverviewResult: UseQueryResult<TargetIndexItem[], Error>;
  guidelinesWithWarning: Record<string, string[]>;
}

export function useTargetPage(): UseTargetPageResult {
  const { id } = useParams<{ id: TargetIndexItem['uuid'] }>();
  const { t } = useTranslation();
  const dialogWithLSReloadControl = useIsOpen();
  const [authToken] = useContext(AuthContext);
  const privileges = useTargetsPrivileges();

  const [drawerEditTargetIsOpen, setDrawerEditTargetIsOpen] =
    useState<boolean>(false);
  const [expandedGoals, setExpandedGoals] = useState<boolean>(false);
  const [openContextDrawerFor, setOpenContextDrawerFor] =
    useState<EditContextsDrawerForProps>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [dialogWithLSReloadIsActive, setDialogWithLSReloadIsActive] =
    useState<boolean>(false);
  const [dialogWithReloadLSData, setDialogWithReloadLSData] = useState<
    DialogReloadLSData[]
  >([]);
  const [guidelinesWithWarning, setGuidelinesWithWarning] = useState<
    Record<string, string[]>
  >({});
  const headerDimensions = useResize<HTMLDivElement>();
  const buttonsDimensions = useResize<HTMLDivElement>();

  const targetResult = useFetchTarget();
  const { targetsResult: targetsOverviewResult } = useFetchTargets();
  const commentData = useComment(targetResult.data?.comment || '');

  const [target, setTarget] = useState<Target | undefined>();
  // Experimental for isSuperReactiveModeEnabled
  const [, setModifiedByUser] = useState(false);

  useEffect(() => {
    // Clone target initial load. So we can edit it without changing the original target.
    // Important for revert and change detection.
    if (targetResult.data) {
      setTarget(cloneDeep(targetResult.data));
    }
  }, [targetResult.data]);
  useTitle(
    `${target?.displayName} - ${t('capture.titles.targets')} - ${t(
      'shared.app.name'
    )}`
  );

  const { languageId, languageDisplayName } = useTargetLanguage({
    target,
    targetsOverviewResult,
  });
  const goalsResult = useFetchGoals({ languageId });

  const allGoals: Goal[] = useMemo(
    () => goalsResult.data ?? [],
    [goalsResult.data]
  );
  const configuredGoals: Goal[] = allGoals.filter((goal) =>
    target?.targetGoalInfos.find((x) => x.goalId === goal.identifier)
  );

  const getGoalConfig = useCallback(
    (goalId: string): GoalConfig => {
      const config = {
        goalId: goalId,
        recommended: false,
      };
      return target?.targetGoalInfos.find((x) => x.goalId === goalId) || config;
    },
    [target]
  );

  const actions: TargetActions = useTargetActions({
    allGoals,
    dialogWithReloadLSData,
    openContextDrawerFor,
    originalTarget: targetResult.data,
    setDialogWithLSReloadIsActive,
    setOpenContextDrawerFor,
    setDialogWithReloadLSData,
    setModifiedByUser,
    setSnackbarMessage,
    setTarget,
    target,
  });

  async function save() {
    await fetchJsonFromAPI<Target, never>(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGET_PATH.replace(':id', id)}`,
      { method: HttpMethod.PUT, body: target }
    );
    commentData.setTextValue('');

    await targetResult.refetch();
  }

  async function reloadLS() {
    await fetchPlainResponse(
      authToken,
      `/target-service/api/v1/${RELOAD_LS_PATH}?language-id=${
        languageId.split(':')[0]
      }`,
      { method: HttpMethod.GET }
    );
  }

  function reset() {
    setTarget(cloneDeep(targetResult.data));
  }

  useDidMountEffect(() => {
    if (
      !commentData.error &&
      target &&
      target.comment !== commentData.textValue
    ) {
      setTarget({
        ...target,
        comment: commentData.textValue,
      });
    }
  }, [commentData]);

  async function patchTarget(targetPatch: TargetNew): Promise<void> {
    if (!target) {
      throw new Error('Target is unexpected undefined.');
    }

    const newTarget = cloneDeep(target);

    newTarget.displayName = targetPatch.displayName;
    newTarget.description = targetPatch.description;
    newTarget.targetGoalInfos = targetPatch.goalIds.map(
      (goalId) =>
        newTarget.targetGoalInfos.find((x) => x.goalId === goalId) ||
        createNewGoalConfig(allGoals, goalId)
    );

    setTarget(newTarget);
    setModifiedByUser(true);
  }

  function handleClose() {
    setSnackbarMessage('');
  }

  useEffect(() => {
    const guidelines = configuredGoals.reduce(
      findGuidelinesWithWarnings({ getGoalConfig }),
      {} as Record<string, string[]>
    );
    if (!isEqual(guidelines, guidelinesWithWarning)) {
      setGuidelinesWithWarning(guidelines);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getGoalConfig, configuredGoals]);

  return {
    actions,
    buttonsDimensions,
    commentData,
    configuredGoals,
    dialogWithLSReloadControl,
    dialogWithReloadLSData,
    dialogWithLSReloadIsActive,
    drawerEditTargetIsOpen,
    expandedGoals,
    getGoalConfig,
    handleClose,
    headerDimensions,
    languageDisplayName,
    languageIdShort: languageId.split(/[:-]/)[0],
    openContextDrawerFor,
    patchTarget,
    privileges,
    reloadLS,
    reset,
    save,
    setDialogWithLSReloadIsActive,
    setDialogWithReloadLSData,
    setDrawerEditTargetIsOpen,
    setExpandedGoals,
    setOpenContextDrawerFor,
    snackbarMessage,
    target,
    targetResult,
    targetsOverviewResult,
    guidelinesWithWarning,
  };
}

export type UseFetchTargetResult = UseQueryResult<Target, Error>;

export function useFetchTarget(): UseFetchTargetResult {
  const [authToken] = useContext(AuthContext);
  const { id } = useParams<{ id: TargetIndexItem['uuid'] }>();

  async function fetchTarget() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGET_PATH.replace(':id', id)}`
    );
  }
  return useQuery<Target, Error>({
    queryKey: [TARGET_QUERY_KEY, id],
    queryFn: fetchTarget,
  });
}

export interface UseTargetLanguageProps {
  target: Target | undefined;
  targetsOverviewResult: UseQueryResult<TargetIndexItem[], Error>;
}

export function useTargetLanguage({
  target,
  targetsOverviewResult,
}: UseTargetLanguageProps) {
  const [languageDetails, setLanguageDetails] = useState({
    languageId: '',
    languageDisplayName: '',
  });

  useEffect(() => {
    const languageId = target?.languageId || '';
    const targetIndexObj = targetsOverviewResult.data?.find(
      (x) => x.uuid === target?.uuid
    );
    const languageDisplayName =
      targetIndexObj?.languageVariantDisplayName || '';

    if (
      languageDetails.languageId !== languageId ||
      languageDetails.languageDisplayName !== languageDisplayName
    ) {
      setLanguageDetails({
        languageId,
        languageDisplayName,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [target?.languageId, target?.uuid, targetsOverviewResult.data]);

  return languageDetails;
}

export interface UseFetchGoalsProps {
  languageId: string;
}

export function useFetchGoals({
  languageId,
}: UseFetchGoalsProps): UseQueryResult<Goal[], Error> {
  const [authToken] = useContext(AuthContext);

  const path = languageId
    ? GOALS_BY_LANGUAGE_PATH.replace(':languageId', languageId)
    : '';

  async function fetchGoals() {
    return path
      ? await requestResponseOrThrowError(
          authToken,
          path ? `${TARGET_SERVICE_PATH}/${path}` : ''
        )
      : null;
  }

  return useQuery<Goal[], Error>({
    queryKey: [GOALS_QUERY_KEY, languageId],
    queryFn: fetchGoals,
  });
}

export interface UseGuidelineResult {
  cancelFn: () => void;
  closeWarningModal: () => void;
  guidelineIsActive: boolean;
  okFn: () => void;
  openWarningModal: () => void;
  performOpenEditContextsDrawer: () => void;
  presetWarningModalShouldAppear: boolean;
  setWarningModalCancelCallback: (callback: (() => void) | null) => void;
  setWarningModalOKCallback: (callback: (() => void) | null) => void;
  warningModalIsOpen: boolean;
  warningModalCancelCallback: (() => void) | null;
  warningModalOKCallback: (() => void) | null;
}

export type UseGuidelineProps = Omit<
  GuidelineSectionProps,
  'languageIdShort' | 'readOnly'
>;

export function useGuideline({
  goal,
  guideline,
  guidelineConfig,
  targetActions,
  targetGoal,
}: UseGuidelineProps): UseGuidelineResult {
  const {
    close: closeWarningModal,
    elementIsOpen: warningModalIsOpen,
    open: openWarningModal,
  } = useIsOpen();
  const guidelineIsActive = guidelineConfig?.active ?? true;
  const [warningModalOKCallback, setWarningModalOKCallback] = useState<
    (() => void) | null
  >(null);
  const [warningModalCancelCallback, setWarningModalCancelCallback] = useState<
    (() => void) | null
  >(null);
  const presetWarningModalShouldAppear =
    targetGoal?.selectedPresetId?.toLowerCase() !== CUSTOM &&
    !!targetGoal?.customPreset?.edited;

  function performOpenEditContextsDrawer() {
    if (goal && guidelineConfig) {
      targetActions.openEditContextsDrawer({
        goal,
        guideline,
        contextConfig: {
          contexts: guidelineConfig.contexts,
          active: true,
          contextsFilterType: guidelineConfig.contextsFilterType,
        },
        warningModal: {
          presetWarningModalShouldAppear,
          openWarningModal,
          setWarningModalCancelCallback,
          setWarningModalOKCallback,
        },
      });
    }
  }

  const okFn = useCallback(() => {
    if (typeof warningModalOKCallback === 'function') {
      warningModalOKCallback();
      setWarningModalOKCallback(null);
      setWarningModalCancelCallback(null);
    }
  }, [
    warningModalOKCallback,
    setWarningModalOKCallback,
    setWarningModalCancelCallback,
  ]);

  const cancelFn = useCallback(() => {
    if (typeof warningModalCancelCallback === 'function') {
      warningModalCancelCallback();
      setWarningModalCancelCallback(null);
      setWarningModalOKCallback(null);
    }
  }, [
    warningModalCancelCallback,
    setWarningModalCancelCallback,
    setWarningModalOKCallback,
  ]);

  return {
    cancelFn,
    closeWarningModal,
    guidelineIsActive,
    okFn,
    openWarningModal,
    performOpenEditContextsDrawer,
    presetWarningModalShouldAppear,
    setWarningModalCancelCallback,
    setWarningModalOKCallback,
    warningModalIsOpen,
    warningModalCancelCallback,
    warningModalOKCallback,
  };
}

export function useTargetGoal(target: Target, goal: Goal): GoalConfig | null {
  const [targetGoal, setTargetGoal] = useState<GoalConfig | null>(null);

  useEffect(() => {
    if (target && goal) {
      setTargetGoal(
        target.targetGoalInfos.find(
          (goalInfo) => goalInfo.goalId === goal.identifier
        ) as GoalConfig
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [target, goal]);

  return targetGoal;
}
