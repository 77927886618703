import React from 'react';

import { CompletePhrase, ReuseDomainCluster } from '../../types';
import { StyledAccordionPlain } from 'components/FixedAccordion';
import useExpandCollapse from 'hooks/useExpandCollapse';
import { UseCreateOrEditReplacementResult } from '../CreateOrEditReplacementDrawer/hooks';
import AdvancedSettingsSummary from './AdvancedSettingsSummary';
import EditableSettings from './EditableSettings';
import ReadOnlySettings from './ReadOnlySettings';

export interface AdvancedSettingsProps {
  defaultOpen: boolean;
  readOnly: boolean;
}

export interface ReadOnlyAdvancedSettingsProps extends AdvancedSettingsProps {
  deprecatedPhrases: CompletePhrase[];
}

export interface EditableAdvancedSettingsProps extends AdvancedSettingsProps {
  clusterId: string | undefined;
  deprecatedPhrases: ReuseDomainCluster['deprecatedPhrases'];
  duplicatedPhrases: UseCreateOrEditReplacementResult['duplicatedPhrases'];
  newAddedDeprecatedPhrases: string[];
  preferredPhrase: string;
  selectedLanguageId: string;
  setDeprecatedPhrases: (arg: ReuseDomainCluster['deprecatedPhrases']) => void;
}

export type UpdateDeprecatedPhrasesFn = (
  value: string,
  index: number,
  toDelete?: boolean
) => void;

export default function AdvancedSettings(
  props: ReadOnlyAdvancedSettingsProps | EditableAdvancedSettingsProps
) {
  const { defaultOpen, readOnly } = props;
  const { expanded, expandIconTooltipText, setExpanded } =
    useExpandCollapse(defaultOpen);

  return (
    <StyledAccordionPlain
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AdvancedSettingsSummary
        expandIconTooltipText={expandIconTooltipText}
        readOnly={readOnly}
      />
      {readOnly ? (
        <ReadOnlySettings
          deprecatedPhrases={
            (props as ReadOnlyAdvancedSettingsProps).deprecatedPhrases
          }
        />
      ) : (
        <EditableSettings {...(props as EditableAdvancedSettingsProps)} />
      )}
    </StyledAccordionPlain>
  );
}
