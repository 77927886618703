import { styled } from '@mui/material/styles';
import AugmentedIconButton from '../../pages/UsersIndexPage/components/AugmentedIconButton';

export const StyledAugmentedIconButton = styled(AugmentedIconButton)(
  ({ theme }) => ({
    '&:disabled': {
      color: theme.palette.primary.main,
      opacity: 0.4,
    },
    '&:hover, &:focus': {
      color: theme.palette.custom.darkGrey,
      backgroundColor: theme.palette.primary.light,
    },
  })
);
