import React, { useMemo } from 'react';
import { ErrorNotificationDialogControlled } from './ErrorNotificationDialog';

const ErrorNotificationDialogContext = React.createContext<{
  open: (props: OpenProps) => void;
}>({
  open: () => {
    throw new Error(
      'You are using the ErrorNotificationDialog hook without ErrorNotificationDialogProvider. Please add it to the component tree.'
    );
  },
});

type OpenProps = Omit<
  React.ComponentProps<typeof ErrorNotificationDialogControlled>,
  'open' | 'onClose'
> & {
  onClose?: () => void;
};

export const ErrorNotificationDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);
  const [config, setConfig] = React.useState<OpenProps>();

  const onClose = () => {
    setOpen(false);
    config?.onClose?.();
    setConfig(undefined);
  };

  const providerValue = useMemo(
    () => ({
      open: (props: OpenProps) => {
        setOpen(true);
        setConfig(props);
      },
    }),
    []
  );

  return (
    <ErrorNotificationDialogContext.Provider value={providerValue}>
      <ErrorNotificationDialogControlled
        open={open}
        onClose={onClose}
        title={config?.title ?? ''}
        description={config?.description ?? ''}
        error={config?.error}
        clipboardText={config?.clipboardText}
      />
      {children}
    </ErrorNotificationDialogContext.Provider>
  );
};

export const useErrorNotificationDialog = () =>
  React.useContext(ErrorNotificationDialogContext);
