import CommonTooltip from 'components/CommonTooltip';
import React from 'react';
import {
  ReuseHarvestingFailedIconButton,
  ReuseHarvestingFinishedIconButton,
  ReuseHarvestingRunningIconButton,
} from '../ReuseHarvestingStatusIcons';
import HarvestingStatusDialog from './HarvestingStatusDialog';
import { ReuseHarvestingState, useStatusInfo } from './hooks';
import { useTranslation } from 'react-i18next';

export default function HarvestingStatusButtonAndDialog() {
  const {
    closeStatusDialog,
    harvestingStatus,
    openStatusDialog,
    statusDialogIsOpen,
  } = useStatusInfo();
  const { t } = useTranslation();

  return (
    <>
      {harvestingStatus?.reuseHarvestingState ===
        ReuseHarvestingState.HARVESTING && (
        <CommonTooltip title={t('reuseInbox.tooltips.statusIconRunning')}>
          <ReuseHarvestingRunningIconButton
            onClick={openStatusDialog}
            sx={() => ({
              animation: 'rotate 2s linear infinite',
              '@keyframes rotate': {
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            })}
          />
        </CommonTooltip>
      )}
      {harvestingStatus?.reuseHarvestingState === ReuseHarvestingState.IDLE && (
        <CommonTooltip title={t('reuseInbox.tooltips.statusIconSuccess')}>
          <ReuseHarvestingFinishedIconButton onClick={openStatusDialog} />
        </CommonTooltip>
      )}
      {harvestingStatus?.reuseHarvestingState ===
        ReuseHarvestingState.FAILURE && (
        <CommonTooltip title={t('reuseInbox.tooltips.statusIconFailed')}>
          <ReuseHarvestingFailedIconButton onClick={openStatusDialog} />
        </CommonTooltip>
      )}
      {statusDialogIsOpen ? (
        <HarvestingStatusDialog
          open={statusDialogIsOpen}
          onClose={closeStatusDialog}
          harvestingStatus={harvestingStatus}
        />
      ) : (
        <></>
      )}
    </>
  );
}
