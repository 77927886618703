import React from 'react';
import { UpdateInformation } from '../../types/types';
import TextWithEllipsisAndTooltip from '../TextWithEllipsisAndTooltip';

export interface UserNameProps {
  data: UpdateInformation | null | undefined;
  defaultNameReplacement: string;
  maxWidth?: string;
}
export default function UserName({
  data,
  defaultNameReplacement,
  maxWidth = '270px', // default for the drawer
}: UserNameProps) {
  if (!data || (!data.user && !data.userFullName)) {
    return <>{defaultNameReplacement}</>;
  }

  return (
    <>
      <TextWithEllipsisAndTooltip maxWidth={maxWidth} text={data.user} />
      {data.userFullName && (
        <TextWithEllipsisAndTooltip
          maxWidth={maxWidth}
          text={data.userFullName}
        />
      )}
    </>
  );
}
