import { useQuery } from '@tanstack/react-query';

import { useFetch } from 'utils/fetch';
import { ROLES_API_PATH, USER_API_PATH } from 'constants/constants';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { Role, User } from '../../../../types';

type RolesResponse = {
  data: ReadonlyArray<Role>;
};

export const useRolesQuery = () => {
  const { open } = useErrorNotificationDialog();
  const http = useFetch();

  return useQuery<RolesResponse, Error>({
    queryKey: ['roles'],
    queryFn: async () => {
      try {
        return await http.get<RolesResponse>(ROLES_API_PATH);
      } catch (error) {
        open(apiErrorToNotification(error));
        throw error;
      }
    },
  });
};

type UserResponse = {
  data: User;
};

export const useGetUserQuery = (
  userId: string,
  isCurrentUserSelected: boolean
) => {
  const { open } = useErrorNotificationDialog();
  const http = useFetch();

  return useQuery<UserResponse, Error>({
    queryKey: ['user', userId],
    queryFn: async () => {
      try {
        return await http.get<UserResponse>(`${USER_API_PATH}/${userId}`);
      } catch (error) {
        open(apiErrorToNotification(error));
        throw error;
      }
    },
    enabled: !isCurrentUserSelected,
  });
};
