import React, { ChangeEvent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import CommonDrawer from 'components/CommonDrawer';
import InnerEditContextsDrawerContent from './InnerEditContextsDrawerContent';
import {
  ContextConfig,
  ContextsFilterType,
  Goal,
  GuidelineId,
} from '../../types';
import { CheckboxValue } from 'types/types';
import { EditContextsDrawerForProps, useContexts } from './hooks';
import CommonLockIconButton from '../../../../../../components/CommonLockIconButton';

export interface EditContextsDrawerProps {
  close: () => void;
  disabled: boolean;
  openFor: EditContextsDrawerForProps;
  setContextConfig: (
    goal: Goal,
    guidelineId: GuidelineId,
    contextConfig: ContextConfig
  ) => void;
  setOpenFor: (arg: EditContextsDrawerForProps | undefined) => void;
}

/* eslint-disable react/prop-types */
export function EditContextsDrawer({
  close,
  disabled,
  openFor,
  setContextConfig,
  setOpenFor,
}: EditContextsDrawerProps): JSX.Element {
  const { t } = useTranslation();
  const {
    contextConfigIsModified,
    contextResponse,
    drawerFooterRect,
    localContextConfig,
    setContextConfigIsModified,
    setDrawerFooterRect,
    setLocalContextConfig,
  } = useContexts(openFor);

  function handleContextsChange(values: Set<CheckboxValue>) {
    const contexts = Array.from(values)
      .filter((v) => v.checked)
      .map((v) => v.label);
    setLocalContextConfig({
      ...localContextConfig,
      contexts: new Set(contexts),
    });

    setContextConfigIsModified(
      !isEqual(contexts, openFor.contextConfig.contexts)
    );
  }

  function handleFilterChange(evt: ChangeEvent<{ value?: unknown }>): void {
    setLocalContextConfig({
      ...localContextConfig,
      contextsFilterType: evt.target.value as ContextsFilterType,
    });
    setContextConfigIsModified(
      evt.target.value !== openFor.contextConfig.contextsFilterType
    );
  }

  const makeUpdate = useCallback(() => {
    setContextConfig(openFor.goal, openFor.guideline.identifier, {
      ...localContextConfig,
      contexts: Array.from(localContextConfig.contexts),
    });
    close();
    setContextConfigIsModified(false);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [localContextConfig]);

  function submitHandler() {
    const {
      presetWarningModalShouldAppear,
      openWarningModal,
      setWarningModalCancelCallback,
      setWarningModalOKCallback,
    } = openFor.warningModal || {};
    if (
      presetWarningModalShouldAppear &&
      typeof openWarningModal === 'function' &&
      typeof setWarningModalOKCallback === 'function' &&
      typeof setWarningModalCancelCallback === 'function'
    ) {
      openWarningModal();
      setWarningModalOKCallback(() => makeUpdate);
      setWarningModalCancelCallback(() => close);
      return;
    }
    makeUpdate();
  }

  return (
    <CommonDrawer
      drawerIsOpen={!!openFor}
      submitButtonClickHandler={submitHandler}
      cancelAndCloseHandler={() => {
        setOpenFor(undefined);
        close();
      }}
      drawerTitle={
        <>
          {disabled && (
            <CommonLockIconButton
              sx={(theme) => ({
                marginLeft: theme.spacing(-2.2),
                marginRight: theme.spacing(1),
              })}
              tooltipText={t('capture.tooltips.readonly')}
            />
          )}
          {t(
            disabled
              ? 'capture.titles.viewContexts'
              : 'capture.titles.editContexts'
          )}
        </>
      }
      cancelButtonLabel={t('shared.actions.cancel')}
      setFooterRect={setDrawerFooterRect}
      submitButtonLabel={t('capture.actions.select')}
      submitDisabled={!contextConfigIsModified}
      submitErrorText=""
    >
      <InnerEditContextsDrawerContent
        contextList={contextResponse.data ?? []}
        contextConfig={openFor.contextConfig}
        footerRect={drawerFooterRect}
        guidelineDisplayName={openFor.guideline.displayName}
        handleContextsChange={handleContextsChange}
        handleFilterChange={handleFilterChange}
        contextConfigIsModified={contextConfigIsModified}
        disabled={disabled}
      />
    </CommonDrawer>
  );
}

export default memo(EditContextsDrawer, isEqual);
