import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  QueryObserverResult,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { requestResponseOrThrowError } from 'utils/fetch';

import {
  OriginalReuseInboxSuggestions,
  ReuseDomainCluster,
  ReuseNewSuggestion,
  ReuseSuggestion,
  ReuseSuggestionStatus,
  SuggestionDataType,
  SuggestionStatusType,
} from 'sections/Reuse/types';
import {
  REUSE_ASSISTANT_PATH,
  REUSE_INBOX_PATH,
  REUSE_NOT_SHOW_DISMISS_SUGGESTION_DIALOG,
  REUSE_SUGGESTIONS_STATUS_PATH,
  REUSE_SUPPORTED_LANGUAGES,
  SUGGESTIONS_PER_PAGE,
} from 'sections/Reuse/constants';
import { createNewClusterObject } from 'sections/Reuse/components/CreateOrEditReplacementDrawer/helpers';
import { useIsOpen, UseIsOpenResult } from 'hooks/useIsOpen';
import {
  REUSE_BASE_PATH,
  REUSE_SUGGESTIONS_QUERY_KEY,
} from 'constants/constants';
import {
  UseReuseDomainsResult,
  useReuseDomains,
} from 'sections/Reuse/hooks/useReuseDomains';
import useFetchTargets, { UseFetchTargetsResult } from 'hooks/useFetchTargets';
import { CheckboxValue, LanguageFull } from 'types/types';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useFetchLanguages } from 'hooks/useFetchLanguages';
import AuthContext from 'contexts/AuthContext';
import { createListOfSupportedLanguages } from 'components/LanguageDropdown/helpers';
import useResize from 'hooks/useResize';

export function useFetchSuggestions(dismissed: boolean) {
  const [authToken] = useContext(AuthContext);
  const [page, setPage] = useState<number>(1);

  async function fetchSuggestions() {
    const path = dismissed
      ? `${REUSE_BASE_PATH}/${REUSE_ASSISTANT_PATH}?status=dismissed&pageSize=${SUGGESTIONS_PER_PAGE}&pageNumber=${page}`
      : `${REUSE_BASE_PATH}/${REUSE_INBOX_PATH}`;

    return await requestResponseOrThrowError(authToken, path);
  }
  const reuseSuggestionsResult = useQuery<
    OriginalReuseInboxSuggestions | PaginatedSuggestions,
    Error
  >({
    queryKey: [REUSE_SUGGESTIONS_QUERY_KEY, dismissed, page],
    queryFn: fetchSuggestions,
  });

  useDidMountEffect(() => {
    setPage(1);
  }, [dismissed]);

  return {
    page,
    reuseSuggestionsResult,
    setPage,
  };
}

export interface PaginatedSuggestions {
  suggestionsPage: OriginalReuseInboxSuggestions;
  totalSuggestionCount: number;
}

export type UseSuggestionListResult = Omit<
  UseQueryResult<OriginalReuseInboxSuggestions | PaginatedSuggestions, Error>,
  'refetch'
> & {
  page: number;
  refreshSuggestionList: () =>
    | Promise<
        | QueryObserverResult<
            PaginatedSuggestions | OriginalReuseInboxSuggestions,
            Error
          >
        | undefined
      >
    | undefined;
  setPage: Dispatch<SetStateAction<number>>;
  suggestionList: ReuseSuggestion[];
  totalPages: number;
};

export function useSuggestionList(dismissed = false): UseSuggestionListResult {
  const { page, reuseSuggestionsResult, setPage } =
    useFetchSuggestions(dismissed);

  const suggestionList = useMemo(() => {
    const suggestions = dismissed
      ? (reuseSuggestionsResult.data as PaginatedSuggestions)?.suggestionsPage
      : (reuseSuggestionsResult.data as OriginalReuseInboxSuggestions);

    if (suggestions?.length) {
      return suggestions.map((suggestion): ReuseSuggestion => {
        if (suggestion.type === SuggestionDataType.NEW) {
          const newSuggestion = { ...suggestion } as ReuseSuggestion;
          const newCluster = createNewClusterObject(null);
          newCluster.preferredPhrase.phrase = (
            suggestion as ReuseNewSuggestion
          ).preferredPhrase;
          newCluster.deprecatedPhrases = newSuggestion.deprecatedPhrases.map(
            (dPhrase) => ({
              phrase: dPhrase,
            })
          );
          newCluster.language = suggestion.language;
          newSuggestion.cluster = newCluster;

          return newSuggestion;
        }
        return suggestion as ReuseSuggestion;
      });
    }

    return [];
  }, [dismissed, reuseSuggestionsResult.data]);

  const refreshSuggestionList = useCallback(async () => {
    if (dismissed) {
      const rest =
        (reuseSuggestionsResult.data as PaginatedSuggestions)?.suggestionsPage
          .length - 1;

      if (!rest && page > 1) {
        setPage(page - 1);
        return;
      }

      return reuseSuggestionsResult.refetch();
    }

    return reuseSuggestionsResult.refetch();
  }, [dismissed, page, reuseSuggestionsResult, setPage]);

  return {
    page,
    ...reuseSuggestionsResult,
    refreshSuggestionList,
    setPage,
    suggestionList,
    totalPages: dismissed
      ? Math.ceil(
          (reuseSuggestionsResult.data as PaginatedSuggestions)
            ?.totalSuggestionCount / SUGGESTIONS_PER_PAGE
        )
      : 1,
  };
}

export interface UseReuseInboxResult {
  closeReplacementDrawer: UseIsOpenResult['close'];
  closeTargetSettingsDrawer: UseIsOpenResult['close'];
  createOrEditReplacementDrawerIsOpen: UseIsOpenResult['elementIsOpen'];
  data: OriginalReuseInboxSuggestions | undefined;
  deprecatedPhrases: string[];
  fetchingTargets: boolean;
  filteredSuggestionList: ReuseSuggestion[];
  languages: LanguageFull[];
  openReplacementDrawer: (
    cluster: ReuseDomainCluster,
    deprecatedPhrases: string[],
    id: string
  ) => void;
  openTargetSettingsDrawer: () => void;
  page: UseSuggestionListResult['page'];
  refreshSuggestionList: UseSuggestionListResult['refreshSuggestionList'];
  refreshTargets: () => void;
  replacement: ReuseDomainCluster | null;
  showLanguageLabelOnSuggestionCard: boolean;
  setFilteredSuggestionList: Dispatch<SetStateAction<ReuseSuggestion[]>>;
  setPage: UseSuggestionListResult['setPage'];
  sortedDomains: UseReuseDomainsResult['sortedDomains'];
  sortedTargets: UseFetchTargetsResult['sortedTargets'];
  suggestionId: string;
  suggestionList: UseSuggestionListResult['suggestionList'];
  targetSettingsDrawerIsOpen: UseIsOpenResult['elementIsOpen'];
  targetValues: CheckboxValue[];
  totalPages: number;
}

export function useReuseInbox(
  dismissedSuggestionsAreVisible: boolean
): UseReuseInboxResult {
  const {
    close: closeReplacementDrawer,
    open: openReplacementDrawer,
    elementIsOpen: createOrEditReplacementDrawerIsOpen,
  } = useIsOpen();
  const {
    close: closeTargetSettingsDrawer,
    open: openTargetSettingsDrawer,
    elementIsOpen: targetSettingsDrawerIsOpen,
  } = useIsOpen();

  const [replacement, setReplacement] = useState<ReuseDomainCluster | null>(
    null
  );
  const [suggestionId, setSuggestionId] = useState<string>('');
  const [deprecatedPhrases, setDeprecatedPhrases] = useState<string[]>([]);
  const { sortedDomains } = useReuseDomains();

  const {
    data,
    page,
    refreshSuggestionList,
    setPage,
    suggestionList,
    totalPages,
  } = useSuggestionList(dismissedSuggestionsAreVisible);
  const [filteredSuggestionList, setFilteredSuggestionList] =
    useState<ReuseSuggestion[]>(suggestionList);
  const { data: allLanguages } = useFetchLanguages<LanguageFull>('full');
  const supportedLanguages = useMemo(
    () =>
      createListOfSupportedLanguages(allLanguages, REUSE_SUPPORTED_LANGUAGES),
    [allLanguages]
  );

  const supportedLanguageList = useMemo(
    () => supportedLanguages.map((lng) => lng.languageVariantDisplayName),
    [supportedLanguages]
  );

  const showLanguageLabelOnSuggestionCard = useMemo(
    () =>
      [...new Set(supportedLanguages.map((lng) => lng.abbreviation))].length >
      1,
    [supportedLanguages]
  );

  const { sortedTargets, fetchingTargets, targetsResult } = useFetchTargets();
  const openReplacementDrawerFn = useCallback(
    (cluster: ReuseDomainCluster, deprecatedPhrases: string[], id: string) => {
      setReplacement(cluster);
      setDeprecatedPhrases(deprecatedPhrases);
      setSuggestionId(id);
      openReplacementDrawer();
    },
    [openReplacementDrawer, setReplacement]
  );

  useDidMountEffect(() => {
    if (suggestionList.length) {
      setFilteredSuggestionList(suggestionList);
    }
  }, [suggestionList]);

  const targetValues = useMemo(
    () =>
      sortedTargets
        .filter((t) =>
          supportedLanguageList.includes(t.languageVariantDisplayName)
        )
        .map(
          (t): CheckboxValue => ({
            id: t.uuid,
            checked: t.allowHarvesting,
            label: t.displayName,
          })
        ),
    [sortedTargets, supportedLanguageList]
  );

  return {
    closeReplacementDrawer,
    closeTargetSettingsDrawer,
    createOrEditReplacementDrawerIsOpen,
    data: dismissedSuggestionsAreVisible
      ? (data as PaginatedSuggestions)?.suggestionsPage
      : (data as OriginalReuseInboxSuggestions),
    deprecatedPhrases,
    fetchingTargets,
    filteredSuggestionList,
    languages: supportedLanguages,
    openReplacementDrawer: openReplacementDrawerFn,
    openTargetSettingsDrawer,
    page,
    refreshSuggestionList,
    refreshTargets: targetsResult.refetch,
    replacement,
    showLanguageLabelOnSuggestionCard,
    setFilteredSuggestionList,
    setPage,
    sortedDomains,
    sortedTargets,
    suggestionId,
    suggestionList,
    targetSettingsDrawerIsOpen,
    targetValues,
    totalPages,
  };
}

export interface UseUnDismissSuggestionDialogResult {
  unDismissSuggestionOrOpenDialog: () => Promise<void>;
  unDismissSuggestionOrCloseDialog: (dismiss: boolean) => Promise<void>;
  unDismissSuggestionDialogIsOpen: UseIsOpenResult['elementIsOpen'];
  onCheck: () => void;
}

export interface UseUnDismissSuggestionDialogProps {
  dismissed: boolean;
  uuid: string;
  refreshSuggestionList: UseReuseInboxResult['refreshSuggestionList'];
}

export function useUnDismissSuggestionDialog({
  dismissed,
  uuid,
  refreshSuggestionList,
}: UseUnDismissSuggestionDialogProps): UseUnDismissSuggestionDialogResult {
  const { open, close, elementIsOpen } = useIsOpen();
  const [authToken] = useContext(AuthContext);

  const [check, setCheck] = useState(
    localStorage.getItem(REUSE_NOT_SHOW_DISMISS_SUGGESTION_DIALOG) === 'true'
  );

  function updateSuggestions(suggestion: ReuseSuggestionStatus) {
    return requestResponseOrThrowError(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_SUGGESTIONS_STATUS_PATH}`,
      {
        method: 'PUT',
        body: {
          suggestionUuid: suggestion.suggestionUuid,
          suggestionStatus: suggestion.suggestionStatus,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  const mutation = useMutation({
    mutationFn: updateSuggestions,
  });

  function onCheck() {
    setCheck(!check);
    localStorage.setItem(
      REUSE_NOT_SHOW_DISMISS_SUGGESTION_DIALOG,
      JSON.stringify(!check)
    );
  }

  async function mutateSuggestions() {
    await mutation.mutateAsync({
      suggestionUuid: uuid,
      suggestionStatus: dismissed
        ? SuggestionStatusType.PENDING
        : SuggestionStatusType.DISMISSED,
    });
    await refreshSuggestionList();
  }

  async function unDismissSuggestionOrOpenDialog() {
    if (check) {
      await mutateSuggestions();
    } else {
      open();
    }
  }

  async function unDismissSuggestionOrCloseDialog(dismiss: boolean) {
    if (dismiss) {
      await mutateSuggestions();
    } else {
      setCheck(false);
      localStorage.setItem(REUSE_NOT_SHOW_DISMISS_SUGGESTION_DIALOG, 'false');
    }

    close();
  }

  return {
    unDismissSuggestionOrOpenDialog,
    unDismissSuggestionOrCloseDialog,
    unDismissSuggestionDialogIsOpen: elementIsOpen,
    onCheck,
  };
}

export interface UseScrollableSuggestionsProps {
  dismissedSuggestionsShown: boolean;
  filteredSuggestionList: ReuseSuggestion[];
}

export interface UseScrollableSuggestionsResult {
  setResizeElement: (resizeTarget: HTMLDivElement | null) => void;
  styles: React.CSSProperties;
}

/* istanbul ignore next */
export function useScrollableSuggestions({
  dismissedSuggestionsShown,
  filteredSuggestionList,
}: UseScrollableSuggestionsProps): UseScrollableSuggestionsResult {
  const [styles, setStyles] = useState<React.CSSProperties>({});
  const { setResizeElement, resizeElement, resizedHeight } = useResize();

  useEffect(() => {
    if (filteredSuggestionList.length && resizeElement && resizedHeight) {
      // section padding 40 + main padding 30 + 1 to hide scroll (+65 pagination)
      const max = window.innerHeight - (dismissedSuggestionsShown ? 135 : 71);
      const rect = resizeElement.getBoundingClientRect();

      if (styles.maxHeight !== max - rect.top) {
        setStyles({
          padding: '5px',
          overflow: 'scroll',
          maxHeight: `${max - rect.top}px`,
        });
      }
    }
  }, [
    dismissedSuggestionsShown,
    filteredSuggestionList.length,
    resizeElement,
    resizedHeight,
    styles.maxHeight,
  ]);

  return {
    setResizeElement,
    styles,
  };
}

export interface UseShowDismissedSuggestionsResult {
  dismissedSuggestionsShown: boolean;
  setDismissedSuggestionsShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useShowDismissedSuggestions(): UseShowDismissedSuggestionsResult {
  const [dismissedSuggestionsShown, setDismissedSuggestionsShown] =
    useState<boolean>(false);

  return {
    dismissedSuggestionsShown,
    setDismissedSuggestionsShown,
  };
}
