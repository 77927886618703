import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import Grid from '@mui/material/Grid';

import {
  Goal,
  GoalConfig,
  Guideline,
  GuidelineValue,
} from 'sections/Targets/pages/TargetPage/types';
import { TargetActions } from '../GoalSection';
import CustomPresetChangeWarningDialog from '../CustomPresetChangeWarningDialog';
import ContextualSettings from './ContextualSettings';
import GuidelineHelp from './GuidelineHelp';
import GuidelineToggleActive from './GuidelineToggleActive';
import { useGuideline } from '../../hooks';
import { GuidelineRow } from './styles';
import Parameter from './Parameter';

export interface GuidelineSectionProps {
  goal: Goal;
  guideline: Guideline;
  guidelineConfig?: GuidelineValue;
  languageIdShort: string;
  modified: boolean;
  readOnly: boolean;
  targetActions: TargetActions;
  targetGoal: GoalConfig;
}

export function GuidelineSection({
  languageIdShort,
  readOnly,
  ...props
}: GuidelineSectionProps): JSX.Element {
  const guidelineData = useGuideline(props);
  const {
    cancelFn,
    closeWarningModal,
    guidelineIsActive,
    okFn,
    openWarningModal,
    performOpenEditContextsDrawer,
    presetWarningModalShouldAppear,
    setWarningModalOKCallback,
    warningModalIsOpen,
  } = guidelineData;

  const contexts = props.guidelineConfig?.contexts;

  return (
    <GuidelineRow container justifyContent="space-between">
      <CustomPresetChangeWarningDialog
        open={warningModalIsOpen}
        closeFn={closeWarningModal}
        okFn={okFn}
        cancelFn={cancelFn}
      />
      <Grid
        xs={9}
        item
        container
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={7} alignItems="baseline" container pt={0.8}>
          <GuidelineToggleActive
            disabled={readOnly}
            guideline={props.guideline}
            guidelineIsActive={guidelineIsActive}
            goal={props.goal}
            languageIdShort={languageIdShort}
            openWarningModal={openWarningModal}
            presetWarningModalShouldAppear={presetWarningModalShouldAppear}
            setWarningModalOKCallback={setWarningModalOKCallback}
            targetActions={props.targetActions}
          />
        </Grid>

        <Grid container flexDirection="row" alignItems="center" item xs={5}>
          {props.guidelineConfig ? (
            <Parameter {...props} {...guidelineData} readOnly={readOnly} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid
        className="guidelineIconsContainer"
        justifyContent="flex-end"
        alignItems="center"
        item
        xs={3}
        container
      >
        <GuidelineHelp helpUrl={props.guideline.helpUrl} />
        <ContextualSettings
          contextsExist={!!contexts}
          goal={props.goal}
          guideline={props.guideline}
          guidelineConfig={props.guidelineConfig}
          guidelineIsActive={guidelineIsActive}
          openWarningModal={openWarningModal}
          performOpenEditContextsDrawer={performOpenEditContextsDrawer}
          presetWarningModalShouldAppear={presetWarningModalShouldAppear}
          readOnly={readOnly}
          setWarningModalOKCallback={setWarningModalOKCallback}
          targetActions={props.targetActions}
        />
      </Grid>
    </GuidelineRow>
  );
}

export default memo(GuidelineSection, isEqual);
