import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../public/locales/en-US/translation.json';
import translationDE from '../public/locales/de-DE/translation.json';
import translationFR from '../public/locales/fr-FR/translation.json';
import translationJA from '../public/locales/ja-JP/translation.json';
import translationSV from '../public/locales/sv-SE/translation.json';

import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LANGUAGE, LANGUAGE_COOKIE_NAME } from './constants';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  fr: { translation: translationFR },
  ja: { translation: translationJA },
  sv: { translation: translationSV },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: FALLBACK_LANGUAGE,
    detection: {
      lookupCookie: LANGUAGE_COOKIE_NAME,
      order: ['cookie'],
      caches: ['cookie'],
    },
    debug: true,
    load: 'currentOnly',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: true,
    },
  });
