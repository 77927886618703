import React, { ReactElement, useState, useEffect, useRef } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { IconButton, StyledInput, StyledTotalBox } from './styles';

export interface TablePaginationCustomProps {
  page: number;
  totalPages: number;
  onChangePage: (page: number) => void;
  localStorageKey?: string;
}

function TablePaginationCustom({
  page,
  totalPages = 0,
  onChangePage,
  localStorageKey,
}: TablePaginationCustomProps): ReactElement {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(`${page}`);

  useEffect(() => {
    setInputValue(`${page}`);
  }, [page]);

  const inputRef = useRef<HTMLInputElement>();

  const switchPageButtonKey =
    'shared.components.pagination.a11y.switchPageButton';

  const onClickPreviousPage = () => {
    !!localStorageKey && localStorage.setItem(localStorageKey, `${page - 1}`);
    onChangePage(page - 1);
  };

  const onClickNextPage = () => {
    !!localStorageKey && localStorage.setItem(localStorageKey, `${page + 1}`);
    onChangePage(page + 1);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const newNumber = parseInt(inputValue, 10);

      if (newNumber > 0 && newNumber <= totalPages) {
        // Propagate value outside
        onChangePage(newNumber);

        // Remove focus
        event.currentTarget.blur();
        !!localStorageKey &&
          localStorage.setItem(localStorageKey, event.currentTarget.value);
      } else {
        // Set default page number
        setInputValue(`${page}`);
      }

      // Remove focus from element
      inputRef?.current?.blur();
    }

    event.persist();
  };

  const onBlur = () => {
    setInputValue(`${page}`);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <IconButton
          onClick={onClickPreviousPage}
          disabled={page === 1}
          aria-label={t(switchPageButtonKey, {
            page: page - 1,
          })}
          title={t(switchPageButtonKey, {
            page: page - 1,
          })}
          size="large"
          disableFocusRipple
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Box pl={2} pr={2}>
        <StyledInput
          inputRef={inputRef}
          type="number"
          value={inputValue}
          onChange={onChange}
          onKeyDown={onKeyPress}
          onBlur={onBlur}
          inputProps={{
            'aria-label': t('shared.components.pagination.a11y.currentPage', {
              page,
            }),
            min: 1,
            max: totalPages,
          }}
          disabled={totalPages === 1}
        />
      </Box>
      <Box>
        <IconButton
          onClick={onClickNextPage}
          disabled={page === totalPages}
          aria-label={t(switchPageButtonKey, {
            page: page + 1,
          })}
          title={t(switchPageButtonKey, {
            page: page + 1,
          })}
          size="large"
          disableFocusRipple
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <StyledTotalBox>
        {t('shared.baseComponents.pagination.pageCount', {
          pagesCount: totalPages,
        })}
      </StyledTotalBox>
    </Box>
  );
}

export default TablePaginationCustom;
