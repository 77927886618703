import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import StyledRadioGroup from 'components/CommonRadioGroup';
import { UseShowDismissedSuggestionsResult } from '../hooks';
import { typographyClasses } from '@mui/material/Typography';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';

export interface SuggestionListToggleProps {
  stateObject: UseShowDismissedSuggestionsResult;
}

export default function SuggestionListToggle({
  stateObject,
}: SuggestionListToggleProps) {
  const { t } = useTranslation();

  const radioButtons = [
    {
      label: t('reuseInbox.labels.newSuggestions'),
      value: '',
      key: 'new',
    },
    {
      label: t('reuseInbox.labels.blockedSuggestions'),
      value: 'blocked',
      key: 'blocked',
    },
  ];

  const changeHandler = useCallback(
    (event: ChangeEvent<{ value?: unknown }>) => {
      stateObject.setDismissedSuggestionsShown(
        !!(event.target as HTMLOptionElement).value
      );
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [stateObject.setDismissedSuggestionsShown]
  );

  return (
    <StyledRadioGroup
      aria-label={t('reuseInbox.a11y.suggestionLists')}
      name="suggestion-lists"
      numberOfColumns={2}
      data={radioButtons}
      changeHandler={changeHandler}
      value={stateObject.dismissedSuggestionsShown ? 'blocked' : ''}
      sx={(theme) => ({
        marginTop: theme.spacing(4 / 5),
        marginLeft: theme.spacing(10),
        [`& .${typographyClasses.body2}`]: {
          lineHeight: theme.typography.pxToRem(22),
        },
        [`& .${formControlLabelClasses.root}`]: {
          width: 'auto',
        },
      })}
    />
  );
}
