import React, { useEffect, useState, useContext } from 'react';

import FilledTextFieldPassword from 'sections/Users/components/FilledTextFieldPassword';
import ButtonPrimary from 'components/ButtonPrimary';

import AuthContext from 'contexts/AuthContext';
import { fetchJsonFromAPI, FetchJsonReturnType } from 'utils/fetch';

import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePassword } from 'sections/Users/utils';
import { PASSWORD_VALIDATION_PATTERN } from 'sections/Users/constants';
import { User } from 'sections/Users/types';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogTitle,
} from './styles';

type IFormInput = {
  password: string;
};

interface GeneratePasswordDialogProps {
  userId: string;
  username: string;
  onClose?: (ok: boolean) => void;
}

const GeneratePasswordDialog = ({
  userId,
  username,
  onClose,
}: GeneratePasswordDialogProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const {
    formState: { isValid },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm<IFormInput>({
    mode: 'all',
    defaultValues: {
      password: generatePassword(),
    },
  });

  const [authToken] = useContext(AuthContext);

  const handleClose = (ok: boolean) => {
    setIsOpen(false);
    reset();

    if (onClose) {
      onClose(ok);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({ password }) => {
    if (isValid) {
      await fetchJsonFromAPI<
        {
          password: string;
        },
        FetchJsonReturnType & {
          data: User;
        }
      >(authToken, `/api/v1/user/${userId}`, {
        method: 'PUT',
        body: {
          password: password,
        },
      });

      handleClose(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue('password', generatePassword());
    }
  }, [isOpen, setValue]);

  return (
    <>
      <ButtonPrimary
        variant="contained"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('users.actions.generateNewPassword')}
      </ButtonPrimary>
      <StyledDialog open={isOpen} onClose={() => handleClose(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>
            {t('user.headers.generateNewPassword')}
          </StyledDialogTitle>
          <StyledDialogContent>
            <StyledDialogContentText>
              {t('users.descriptions.generateNewPassword', {
                username,
              })}
            </StyledDialogContentText>
            <Controller
              control={control}
              name="password"
              rules={{
                required: true,
                pattern: PASSWORD_VALIDATION_PATTERN,
              }}
              render={({ field, fieldState: { error } }) => (
                <FilledTextFieldPassword
                  {...field}
                  fullWidth
                  helperText={error?.type}
                  error={
                    !!error?.type &&
                    ['required', 'pattern'].includes(error?.type)
                  }
                  inputProps={{ maxLength: 64 }}
                  hasCharacterCounter
                />
              )}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <ButtonPrimary
              variant="outlined"
              onClick={() => handleClose(false)}
            >
              {t('users.actions.cancel')}
            </ButtonPrimary>
            <ButtonPrimary
              type="submit"
              variant="contained"
              aria-disabled={!isValid}
            >
              {t('users.actions.updatePassword')}
            </ButtonPrimary>
          </StyledDialogActions>
        </form>
      </StyledDialog>
    </>
  );
};

export default GeneratePasswordDialog;
