import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { TooltipProps } from '@mui/material/Tooltip';

import CommonTooltip from 'components/CommonTooltip';

export interface AddButtonProps {
  disabled: boolean;
  onClick: () => void;
  tooltipProps: Omit<TooltipProps, 'children'>;
}

export default function AddButton({
  disabled,
  onClick,
  tooltipProps,
}: AddButtonProps) {
  return (
    <CommonTooltip {...tooltipProps}>
      <IconButton
        sx={(theme) => ({
          padding: theme.spacing(0.8),
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&[aria-disabled=true]': {
            cursor: 'not-allowed',
            '& svg': {
              color: theme.palette.disabled.primary,
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        })}
        aria-disabled={disabled}
        onClick={disabled ? undefined : onClick}
      >
        <AddCircleRoundedIcon color="primary" />
      </IconButton>
    </CommonTooltip>
  );
}
