import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { blueGrey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import CommonDrawer, { CommonDrawerInterface } from 'components/CommonDrawer';
import TablePaginationCustom from 'components/TablePaginationCustom';
import { useRecentActivities, useScrollableList } from './hooks';
import RecentActivity from './RecentActivity';
import CommonStartPageNoData from 'components/CommonStartPage';

export type RecentActivitiesDrawerProps = Pick<
  CommonDrawerInterface,
  'cancelAndCloseHandler' | 'drawerIsOpen' | 'drawerTitle' | 'drawerSubTitle'
>;

export default function RecentActivitiesDrawer(
  props: RecentActivitiesDrawerProps
) {
  const { t } = useTranslation();
  const insideRef = useRef<HTMLDivElement | null>(null);
  const { scrollStyles, setResizeElement } = useScrollableList(
    insideRef.current?.clientHeight
  );
  const { recentActivitiesList, totalPages, page, setPage } =
    useRecentActivities();

  function mergeRefs(element: HTMLDivElement) {
    insideRef.current = element;
  }

  return (
    <CommonDrawer
      {...props}
      footerComponent={
        <Grid
          container
          justifyContent="center"
          sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            width: theme.spacing(70),
            borderTop: `1px solid ${blueGrey[100]}`,
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(3, 0),
          })}
          ref={mergeRefs}
        >
          <TablePaginationCustom
            totalPages={totalPages || 1}
            onChangePage={setPage}
            page={page}
          />
        </Grid>
      }
    >
      {recentActivitiesList?.length ? (
        <List
          ref={
            setResizeElement as (resizeTarget: HTMLUListElement | null) => void
          }
          sx={{
            ...scrollStyles,
            padding: 0,
            '& li:first-of-type': {
              paddingTop: 0,
            },
            '& li:last-of-type': {
              border: 'none',
            },
          }}
        >
          {recentActivitiesList?.map((activity) => (
            <RecentActivity key={activity.uuid} activity={activity} />
          ))}
        </List>
      ) : (
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          sx={(theme) => ({
            width: theme.spacing(60),
            marginLeft: theme.spacing(5),
            height: '100%',
          })}
        >
          <CommonStartPageNoData
            description={t('capture.descriptions.noDataRecentActivities')}
            title={t('capture.titles.noDataRecentActivities')}
          />
        </Grid>
      )}
    </CommonDrawer>
  );
}
