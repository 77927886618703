import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material';
import {
  BootstrapInput,
  BootstrapInputLabel,
  BootstrapSelect,
  StyledMenuItem,
} from './styles';

interface SelectOption {
  label: string;
  value: string;
}

export interface SelectTypeAProps {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  options: SelectOption[];
  children?: ReactElement | ReactElement[];
  id?: string;

  [propName: string]: unknown;
}

export default function SelectTypeA({
  onChange,
  value,
  label,
  options,
  children,
  id = 'select-type-A',
  ...selectProps
}: SelectTypeAProps): ReactElement {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const option = options.find((o) => o.value === event.target.value);

    // Allow the change only if option exists
    if (option) {
      onChange(event.target.value as string);
    }
  };

  const SelectComponent = (
    <BootstrapSelect
      id={id}
      {...(label && { labelId: `${id}-label` })}
      value={value}
      onChange={handleChange}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: {
            marginTop: 2 / 5,
          },
        },
      }}
      input={<BootstrapInput color={'primary'} />}
      {...selectProps}
    >
      {children}
      {options.map(({ value: v, label: l }) => (
        <StyledMenuItem key={v} value={v}>
          {l}
        </StyledMenuItem>
      ))}
    </BootstrapSelect>
  );

  if (label) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box p={2}>
          <BootstrapInputLabel id={`${id}-label`}>{label}</BootstrapInputLabel>
        </Box>
        <Box>{SelectComponent}</Box>
      </Box>
    );
  }

  return SelectComponent;
}
