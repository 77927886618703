import * as React from 'react';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ExportUsersError } from './components/ExportUsersError';
import { GetUsersError } from './components/GetUsersError';
import { ChangeLicenseTypeError } from './components/ChangeLicenseTypeError';
import { CustomFieldsError } from './components/CustomFieldsError';
import { UserDeleteError } from './components/UserDeleteError';

let ErrorComponent: React.FunctionComponent = () => null;

const CustomFieldsErrorDashboard = () => <CustomFieldsError page="dashboard" />;
const CustomFieldsErrorUserDetails = () => (
  <CustomFieldsError page="userDetails" />
);

export const WithErrorHandling = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { error } = useError();

  if (error) {
    const { value, origin } = error;

    switch (origin) {
      case 'getUsers':
        return <GetUsersError error={value} />;
      case 'exportUsers':
        ErrorComponent = ExportUsersError;
        break;
      case 'releaseLicense':
      case 'changeLicenseType':
        ErrorComponent = ChangeLicenseTypeError;
        break;
      case 'setCustomField.dashboard':
        ErrorComponent = CustomFieldsErrorDashboard;
        break;
      case 'setCustomField.userDetails':
        ErrorComponent = CustomFieldsErrorUserDetails;
        break;
      case 'userDelete.single':
      case 'userDelete.bulk':
        ErrorComponent = UserDeleteError;
        break;
    }
  }

  return (
    <>
      <ErrorComponent />
      {children}
    </>
  );
};
