import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CommonOutlinedMultilineTextFieldWithInnerIcons from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextFieldWithInnerIcons';

import LanguageDropdown from 'components/LanguageDropdown';
import {
  CommonDrawerFieldTitle,
  DrawerContent,
  InnerDivider,
} from 'components/CommonDrawer';
import { BreakGrid, PaddingGrid, StyledHelpIcon } from './styles';
import {
  REUSE_PREFERRED_PHRASE_MAX_LENGTH,
  REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH,
  REUSE_SUPPORTED_LANGUAGES,
} from 'sections/Reuse/constants';
import { ReuseDomainCluster, ReuseIndexDomain } from 'sections/Reuse/types';
import { UseTextResult } from 'hooks/useText';
import CommonTooltip from 'components/CommonTooltip';
import AdvancedSettings from '../AdvancedSettings';
import {
  UseClusterDomainsResult,
  UseCreateOrEditReplacementResult,
  useInner,
} from './hooks';
import ShowDomainMenuButton from './ShowDomainMenuButton';
import WarningDuplicatedPhrase from '../WarningDuplicatedPhrase/WarningDuplicatedPhrase';
import { AdministrativeInformationAccordion } from 'components/AdministrativeInformation';
import CommonWarningMessage from 'components/CommonWarningMessage';
import DomainChips from './DomainChips';
import { LanguageFull, LanguageSimple, UpdateInformation } from 'types/types';
import { useEqualityCheck } from '../AdvancedSettings/hooks';

export interface InnerCreateOrEditReplacementDrawerProps {
  clusterDomains: UseClusterDomainsResult;
  clusterId: string | undefined;
  created: UpdateInformation | undefined;
  deprecatedPhrases: ReuseDomainCluster['deprecatedPhrases'];
  descriptionErrorMessage: string;
  descriptionState: UseTextResult;
  domainList: ReuseIndexDomain[];
  duplicatedPhrases: UseCreateOrEditReplacementResult['duplicatedPhrases'];
  footerRect: DOMRect | null;
  languages: LanguageFull[] | LanguageSimple[];
  modified: UpdateInformation | undefined;
  newAddedDeprecatedPhrases: string[];
  newCluster: ReuseDomainCluster;
  originalCluster: ReuseDomainCluster | null;
  originallyDeprecatedPhrasesExist: boolean;
  preferredPhraseErrorMessage: string;
  preferredPhraseState: UseTextResult;
  selectedLanguageId: LanguageFull['languageId'];
  setDeprecatedPhrases: (arg: ReuseDomainCluster['deprecatedPhrases']) => void;
  setSelectedLanguageId: (arg: LanguageFull['languageId']) => void;
}

export default function InnerCreateOrEditReplacementDrawer({
  clusterDomains,
  clusterId,
  created,
  deprecatedPhrases,
  descriptionErrorMessage,
  descriptionState,
  domainList,
  duplicatedPhrases,
  footerRect,
  languages,
  modified,
  newAddedDeprecatedPhrases,
  originallyDeprecatedPhrasesExist,
  preferredPhraseErrorMessage,
  preferredPhraseState,
  selectedLanguageId,
  setDeprecatedPhrases,
  setSelectedLanguageId,
}: InnerCreateOrEditReplacementDrawerProps) {
  const { t } = useTranslation();
  const {
    elementHeight,
    handleChangeDomainCheckbox,
    localPreferredPhraseErrorMessage,
    refElement,
    setPreferredPhraseIsPristine,
  } = useInner({
    clusterDomains,
    duplicatedPhrases,
    footerRect,
    preferredPhraseErrorMessage,
  });
  const { equalityState: replacementsWithTheSamePreferredPhrase } =
    useEqualityCheck({
      phrase: preferredPhraseState.textValue,
      language: selectedLanguageId,
      preferred: true,
      clusterId,
    });

  return (
    <DrawerContent
      ref={refElement}
      style={{ height: `${elementHeight - 5}px`, overflowY: 'scroll' }}
    >
      <PaddingGrid>
        <CommonOutlinedMultilineTextFieldWithInnerIcons
          copyToClipboard={!!preferredPhraseState.textValue}
          autoFocus
          charCount={preferredPhraseState.textValueCount}
          maxCharCount={REUSE_PREFERRED_PHRASE_MAX_LENGTH}
          label={t('reuse.labels.preferredPhrase')}
          inputProps={{ lang: selectedLanguageId }}
          fullWidth
          error={!!localPreferredPhraseErrorMessage}
          helperText={
            <>{localPreferredPhraseErrorMessage || t('reuse.hints.required')}</>
          }
          onChange={(evt) => {
            setPreferredPhraseIsPristine(false);
            preferredPhraseState.setTextValue(evt.target.value);
          }}
          onBlur={() => {
            setPreferredPhraseIsPristine(false);
          }}
          rows="4"
          value={preferredPhraseState.textValue}
          id="preferredPhrase"
        />
        {!!replacementsWithTheSamePreferredPhrase.length && (
          <WarningDuplicatedPhrase />
        )}
        <BreakGrid />
        <Grid sx={(theme) => ({ width: theme.spacing(62) })}>
          <LanguageDropdown
            languages={languages}
            languagesDisabled={false}
            languagesLabel={t('reuse.labels.language')}
            readOnly={false}
            readOnlyLabel={t('capture.labels.language')}
            selectedLanguageId={selectedLanguageId}
            setSelectedLanguageId={setSelectedLanguageId}
            helperText={t('reuse.hints.required')}
            supportedLanguageAbbreviations={REUSE_SUPPORTED_LANGUAGES}
          />
        </Grid>
        <BreakGrid />
        <Grid>
          <CommonDrawerFieldTitle
            sx={(theme) => ({ paddingBottom: theme.spacing(3) })}
          >
            {t('reuse.labels.replacementDomains')}
          </CommonDrawerFieldTitle>
          <Grid container alignItems="center">
            <ShowDomainMenuButton
              selectedDomains={clusterDomains.domains}
              domainList={domainList}
              handleChangeDomainCheckbox={handleChangeDomainCheckbox}
            />
            {clusterDomains.domains.length ? (
              <DomainChips
                clusterDomains={clusterDomains}
                domainList={domainList}
              />
            ) : (
              <CommonWarningMessage
                text={t('reuse.errors.replacementDomainsIsEmpty')}
              />
            )}
          </Grid>
        </Grid>
        <BreakGrid />
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({
            paddingBottom: theme.spacing(5),
          })}
        >
          <CommonDrawerFieldTitle
            sx={{
              display: 'inline-block',
            }}
          >
            {t('reuse.labels.replacementDescription')}
          </CommonDrawerFieldTitle>
          <CommonTooltip
            title={t('reuse.tooltips.replacementDescriptionHelpIcon') || ''}
            type="yellow"
            placement="right-start"
          >
            <StyledHelpIcon tabIndex={0} aria-hidden={false} />
          </CommonTooltip>
        </Grid>
        <CommonOutlinedMultilineTextFieldWithInnerIcons
          charCount={descriptionState.textValueCount}
          maxCharCount={REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH}
          label={t('reuse.labels.replacementDescription')}
          inputProps={{ lang: selectedLanguageId }}
          fullWidth
          error={!!descriptionErrorMessage}
          helperText={
            <>{descriptionErrorMessage || t('reuse.hints.optional')}</>
          }
          onChange={(evt) => {
            descriptionState.setTextValue(evt.target.value);
          }}
          rows="4"
          value={descriptionState.textValue}
          id="description"
        />
        <InnerDivider
          sx={(theme) => ({
            margin: theme.spacing(6, 0, 1, 0),
          })}
        />
        <AdvancedSettings
          clusterId={clusterId}
          deprecatedPhrases={deprecatedPhrases}
          duplicatedPhrases={duplicatedPhrases}
          setDeprecatedPhrases={setDeprecatedPhrases}
          defaultOpen={
            originallyDeprecatedPhrasesExist ||
            !!newAddedDeprecatedPhrases.length
          }
          readOnly={false}
          selectedLanguageId={selectedLanguageId}
          newAddedDeprecatedPhrases={newAddedDeprecatedPhrases}
          preferredPhrase={preferredPhraseState.textValue}
        />

        {!!clusterId && (
          <AdministrativeInformationAccordion
            created={created}
            modified={modified}
          />
        )}
      </PaddingGrid>
    </DrawerContent>
  );
}
