import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionDetails from '@mui/material/AccordionDetails';

import AddButton from '../CreateOrEditReplacementDrawer/AddButton';
import DeprecatedPhraseWrapper from './DeprecatedPhraseWrapper';
import { EditableAdvancedSettingsProps } from './AdvancedSettings';
import { newPhrase } from '../CreateOrEditReplacementDrawer/hooks';

export default function EditableSettings({
  clusterId,
  deprecatedPhrases,
  duplicatedPhrases,
  newAddedDeprecatedPhrases = [],
  preferredPhrase,
  selectedLanguageId,
  setDeprecatedPhrases,
}: EditableAdvancedSettingsProps) {
  const { t } = useTranslation();

  function updateDeprecatedPhrases(
    value: string,
    index: number,
    toDelete?: boolean
  ): void {
    const newDeprecatedPhrases = [...deprecatedPhrases];

    if (toDelete && deprecatedPhrases.length > 1) {
      newDeprecatedPhrases.splice(index, 1);
    } else {
      newDeprecatedPhrases[index] = {
        ...newDeprecatedPhrases[index],
        phrase: toDelete ? '' : value,
      };
    }

    setDeprecatedPhrases(newDeprecatedPhrases);
  }

  function addDeprecatedPhrase() {
    const newDeprecatedPhrases = [...deprecatedPhrases];

    newDeprecatedPhrases.push({ ...newPhrase });

    setDeprecatedPhrases(newDeprecatedPhrases);
  }

  const addButtonDisabled =
    !deprecatedPhrases[deprecatedPhrases.length - 1]?.phrase.trim();
  const addButtonTitle = addButtonDisabled
    ? t('reuse.tooltips.addAnotherDeprecatedPhraseDisabled')
    : t('reuse.tooltips.addAnotherDeprecatedPhrase');

  return (
    <AccordionDetails
      sx={(theme) => ({
        marginTop: theme.spacing(4),
      })}
    >
      {deprecatedPhrases.map((dPhrase, index) => (
        <DeprecatedPhraseWrapper
          key={index}
          dPhrase={dPhrase}
          updateDeprecatedPhrases={updateDeprecatedPhrases}
          newAddedDeprecatedPhrases={newAddedDeprecatedPhrases}
          duplicatedPhrases={duplicatedPhrases}
          index={index}
          clusterId={clusterId}
          selectedLanguageId={selectedLanguageId}
          preferredPhrase={preferredPhrase}
        />
      ))}
      <AddButton
        disabled={addButtonDisabled}
        onClick={addDeprecatedPhrase}
        tooltipProps={{
          title: addButtonTitle,
          placement: 'right',
        }}
      />
    </AccordionDetails>
  );
}
