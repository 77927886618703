import { styled } from '@mui/material/styles';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import CommonTooltip from 'components/CommonTooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/system/Box';
import ButtonPrimary from 'components/ButtonPrimary';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: 0,
  [`& .${formControlLabelClasses.label}`]: {
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const StyledDisabledOptionTooltip = styled(CommonTooltip)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(-1),
    },
  })
);

export const StyledLongTextOptionTooltip = styled(CommonTooltip)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      marginLeft: theme.spacing(8),
      marginTop: theme.spacing(-1),
    },
  })
);

export const ListPanel = styled(Box)(() => ({
  overflowY: 'scroll',
}));

export const ApplyButton = styled(ButtonPrimary)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
