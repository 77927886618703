import React, { ChangeEvent, useState } from 'react';
import { MAX_REUSE_DOMAIN_DESCRIPTION_LENGTH } from './constants';
import { useTranslation } from 'react-i18next';
import { StyledDrawerContent } from './styles';

import CommonOutlinedMultilineTextFieldWithInnerIcons from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextFieldWithInnerIcons';
import Box from '@mui/system/Box';
import { InnerCreateOrEditDomainDrawerProps } from 'sections/Reuse/types';
import {
  InnerDivider,
  CommonDrawerTextField,
  useCalculateDrawerContentHeight,
} from 'components/CommonDrawer';
import AdministrativeInformation from 'components/AdministrativeInformation';

export default function InnerCreateOrEditDomainDrawer({
  created,
  domainDescriptionErrorMessage,
  domainDescriptionState,
  domainExists,
  domainNameErrorMessage,
  domainNameState,
  modified,
  footerRect,
}: InnerCreateOrEditDomainDrawerProps) {
  const { t } = useTranslation();
  const [domainNameIsPristine, setDomainNameIsPristine] = useState(true);
  const { elementHeight, refElement } =
    useCalculateDrawerContentHeight(footerRect);

  const localDomainNameErrorMessage =
    (!domainNameIsPristine && domainNameErrorMessage) || '';

  return (
    <StyledDrawerContent
      ref={refElement}
      style={{ height: `${elementHeight}px` }}
    >
      <Box mb={4}>
        <CommonDrawerTextField
          autoFocus
          id="domain-name"
          label={t('reuse.labels.domainName')}
          value={domainNameState.textValue}
          helperText={t('reuse.hints.required')}
          errorText={localDomainNameErrorMessage}
          onChange={(ev) => {
            setDomainNameIsPristine(false);
            domainNameState.setTextValue(ev.target.value);
          }}
          onBlur={() => {
            setDomainNameIsPristine(false);
            domainNameState.setTextValue(domainNameState.textValue.trim());
          }}
        />
      </Box>

      <CommonOutlinedMultilineTextFieldWithInnerIcons
        id="domain-description"
        label={t('reuse.labels.domainDescription')}
        rows="4"
        fullWidth
        helperText={
          domainDescriptionErrorMessage || t('capture.hints.optional')
        }
        margin="none"
        error={!!domainDescriptionErrorMessage}
        value={domainDescriptionState.textValue}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          domainDescriptionState.setTextValue(ev.target.value);
        }}
        onBlur={() => {
          domainDescriptionState.setTextValue(
            domainDescriptionState.textValue.trim()
          );
        }}
        charCount={domainDescriptionState.textValueCount}
        maxCharCount={MAX_REUSE_DOMAIN_DESCRIPTION_LENGTH}
        copyToClipboard={!!domainDescriptionState.textValue}
      />
      {domainExists && [
        <InnerDivider
          key="divider"
          sx={(theme) => ({
            margin: theme.spacing(6, 0, 2, 0),
          })}
        />,
        <AdministrativeInformation
          key="administrativeInformation"
          created={created}
          modified={modified}
        />,
      ]}
    </StyledDrawerContent>
  );
}
