import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { CheckboxValue } from 'types/types';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { CheckboxListProps } from './CheckboxList';

export interface UseCheckboxListResult {
  allChecked: boolean;
  allIndeterminate: boolean;
  amountChecked: number;
  getFilteredArrayWithUpdatedValues: () => CheckboxValue[];
  localValues: Set<CheckboxValue>;
  setLocalValues: Dispatch<SetStateAction<Set<CheckboxValue>>>;
}

export function useCheckboxList({
  filteredValues,
  valuesWereModified,
  handleValueChange,
  values,
}: CheckboxListProps): UseCheckboxListResult {
  const [localValues, setLocalValues] = useState<Set<CheckboxValue>>(
    new Set(values.map((v, i) => ({ ...v, originalIndex: i })))
  );
  const localFilteredValues = useRef<CheckboxValue[] | undefined>(
    filteredValues
  );
  const [amountChecked, setAmountChecked] = useState<number>(
    values.filter((v) => v.checked).length
  );
  const [allChecked, setAllChecked] = useState(
    values.length ? values.every((v) => v?.checked) : false
  );
  const [allIndeterminate, setAllIndeterminate] = useState(() => {
    return values.some((v) => v?.checked) && !values.every((v) => v?.checked);
  });

  const stateUpdateFunction = (data: CheckboxValue[]) => {
    const checkedCheckboxes = data.filter((v) => v.checked).length;

    setAmountChecked(checkedCheckboxes);
    setAllChecked(!!data.length && checkedCheckboxes === data.length);
    setAllIndeterminate(!!checkedCheckboxes && checkedCheckboxes < data.length);
  };

  useDidMountEffect(() => {
    if (values.length) {
      setLocalValues(
        new Set(values.map((v, i) => ({ ...v, originalIndex: i })))
      );
    }
  }, [values]);

  // if filtered data are changed (except on mounting)
  useDidMountEffect(() => {
    if (filteredValues) {
      stateUpdateFunction(
        Array.from(localValues).filter((lv) =>
          filteredValues.find((fl) => fl.id === lv.id)
        )
      );
      localFilteredValues.current = filteredValues;
    }
  }, [filteredValues, localValues]);

  const getFilteredArrayWithUpdatedValues = useCallback((): CheckboxValue[] => {
    const valuesArray = Array.from(localValues);

    return valuesArray.length && filteredValues
      ? filteredValues?.map((v) => {
          return valuesArray.find((lv) => lv.id === v.id) as CheckboxValue;
        })
      : valuesArray;
  }, [filteredValues, localValues]);

  // after any update (except on mounting)
  useDidMountEffect(() => {
    if (valuesWereModified || !isEqual(values, Array.from(localValues))) {
      const newValues = values.map((v) => {
        const matchingValue = Array.from(localValues).find(
          (lv) => lv.id === v.id
        );
        if (matchingValue) {
          return matchingValue;
        }
        return v;
      });
      handleValueChange(new Set(newValues));
    }

    const filteredArrayWithUpdatedValues = getFilteredArrayWithUpdatedValues();
    stateUpdateFunction(filteredArrayWithUpdatedValues);
  }, [localValues]);

  return {
    allChecked,
    allIndeterminate,
    amountChecked,
    getFilteredArrayWithUpdatedValues,
    localValues,
    setLocalValues,
  };
}
