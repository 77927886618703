import React from 'react';
import CommonTooltip from 'components/CommonTooltip';
import RecommendedRequiredIcon from './RecommendedRequiredIcon';
import { DO_NOT_EXPAND_CLASS } from 'constants/constants';
import RecommendedRequiredButton from './RecommendedRequiredButton';

interface RecommendedRequiredButtonProps {
  isRecommended: boolean;
  label: string;
  readOnly: boolean;
  toggleRequiredRecommended: () => void;
  tooltip: string;
}

export default function RecommendedRequiredComponent({
  isRecommended,
  label,
  readOnly,
  toggleRequiredRecommended,
  tooltip,
}: RecommendedRequiredButtonProps) {
  return (
    <CommonTooltip title={tooltip}>
      <RecommendedRequiredButton
        disableRipple
        className={DO_NOT_EXPAND_CLASS}
        data-testid="Select-recommend-button"
        aria-label={label}
        readOnly={readOnly}
        {...(!readOnly && {
          onClick: () => {
            toggleRequiredRecommended();
          },
        })}
      >
        <RecommendedRequiredIcon isRecommended={isRecommended} />
      </RecommendedRequiredButton>
    </CommonTooltip>
  );
}
