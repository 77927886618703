import { TargetActions } from './components/GoalSection';
import React from 'react';
import {
  ContextConfig,
  DialogReloadLSData,
  Goal,
  Guideline,
  GuidelineId,
  PresetId,
  Target,
} from './types';
import {
  setGoalCustomPresetDescription,
  setGoalGuidelineActive,
  setGoalGuidelineContextConfig,
  setGoalGuidelineParameterValue,
  setGoalPreset,
  setGoalRecommendedFlag,
} from './helpers';
import { EditContextsDrawerForProps } from './components/EditContextsDrawer';
import { useTranslation } from 'react-i18next';

export interface UseTargetActionsProps {
  allGoals: Goal[];
  dialogWithReloadLSData: DialogReloadLSData[];
  openContextDrawerFor: EditContextsDrawerForProps | undefined;
  originalTarget: Target | undefined;
  setDialogWithLSReloadIsActive: (v: boolean) => void;
  setDialogWithReloadLSData: (v: DialogReloadLSData[]) => void;
  setModifiedByUser: (v: boolean) => void;
  setOpenContextDrawerFor: (v: EditContextsDrawerForProps) => void;
  setSnackbarMessage: (v: string) => void;
  setTarget: (target: Target) => void;
  target: Target | undefined;
}

export function useTargetActions({
  allGoals,
  dialogWithReloadLSData,
  openContextDrawerFor,
  originalTarget,
  setDialogWithLSReloadIsActive,
  setDialogWithReloadLSData,
  setModifiedByUser,
  setOpenContextDrawerFor,
  setSnackbarMessage,
  setTarget,
  target,
}: UseTargetActionsProps): TargetActions {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return {
      setRecommended(goal: Goal, recommended: boolean) {
        if (target) {
          setGoalRecommendedFlag({
            goal,
            recommended,
            setTarget,
            target,
          });
          setModifiedByUser(true);
        }
      },
      setPreset(goal: Goal, presetId: PresetId) {
        if (target) {
          setGoalPreset({
            goal,
            presetId,
            setTarget,
            target,
          });

          setModifiedByUser(true);
        }
      },
      setGuidelineActive(
        goal: Goal,
        guidelineId: GuidelineId,
        active: boolean
      ) {
        if (target) {
          setGoalGuidelineActive({
            active,
            goal,
            guidelineId,
            originalTarget,
            setSnackbarMessage,
            setTarget,
            t,
            target,
          });

          setModifiedByUser(true);
        }
      },
      setGuidelineParameterValue(
        goal: Goal,
        guideline: Guideline,
        parameterValue: string
      ) {
        if (target) {
          setGoalGuidelineParameterValue({
            allGoals,
            dialogWithReloadLSData,
            goal,
            guideline,
            originalTarget,
            parameterValue,
            setDialogWithLSReloadIsActive,
            setDialogWithReloadLSData,
            setSnackbarMessage,
            setTarget,
            t,
            target,
          });

          setModifiedByUser(true);
        }
      },
      setContextConfig(
        goal: Goal,
        guidelineId: GuidelineId,
        contextConfig: ContextConfig
      ) {
        if (target) {
          setGoalGuidelineContextConfig({
            contextConfig,
            goal,
            guidelineId,
            originalTarget,
            openContextDrawerFor,
            setOpenContextDrawerFor,
            setSnackbarMessage,
            setTarget,
            t,
            target,
          });

          setModifiedByUser(true);
        }
      },
      openEditContextsDrawer(props: EditContextsDrawerForProps) {
        setOpenContextDrawerFor(props);
      },
      setCustomPresetDescription(goal: Goal, description: string) {
        if (target) {
          setGoalCustomPresetDescription({
            description,
            goal,
            setTarget,
            target,
          });
        }

        setModifiedByUser(true);
      },
    };
  }, [
    allGoals,
    dialogWithReloadLSData,
    openContextDrawerFor,
    originalTarget,
    setDialogWithLSReloadIsActive,
    setDialogWithReloadLSData,
    setModifiedByUser,
    setOpenContextDrawerFor,
    setSnackbarMessage,
    setTarget,
    t,
    target,
  ]);
}
