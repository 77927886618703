import { useEffect, useState } from 'react';
import fetcher from 'utils/fetch';
import { UserContextType } from 'contexts/UserContext';
import jwt_decode, { JwtPayload } from 'jwt-decode';

type HookReturnType = {
  accessToken: string | null;
  user: UserContextType | null;
  isLoading: boolean;
  isError: Error | null;
};

const useGetAuthenticationToken = (): HookReturnType => {
  const [state, setState] = useState<HookReturnType>({
    accessToken: null,
    user: null,
    isLoading: true,
    isError: null,
  });

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const { accessToken } = await fetcher<{ accessToken: string }>(
          '/dashboard/api/signin/authenticate',
          { method: 'POST' }
        );
        const { sub } = jwt_decode<JwtPayload>(accessToken);

        if (sub) {
          setState({
            accessToken,
            user: { id: sub },
            isLoading: false,
            isError: null,
          });
        }
      } catch (err: unknown) {
        setState({
          accessToken: null,
          user: null,
          isLoading: false,
          isError: err as Error,
        });
      }
    };

    fetchToken();
  }, []);

  return state;
};

export default useGetAuthenticationToken;
