import { TFunction } from 'i18next';
import { TargetLanguageStructure } from './hooks';
import { CheckboxValue, LanguageFull } from 'types/types';

export function flattenLanguageTargetStructure(
  targetLanguageStructure: TargetLanguageStructure
): CheckboxValue[] {
  return Object.values(targetLanguageStructure).flat();
}

export function generateSelectedValue(
  t: TFunction,
  languageList: CheckboxValue[],
  type: 'language' | 'target'
) {
  const allValuesChecked = languageList.every((langObj) => langObj.checked);
  const checkedObjs = languageList.filter((langObj) => langObj.checked);
  const checked = checkedObjs.length;
  const manyTranslation = t(
    type === 'language'
      ? 'reuseInbox.descriptions.selectedManyLanguages'
      : 'reuseInbox.descriptions.selectedManyTargets',
    {
      selected: checked,
      all: languageList.length,
    }
  );
  const allTranslation = t(
    type === 'language'
      ? 'reuseInbox.descriptions.selectedAllLanguages'
      : 'reuseInbox.descriptions.selectedAllTargets'
  );

  const oneChecked = (checked === 1 && checkedObjs[0].label) || '';
  const manyChecked =
    (!allValuesChecked && checked > 1 && manyTranslation) || '';
  const allChecked = (allValuesChecked && allTranslation) || '';

  return {
    allChecked,
    manyChecked,
    oneChecked,
  };
}

export function generateLanguageSelectedValue(
  t: TFunction,
  languageList: CheckboxValue[]
): string {
  const { allChecked, manyChecked, oneChecked } = generateSelectedValue(
    t,
    languageList,
    'language'
  );

  return oneChecked || allChecked || manyChecked;
}

export function generateTargetSelectedValue(
  t: TFunction,
  targetList: CheckboxValue[]
): string {
  const { allChecked, manyChecked, oneChecked } = generateSelectedValue(
    t,
    targetList,
    'target'
  );

  if (targetList.length === 1) {
    return targetList[0].label;
  }

  if (!targetList.length) {
    return '';
  }

  return allChecked || manyChecked || oneChecked;
}

export function getListStatus(list: CheckboxValue[]) {
  const allChecked = list.every((e) => e.checked);
  const someChecked = !allChecked && list.some((e) => e.checked);
  const noneChecked = !allChecked && !someChecked;
  return {
    allChecked,
    someChecked,
    noneChecked,
  };
}

export function createLanguageListFromFullLanguages(
  targetLanguageStructure: TargetLanguageStructure,
  allLanguages: LanguageFull[],
  existingLanguages: string[],
  checkedData: boolean | string[] = false
) {
  return allLanguages.reduce((acc, language) => {
    if (existingLanguages.includes(language.displayName)) {
      const existingLanguageWithTheSameId = acc.find(
        (l) => l.id === language.abbreviation
      );

      const checked =
        typeof checkedData === 'boolean'
          ? checkedData
          : checkedData.includes(language.displayName);

      if (!existingLanguageWithTheSameId) {
        const numberOfTargets = targetLanguageStructure[
          language.displayName
        ].reduce((acc, target) => {
          return acc + ((target.data as number) || 0);
        }, 0);

        acc.push({
          id: language.abbreviation,
          label: `${language.displayName} (${numberOfTargets})`,
          checked: checked,
        });
      }
    }

    return acc;
  }, [] as CheckboxValue[]);
}
