import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { addAllParents, createMainDomainStructure, update } from './helpers';
import { EnumContentObject } from 'types/types';
import { DomainStructureObj } from './types';

export interface UseDomainMenuProps {
  domainData: EnumContentObject[];
  selectedDomains: string[];
}

export interface UseDomainMenuResult {
  domainStructure: DomainStructureObj[];
  fullDisplayStructure: DomainStructureObj[];
  setFilteredDomainData: (arg: string[]) => void;
}

export function useDomainMenu({
  domainData,
  selectedDomains,
}: UseDomainMenuProps): UseDomainMenuResult {
  const [domainStructure, setDomainStructure] = useState<DomainStructureObj[]>(
    []
  );
  const [fullDisplayStructure, setFullDisplayStructure] = useState<
    DomainStructureObj[]
  >([]);
  const [filteredDomainData, setFilteredDomainData] = useState<string[]>([]);

  useEffect(() => {
    if (domainData.length) {
      setFullDisplayStructure(
        update(
          fullDisplayStructure.length
            ? fullDisplayStructure
            : createMainDomainStructure(domainData),
          selectedDomains
        )
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [domainData, selectedDomains]);

  useEffect(() => {
    if (!filteredDomainData.length && fullDisplayStructure.length) {
      setFilteredDomainData(fullDisplayStructure.map((d) => d.internalId));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fullDisplayStructure]);

  useEffect(() => {
    // if we show all data
    if (
      filteredDomainData.length &&
      fullDisplayStructure.length === filteredDomainData.length
    ) {
      setDomainStructure(fullDisplayStructure);
      return;
    }

    // filtered data
    if (fullDisplayStructure.length > filteredDomainData.length) {
      const parentsOfFilteredDomains: string[] = Array.from(
        filteredDomainData.reduce((parentsArr, domainId) => {
          const domainParents: string[] = [];
          const domainObj = fullDisplayStructure.find(
            (d) => d.internalId === domainId
          );
          addAllParents(domainObj, fullDisplayStructure, domainParents);

          return new Set(Array.from(parentsArr).concat(domainParents));
        }, new Set<string>())
      );

      const completeFilteredStructureWithParents = Array.from(
        new Set(filteredDomainData.concat(parentsOfFilteredDomains))
      );

      const res = fullDisplayStructure.reduce((resArr, objDomain) => {
        if (
          completeFilteredStructureWithParents.includes(objDomain.internalId)
        ) {
          resArr.push(objDomain);
        }

        return resArr;
      }, [] as DomainStructureObj[]);

      if (!isEqual(domainStructure, res)) {
        setDomainStructure(res);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filteredDomainData, fullDisplayStructure]);

  return {
    domainStructure,
    fullDisplayStructure,
    setFilteredDomainData,
  };
}
