import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Redirect } from 'react-router-dom';

import { UnifiedMainPageLayout } from 'components/PageLayout';
import {
  MainPageContentText,
  MainPageH2ContentHeader,
} from 'components/PageLayout/components';
import TransWrapper from 'components/TransWrapper';
import { StyledButtonLink } from './styles';
import { useAnalyticsPrivileges } from 'hooks/usePrivileges';
import Box from '@mui/material/Box';

export default function ReportingAPI() {
  const { t } = useTranslation();
  const privileges = useAnalyticsPrivileges();

  // Access control based on privileges
  if (!privileges.editRights && !privileges.readRights) {
    return <Redirect to="/403" />;
  }

  return (
    <UnifiedMainPageLayout
      heading={t('reportingAPI.title')}
      focusHeading
      helpIconText={t('reportingAPI.a11y.helpIconTooltip')}
    >
      <Box sx={(theme) => ({ maxWidth: theme.spacing(120) })}>
        <MainPageContentText>
          {t('reportingAPI.content.text1')}
        </MainPageContentText>
        <MainPageContentText>
          <TransWrapper i18nKey="reportingAPI.content.text2" />
        </MainPageContentText>
        <MainPageContentText>
          {t('reportingAPI.content.text3')}
        </MainPageContentText>
        <MainPageH2ContentHeader>
          {t('reportingAPI.content.header1')}
        </MainPageH2ContentHeader>
        <MainPageContentText>
          {t('reportingAPI.content.text4')}
        </MainPageContentText>
        <MainPageContentText>
          {t('reportingAPI.content.text5')}
        </MainPageContentText>
        <MainPageH2ContentHeader>
          {t('reportingAPI.content.header2')}
        </MainPageH2ContentHeader>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/1006745016?h=4f401c0939&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            title="API Placeholder Video"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <MainPageH2ContentHeader
          sx={(theme) => ({
            marginTop: theme.spacing(7),
          })}
        >
          {t('reportingAPI.content.header3')}
        </MainPageH2ContentHeader>
        <StyledButtonLink
          target="_blank"
          href="https://acrolinxapi.docs.apiary.io/#reference/reporting-api"
        >
          <Typography component="span">
            {t('reportingAPI.buttonLabel')}
          </Typography>
        </StyledButtonLink>
      </Box>
    </UnifiedMainPageLayout>
  );
}
