import React from 'react';

import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import Accordion, {
  accordionClasses,
  AccordionProps,
} from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { accordionDetailsClasses } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  minHeight: theme.spacing(0),
  '&.Mui-expanded': {
    minHeight: 0,
  },
  '&.MuiAccordionSummary-root.Mui-disabled': {
    opacity: 1,
  },
  '&.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
  },
}));

export const StyledAccordionPlain = styled(AccordionScrollToView)({
  transition: 'none',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  ':before': {
    transition: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  [`& .${accordionSummaryClasses.root}`]: {
    margin: 0,
    padding: 0,
    transition: 'none',
  },
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
  },
  [`& .${accordionDetailsClasses.root}`]: {
    padding: 0,
  },
});

export function AccordionScrollToView(props: AccordionProps) {
  return (
    <Accordion
      {...props}
      TransitionProps={{
        onEntered: (node) => {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        },
      }}
    />
  );
}
