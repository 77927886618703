import { Dispatch, Reducer, useReducer } from 'react';

export interface State<T> {
  inProgress: boolean;
  data: T | null;
  error: Error | null;
}
export enum ActionType {
  InProgress = 'inProgress',
  Success = 'success',
  Failure = 'failure',
  Reset = 'reset',
}
export type ActionInProgress = {
  type: ActionType.InProgress;
};

export type ActionReset = {
  type: ActionType.Reset;
};

export interface ActionSuccess<T> {
  type: ActionType.Success;
  data: T | null;
}

export interface ActionFailure {
  type: ActionType.Failure;
  error: Error | null;
}

export type Action<T> =
  | ActionInProgress
  | ActionReset
  | ActionSuccess<T>
  | ActionFailure;

export interface UseRequestStatusResult<T> {
  state: State<T>;
  dispatch: Dispatch<Action<T>>;
  resetRequestState: () => void;
}

export const initialState = {
  inProgress: false,
  data: null,
  error: null,
};

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case ActionType.InProgress:
      return { ...state, inProgress: true };
    case ActionType.Success:
      return { ...initialState, data: action.data };
    case ActionType.Failure:
      return { ...state, error: action.error, inProgress: false };
    case ActionType.Reset:
      return { ...initialState };
    default:
      return state;
  }
}

export default function useRequestStatus<T>(): UseRequestStatusResult<T> {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(
    reducer,
    initialState
  );

  function resetRequestState() {
    dispatch({ type: ActionType.Reset });
  }

  return { dispatch, resetRequestState, state };
}
