import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TransWrapper from 'components/TransWrapper';
import { UploadFileName, UploadReplacementDescription } from './components';
import ButtonPrimary from 'components/ButtonPrimary';
import Grid from '@mui/material/Grid';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface UploadReplacementDialogProps {
  onClose: (ok: boolean) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedFileName: string;
  open: boolean;
}

export default function UploadReplacementDialog({
  onClose,
  handleChange,
  selectedFileName,
  open,
}: UploadReplacementDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('reuse.titles.importReplacements')}
      open={open}
      onClose={(ok: boolean) => onClose(ok)}
      buttonOKLabel={t('shared.actions.import')}
      buttonCancelLabel={t('shared.actions.cancel')}
      isButtonOKDisabled={!selectedFileName.length}
    >
      <Grid
        flexDirection="column"
        alignItems="baseline"
        container
        component="span"
      >
        <UploadReplacementDescription>
          <TransWrapper
            i18nKey="reuse.descriptions.uploadReplacements"
            components={{ span: <span /> }}
            values={{
              link: `${process.env.PUBLIC_URL}/reuse-sample-import.csv`,
            }}
          />
        </UploadReplacementDescription>

        <ButtonPrimary component="label" variant="contained">
          {t('shared.actions.browse')}
          <input
            hidden
            onChange={handleChange}
            id="upload-replacement"
            data-testid="upload-replacement"
            name="upload-replacement"
            type="file"
            accept=".csv, .txt"
          />
        </ButtonPrimary>

        <UploadFileName>
          <TransWrapper
            i18nKey="reuse.titles.selectedFile"
            values={{ file: selectedFileName }}
            components={{ span: <span /> }}
          />
        </UploadFileName>
      </Grid>
    </ConfirmationDialog>
  );
}
