import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonSelectDense, {
  BootstrapInput,
  CommonMenuItem,
  menuProps,
  StyledInlineSelectLabel,
  StyledPlaceholder,
  StyledSelectedValue,
} from 'components/CommonSelectDense';
import { ReuseGuidelineProps } from '../../types';
import { StyledGuidelineParameterLabelReadOnly } from '../GuidelineSection/styles';

export default function ReuseSentenceGuideline({
  onChangeHandler,
  options,
  readOnly,
  value = '',
}: ReuseGuidelineProps) {
  const { t } = useTranslation();
  const displayName = options.find((o) => o.internalId === value)?.displayName;

  return (
    <>
      <StyledInlineSelectLabel id="repository-select-label" variant="body2">
        {t('capture.labels.selectRepository')}
      </StyledInlineSelectLabel>
      {readOnly ? (
        <StyledGuidelineParameterLabelReadOnly>
          {displayName}
        </StyledGuidelineParameterLabelReadOnly>
      ) : (
        <CommonSelectDense
          MenuProps={{
            ...menuProps,
            id: 'reuse-repository-select',
          }}
          input={<BootstrapInput style={{ backgroundColor: 'white' }} />}
          onClick={(ev) => ev.stopPropagation()}
          value={value}
          aria-labelledby="repository-select-label"
          displayEmpty
          onChange={(event) => {
            onChangeHandler(event.target.value as string);
          }}
          placeholder={t('capture.labels.selectRepositoryPlaceholder')}
          renderValue={() =>
            value ? (
              <StyledSelectedValue variant="body2">
                {displayName}
              </StyledSelectedValue>
            ) : (
              <StyledPlaceholder variant="body2">
                {t('capture.labels.selectRepositoryPlaceholder')}
              </StyledPlaceholder>
            )
          }
        >
          {options.map((option) => (
            <CommonMenuItem
              key={option.internalId}
              value={option.internalId}
              text={option.displayName}
            >
              {option.displayName}
            </CommonMenuItem>
          ))}
        </CommonSelectDense>
      )}
    </>
  );
}
