import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const SuggestionsTitle = styled((props: { children: ReactNode }) => (
  <Typography component="h2" {...props} />
))(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.18),
  marginBottom: theme.spacing(4),
}));

export const CardBreak = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));
