import React, { PropsWithChildren } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

export type ImportStatusDescriptionProps = PropsWithChildren<TypographyProps>;

export default function ImportStatusDescription({
  children,
}: ImportStatusDescriptionProps) {
  return (
    <Typography
      variant="body2"
      sx={(theme) => ({ marginTop: theme.spacing(4) })}
    >
      {children}
    </Typography>
  );
}
