import * as React from 'react';
import ErrorNotificationDialog from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';
import { useHistory } from 'react-router-dom';

export const CustomFieldsError = ({
  page,
}: {
  page: 'dashboard' | 'userDetails';
}) => {
  const { error, setError } = useError();
  const history = useHistory();

  if (error) {
    const { value } = error;
    const onClose = () => {
      if (value instanceof ApiError) {
        if (
          value.status === 403 ||
          (value.status === 404 && page === 'dashboard')
        ) {
          window.location.reload();
        } else if (value.status === 404 && page === 'userDetails') {
          history.replace({ pathname: '/user-management/users' });
        }
      }

      setError(null);
    };

    return (
      <ErrorNotificationDialog
        {...apiErrorToNotification(value)}
        onClose={onClose}
      />
    );
  }

  return null;
};
