import React, { useContext } from 'react';
import Box from '@mui/material/Box';

import CustomFieldList from '../../../CustomFieldList';
import CustomFieldFreeText from '../../../CustomFieldFreeText';
import RolesTableCellValue from '../../../RolesTableCellValue';
import DisplayDate from 'components/DisplayDate';
import PrivilegesContext from 'contexts/PrivilegesContext';
import UserContext from 'contexts/UserContext';
import { MAX_COLUMN_WIDTH } from '../../UsersTable';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';

import type { UserTypeExtended } from '../UsersTableBodyRow';
import {
  StyledCustomUsernameTableCell,
  StyledCellLink,
  StyledCustomTableCell,
} from './styles';
import { User } from 'sections/Users/types';
import { useFetch } from 'utils/fetch';
import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';

interface UsersTableBodyRowCellProps extends Partial<UserTypeExtended> {
  cellType: keyof User;
  id: string;
  labelId?: string;
  onAfterChange?: () => void;
}

const UsersTableBodyRowCell = React.forwardRef<any, UsersTableBodyRowCellProps>(
  ({ cellType, labelId, customFields, onAfterChange, ...rowData }, ref) => {
    const { t } = useTranslation();
    const { id: userId } = useContext(UserContext) || {};
    const userPrivileges = useContext(PrivilegesContext);
    const http = useFetch();
    const { setError } = useError();

    const { id } = rowData;

    const { enqueueSnackbar } = useSnackbar();

    const handleSetCustomField = async (
      uId: string,
      customFieldId: string,
      value: string
    ) => {
      try {
        await http.put(`/api/v1/user/${uId}`, {
          customFields: [
            {
              key: customFieldId,
              value,
            },
          ],
        });
        setTimeout(() => {
          enqueueSnackbar(
            t('users.notification.customFieldUpdatedSuccessfully'),
            {
              snackbarContentProps: {
                role: 'status',
              },
            }
          );
        }, 400);

        if (onAfterChange) {
          onAfterChange();
        }
      } catch (error: unknown) {
        setError({
          value: error as ApiError,
          origin: 'setCustomField.dashboard',
        });
      }
    };

    switch (cellType) {
      case 'username':
        return (
          <StyledCustomUsernameTableCell
            ref={ref}
            component="th"
            scope="row"
            {...(rowData[cellType] && {
              tooltipContent: rowData[cellType],
            })}
            {...(labelId && { id: labelId })}
          >
            {(rowData[cellType] && (
              <StyledCellLink
                href={`/user-management/users/${id}/details`}
                underline="hover"
                color="black"
              >
                {rowData[cellType]}
              </StyledCellLink>
            )) || <em>{t('users.fields.none')}</em>}
          </StyledCustomUsernameTableCell>
        );

      case 'roles':
        if (Array.isArray(rowData.roles) && rowData.roles.length) {
          return (
            <StyledCustomTableCell
              ref={ref}
              width={MAX_COLUMN_WIDTH}
              tooltipContent={
                [...rowData.roles].sort((roleA, roleB) =>
                  roleA.name.localeCompare(roleB.name)
                )[0].name
              }
              sx={{
                overflow: 'initial',
                whiteSpace: 'initial',
                px: 2,
                py: 0,
              }}
            >
              <RolesTableCellValue roles={rowData.roles} />
            </StyledCustomTableCell>
          );
        }

        return (
          <StyledCustomTableCell ref={ref} width={MAX_COLUMN_WIDTH}>
            <em>{t('users.fields.none')}</em>
          </StyledCustomTableCell>
        );
      case 'lastIntegrationAccess':
        if (rowData.lastIntegrationAccess) {
          return (
            <StyledCustomTableCell ref={ref}>
              <DisplayDate
                date={rowData.lastIntegrationAccess}
                tooltipVisible
              />
            </StyledCustomTableCell>
          );
        }

        return (
          <StyledCustomTableCell ref={ref}>
            <em>{t('users.fields.none')}</em>
          </StyledCustomTableCell>
        );
      case 'checkingFrequency':
        if (rowData.checkingFrequency) {
          return (
            <StyledCustomTableCell
              ref={ref}
              tooltipContent={t(
                `users.values.checkingFrequency.${rowData[cellType]}`
              )}
            >
              {t(`users.values.checkingFrequency.${rowData[cellType]}`)}
            </StyledCustomTableCell>
          );
        }

        return (
          <StyledCustomTableCell ref={ref}>
            <em>{t('users.fields.none')}</em>
          </StyledCustomTableCell>
        );
      case 'licenseType':
        if (!rowData[cellType]) {
          return (
            <StyledCustomTableCell ref={ref}>
              <em>{t('users.fields.none')}</em>
            </StyledCustomTableCell>
          );
        }

        let status = t('users.info.status.integratedLicense');

        if (rowData[cellType] === 'named') {
          status = t('users.labels.licenseNamedUser');
        } else if (rowData[cellType] === 'concurrent') {
          status = t('users.labels.licenseConcurrentUser');
        }

        let tooltipContent = status;
        const licenseStatus = rowData['licenseStatus'];

        if (rowData[cellType] === 'named') {
          if (licenseStatus) {
            tooltipContent += t(
              `users.info.status.${
                licenseStatus === 'active' ? 'activeLicense' : 'inactiveLicense'
              }`
            );
          }
        }

        return (
          <StyledCustomTableCell ref={ref} tooltipContent={tooltipContent}>
            <>
              <Box component="span">{status}</Box>
              {rowData[cellType] === 'named' && licenseStatus && (
                <Box
                  component="span"
                  color={
                    licenseStatus === 'active'
                      ? 'custom.green'
                      : 'custom.bluishGrey'
                  }
                  paddingLeft={1}
                >
                  {t(
                    `users.info.status.${
                      licenseStatus === 'active'
                        ? 'activeLicense'
                        : 'inactiveLicense'
                    }`
                  )}
                </Box>
              )}
            </>
          </StyledCustomTableCell>
        );

      default:
        if (
          cellType.startsWith('customFields.') &&
          customFields &&
          userPrivileges
        ) {
          const customFieldKey = cellType.replace('customFields.', '');

          const customField = customFields.find(
            ({ key: k }) => customFieldKey === k
          );

          if (customField) {
            const {
              key: cfKey,
              displayName,
              inputType,
              type,
              value = '',
            } = customField;

            const defaultCellProps = {
              ref,
              tooltipContent: value,
              maxWidth: MAX_COLUMN_WIDTH,
            };

            if (!userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && !value) {
              return (
                <StyledCustomTableCell {...defaultCellProps}>
                  <em>{t('users.fields.none')}</em>
                </StyledCustomTableCell>
              );
            }

            if (
              inputType === 'externallyProvided' ||
              (!userPrivileges.includes(USER_AND_ROLES_EDIT_USER) &&
                rowData['username'] !== userId)
            ) {
              return (
                <StyledCustomTableCell {...defaultCellProps}>
                  {value}
                </StyledCustomTableCell>
              );
            }

            if (type === 'list') {
              return (
                <StyledCustomTableCell
                  {...defaultCellProps}
                  sx={{
                    py: 0,
                  }}
                >
                  <CustomFieldList
                    id={`${id}-${cfKey.replace(' ', '')}`}
                    possibleValues={customField.possibleValues || []}
                    value={value}
                    displayName={displayName}
                    inputType={inputType}
                    onSubmit={(v) => {
                      handleSetCustomField(id, cfKey, v);
                    }}
                  />
                </StyledCustomTableCell>
              );
            }

            return (
              <StyledCustomTableCell
                {...defaultCellProps}
                tooltipTypeYellow
                sx={{
                  py: 0,
                }}
              >
                <CustomFieldFreeText
                  value={value}
                  displayName={displayName}
                  inputType={inputType}
                  onSubmit={(v) => {
                    handleSetCustomField(id, cfKey, v);
                  }}
                />
              </StyledCustomTableCell>
            );
          }
        }

        if (cellType === 'customFields') {
          return <></>;
        }

        return (
          <StyledCustomTableCell
            ref={ref}
            width={MAX_COLUMN_WIDTH}
            tooltipContent={rowData[cellType]}
          >
            {rowData[cellType] || <em>{t('users.fields.none')}</em>}
          </StyledCustomTableCell>
        );
    }
  }
);

export default UsersTableBodyRowCell;
