import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { SuggestionDetailLabel, SuggestionDetailText } from './components';
import { ReuseIndexDomain, ReuseSuggestion } from 'sections/Reuse/types';

import { LanguageFull } from 'types/types';

export interface SuggestionDetailsProps {
  data: ReuseSuggestion;
  languages: LanguageFull[];
  showLanguageLabelOnSuggestionCard: boolean;
  sortedDomains?: ReuseIndexDomain[];
}

function SuggestionDetails({
  data,
  languages,
  showLanguageLabelOnSuggestionCard,
  sortedDomains,
}: SuggestionDetailsProps) {
  const { t } = useTranslation();

  const language = useMemo(
    () => languages.find((lng) => lng.abbreviation === data.language),
    [data.language, languages]
  );
  const sets = useMemo(
    () =>
      data.cluster.reuseDomains.map(
        (domainId) =>
          sortedDomains?.find((d) => d.uuid === domainId)?.displayName
      ),
    [data.cluster.reuseDomains, sortedDomains]
  );

  return (
    <Grid sx={(theme) => ({ padding: theme.spacing(1, 0, 0), margin: 0 })}>
      {sets.length ? (
        <div>
          <SuggestionDetailLabel>
            {t('reuseInbox.labels.set', { count: sets.length })}:
          </SuggestionDetailLabel>{' '}
          <SuggestionDetailText>{sets.join(', ')}</SuggestionDetailText>
        </div>
      ) : (
        <></>
      )}
      <div>
        <SuggestionDetailLabel>
          {t('reuseInbox.labels.target', {
            count: data.targetDisplayNames.length,
          })}
          :
        </SuggestionDetailLabel>{' '}
        <SuggestionDetailText>
          {data.targetDisplayNames.join(', ')}
        </SuggestionDetailText>
      </div>
      {showLanguageLabelOnSuggestionCard && (
        <div>
          <SuggestionDetailLabel>
            {t('reuseInbox.labels.language')}:
          </SuggestionDetailLabel>{' '}
          <SuggestionDetailText>{language?.displayName}</SuggestionDetailText>
        </div>
      )}
    </Grid>
  );
}

export default React.memo(SuggestionDetails, isEqual);
