import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import ConfirmationDialog from 'components/ConfirmationDialog';
import Typography from '@mui/material/Typography';

interface DownloadReplacementsDialogProps {
  open: boolean;
  onClose: (ok: boolean) => void;
}

export default function ExportReplacementsDialog({
  open,
  onClose,
}: DownloadReplacementsDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('reuse.titles.exportReplacements')}
      open={open}
      onClose={onClose}
      buttonOKLabel={t('shared.actions.export')}
      buttonCancelLabel={t('shared.actions.cancel')}
      ContentComponent={Grid}
    >
      <Grid>
        <Typography variant="body2">
          {t('reuse.descriptions.exportReplacements')}
        </Typography>
      </Grid>
    </ConfirmationDialog>
  );
}
