import React, { ReactNode, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ContentBox, PageHeaderBox } from './components';
import {
  BackIconButton,
  StyledLink,
  StyledBackIconButtonTooltip,
} from './styles';
import { UseResizeResult } from 'hooks/useResize';

export interface PageLayoutProps {
  backLink: string;
  children: React.ReactElement | React.ReactElement[];
  focusHeading?: boolean;
  headerDimensions?: UseResizeResult<HTMLDivElement>;
  heading: string | undefined;
  rightSideHeaderComponent?: ReactNode;
  backIconText: string;
  lang?: string;
}

export default function DetailsPageLayout({
  backLink,
  children,
  focusHeading = false,
  heading = '',
  headerDimensions,
  rightSideHeaderComponent,
  backIconText,
  lang,
}: PageLayoutProps): JSX.Element {
  const headingRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusHeading) {
      headingRef.current?.focus();
    }
  }, [focusHeading]);

  return (
    <Box
      component="section"
      sx={(theme) => ({
        padding: theme.spacing(8),
        paddingTop: theme.spacing(19 / 5),
      })}
    >
      <PageHeaderBox ref={headerDimensions?.setResizeElement}>
        <Grid container>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Grid
              sx={{ display: 'inline-flex' }}
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
            >
              <StyledBackIconButtonTooltip
                title={backIconText}
                placement="bottom-start"
              >
                <StyledLink tabIndex={-1} to={backLink}>
                  <BackIconButton
                    aria-label={backIconText}
                    size="large"
                    disableRipple
                  >
                    <ArrowBackIcon color="primary" />
                  </BackIconButton>
                </StyledLink>
              </StyledBackIconButtonTooltip>
              <Typography
                variant="h1"
                ref={headingRef}
                tabIndex={-1}
                sx={{
                  margin: 0,
                }}
                {...(lang && { lang })}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid
              sx={(theme) => ({
                marginRight: theme.spacing(0.4),
              })}
            >
              {rightSideHeaderComponent}
            </Grid>
          </Grid>
        </Grid>
      </PageHeaderBox>
      <ContentBox>{children}</ContentBox>
    </Box>
  );
}
