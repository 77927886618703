import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import CommonTooltip from 'components/CommonTooltip';

import { SuggestionActionButtonProps } from '../../../../types';

export default function SuggestionUnDismissButton({
  dismissed,
  handleOnClick,
}: SuggestionActionButtonProps) {
  const { t } = useTranslation();
  const tooltip = t(
    dismissed
      ? 'reuseInbox.tooltips.unDismissSuggestion'
      : 'reuseInbox.tooltips.dismissSuggestion'
  );
  const IconComponent = dismissed
    ? UnarchiveOutlinedIcon
    : NotInterestedOutlinedIcon;

  return (
    <CommonTooltip title={tooltip}>
      <IconButton aria-label={tooltip} onClick={handleOnClick}>
        <IconComponent
          sx={(theme) => ({
            fill: theme.palette.common.black,
            fontSize: theme.typography.pxToRem(22),
          })}
        />
      </IconButton>
    </CommonTooltip>
  );
}
