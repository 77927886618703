import {
  GoalConfig,
  GoalId,
  Target,
} from 'sections/Targets/pages/TargetPage/types';

export function getOriginalSelectedGoals(
  target: Target | undefined
): Set<GoalId> {
  return (
    target?.targetGoalInfos.reduce((sg, x: GoalConfig) => {
      sg.add(x.goalId);
      return sg;
    }, new Set<GoalId>([])) || new Set([])
  );
}
