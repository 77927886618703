import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ButtonPrimary from 'components/ButtonPrimary';

import { useTranslation } from 'react-i18next';
import {
  StyledDialogContent,
  StyledOutlinedMultilineTextField,
  StyledTitle,
} from './styles';
import { useApiToken } from './hooks';
import TransWrapper from 'components/TransWrapper';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Grid from '@mui/material/Grid';

interface CreateApiTokenProps {
  username?: string;
  userId: string;
}

export default function CreateApiToken({
  userId,
  username,
}: CreateApiTokenProps) {
  const { t } = useTranslation();

  const [isCreateTokenDialogOpen, setIsCreateTokenDialogOpen] = useState(false);
  const [isTokenCreatedDialogOpen, setIsTokenCreatedDialogOpen] =
    useState(false);

  const { generateToken, newApiToken, isUserEditingSelf } = useApiToken({
    userId,
    username,
  });

  const openNewTokenDialog = async () => {
    if (await generateToken()) {
      setIsTokenCreatedDialogOpen(true);
      setIsCreateTokenDialogOpen(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={isCreateTokenDialogOpen}
        title={t('users.headers.generateApiToken')}
        onClose={(ok) =>
          ok ? openNewTokenDialog() : setIsCreateTokenDialogOpen(false)
        }
        buttonCancelLabel={t('shared.actions.cancel')}
        buttonOKLabel={t('shared.actions.create')}
        ContentComponent={Grid}
      >
        <StyledDialogContent>
          {isUserEditingSelf
            ? t('users.descriptions.generateOwnApiTokenWarning')
            : t('users.descriptions.generateOtherApiTokenWarning')}
        </StyledDialogContent>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isTokenCreatedDialogOpen}
        title={t('users.headers.apiTokenGenerated')}
        onClose={() => {
          setIsTokenCreatedDialogOpen(false);
        }}
        buttonOKLabel={t('shared.actions.close')}
        ContentComponent={Grid}
      >
        <StyledDialogContent pb={3}>
          <TransWrapper
            i18nKey={
              isUserEditingSelf
                ? 'users.descriptions.ownApiTokenGeneratedWarning'
                : 'users.descriptions.otherApiTokenGeneratedWarning'
            }
          />
        </StyledDialogContent>
        <StyledOutlinedMultilineTextField
          label={t('users.labels.apiToken')}
          fullWidth
          rows={8}
          value={newApiToken}
          copyToClipboard
          aria-disabled
          id="apiTokenTextField"
        />
      </ConfirmationDialog>
      <Box width={500}>
        <StyledTitle variant="h2" mt={31 / 5} mb={4}>
          {t('shared.navigation.apiToken')}
        </StyledTitle>
        <Typography mb={4} variant="body2">
          {isUserEditingSelf
            ? t('users.descriptions.generateOwnApiToken')
            : t('users.descriptions.generateOtherApiToken')}
        </Typography>
        <ButtonPrimary
          variant="contained"
          onClick={() => {
            setIsCreateTokenDialogOpen(true);
          }}
        >
          {t('users.actions.generateApiToken')}
        </ButtonPrimary>
      </Box>
    </>
  );
}
