import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CommonTooltip from 'components/CommonTooltip';
import { buttonSx, iconSx } from './helpers';
import { SuggestionActionButtonProps } from '../../../../types';

export default function SuggestionAddButton({
  handleOnClick,
  tooltip,
  disabled = false,
}: SuggestionActionButtonProps) {
  return (
    <CommonTooltip title={tooltip}>
      <IconButton
        aria-label={tooltip}
        color="primary"
        sx={buttonSx}
        onClick={handleOnClick}
        disabled={disabled}
      >
        <AddCircleOutlineRoundedIcon sx={iconSx} />
      </IconButton>
    </CommonTooltip>
  );
}
