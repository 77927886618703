import { styled } from '@mui/material/styles';
import { Popover, popoverClasses } from '@mui/material';

export const StyledPopover = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    padding: theme.spacing(3),
    boxShadow: '0px 2px 4px #0000004D',
    borderRadius: theme.spacing(1.2),
  },
}));
