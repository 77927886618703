import React from 'react';
import Box from '@mui/material/Box';

import { StyledPrivilegeName, StyledRoleName } from './styles';
import { Role } from 'sections/Users/types';

import { useTranslation } from 'react-i18next';

interface RoleWithPrivilegesProps {
  userRoleId: string;
  name: string;
  rolesData: { data: ReadonlyArray<Role> } | undefined;
}

const RoleWithPrivileges = ({
  userRoleId,
  name,
  rolesData,
}: RoleWithPrivilegesProps) => {
  const { t } = useTranslation();

  return (
    <Box key={userRoleId} id={name}>
      <StyledRoleName variant="body2">{name}</StyledRoleName>
      <StyledPrivilegeName variant="body2">
        {rolesData?.data
          .filter(({ id }) => id === userRoleId)
          .map(({ privileges }) =>
            privileges
              ?.map((privilege) => {
                const privilegeName =
                  privilege.charAt(0).toLowerCase() + privilege.slice(1);
                return t(`roles.privileges.labels.${privilegeName}`);
              })
              .join(', ')
          )}
      </StyledPrivilegeName>
    </Box>
  );
};

export default RoleWithPrivileges;
