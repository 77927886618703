import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

import LayoutForUnauthorized from 'components/LayoutForUnauthorized';
import { ReactComponent as AccessDeniedImage } from './access-denied.svg';
import ButtonPrimary from 'components/ButtonPrimary';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AdminAccessErrorPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          body: { backgroundColor: theme.palette.background.paper },
        })}
      />
      <LayoutForUnauthorized>
        <Box
          width={500}
          sx={{
            pt: 20,
            m: '0 auto',
            textAlign: 'center',
          }}
        >
          <AccessDeniedImage height={200} width={200} />
          <Typography variant="h1" mt={5} mb={2} fontWeight="regular">
            {t('shared.signIn.title.adminAccessError')}
          </Typography>
          <Typography
            variant="h2"
            mb={6}
            lineHeight="24px"
            fontWeight="regular"
          >
            <Box>{t('shared.signIn.msg.adminAccessError1')}</Box>
            <Box>{t('shared.signIn.msg.adminAccessError2')}</Box>
          </Typography>
          <ButtonPrimary
            variant="contained"
            {...{
              component: Link,
              to: '/dashboard.html#_LOG__ON',
            }}
          >
            {t('shared.signIn.buttons.acrolinxLogOn')}
          </ButtonPrimary>
        </Box>
      </LayoutForUnauthorized>
    </>
  );
}

export default AdminAccessErrorPage;
