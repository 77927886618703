import React, { ReactElement, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';

import CustomCheckbox from 'components/CustomCheckbox';
import CommonTooltip from 'components/CommonTooltip';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { HeadCellType } from '../UsersTable';
import ButtonPrimary from 'components/ButtonPrimary';
import AugmentedIconButton from '../AugmentedIconButton';
import {
  ApplyButton,
  ListPanel,
  StyledDisabledOptionTooltip,
  StyledFormControlLabel,
  StyledLongTextOptionTooltip,
} from './styles';

const POPOVER_WIDTH_DEFAULT = 200;
const POPOVER_WIDTH_BY_LANGUAGE = {
  de: 242,
  fr: 222,
  ja: 214,
};

interface ColumnSelectorProps {
  columns: HeadCellType[];
  onSubmit: (columns: HeadCellType[]) => void;
  rolesColumnDisabled: boolean;
}

const ColumnSelector = ({
  columns,
  onSubmit,
  rolesColumnDisabled,
}: ColumnSelectorProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visibleTooltipId, setVisibleTooltipId] = useState<string | boolean>(
    false
  );

  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  useEffect(() => {
    // Extract displayed columns
    setVisibleColumns(
      columns.filter(({ visible }) => visible).map(({ key }) => key)
    );
  }, [columns]);

  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <CommonTooltip title={<>{t('users.tooltips.columnSelector')}</>}>
        <AugmentedIconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          role="button"
          aria-label={t('users.tooltips.columnSelector')}
          aria-controls={anchorEl ? 'column-selector-dialog' : undefined}
          aria-haspopup="true"
          active={Boolean(anchorEl)}
        >
          <ViewWeekOutlinedIcon color="primary" />
        </AugmentedIconButton>
      </CommonTooltip>
      <Popover
        id="column-selector-dialog"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width:
              POPOVER_WIDTH_BY_LANGUAGE[
                i18n.language as keyof typeof POPOVER_WIDTH_BY_LANGUAGE
              ] || POPOVER_WIDTH_DEFAULT,
          },
        }}
      >
        <ListPanel
          display="flex"
          flexDirection={'column'}
          p={1}
          maxHeight={333}
          role="listbox"
        >
          {columns
            .filter(({ key }) => key !== 'username')
            .map((column) => ({
              ...column,
              disabled: column.key === 'roles' && rolesColumnDisabled,
            }))
            .map(({ key, label, disabled }) => (
              <Box key={label} role="option">
                <ConditionalWrapper
                  condition={disabled}
                  wrapper={(children: ReactElement) => (
                    <StyledDisabledOptionTooltip
                      type="yellow"
                      id="disabledBecauseUsed"
                      title={`${t('users.hints.disabledBecauseUsed')}`}
                      placement="bottom-start"
                    >
                      <div>{children}</div>
                    </StyledDisabledOptionTooltip>
                  )}
                >
                  <StyledLongTextOptionTooltip
                    title={
                      key.startsWith('customFields.') ? (
                        <>{label}</>
                      ) : (
                        <>{t(label)}</>
                      )
                    }
                    placement="bottom-start"
                    open={visibleTooltipId === key}
                    onOpen={(event: React.SyntheticEvent) => {
                      const target = event.target as HTMLElement;

                      setVisibleTooltipId(
                        target.scrollWidth > target.clientWidth ? key : false
                      );
                    }}
                    onClose={() => setVisibleTooltipId(false)}
                    disableFocusListener
                    disableTouchListener
                  >
                    <StyledFormControlLabel
                      control={
                        <CustomCheckbox
                          style={{ opacity: disabled ? 0.4 : 1 }}
                          name={key}
                          checked={visibleColumns.includes(key)}
                          inputProps={{
                            'aria-disabled': disabled,
                            'aria-describedby': disabled
                              ? 'disabledBecauseUsed'
                              : '',
                          }}
                          onChange={(event) => {
                            if (disabled) {
                              return false;
                            }
                            setVisibleColumns(
                              visibleColumns.includes(event.target.name)
                                ? visibleColumns.filter(
                                    (i) => i !== event.target.name
                                  )
                                : [...visibleColumns, event.target.name]
                            );
                            return true;
                          }}
                        />
                      }
                      label={
                        <>
                          {key.startsWith('customFields.') ? label : t(label)}
                        </>
                      }
                    />
                  </StyledLongTextOptionTooltip>
                </ConditionalWrapper>
              </Box>
            ))}
        </ListPanel>
        <Box p={3} borderTop={1} borderColor={'custom.lighterGrey'}>
          <ButtonPrimary variant="outlined" onClick={handleClose}>
            {t('shared.actions.cancel')}
          </ButtonPrimary>
          <ApplyButton
            variant="contained"
            onClick={() => {
              onSubmit(
                columns.map((column) => {
                  return {
                    ...column,
                    visible: visibleColumns.includes(column.key),
                  };
                })
              );

              handleClose();
            }}
          >
            {t('shared.actions.apply')}
          </ApplyButton>
        </Box>
      </Popover>
    </Box>
  );
};

export default ColumnSelector;
