import React from 'react';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

export interface ActivityCommentProps {
  comment: string;
}

export default function ActivityComment({ comment }: ActivityCommentProps) {
  return (
    <Grid
      container
      direction="column"
      sx={(theme) => ({ paddingTop: theme.spacing(1) })}
    >
      <SvgIcon
        width="20"
        height="10.806"
        viewBox="0 0 20 10.806"
        sx={(theme) => ({
          padding: 0,
          width: theme.spacing(4),
          height: theme.spacing(2.16),
          '& path': {
            fill: theme.palette.primary.light,
          },
        })}
      >
        <path
          id="Corner"
          d="M20,10.806,8.325,1.668C4.03-1.589.14.222,0,4.526l.008,6.281Z"
        />
      </SvgIcon>
      <Grid
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
          borderRadius: '4px',
          borderTopLeftRadius: 0,
          padding: theme.spacing(2),
          overflow: 'hidden',
          width: '100%',
        })}
      >
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(13),
            lineHeight: theme.typography.pxToRem(19),
            color: theme.palette.custom.darkBluishGrey,
            overflowWrap: 'anywhere',
          })}
        >
          {comment}
        </Typography>
      </Grid>
    </Grid>
  );
}
