import React from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import TransWrapper from 'components/TransWrapper';

type Props = { date: string };

export default function DateComponent({ date }: Props) {
  const { t } = useTranslation();

  dayjs.extend(isYesterday);
  dayjs.extend(isToday);
  dayjs.extend(relativeTime);

  const timeDiffString = dayjs(date).fromNow(true);
  const timeUnit = timeDiffString.split(' ')[1]; // days or month or months
  const timeDiff = +timeDiffString.split(' ')[0];

  if (date === '1970-01-01T00:00:00Z') {
    return <>{t('shared.dateFormat.never')}</>;
  } else if (dayjs(date).isToday()) {
    return <>{t('shared.dateFormat.today')}</>;
  } else if (dayjs(date).isYesterday()) {
    return <>{t('shared.dateFormat.yesterday')}</>;
  } else if (timeUnit === 'days') {
    if (timeDiff < 7) {
      return (
        <TransWrapper i18nKey="shared.dateFormat.daysAgo" count={timeDiff} />
      );
    } else if (timeDiff < 29) {
      const weeks = Math.round(timeDiff / 7);

      return weeks === 1 ? (
        <>{t('shared.dateFormat.weekAgo')}</>
      ) : (
        <TransWrapper i18nKey="shared.dateFormat.weeksAgo" count={weeks} />
      );
    }
  } else if (['months', 'month'].indexOf(timeUnit) >= 0) {
    return timeDiff > 1 ? (
      <TransWrapper i18nKey="shared.dateFormat.monthsAgo" count={timeDiff} />
    ) : (
      <>{t('shared.dateFormat.monthAgo')}</>
    );
  }

  return <>{dayjs(date).format('MMM D, YYYY')}</>;
}
