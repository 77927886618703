import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonDrawer, {
  CommonDrawerInterface,
  DrawerContent,
} from 'components/CommonDrawer';
import { UseReuseInboxResult } from '../../hooks';
import { useTargetSettings } from './hooks';
import InnerTargetSettingsDrawer from './InnerTargetSettingsDrawer';
import isEqual from 'lodash/isEqual';
import CommonWarningMessage from 'components/CommonWarningMessage';

export interface TargetSettingsDrawerProps {
  closeDrawer: () => void;
  drawerIsOpen: boolean;
  fetchingTargets: boolean;
  refreshTargets: UseReuseInboxResult['refreshTargets'];
  targetValues: UseReuseInboxResult['targetValues'];
}

function TargetSettingsDrawer(props: TargetSettingsDrawerProps) {
  const { closeDrawer, drawerIsOpen, fetchingTargets, targetValues } = props;
  const { t } = useTranslation();
  const {
    checkboxesHeight,
    filterByText,
    filteredTargets,
    handleChange,
    refCheckboxes,
    setDrawerFooterRect,
    settingsWereModified,
    submitButtonClickHandler,
    targetsForHarvesting,
  } = useTargetSettings(props);

  return (
    <CommonDrawer
      cancelAndCloseHandler={closeDrawer}
      cancelButtonLabel={t('shared.actions.cancel')}
      drawerIsOpen={drawerIsOpen}
      drawerTitle={t('reuseInbox.titles.targetSettings')}
      submitButtonClickHandler={
        submitButtonClickHandler as CommonDrawerInterface['submitButtonClickHandler']
      }
      submitButtonLabel={t('shared.actions.save')}
      submitDisabled={!settingsWereModified}
      setFooterRect={setDrawerFooterRect}
    >
      {!fetchingTargets &&
        (!targetValues?.length ? (
          <DrawerContent sx={(theme) => ({ marginRight: theme.spacing(3.2) })}>
            <CommonWarningMessage
              text={t('reuseInbox.descriptions.noTargets')}
            />
          </DrawerContent>
        ) : (
          <InnerTargetSettingsDrawer
            checkboxesHeight={checkboxesHeight}
            filterByText={filterByText}
            filteredTargets={filteredTargets}
            handleChange={handleChange}
            refCheckboxes={refCheckboxes}
            targetsForHarvesting={targetsForHarvesting}
          />
        ))}
    </CommonDrawer>
  );
}

export default React.memo(TargetSettingsDrawer, isEqual);
