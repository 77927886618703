import React from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { CommonFilledDenseTextFieldWithInformativeAdornment } from 'components/CommonFilledDenseTextField';

const BiggerCommonFilledDenseTextFieldWithInformativeAdornment = styled(
  CommonFilledDenseTextFieldWithInformativeAdornment
)(({ theme }) => ({
  padding: theme.spacing(1.5, 2, 1.5, 2),
}));

export default function CommonSearchFilterTextField({
  type = 'search',
  ...props
}) {
  const Icon = <SearchIcon />;

  return (
    <BiggerCommonFilledDenseTextFieldWithInformativeAdornment
      adornmentComponent={Icon}
      {...props}
    />
  );
}
