import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import GoalColorSvg from '../GoalColorSvg';
import GoalAccordion from '../GoalAccordion/GoalAccordion';
import { GridRow, StyledAccordionDetails } from '../../styles';
import GuidelineToggleActive from '../GuidelineSection/GuidelineToggleActive';
import GuidelineHelp from '../GuidelineSection/GuidelineHelp';
import ContextualSettings from '../GuidelineSection/ContextualSettings';
import { useGuideline, useTargetGoal } from '../../hooks';
import { useDomainSummary } from '../TerminologyGuideline/hooks';
import { GoalConfig, ReuseGoalProps } from '../../types';
import ReuseSentenceGuideline from './ReuseSentenceGuideline';
import ReuseModernGuideline from './ReuseModernGuideline';
import { getModernReuseGuidelineDomainNames } from '../../helpers';

export const modernGuidelineDefaultValue = '{ "domainNames": [] }';

export default function ReuseGoal(props: ReuseGoalProps) {
  const { t } = useTranslation();
  const { goal, goalConfig, expandedGoals, targetActions, modified } = props;
  const targetGoal = useTargetGoal(props.target, props.goal) as GoalConfig;

  const { domainSummary, guideline, presetsSelectOptions } = useDomainSummary({
    goal,
    goalConfig,
  });

  const [newDomains, setNewDomains] = useState<string[]>(
    getModernReuseGuidelineDomainNames(targetGoal, modernGuidelineDefaultValue)
  );

  if (goalConfig.customPreset?.edited) {
    presetsSelectOptions.push(goalConfig.customPreset);
  }

  const selectedPreset = presetsSelectOptions.find(
    (x) => x.identifier === goalConfig.selectedPresetId
  );

  const guidelineConfig = selectedPreset?.guidelineValues?.find(
    (x) => x.guidelineId === guideline.identifier
  );

  const {
    guidelineIsActive,
    openWarningModal,
    performOpenEditContextsDrawer,
    presetWarningModalShouldAppear,
    setWarningModalOKCallback,
  } = useGuideline({
    goal,
    guideline,
    guidelineConfig,
    targetActions: props.targetActions,
    targetGoal,
    modified,
  });

  const repositoryOptions = useMemo(
    () =>
      goal
        ? goal.goalGroups[0]?.guidelines[0]?.parameter?.enumContent || []
        : [],
    [goal]
  );

  const repositoryValue = targetGoal?.customPreset?.guidelineValues?.length
    ? (targetGoal?.customPreset?.guidelineValues[0]?.parameterValue as string)
    : undefined;

  const summary = useMemo(() => {
    if (props.guidelinesWithWarning[goal.identifier]?.length) {
      return {};
    }

    if (guideline.guidelineType === 'reuse_sentence') {
      return {
        summary:
          repositoryOptions.find((o) => o.internalId === repositoryValue)
            ?.displayName || '',
        translation: t('capture.labels.selectedRepository'),
      };
    }

    if (guideline.guidelineType === 'modern_reuse') {
      return {
        summary: domainSummary,
        translation: t('capture.labels.selectedReuseDomains', {
          count: newDomains.length,
        }),
      };
    }

    return {};
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    goal.identifier,
    guideline.guidelineType,
    props.guidelinesWithWarning,
    repositoryValue,
    t,
    newDomains.length,
  ]);

  const onChangeHandler = targetActions.setGuidelineParameterValue.bind(
    null,
    goal,
    guideline
  );

  return (
    <GoalAccordion
      goalColor={<GoalColorSvg color={goal.color} />}
      expandedGoals={expandedGoals}
      presets={false}
      recommended={goalConfig.recommended}
      goal={goal}
      languageIdShort={props.languageIdShort}
      setPreset={props.targetActions.setPreset}
      toggleRequiredRecommended={() => {
        props.targetActions.setRecommended(goal, !goalConfig.recommended);
      }}
      presetId={goalConfig.selectedPresetId}
      readOnly={props.readOnly}
      goalWithWarning={props.guidelinesWithWarning[goal.identifier]}
      presetsSelectOptions={[]}
      warningTranslationString={
        guideline.guidelineType === 'modern_reuse'
          ? 'capture.tooltips.modernReuseNoRepositoryForGoalWarning'
          : 'capture.tooltips.noRepositoryForGoalWarning'
      }
      parameterSummary={summary}
    >
      <StyledAccordionDetails onClick={(ev) => ev.stopPropagation()}>
        <Grid container direction="column">
          <GridRow
            item
            container
            xs={12}
            justifyContent="space-between"
            alignContent="center"
            flexWrap="nowrap"
          >
            <Grid item>
              <Grid>
                <GuidelineToggleActive
                  guideline={guideline}
                  guidelineIsActive={guidelineIsActive}
                  goal={props.goal}
                  languageIdShort={props.languageIdShort}
                  targetActions={props.targetActions}
                  presetWarningModalShouldAppear={
                    presetWarningModalShouldAppear
                  }
                  openWarningModal={openWarningModal}
                  setWarningModalOKCallback={setWarningModalOKCallback}
                  disabled={true}
                />
                <Grid sx={(theme) => ({ paddingLeft: theme.spacing(7) })} item>
                  {guideline.guidelineType === 'reuse_sentence' && (
                    <ReuseSentenceGuideline
                      onChangeHandler={onChangeHandler}
                      options={repositoryOptions}
                      readOnly={props.readOnly}
                      value={repositoryValue}
                    />
                  )}
                  {guideline.guidelineType === 'modern_reuse' && (
                    <ReuseModernGuideline
                      newDomains={newDomains}
                      onChangeHandler={onChangeHandler}
                      options={repositoryOptions}
                      readOnly={props.readOnly}
                      setNewDomains={setNewDomains}
                      targetGoal={targetGoal}
                      value={repositoryValue}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="guidelineIconsContainer"
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={3}
              container
              sx={(theme) => ({ height: theme.spacing(6.8) })}
            >
              <GuidelineHelp helpUrl={guideline.helpUrl} />
              <ContextualSettings
                contextsExist={!!guidelineConfig?.contexts}
                goal={props.goal}
                guideline={guideline}
                guidelineIsActive={guidelineIsActive}
                guidelineConfig={guidelineConfig}
                openWarningModal={openWarningModal}
                performOpenEditContextsDrawer={performOpenEditContextsDrawer}
                presetWarningModalShouldAppear={presetWarningModalShouldAppear}
                readOnly={props.readOnly}
                setWarningModalOKCallback={setWarningModalOKCallback}
                targetActions={props.targetActions}
              />
            </Grid>
          </GridRow>
        </Grid>
      </StyledAccordionDetails>
    </GoalAccordion>
  );
}
