import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TargetIndexItem } from 'types/types';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  COPY_TARGET_PATH,
  MAX_TARGET_NAME_LENGTH,
} from 'sections/Targets/constants';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import AuthContext from 'contexts/AuthContext';
import { fetchJsonFromAPI, HttpMethod } from 'utils/fetch';
import Grid from '@mui/material/Grid';
import FilledTextField from 'components/FilledTextField';
interface CopyTargetDialogProps {
  target: TargetIndexItem;
  close: () => void;
  targetsNames: string[];
}

export default function CopyTargetDialog({
  target,
  close,
  targetsNames,
}: CopyTargetDialogProps): ReactElement {
  const { t } = useTranslation();
  const nameCopy = t('capture.defaults.copyTargetName', {
    targetName: target?.displayName,
  });

  const defaultName =
    nameCopy.length > MAX_TARGET_NAME_LENGTH ? target.displayName : nameCopy;

  const [displayName, setDisplayName] = useState(defaultName);
  const [nameRequired, setNameRequired] = useState(false);
  const [repeatedName, setRepeatedName] = useState(false);

  useEffect(() => {
    setNameRequired(displayName?.trim().length === 0);
    setRepeatedName(targetsNames?.includes(displayName?.trim()));
  }, [displayName, targetsNames]);

  const [authToken] = useContext(AuthContext);

  const onSubmit = useCallback(
    async (ok: boolean) => {
      if (!ok) {
        close();
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await fetchJsonFromAPI<any, any>(
        authToken,
        `${TARGET_SERVICE_PATH}/${
          COPY_TARGET_PATH.replace(':id', target.uuid) + '=' + displayName
        }`,
        {
          method: HttpMethod.POST,
        }
      );
      close();
    },
    [authToken, displayName, target.uuid, close]
  );

  return (
    <ConfirmationDialog
      title={t('capture.titles.copyTarget')}
      buttonOKLabel={t('capture.actions.copy')}
      buttonCancelLabel={t('shared.actions.cancel')}
      open={!!target}
      onClose={onSubmit}
      isButtonOKDisabled={nameRequired || repeatedName}
      ContentComponent={Grid}
    >
      {(target && targetsNames && (
        <FilledTextField
          data-testid="target-name"
          autoFocus
          label={t('capture.labels.name')}
          fullWidth
          value={displayName}
          error={nameRequired || repeatedName}
          inputProps={{
            maxLength: MAX_TARGET_NAME_LENGTH,
          }}
          helperText={
            nameRequired
              ? t('capture.hints.nameRequired')
              : repeatedName
              ? t('capture.hints.duplicatedName')
              : ' '
          }
          onChange={(ev) => setDisplayName(ev.target.value as string)}
        />
      )) || <></>}
    </ConfirmationDialog>
  );
}
