import { useState, useEffect, createRef } from 'react';
import type { HeadCellType } from '../../UsersTable';

type useCellRefsReturnType = Record<any, React.Ref<any>>;

const useCellRefs = (columns: HeadCellType[]): useCellRefsReturnType => {
  const [refs, setRefs] = useState<useCellRefsReturnType>({});

  useEffect(() => {
    setRefs((prev) => ({
      ...prev,
      ...columns
        .filter(({ key }) => !prev[key])
        .reduce(
          (previousValue, { key }) => ({
            ...previousValue,
            [key]: createRef(),
          }),
          {}
        ),
    }));
  }, [columns]);

  return refs;
};

export default useCellRefs;
