import React, { FunctionComponent, ReactElement, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as CommonErrorImage } from './errorImage.svg';

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ErrorTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: theme.spacing(4),
  lineHeight: theme.typography.pxToRem(32),
}));

const ErrorDescription = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(24),
}));

interface ErrorComponentProps {
  errorTitle: string;
  errorDescription: string;
  ErrorImage?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
}

const CommonErrorComponent = ({
  errorTitle,
  errorDescription,
  ErrorImage = CommonErrorImage,
}: ErrorComponentProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center">
      <ErrorImage aria-hidden={true} />
      <StyledDiv />
      <ErrorTitle variant="h1">{t(errorTitle)}</ErrorTitle>
      <ErrorDescription variant="h2">{t(errorDescription)}</ErrorDescription>
    </Box>
  );
};

export default CommonErrorComponent;
