import React, { ReactElement, useContext } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import isEqual from 'lodash/isEqual';

import UserContext from 'contexts/UserContext';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';
import ConditionalWrapper from 'components/ConditionalWrapper';
import CommonTooltip from 'components/CommonTooltip';
import { MenuMoreIconButton } from '../../styles';
import CustomCheckbox from 'components/CustomCheckbox';
import type { HeadCellType } from '../../UsersTable';
import UsersTableBodyRowCell from '../UsersTableBodyRowCell';
import useCellRefs from '../../hooks/useCellRefs';

import { useTranslation } from 'react-i18next';
import { User } from 'sections/Users/types';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-hover': {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.custom.green50,
  },
}));

const StyledCustomCheckbox = styled(CustomCheckbox)(() => ({
  '& .Mui-disabled': {
    pointerEvents: 'none !important' as 'none',
  },
}));

export interface UserTypeExtended extends User {
  highlighted?: boolean;
}

interface Props extends UserTypeExtended {
  columns: HeadCellType[];
  index: number;
  isSelected: boolean;
  onSelect: (id: string, checked: boolean) => void;
  onMenuMoreClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => void;
  isCheckboxToSelectDisabled?: boolean;
  onAfterChange?: () => void;
}

const UsersTableBodyRow = ({
  columns,
  index,
  isSelected,
  onSelect,
  onMenuMoreClick,
  highlighted = false,
  isCheckboxToSelectDisabled = false,
  ...rowData
}: Props) => {
  const { t } = useTranslation();
  const { id: userId } = useContext(UserContext) || {};
  const userPrivileges = useContext(PrivilegesContext);

  const { id, username, licenseType } = rowData;
  const labelId = `table-checkbox-${index}`;

  const refs = useCellRefs(columns);

  const handleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSelect(id, checked);
  };

  return (
    <StyledTableRow key={id} hover selected={highlighted}>
      {userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && (
        <>
          <TableCell padding="none">
            <MenuMoreIconButton
              aria-label={t('users.a11y.label.moreOptionsMenu.expandUser', {
                username,
              })}
              aria-controls="more-options-menu-item"
              aria-haspopup="true"
              size="small"
              onClick={(event) => onMenuMoreClick(event, id)}
              disableFocusRipple={true}
              disabled={licenseType === 'builtin' || username === userId}
            >
              <MoreVertIcon />
            </MenuMoreIconButton>
          </TableCell>
          <TableCell padding="none">
            <ConditionalWrapper
              condition={licenseType === 'builtin' || username === userId}
              wrapper={(children: ReactElement) => (
                <CommonTooltip
                  type="yellow"
                  title={`${
                    username === userId
                      ? t('users.tooltips.unableDeleteOwnAccount')
                      : t('users.tooltips.unableDeleteBuiltInAccount')
                  }`}
                  placement="bottom-start"
                  enterDelay={0}
                  describeChild
                >
                  {children}
                </CommonTooltip>
              )}
            >
              <Box>
                <StyledCustomCheckbox
                  name="select"
                  color="primary"
                  checked={isSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                    'aria-checked': isSelected,
                  }}
                  onChange={handleChange}
                  disabled={
                    isCheckboxToSelectDisabled ||
                    licenseType === 'builtin' ||
                    username === userId
                  }
                />
              </Box>
            </ConditionalWrapper>
          </TableCell>
        </>
      )}
      <TransitionGroup component={null}>
        {columns.map(({ key: columnId }) => {
          if (!refs[columnId]) {
            return null;
          }

          return (
            <CSSTransition
              key={`${id}-${columnId}`}
              nodeRef={refs[columnId]}
              timeout={400}
              classNames="table-cell"
            >
              <UsersTableBodyRowCell
                ref={refs[columnId]}
                cellType={columnId}
                labelId={labelId}
                {...rowData}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </StyledTableRow>
  );
};

export default React.memo(UsersTableBodyRow, isEqual);
