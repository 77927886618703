import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import CommonFilledDenseTextField, {
  CommonFilledDenseTextFieldProps,
} from './CommonFilledDenseTextField';

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& svg': {
    height: '19px',
    width: '19px',
    color: theme.palette.grey[600],
  },
}));

interface CommonFilledDenseTextFieldWithInformativeAdornmentProps
  extends CommonFilledDenseTextFieldProps {
  adornmentComponent: ReactElement<typeof SvgIcon>;
}

export default function CommonFilledDenseTextFieldWithInformativeAdornment({
  adornmentComponent,
  ...props
}: CommonFilledDenseTextFieldWithInformativeAdornmentProps) {
  return (
    <CommonFilledDenseTextField
      {...props}
      endAdornment={
        <StyledInputAdornment position="end">
          {adornmentComponent}
        </StyledInputAdornment>
      }
    />
  );
}
