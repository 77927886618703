import React, { ReactElement, ReactNode } from 'react';

import ButtonPrimary from 'components/ButtonPrimary';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogTitle,
} from './components';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  onClose: (ok: boolean) => void;
  children: ReactNode;
  buttonOKLabel: string;
  buttonCancelLabel?: string;
  ContentComponent?: React.ElementType;
  isButtonOKDisabled?: boolean;
}

export default function ConfirmationDialog({
  open,
  title,
  onClose,
  children,
  buttonOKLabel,
  buttonCancelLabel,
  ContentComponent = StyledDialogContentText,
  isButtonOKDisabled,
  ...props
}: ConfirmationDialogProps): ReactElement {
  return (
    <StyledDialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      {...props}
    >
      <StyledDialogTitle id="confirmation-dialog-title">
        {title}
      </StyledDialogTitle>
      <StyledDialogContent>
        <ContentComponent id="confirmation-dialog-description">
          {children}
        </ContentComponent>
      </StyledDialogContent>
      <StyledDialogActions>
        {buttonCancelLabel && (
          <ButtonPrimary
            variant="outlined"
            onClick={() => onClose(false)}
            autoFocus
          >
            {buttonCancelLabel}
          </ButtonPrimary>
        )}
        {buttonOKLabel && (
          <ButtonPrimary
            variant="contained"
            onClick={() => onClose(true)}
            disabled={isButtonOKDisabled}
          >
            {buttonOKLabel}
          </ButtonPrimary>
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
}
