import { useContext } from 'react';
import defaultPagesDefinition from './pages.json';

import FeaturesContext from 'contexts/FeaturesContext';
import { PageDefinitionProps } from 'components/NavigationMenu';

export default function usePages(): ReadonlyArray<PageDefinitionProps> {
  const {
    enableNewUserManagement,
    enableNewReuse,
    oldReuse,
    enableNewReuseHarvesting,
    enableContentCube,
  } = useContext(FeaturesContext);

  return (defaultPagesDefinition as ReadonlyArray<PageDefinitionProps>).map(
    (page) => ({
      ...page,
      ...(!page.type && {
        ...(page.routePath === '/users' &&
          !enableNewUserManagement && {
            mainMenu: {
              ...page.mainMenu,
              display: true,
            },
          }),
        ...(page.routePath === '/user-management' &&
          enableNewUserManagement && {
            mainMenu: {
              ...page.mainMenu,
              linkToClassicView: undefined,
              display: true,
            },
          }),
        ...(page.routePath === '/user-management/users' &&
          enableNewUserManagement && {
            mainMenu: {
              ...page.mainMenu,
              linkToClassicView: undefined,
              display: true,
            },
          }),
        // old reuse
        ...(page.routePath === '/reuse' &&
          page.mainMenu.linkToClassicView &&
          oldReuse &&
          !enableNewReuse && {
            mainMenu: {
              ...page.mainMenu,
              display: oldReuse,
            },
          }),
        // new reuse
        ...(page.routePath === '/reuse' &&
          !page.mainMenu.linkToClassicView &&
          enableNewReuse && {
            mainMenu: {
              ...page.mainMenu,
              linkToClassicView: undefined,
              display: enableNewReuse,
            },
          }),
        // new reuse
        ...(page.routePath === '/reuse/assistant' &&
          enableNewReuseHarvesting && {
            mainMenu: {
              ...page.mainMenu,
              display: enableNewReuseHarvesting,
            },
          }),
        ...(page.routePath === '/content-cube' &&
          !enableContentCube && {
            mainMenu: {
              ...page.mainMenu,
              display: false,
            },
          }),
      }),
      ...(page.type === 'divider' &&
        page.privileges?.includes('ContentCube.useContentCube') &&
        !enableContentCube && {
          mainMenu: {
            ...page.mainMenu,
            display: false,
          },
        }),
    })
  );
}
