import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface CommonStartPageNoDataProps {
  title: string;
  description: string;
}

export default function CommonStartPageNoData({
  title,
  description,
}: CommonStartPageNoDataProps) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(22),
      })}
    >
      <Box
        component="img"
        sx={(theme) => ({
          width: theme.spacing(40),
          height: theme.spacing(40),
          marginBottom: theme.spacing(6),
        })}
        src={process.env.PUBLIC_URL + '/assets/images/no-data-found.svg'}
        alt=""
      />
      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(24),
          lineHeight: theme.typography.pxToRem(30),
          letterSpacing: theme.typography.pxToRem(0.48),
          textAlign: 'center',
          marginBottom: theme.spacing(2),
        })}
      >
        {title}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(18),
          lineHeight: theme.typography.pxToRem(24),
          letterSpacing: theme.typography.pxToRem(0.36),
          textAlign: 'center',
          maxWidth: theme.spacing(100),
        })}
      >
        {description}
      </Typography>
    </Box>
  );
}
