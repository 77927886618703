import React from 'react';
import { StyledIconButton } from './styles';
import { useTranslation } from 'react-i18next';
import CommonTooltip from 'components/CommonTooltip';
import CreateIcon from '@mui/icons-material/Create';

interface TargetAssignmentsEditButtonProps {
  onClick: () => void;
}

export function TargetAssignmentsEditButton({
  onClick,
}: TargetAssignmentsEditButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <CommonTooltip placement="bottom" title={<>{t('shared.actions.edit')}</>}>
      <StyledIconButton
        color="primary"
        size="small"
        aria-label={t('capture.a11y.editAssignment')}
        onClick={() => {
          onClick();
        }}
      >
        <CreateIcon />
      </StyledIconButton>
    </CommonTooltip>
  );
}

export default TargetAssignmentsEditButton;
