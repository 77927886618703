import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import ButtonPrimary from 'components/ButtonPrimary/ButtonPrimary';
import ConditionalWrapper from 'components/ConditionalWrapper';
import CommonTooltip from 'components/CommonTooltip';
import BusyBackdrop from 'components/BusyBackdrop';
import ButtonAddIcon from 'icons/ButtonAddIcon';

import AddReplacementButton from 'sections/Reuse/components/AddReplacementButton/AddReplacementButton';
import CreateOrEditDomainDrawer from 'sections/Reuse/components/CreateOrEditDomainDrawer';
import useCreateEditReplacement from 'sections/Reuse/hooks/useCreateEditReplacement';
import CreateOrEditReplacementDrawer from 'sections/Reuse/components/CreateOrEditReplacementDrawer';
import { NewReuseIndexDomain, ReuseIndexDomain } from 'sections/Reuse/types';

import {
  StyledButtonTab,
  StyledDownloadIcon,
  StyledIconButton,
  StyledUploadIcon,
} from './components';
import DeleteDomainDialog from './components/DeleteDomainDialog';
import UploadReplacementDialog from './components/UploadReplacementDialog';
import UploadReplacementResultDialog from './components/UploadReplacementResultDialog';
import ExportReplacementsDialog from './components/ExportReplacementsDialog';
import {
  useCreateDomain,
  UseDeleteDomainResult,
  useExportReplacements,
  useUploadReplacements,
} from './hooks';
import CommonLockIconButton from 'components/CommonLockIconButton';
import { useFetchLanguages } from 'hooks/useFetchLanguages';
import { LanguageFull } from 'types/types';
import { UseReuseDomainsResult } from '../../hooks/useReuseDomains';

export interface ButtonTabProps {
  deleteDomainDialogState: UseDeleteDomainResult;
  editRights: boolean;
  readRights: boolean;
  refreshIndexDomains: UseReuseDomainsResult['refreshIndexDomains'];
  reuseData: ReuseIndexDomain[];
  sortedDomains: ReuseIndexDomain[];
}

export default function ButtonPanel({
  deleteDomainDialogState,
  editRights,
  refreshIndexDomains,
  reuseData,
  sortedDomains,
}: ButtonTabProps) {
  const { t } = useTranslation();
  const { data: languages } = useFetchLanguages<LanguageFull>('full');

  const { closeDeleteConfirmationDialog, domainForDeleteConfirmation } =
    deleteDomainDialogState;

  const {
    handleChange,
    selectedFileName,
    openUploadDialog,
    closeUploadReplacementsDialog,
    uploadDialogIsOpen,
    importState,
    resetImportState,
  } = useUploadReplacements(refreshIndexDomains);

  const { actionHandler, exportDialogIsOpen, openExportDialog } =
    useExportReplacements();

  const { closePhraseDrawer, openPhraseDrawer, phraseDrawerIsOpen } =
    useCreateEditReplacement();

  const {
    closeDrawerCreateDomain,
    drawerCreateDomainIsOpen,
    setDrawerCreateDomainIsOpen,
  } = useCreateDomain(refreshIndexDomains);

  return editRights ? (
    <StyledButtonTab>
      <ButtonPrimary
        variant="contained"
        onClick={() => setDrawerCreateDomainIsOpen(true)}
        aria-label={t('reuse.actions.addDomain')}
      >
        <ButtonAddIcon />
        {t('reuse.actions.addDomain')}
      </ButtonPrimary>
      <Box
        key="space-1"
        sx={(theme) => ({
          display: 'inline-block',
          padding: theme.spacing(2),
        })}
      />
      <ConditionalWrapper
        condition={!sortedDomains.length}
        wrapper={(children: ReactElement) => (
          <CommonTooltip title={t('reuse.tooltips.addReplacementDisabled')}>
            {children}
          </CommonTooltip>
        )}
      >
        <AddReplacementButton
          onClickHandler={() => {
            openPhraseDrawer();
          }}
          aria-disabled={!sortedDomains.length}
        />
      </ConditionalWrapper>
      <CommonTooltip title={t('reuse.actions.uploadReplacement')}>
        <StyledIconButton
          aria-label={t('reuse.actions.uploadReplacement')}
          onClick={() => openUploadDialog()}
        >
          <StyledUploadIcon />
        </StyledIconButton>
      </CommonTooltip>
      <CommonTooltip title={t('reuse.actions.exportReplacements')}>
        <StyledIconButton
          sx={(theme) => ({
            marginLeft: theme.spacing(1),
          })}
          aria-label={t('reuse.actions.exportReplacements')}
          onClick={() => openExportDialog()}
        >
          <StyledDownloadIcon />
        </StyledIconButton>
      </CommonTooltip>
      <CreateOrEditDomainDrawer
        refresh={refreshIndexDomains}
        drawerIsOpen={drawerCreateDomainIsOpen}
        close={closeDrawerCreateDomain}
        domainsNames={reuseData.map((x: NewReuseIndexDomain) => x.displayName)}
      />
      <CreateOrEditReplacementDrawer
        cluster={null}
        drawerIsOpen={phraseDrawerIsOpen}
        closeDrawer={closePhraseDrawer}
        domainList={reuseData}
        editRights={editRights}
        languages={languages}
      />
      {domainForDeleteConfirmation && (
        <DeleteDomainDialog
          key="domain-deletion-confirmation"
          domain={domainForDeleteConfirmation}
          onClose={closeDeleteConfirmationDialog}
        />
      )}
      {uploadDialogIsOpen && (
        <UploadReplacementDialog
          key="upload-replacements-dialog"
          onClose={closeUploadReplacementsDialog}
          handleChange={handleChange}
          selectedFileName={selectedFileName}
          open={uploadDialogIsOpen}
        />
      )}
      <BusyBackdrop
        key="replacements-import-spinner"
        open={importState.inProgress}
        title={t('reuse.titles.replacementsImportInProgress')}
      />
      {!importState.inProgress && (importState.data || importState.error) && (
        <UploadReplacementResultDialog
          key="upload-replacements-confirmation"
          onClose={resetImportState}
          importState={importState}
        />
      )}
      {exportDialogIsOpen && (
        <ExportReplacementsDialog
          key="download-replacements-dialog"
          onClose={actionHandler}
          open={exportDialogIsOpen}
        />
      )}
    </StyledButtonTab>
  ) : (
    <StyledButtonTab container justifyContent="flex-end">
      <CommonLockIconButton tooltipText={t('reuse.tooltips.readonly')} />
    </StyledButtonTab>
  );
}
