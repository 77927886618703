import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as IconWarning } from 'icons/IconWarning.svg';
import Box from '@mui/material/Box';

const StyledIconWarning = styled(IconWarning)<SvgIconProps>(({ theme }) => ({
  verticalAlign: 'middle',
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

export const ExternalAuthWarning = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="flex-start" mb={4}>
      <StyledIconWarning />
      <Typography
        sx={{
          flex: 1,
          fontSize: (theme) => theme.typography.pxToRem(12),
          fontWeight: 'bold',
        }}
      >
        {t('users.descriptions.addNewUserExternalAuthWarning')}
      </Typography>
    </Box>
  );
};
