import { FALLBACK_LANGUAGE, LANGUAGE_COOKIE_NAME } from '../constants';

export default function getLanguageFromCookie(): string {
  return document.cookie.split(';').reduce((lng, cookie) => {
    if (cookie.includes(LANGUAGE_COOKIE_NAME)) {
      const cookieArray = cookie.trim().split('=');
      return cookieArray[1]?.trim() || lng;
    }

    return lng;
  }, FALLBACK_LANGUAGE);
}
