import React from 'react';

export default function useMenuState(
  initialAnchor: HTMLElement | null = null
): {
  open: boolean;
  handleClick: (event: React.MouseEvent) => void;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
} {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(
    initialAnchor
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    open,
    handleClick,
    handleClose,
    anchorEl,
  };
}
