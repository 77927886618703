import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

import AuthContext from 'contexts/AuthContext';
import FeaturesContext from 'contexts/FeaturesContext';
import { fetchWrapperWithToken } from 'utils/fetch';

import SessionTimeoutDialog from './components/SessionTimeoutDialog';

const DEFAULT_TIMEOUT = 30 * 60 * 1000; // => 30 minutes
const DEFAULT_COUNTDOWN_LENGTH = 60 * 1000; // => 60 seconds

interface SessionTimeoutProps {
  countdownLength?: number;
}

export default function SessionTimeout({
  countdownLength = DEFAULT_COUNTDOWN_LENGTH,
}: SessionTimeoutProps): ReactElement | null {
  const [authToken] = useContext(AuthContext);
  const history = useHistory();
  const [deadlineTS, setDeadlineTS] = useState(0);
  const [timeoutValue, setTimeoutValue] = useState(DEFAULT_TIMEOUT);
  const [closeButtonClicked, setCloseButtonClicked] = useState(false);
  const features = useContext(FeaturesContext);

  const KEY_TIMEOUT_DEADLINE_TS = 'timeout-deadlineTS';

  const handleOnIdle = useCallback(() => {
    if (!deadlineTS && authToken) {
      const sharedDeadline = window.localStorage.getItem(
        KEY_TIMEOUT_DEADLINE_TS
      );

      const calculateDeadline =
        (sharedDeadline && parseInt(sharedDeadline, 10)) ||
        +new Date() + countdownLength;

      if (!sharedDeadline) {
        window.localStorage.setItem(
          KEY_TIMEOUT_DEADLINE_TS,
          `${calculateDeadline}`
        );
      }

      setDeadlineTS(calculateDeadline);
    }
  }, [authToken, deadlineTS, countdownLength]);

  const handleOnAction = useCallback(async () => {
    if (!deadlineTS) {
      await fetchWrapperWithToken(authToken)(
        '/dashboard/api/sessions/refresh',
        {
          method: 'PUT',
        }
      );
    }
  }, [authToken, deadlineTS]);

  // https://www.npmjs.com/package/react-idle-timer
  const { start, reset, pause } = useIdleTimer({
    timeout: timeoutValue - countdownLength,
    onIdle: handleOnIdle,
    stopOnIdle: true,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
      type: 'localStorage',
    },
  });

  useEffect(() => {
    // This is here to make sure this will not be performed when component is unmounted
    // Inspired by https://stackoverflow.com/a/60907638
    let isMounted = true;

    // Get session timeout
    async function getSessionsTimeout() {
      try {
        const response = await fetchWrapperWithToken(authToken)(
          '/dashboard/api/sessions/length'
        );

        if (isMounted) {
          setTimeoutValue(
            response.ok ? +(await response.text()) : DEFAULT_TIMEOUT
          );
        }
      } catch (error) {}
    }
    getSessionsTimeout();

    return () => {
      isMounted = false;
    };
  }, [authToken]);

  useEffect(() => {
    /**
     * NOTE:
     * If user just opens the page in tab and will not interact with it, the
     * dialog with countdown will be displayed at the time user first interacts
     * with UI. This is behavior is not wanted due to fact the tab can be opened
     * for hours and the logout countdown will never be initiated. So the
     * workaround in following line is needed. At the moment of this comment,
     * is unknown whether this is a bug in used library or it is a wanted
     * behavior.
     */
    start();
  }, [start]);

  const handleStorageEvent = useCallback(
    async (event: StorageEvent): Promise<void> => {
      if (event.storageArea !== window.localStorage) {
        return;
      }

      if (event.key === KEY_TIMEOUT_DEADLINE_TS) {
        const { newValue } = event;

        if (newValue === null) {
          setDeadlineTS(0);
        } else if (newValue) {
          const newDeadlineTS = parseInt(newValue, 10);

          setDeadlineTS(newDeadlineTS);

          if (newDeadlineTS === 0) {
            window.localStorage.removeItem(KEY_TIMEOUT_DEADLINE_TS);
          }

          setCloseButtonClicked(true);
        }
      }
    },
    []
  );

  useEffect(() => {
    window.localStorage.removeItem(KEY_TIMEOUT_DEADLINE_TS);

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [handleStorageEvent]);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      try {
        // Check if token still exists
        const response = await fetch('/dashboard/api/signin/authenticate', {
          method: 'POST',
        });

        if (!response.ok) {
          throw Error(response.statusText);
        }
      } catch (error) {
        const parsedUrl = new URL(window.location.href);
        history.push(
          `/${features.enableKeycloak ? 'sign-in' : 'logout'}?continue=${
            parsedUrl.pathname
          }`
        );
      }
    };

    if (closeButtonClicked) {
      if (deadlineTS === 0) {
        checkIsLoggedIn();
      } else {
        reset();
      }
    }
  }, [authToken, closeButtonClicked, deadlineTS, history, reset, features]);

  useEffect(() => {
    // Remove this every time this element is called
    window.localStorage.removeItem('logout-reason');
  }, []);

  const handleCloseDialog = useCallback(() => {
    pause();
    setDeadlineTS(0);
    window.localStorage.setItem(KEY_TIMEOUT_DEADLINE_TS, '0');
    setCloseButtonClicked(true);
  }, [pause]);

  if (deadlineTS) {
    return (
      <SessionTimeoutDialog
        deadlineTimestamp={deadlineTS}
        onClose={handleCloseDialog}
        onLogout={handleCloseDialog}
      />
    );
  }

  return null;
}
