import { UseTextResult } from 'hooks/useText';
import { UpdateInformation } from 'types/types';
import { UseReuseDomainsResult } from './hooks/useReuseDomains';
import { UseFetchDomainResult } from './pages/DomainDetailPage/hooks';

export interface NewReuseIndexDomain {
  displayName: string;
  description: string;
}

export interface ReuseIndexDomain extends NewReuseIndexDomain {
  uuid: string;
  created: UpdateInformation;
  modified: UpdateInformation;
}

export interface NewPhrase {
  phrase: string;
}

export interface CompletePhrase extends NewPhrase {
  uuid: string;
  language: string;
  type: string;
  created: string;
}

export type ClusterPhrase = NewPhrase | CompletePhrase;

export interface ReuseDomainCluster {
  uuid?: string;
  preferredPhrase: ClusterPhrase;
  deprecatedPhrases: ClusterPhrase[];
  active: boolean;
  reuseDomains: ReuseIndexDomain['uuid'][];
  description: string;
  language: string;
  created?: UpdateInformation;
  modified?: UpdateInformation;
}

export interface ReuseDomain extends ReuseIndexDomain {
  clusters: ReuseDomainCluster[];
}

interface InnerCreateOrEditDomainDrawerCommon {
  created?: UpdateInformation;
  domainDescriptionErrorMessage: string;
  domainDescriptionState: UseTextResult;
  domainExists: boolean;

  domainNameErrorMessage: string;
  domainNameState: UseTextResult;
  modified?: UpdateInformation;
  nameRequired: boolean;
}

export interface InnerCreateOrEditDomainDrawerProps
  extends InnerCreateOrEditDomainDrawerCommon {
  domainsNames: string[] | undefined;
  footerRect: DOMRect | null;
}

export interface CreateOrEditDomainDrawerProps
  extends CreateOrEditDomainDrawerHooksProps {
  drawerIsOpen: boolean;
}

export interface CreateOrEditDomainDrawerHooksProps {
  close: () => void;
  domain?: ReuseDomain;
  domainsNames: string[] | undefined;
  refresh:
    | UseReuseDomainsResult['refreshIndexDomains']
    | UseFetchDomainResult['refetch'];
  drawerIsOpen?: boolean;
}

export interface CreateOrEditDomainDrawerHooksReturn
  extends InnerCreateOrEditDomainDrawerCommon {
  onSubmit: () => Promise<void>;
  domainIsValid: boolean;
  domainWasModified: boolean;
}

export interface ReplacementsImportFailedReplacement {
  id: string;
  lines: string[];
}

export enum ReplacementsImportFailedStatus {
  Success = 'success',
  Failure = 'failure',
}

export interface ReplacementsImportResult {
  failed: ReplacementsImportFailedReplacement[];
  fileName: string;
  replacements: number;
  status: ReplacementsImportFailedStatus;
}

export type PhraseEqualityCheckDomain = Omit<ReuseIndexDomain, 'description'>;

export interface PhraseEqualityCheckReplacement {
  uuid: string;
  reuseDomains: PhraseEqualityCheckDomain[];
}

export enum PhraseEqualityCheckResults {
  preferredMatch = 'clustersWithPreferredMatch',
  deprecatedMatch = 'clustersWithDeprecatedMatch',
}

export interface PhraseEqualityCheckResponse {
  [PhraseEqualityCheckResults.preferredMatch]: PhraseEqualityCheckReplacement[];
  [PhraseEqualityCheckResults.deprecatedMatch]: PhraseEqualityCheckReplacement[];
}

export interface PhraseEqualityCheckRequest {
  phrase: string;
  language: string;
}

export interface PhraseSimilarityCheckRequest {
  phrase1: string;
  phrase2: string;
  language: string;
}

export interface PhraseSimilarityCheckResponse {
  similar: boolean;
}

export enum SuggestionDataType {
  AMEND = 'AMEND',
  NEW = 'NEW',
}

export enum SuggestionStatusType {
  DISMISSED = 'DISMISSED',
  PENDING = 'PENDING',
}

export interface SuggestionData {
  deprecatedPhrases: string[];
  harvestDate: string;
  language: string;
  targetDisplayNames: string[];
  type: SuggestionDataType;
  status: SuggestionStatusType;
}

export type ReuseNewSuggestion = SuggestionData & {
  preferredPhrase: string;
};

export interface ReuseSuggestion extends SuggestionData {
  cluster: ReuseDomainCluster;
  uuid: string;
}

export type OriginalReuseInboxSuggestions =
  | ReuseSuggestion[]
  | ReuseNewSuggestion[];

export interface ReuseSuggestionStatus {
  suggestionUuid: string;
  suggestionStatus: string;
}
export interface ReuseTargetSettings {
  uuid: string;
  allowHarvesting: boolean;
}

export interface SuggestionActionButtonProps {
  disabled?: boolean;
  dismissed?: boolean;
  handleOnClick: () => void;
  tooltip?: string;
}
