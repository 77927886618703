import { TextField, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  height: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& fieldset': {
    borderColor: blueGrey[100],
    borderWidth: theme.spacing(0.2),
  },
  '& input': {
    padding: theme.spacing(1, 2, 1, 2),
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.28),
    lineHeight: theme.typography.pxToRem(21),
  },
  [`&:hover fieldset.${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.primary.main,
    borderWidth: theme.spacing(0.2),
  },
  [`& .${outlinedInputClasses.root}`]: {
    [`&.Mui-focused fieldset.${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: theme.spacing(0.2),
    },
  },
}));
