import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { StatusText } from './components';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export default function SuccessIconAndText() {
  const { t } = useTranslation();

  return (
    <Grid flexWrap="nowrap" alignItems="center" container>
      <CheckIcon
        viewBox="0 0 24 24"
        sx={(theme) => ({
          stroke: theme.palette.success.main,
          strokeWidth: '1.7px',
        })}
        color="success"
      />
      <StatusText>{t('reuse.titles.replacementsImportSuccess')}</StatusText>
    </Grid>
  );
}
