import React from 'react';
import { styled, Theme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link, LinkProps } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';

import PageContentWrapper from '../../components/PageContentWrapper';

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.48),
  lineHeight: theme.typography.pxToRem(36),
  marginBottom: theme.spacing(4),
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(30),
  marginBottom: theme.spacing(6),
}));

export const StyledContent = styled(List)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(30),
  '& li:before': {
    content: '"·"',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.typography.pxToRem(30),
    paddingRight: theme.spacing(1),
  },
}));

export const StyledMainBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  minHeight: 'fit-content',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  padding: theme.spacing(6),
}));

export const SplashPageContentWrapper = styled(PageContentWrapper)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    backgroundImage:
      'radial-gradient(91.43% 100% at 52.44% 0%, rgba(255, 255, 255, 0.5) 51.5%, rgba(200, 255, 225, 0.5) 80.58%, rgba(161, 244, 255, 0.5) 100%)',
    height: `calc(100vh - ${theme.spacing(14)})`,
    paddingTop: theme.spacing(6),
  })
);

export const SplashTileContainer = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(0),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  // gap has padding/margin syntax
  gap: theme.spacing(8, 10),
  paddingTop: theme.spacing(3),
}));

export const SplashTile = styled((props: GridProps) => (
  <Grid
    container
    direction="row"
    alignContent="space-between"
    component="li"
    {...props}
  />
))(({ theme }) => ({
  boxShadow: '0px 2px 4px 0px #0000001A',
  border: `1px solid ${blueGrey[100]}`,
  padding: theme.spacing(6, 4, 4, 6),
  borderRadius: '8px 0px 0px 0px',
  cursor: 'unset',
  backgroundColor: theme.palette.background.paper,
  ':hover': {
    border: `1px solid ${theme.palette.custom.midDarkGrey}`,
    boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
  },
}));

function SplashLinkStyleStyling({ theme }: { theme: Theme }) {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
    border: `1px solid ${blueGrey[100]}`,
    padding: theme.spacing(6, 4, 4, 6),
    borderRadius: '8px',
    cursor: 'unset',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    ':hover': {
      border: `1px solid ${theme.palette.custom.midDarkGrey}`,
      boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.15)',
      cursor: 'pointer',
    },
    '&:link, &:visited, &:focus, &:hover, &:active': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  };
}

// @ts-ignore
export const SplashLinkTile = styled('a')(SplashLinkStyleStyling);
export const SplashRoutingTile = styled(
  (props: Omit<LinkProps, 'to'> & { href: string }) => (
    <Link {...props} to={props.href} />
  )
  // @ts-ignore
)(SplashLinkStyleStyling);

export const SplashTileIconContainer = styled((props: GridProps) => (
  <Grid item {...props} />
))(({ theme }) => ({
  marginRight: theme.spacing(6),
}));

export const SplashTileTextContainer = styled((props: GridProps) => (
  <Grid item {...props} />
))(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  flex: `1 0 calc(100% - ${theme.spacing(18)})`,
}));

export const SplashTileTextHeader = styled((props: TypographyProps) => (
  <Typography variant="body1" component="h2" {...props} />
))(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.2),
  textAlign: 'left',
}));

export const SplashTileTextDescription = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(24),
  marginBottom: theme.spacing(2),
}));

export const SplashTileGoToText = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(24),
  marginRight: theme.spacing(1),
  letterSpacing: theme.typography.pxToRem(0.3),
  fontWeight: theme.typography.fontWeightMedium,
}));
