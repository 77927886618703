import React, { forwardRef } from 'react';
import Grid from '@mui/material/Grid';

import GoalRecommendedIcon from 'sections/Targets/pages/TargetPage/components/GoalAccordion/GoalRecommendedIcon';
import GoalRequiredIcon from 'sections/Targets/pages/TargetPage/components/GoalAccordion/GoalRequiredIcon';

export interface RecommendedRequiredIconProps {
  isRecommended: boolean;
}

export default forwardRef(function RecommendedRequiredIcon(
  { isRecommended, ...props }: RecommendedRequiredIconProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Grid
      {...props}
      ref={ref}
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        display: 'inline-flex',
        marginTop: theme.spacing(2 / 5),
      })}
    >
      {isRecommended ? <GoalRecommendedIcon /> : <GoalRequiredIcon />}
    </Grid>
  );
});
