import React from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { InputAdornment, StandardTextFieldProps } from '@mui/material';
import {
  StyledFormControl,
  StyledHelperTextContainer,
  StyledTextField,
} from '../styles';

export interface CommonOutlinedMultilineTextFieldProps
  extends Omit<StandardTextFieldProps, 'id'> {
  charCount?: number;
  fullWidth?: boolean;
  id: string;
  maxCharCount?: number;
  text?: string;
}

export default function CommonOutlinedMultilineTextField({
  charCount = 0,
  fullWidth = false,
  maxCharCount,
  text = '',
  ...props
}: Readonly<CommonOutlinedMultilineTextFieldProps>) {
  const helperText = maxCharCount ? '' : props.helperText;
  const ariaId = `field-description-${props.id}`;

  return (
    <StyledFormControl {...(fullWidth && { fullWidth })}>
      {/* @ts-ignore */}
      <StyledTextField
        {...props}
        multiline
        helperText={helperText}
        InputProps={{
          'aria-describedby': ariaId,
          ...(props.error && {
            endAdornment: (
              <InputAdornment
                position="end"
                sx={(theme) => ({
                  alignSelf: 'flex-start',
                  paddingTop: theme.spacing(3.2),
                  marginRight: theme.spacing(-1.6),
                  marginTop: theme.spacing(2),
                })}
              >
                <ErrorOutlineRoundedIcon color="error" />
              </InputAdornment>
            ),
          }),
        }}
      />
      {maxCharCount && (
        <StyledHelperTextContainer
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="flex-start"
          flexWrap="nowrap"
          error={!!props.error}
        >
          {!props.error && <div>{props.helperText}</div>}
          {props.error && (
            <div role="alert" id={ariaId} aria-live="polite">
              {props.error && props.helperText}
            </div>
          )}
          <div className="charCount">
            {charCount} / {maxCharCount}
          </div>
        </StyledHelperTextContainer>
      )}
    </StyledFormControl>
  );
}
