import { styled } from '@mui/material/styles';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/system/Box';
import Popover from '@mui/material/Popover';

export const StyledLongTextOptionTooltip = styled(CommonTooltip)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      marginTop: theme.spacing(1),
    },
  })
);

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.custom.oysterBay,
  color: theme.palette.custom.cyprus,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.custom.morningGlory,
  },
  '&:focus': {
    backgroundColor: theme.palette.custom.oysterBay,
  },
  maxWidth: '240px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
  color: theme.palette.custom.cyprus,
}));

export const ListPanel = styled(Box)(() => ({
  overflowY: 'scroll',
}));

export const StyledPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    width: 300,
  },
}));
