import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import {
  ContextConfig,
  GuidelineContext,
} from 'sections/Targets/pages/TargetPage/types';
import { FILTER_TYPES } from './constants';
import CheckboxList from 'components/CheckboxList';
import CommonRadioGroup from 'components/CommonRadioGroup';
import {
  Divider,
  RadioGroupContainer,
  StyledSearchTextFieldWrapper,
  TextBlock,
} from './styles';
import {
  DrawerContent,
  ScrollableDrawerContent,
} from 'components/CommonDrawer';
import CommonSearchFilterTextField from 'components/CommonSearchFilterTextField';
import { CheckboxValue } from 'types/types';

interface InnerEditContextsDrawerContentProps {
  contextConfig: ContextConfig;
  contextConfigIsModified: boolean;
  contextList: GuidelineContext[];
  disabled: boolean;
  footerRect: DOMRect | null;
  handleContextsChange: (contexts: Set<CheckboxValue>) => void;
  handleFilterChange: (evt: ChangeEvent<{ value?: unknown }>) => void;
  guidelineDisplayName: string;
}

export default function InnerEditContextsDrawerContent({
  contextConfig,
  contextConfigIsModified,
  contextList,
  disabled,
  footerRect,
  handleContextsChange,
  handleFilterChange,
  guidelineDisplayName,
}: InnerEditContextsDrawerContentProps): JSX.Element {
  const { t } = useTranslation();
  const [contextFilteredList, setContextFilteredList] = useState([
    ...contextList,
  ]);
  const [checkboxesHeight, setCheckboxesHeight] = useState<number>(0);
  const refCheckboxes = useRef<HTMLFieldSetElement | null>(null);

  useEffect(() => {
    if (refCheckboxes.current && footerRect) {
      setCheckboxesHeight(
        footerRect?.top - refCheckboxes.current?.getBoundingClientRect()?.top
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [footerRect, refCheckboxes.current]);

  useEffect(() => {
    if (contextList.length) {
      setContextFilteredList(contextList);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [contextList]);

  const data = FILTER_TYPES.map((filterValue, index) => ({
    label: t(`capture.labels.${filterValue.toLowerCase()}`),
    value: filterValue,
    key: index.toString(),
    disabled,
  }));

  const filteredValues = useMemo(
    () =>
      contextFilteredList.map((ctx) => ({
        id: ctx,
        label: ctx,
        checked: contextConfig.contexts.includes(ctx),
        disabled,
      })),
    [contextFilteredList, contextConfig.contexts, disabled]
  );
  const values = useMemo(
    () =>
      contextList.map((ctx) => ({
        id: ctx,
        label: ctx,
        checked: contextConfig.contexts.includes(ctx),
        disabled,
      })),
    [contextList, contextConfig.contexts, disabled]
  );

  return (
    <>
      <DrawerContent>
        <TextBlock>
          <Typography
            sx={(theme) => ({ letterSpacing: theme.typography.pxToRem(0.32) })}
          >
            <strong>{t('capture.descriptions.guideline')}</strong>
            {guidelineDisplayName}
          </Typography>
        </TextBlock>
        <RadioGroupContainer>
          <CommonRadioGroup
            data={data}
            defaultValue={contextConfig.contextsFilterType}
            changeHandler={handleFilterChange}
          />
        </RadioGroupContainer>
        <StyledSearchTextFieldWrapper>
          <CommonSearchFilterTextField
            fullWidth
            placeholder={t('shared.actions.search')}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              setContextFilteredList(
                contextList.filter((contextId) =>
                  contextId
                    .toLowerCase()
                    .includes(evt.target.value.toLowerCase())
                )
              );
            }}
          />
        </StyledSearchTextFieldWrapper>
      </DrawerContent>
      <Divider />
      <ScrollableDrawerContent style={{ height: `${checkboxesHeight - 5}px` }}>
        <CheckboxList
          allCheckboxLabel={t('capture.labels.contexts')}
          ref={refCheckboxes}
          filteredValues={filteredValues}
          handleValueChange={handleContextsChange}
          values={values}
          valuesWereModified={contextConfigIsModified}
          disabled={disabled}
        />
      </ScrollableDrawerContent>
    </>
  );
}
