import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material';

export const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.popper}`]: {
    pointerEvents: 'none',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    opacity: 1,
    pointerEvents: 'none',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    letterSpacing: theme.typography.pxToRem(0.14),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
  },
}));

export const YellowTooltip = styled(BlackTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.common.black,
    letterSpacing: theme.typography.pxToRem(0.13),
    backgroundColor: theme.palette.custom.lightYellow,
    padding: theme.spacing(2),
    boxShadow: '0px 3px 10px #00000041',
  },
}));

interface CommonTooltipProps extends TooltipProps {
  type?: 'black' | 'yellow';
  initialValue?: boolean;
}

export default function CommonTooltip({
  type = 'black',
  initialValue = false,
  ...props
}: CommonTooltipProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(initialValue);

  const TooltipComponent = type === 'black' ? BlackTooltip : YellowTooltip;

  return (
    <TooltipComponent
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onClick={() => setOpen(false)}
      {...props}
    />
  );
}
