import React from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { Phrase, SuggestedPhraseContainer } from './components';
import { ReuseDomainCluster } from 'sections/Reuse/types';
import { staticIconSx } from './helpers';

export interface SuggestionPreferredPhraseProps {
  data: ReuseDomainCluster;
}

export default function SuggestionPreferredPhrase({
  data,
}: SuggestionPreferredPhraseProps) {
  return (
    <SuggestedPhraseContainer>
      <CheckCircleOutlineRoundedIcon color="success" sx={staticIconSx} />
      <Phrase lang={data.language}>{data.preferredPhrase.phrase}</Phrase>
    </SuggestedPhraseContainer>
  );
}
