import React, { ReactElement } from 'react';
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledButtonPrimary = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16),
  lineHeight: theme.typography.pxToRem(19),
  letterSpacing: theme.typography.pxToRem(0.14),
  height: theme.typography.pxToRem(36),
  [`&.${buttonClasses.contained}`]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.2),
    paddingBottom: theme.spacing(2.2),
  },
  [`&.${buttonClasses.containedError}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
    [`&.Mui-disabled, &[aria-disabled="true"]`]: {
      color: theme.palette.disabled.contrastText,
      backgroundColor: theme.palette.disabled.error,
      cursor: 'not-allowed ', // Source: https://www.smashingmagazine.com/2021/08/frustrating-design-patterns-disabled-buttons/#making-disabled-buttons-more-inclusive
      pointerEvents: 'all !important', // https://github.com/mui/material-ui/issues/32371#issuecomment-1813963402
    },
  },
  [`&.${buttonClasses.containedPrimary}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    [`&.Mui-disabled, &[aria-disabled="true"]`]: {
      color: theme.palette.disabled.contrastText,
      backgroundColor: theme.palette.disabled.primary,
      cursor: 'not-allowed ', // Source: https://www.smashingmagazine.com/2021/08/frustrating-design-patterns-disabled-buttons/#making-disabled-buttons-more-inclusive
      pointerEvents: 'all !important', // https://github.com/mui/material-ui/issues/32371#issuecomment-1813963402
    },
  },
  [`&.${buttonClasses.outlined}`]: {
    padding: 10,
  },
  [`&.${buttonClasses.outlinedError}`]: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.light,
    },
    '&.Mui-disabled, &[aria-disabled="true"]': {
      color: theme.palette.disabled.error,
      borderColor: theme.palette.disabled.error,
      backgroundColor: theme.palette.common.white,
      cursor: 'not-allowed', // Source: https://www.smashingmagazine.com/2021/08/frustrating-design-patterns-disabled-buttons/#making-disabled-buttons-more-inclusive
      pointerEvents: 'all !important', // https://github.com/mui/material-ui/issues/32371#issuecomment-1813963402
    },
  },
  [`&.${buttonClasses.outlinedPrimary}`]: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled, &[aria-disabled="true"]': {
      color: theme.palette.disabled.primary,
      borderColor: theme.palette.disabled.primary,
      backgroundColor: theme.palette.common.white,
      cursor: 'not-allowed', // Source: https://www.smashingmagazine.com/2021/08/frustrating-design-patterns-disabled-buttons/#making-disabled-buttons-more-inclusive
      pointerEvents: 'all !important', // https://github.com/mui/material-ui/issues/32371#issuecomment-1813963402
    },
  },
}));

const ButtonPrimaryWithRef = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonPrimary({ ...props }, ref): ReactElement {
    return (
      <StyledButtonPrimary
        ref={ref}
        color="primary"
        disableElevation
        disableRipple
        {...props}
      />
    );
  }
) as typeof Button;

export default ButtonPrimaryWithRef;
