import React, { ReactElement, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';

import Checkbox, { CheckboxProps } from 'components/CommonCheckbox';
import CommonTooltip from '../CommonTooltip';
import ConditionalWrapper from '../ConditionalWrapper';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  alignItems: 'center',
  '& .MuiTypography-root': {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.28),
    paddingLeft: theme.spacing(3 / 5),
    '& p': {
      margin: 0,
      padding: 0,
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  '&[aria-disabled="true"]': {
    opacity: 0.4,
    '&:hover': {
      opacity: 0.4,
    },
  },
}));

interface CheckboxWithLabelProps extends CheckboxProps {
  label: string | JSX.Element;
  sx?: FormControlLabelProps['sx'];
  tooltip?: string;
}

export default function CheckboxWithLabel({
  label,
  sx,
  tooltip,
  lang,
  ...props
}: CheckboxWithLabelProps): ReactElement {
  const wrapper = useCallback(
    (children: ReactElement) => {
      return (
        <CommonTooltip type="yellow" title={tooltip} placement="bottom-start">
          <div>{children}</div>
        </CommonTooltip>
      );
    },
    [tooltip]
  );

  // aria-disabled should be passed explicitly
  if (!props['aria-disabled']) {
    props['aria-disabled'] = false;
  }

  return (
    <StyledFormControlLabel
      label={label}
      sx={sx}
      {...(lang && { lang })}
      control={
        <ConditionalWrapper condition={!!tooltip} wrapper={wrapper}>
          <StyledCheckbox {...props} />
        </ConditionalWrapper>
      }
    />
  );
}
