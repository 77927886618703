import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCustomPresetButton, PresetName, PresetText } from './styles';
import { TargetActions } from './GoalSection';
import { Goal } from '../../types';
import OutlinedDenseTextField from 'components/OutlinedDenseTextField';

export default function CustomPreset({
  customPresetDescription,
  displayFormToEditPreset,
  goal,
  readOnly,
  setCustomPresetDescription,
  setDisplayFormToEditPreset,
  targetActions,
}: {
  customPresetDescription: string;
  displayFormToEditPreset: boolean;
  goal: Goal;
  readOnly: boolean;
  setCustomPresetDescription: (value: string) => void;
  setDisplayFormToEditPreset: (value: boolean) => void;
  targetActions: TargetActions;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {displayFormToEditPreset ? (
        <>
          <div style={{ display: 'inline-flex' }}>
            <PresetName
              style={{ alignSelf: 'center' }}
              variant="body2"
              component="h4"
            >
              {t('capture.titles.customPresetName')}:&nbsp;
            </PresetName>
            <OutlinedDenseTextField
              sx={(theme) => ({ width: theme.spacing(100) })}
              inputProps={{ maxLength: 150 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCustomPresetDescription(event.target.value);
              }}
              value={customPresetDescription}
            />
          </div>
          <div style={{ display: 'inline-flex' }}>
            <EditCustomPresetButton
              variant="contained"
              color="secondary"
              onClick={() => {
                targetActions.setCustomPresetDescription(
                  goal,
                  customPresetDescription
                );
                setDisplayFormToEditPreset(false);
              }}
            >
              <span className="label">{t('capture.actions.ok')}</span>
            </EditCustomPresetButton>
          </div>
        </>
      ) : (
        <div style={{ display: 'inline-flex' }}>
          <PresetName
            style={{ alignSelf: 'center' }}
            variant="body2"
            component="h4"
          >
            {t('capture.titles.customPresetName')}:&nbsp;
          </PresetName>
          <PresetText style={{ alignSelf: 'center' }} variant="body2">
            {customPresetDescription}
          </PresetText>
        </div>
      )}
      {!displayFormToEditPreset && !readOnly && (
        <div style={{ display: 'inline-flex' }}>
          <EditCustomPresetButton
            variant="contained"
            onClick={() => {
              setDisplayFormToEditPreset(true);
              customPresetDescription
                ? setCustomPresetDescription(customPresetDescription)
                : setCustomPresetDescription('');
            }}
          >
            <span className="label">{t('shared.actions.edit')}</span>
          </EditCustomPresetButton>
        </div>
      )}
    </>
  );
}
