import React, { ReactElement, ReactNode } from 'react';
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    top: '-15%',
    minWidth: theme.spacing(68),
    boxShadow: '0 5px 49px rgba(85, 85, 85, 0.2)',
    padding: theme.spacing(5, 6),
    borderRadius: theme.typography.pxToRem(6),
  },
}));

export interface DialogBoxProps extends DialogProps {
  open: boolean;
  children?: ReactNode;
  onClose?: () => void;
  className?: string;
}

export function DialogBox(
  { open, children, ...rest }: DialogBoxProps,
  ref: React.ForwardedRef<HTMLDivElement>
): ReactElement {
  return (
    <StyledDialog open={open} ref={ref} {...rest}>
      {children}
    </StyledDialog>
  );
}

export default React.forwardRef<HTMLDivElement, DialogBoxProps>(DialogBox);
