import React, { ChangeEvent, ForwardedRef } from 'react';
import { FormControl } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';

import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import AllCheckbox from './AllCheckbox';
import { CheckboxValue } from 'types/types';
import { useCheckboxList } from './hooks';
import { StyledFormGroup, StyledLabel } from './components';

export interface CheckboxListProps {
  allCheckboxLabel: string;
  disabled?: boolean;
  filteredValues?: CheckboxValue[];
  handleValueChange: (data: Set<CheckboxValue>) => void;
  selectedSummary?: boolean;
  values: CheckboxValue[];
  valuesWereModified: boolean;
}

function CheckboxList(
  props: CheckboxListProps,
  ref: ForwardedRef<HTMLFieldSetElement | null>
): JSX.Element {
  const { allCheckboxLabel, filteredValues, selectedSummary = true } = props;
  const {
    allChecked,
    allIndeterminate,
    amountChecked,
    getFilteredArrayWithUpdatedValues,
    localValues,
    setLocalValues,
  } = useCheckboxList(props);

  return (
    <FormControl
      required
      component="fieldset"
      ref={ref}
      sx={{ height: '100%' }}
    >
      <AllCheckbox
        allChecked={allChecked}
        allIndeterminate={allIndeterminate}
        amountChecked={selectedSummary ? amountChecked : undefined}
        disabled={props.disabled ?? false}
        filteredValues={filteredValues}
        label={allCheckboxLabel}
        localValues={localValues}
        setLocalValues={setLocalValues}
      />
      <StyledFormGroup>
        {getFilteredArrayWithUpdatedValues().map(
          ({
            checked,
            label,
            originalIndex,
            disabled = false,
          }: CheckboxValue): JSX.Element => (
            <div key={label}>
              <CommonCheckboxWithLabel
                checked={checked}
                value={label}
                label={<StyledLabel variant="body2">{label}</StyledLabel>}
                onChange={(event: ChangeEvent, checked: boolean) => {
                  if (!disabled) {
                    const newValues = cloneDeep(Array.from(localValues));
                    newValues[originalIndex as number] = {
                      ...newValues[originalIndex as number],
                      checked,
                    };
                    setLocalValues(new Set(newValues));
                  }
                }}
                disabled={disabled}
              />
            </div>
          )
        )}
      </StyledFormGroup>
    </FormControl>
  );
}

export default React.forwardRef(CheckboxList);
