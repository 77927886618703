import { useCallback } from 'react';
import { useFetch } from 'utils/fetch';
import { useHistory } from 'react-router-dom';

interface UseSignOutReturnType {
  handleSignOut: (callback?: () => void) => Promise<void>;
  handleResetUserSessionState: () => void;
}

const handleResetUserSessionState = () => {
  // Remove session state
  window.localStorage.removeItem('Users-VisibleColumns');
  window.localStorage.removeItem('Users-Sorting');
  window.localStorage.removeItem('Users-TextFilter');
  window.localStorage.removeItem('Users-RowsPerPage');
  window.localStorage.removeItem('Users-RolesFilter');
  window.localStorage.removeItem('Users-CurrentPage');
};

const useSignOut = (): UseSignOutReturnType => {
  const history = useHistory();
  const http = useFetch();

  const handleSignOut = useCallback(
    async (callback?: () => void) => {
      try {
        await http.delete('/dashboard/api/signin/authorize-access');
      } catch (error) {
        throw error;
      } finally {
        handleResetUserSessionState();

        if (callback) {
          callback();
        } else {
          history.push('/logout');
        }
      }
    },
    [http, history]
  );

  return {
    handleSignOut,
    handleResetUserSessionState,
  };
};

export default useSignOut;
