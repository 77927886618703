import * as React from 'react';
import { ApiError } from 'errors/ApiError';

export type ErrorType = {
  value: ApiError | Error;
  origin:
    | 'getUsers'
    | 'exportUsers'
    | 'changeLicenseType'
    | 'releaseLicense'
    | 'setCustomField.dashboard'
    | 'setCustomField.userDetails'
    | 'userDelete.single'
    | 'userDelete.bulk';
} | null;

const ErrorContext = React.createContext<{
  error: ErrorType;
  setError: React.Dispatch<React.SetStateAction<ErrorType>>;
}>({
  error: null,
  setError: () => {
    throw new Error(
      'You are using setError without ErrorProvider. Please add it to the component tree.'
    );
  },
});

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = React.useState<ErrorType>(null);

  const providerValue = React.useMemo(
    () => ({
      error,
      setError,
    }),
    [error]
  );

  return (
    <ErrorContext.Provider value={providerValue}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => React.useContext(ErrorContext);
