import React from 'react';
import { SelectChangeEvent } from '@mui/material';

import CommonSelectStandard, {
  CommonMenuItem,
  menuProps,
} from 'components/CommonSelectStandard';
import { StyledListSubheader } from './styles';
import { useLanguages } from './hooks';
import { LanguageDropdownProps } from './types';
import ReadOnlyTextField from '../ReadOnlyTextField';

export default function LanguageDropdown({
  languages,
  languagesDisabled,
  languagesLabel,
  onChange,
  readOnly,
  readOnlyLabel,
  selectedLanguageId,
  setSelectedLanguage,
  setSelectedLanguageId,
  type = 'simple',
  supportedLanguageAbbreviations,
  ...props
}: LanguageDropdownProps) {
  const { languageStructure, selectedLanguage } = useLanguages({
    languages,
    selectedLanguageId,
    setSelectedLanguage,
    setSelectedLanguageId,
    type,
    supportedLanguageAbbreviations,
  });

  if (languageStructure.length === 1 || readOnly) {
    return (
      <ReadOnlyTextField
        title={readOnlyLabel}
        text={selectedLanguage[0]?.languageVariantDisplayName}
        lang={selectedLanguage[0]?.abbreviation}
      />
    );
  }
  return (
    <CommonSelectStandard
      id="languages-select"
      MenuProps={menuProps}
      label={languagesLabel}
      onChange={(ev: SelectChangeEvent<unknown>) => {
        setSelectedLanguageId(ev.target.value as string);
        if (typeof onChange === 'function') {
          onChange(ev);
        }
      }}
      disabled={languagesDisabled}
      value={selectedLanguageId}
      {...props}
    >
      {languageStructure.map((language) => {
        if (!language.languageVariantDisplayName) {
          return (
            <StyledListSubheader
              key={`subheader-${language.displayName}`}
              lang={language.abbreviation}
            >
              {language.displayName}
            </StyledListSubheader>
          );
        }

        return (
          <CommonMenuItem
            sx={(theme) => ({
              maxWidth: theme.spacing(60),
            })}
            type="sub"
            value={language.languageId}
            key={language.languageId}
            text={language.languageVariantDisplayName}
            lang={language.abbreviation}
          >
            {language.languageVariantDisplayName}
          </CommonMenuItem>
        );
      })}
    </CommonSelectStandard>
  );
}
