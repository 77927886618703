import { useEffect, useRef } from 'react';
import { useDidMountEffect } from './useDidMountEffect';

export default function useDelayedAction(
  actionFn: () => void,
  timeout: number
) {
  const timerReference = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  useDidMountEffect(() => {
    clearTimeout(timerReference.current);
    timerReference.current = setTimeout(() => {
      actionFn();
    }, timeout);
  }, [actionFn]);

  useEffect(() => {
    return () => clearTimeout(timerReference.current);
  }, []);

  return {
    timerReference,
  };
}
