import React from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as NoUsersFoundImage } from './noUsersFound.svg';
import { StyledNoUsersFoundDetail, StyledNoUsersFoundTitle } from './styles';
import { useTranslation } from 'react-i18next';

const NoUsersFoundInfo = () => {
  const { t } = useTranslation();

  return (
    <Box pt={20} textAlign={'center'} width={500} margin={'auto'}>
      <NoUsersFoundImage width={200} height={200} />
      <Box pt={7}>
        <StyledNoUsersFoundTitle variant="body1">
          {t('users.notice.noUsersFound')}
        </StyledNoUsersFoundTitle>
        <StyledNoUsersFoundDetail variant="body1">
          {t('users.notice.noUsersFoundDetail')}
        </StyledNoUsersFoundDetail>
      </Box>
    </Box>
  );
};

export default NoUsersFoundInfo;
