import React, { forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material/Button';

import ButtonPrimary from 'components/ButtonPrimary';
import FeaturesContext from 'contexts/FeaturesContext';
import useSignOut from 'hooks/useSignOut';

type Props = {
  text: string;
  onAfterClick?: () => void;
} & ButtonProps;

export const LogoutButton = forwardRef<HTMLButtonElement, Props>(
  ({ text, onAfterClick, ...buttonProps }, ref) => {
    const { t } = useTranslation();
    const { enableKeycloak } = useContext(FeaturesContext);
    const { handleResetUserSessionState, handleSignOut } = useSignOut();

    return enableKeycloak ? (
      <form
        method="POST"
        action="/sign-in/bye"
        onSubmit={handleResetUserSessionState}
      >
        <ButtonPrimary type="submit" {...buttonProps} ref={ref}>
          {t(text)}
        </ButtonPrimary>
      </form>
    ) : (
      <ButtonPrimary
        {...buttonProps}
        onClick={() => handleSignOut(onAfterClick)}
        ref={ref}
      >
        {t(text)}
      </ButtonPrimary>
    );
  }
);
