import { ChangeEvent, useCallback, useState } from 'react';
import { MAX_TARGET_EVENT_COMMENT_LENGTH } from '../constants';
import useText, { UseTextResult } from 'hooks/useText';

export interface UseCommentResult extends UseTextResult {
  changeCommentHandler: (ev: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}

export function useComment(commentText: string): UseCommentResult {
  const { textValue, setTextValue, textValueCount } = useText(commentText);
  const [error, setError] = useState(false);

  const changeCommentHandler = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setTextValue(ev.target.value);
      if (ev.target.value.length > MAX_TARGET_EVENT_COMMENT_LENGTH) {
        setError(true);
        return;
      }

      setError(false);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [commentText.length]
  );

  return {
    changeCommentHandler,
    error,
    setTextValue,
    textValue,
    textValueCount,
  };
}
