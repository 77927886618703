import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledDrawerContent = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  paddingRight: theme.spacing(1.8),
}));

export const LanguagesContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 4, 0),
}));

export const SelectGoalsTitle = styled('p')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(21),
  lineHeight: theme.typography.pxToRem(28),
  letterSpacing: theme.typography.pxToRem(0.42),
  fontWeight: theme.typography.fontWeightRegular,
  margin: `${theme.spacing(4)} 0 ${theme.spacing(3.6)} 0`,
}));

export const GoalLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.42),
  fontWeight: theme.typography.fontWeightBold,
}));

export const GoalDescription = styled('p')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  color: grey[600],
  margin: theme.spacing(0, 0, 4, 9),
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(18),
}));
