import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import ButtonPrimary from 'components/ButtonPrimary';
import UserFormInDrawer from './components/UserFormInDrawer';

import { useTranslation } from 'react-i18next';
import { User } from 'sections/Users/types';

type Props = {
  onClose?: (user?: User | undefined) => void;
};

export default function UserForm({ onClose }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box mr={4}>
      <ButtonPrimary
        variant="contained"
        onClick={() => {
          setIsOpen(true);
        }}
        startIcon={<AddIcon />}
      >
        {t('users.actions.addUser')}
      </ButtonPrimary>
      {isOpen && (
        <UserFormInDrawer
          open={isOpen}
          onClose={(user?: User) => {
            if (onClose) {
              onClose(user);
            }

            setIsOpen(false);
          }}
        />
      )}
    </Box>
  );
}
