import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import BusyIndicator from '../BusyIndicator';

export interface BusyBackdropProps {
  open: boolean;
  title?: string;
}

export default function BusyBackdrop({ open, title }: BusyBackdropProps) {
  return open ? (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={true}
      aria-hidden={!open}
    >
      <BusyIndicator title={title} />
    </Backdrop>
  ) : null;
}
