import React, { ReactElement, useContext, useCallback } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Box } from '@mui/material';

import AuthContext from 'contexts/AuthContext';
import PrivilegesContext from 'contexts/PrivilegesContext';
import usePages from 'hooks/usePages';
import { PageDefinitionListItem } from 'components/NavigationMenu';

interface ProtectedRouteProps extends RouteProps {
  checkAuthorization?: boolean;
}

const ProtectedRoute = ({
  checkAuthorization = true,
  children,
  render,
  ...rest
}: ProtectedRouteProps): ReactElement => {
  const [token] = useContext(AuthContext);
  const userPrivileges = useContext(PrivilegesContext);
  const pagesDefinition = usePages();

  const renderComponent: (props: RouteComponentProps<any>) => React.ReactNode =
    useCallback(
      (renderProps) => {
        const { location, match } = renderProps;

        let isAllowedAccess = false;

        const pageDefinition = pagesDefinition.find(
          (page) =>
            !page.type &&
            (page.routePath === match.path ||
              page.alternatePaths?.includes(match.path))
        ) as PageDefinitionListItem;

        if (checkAuthorization) {
          if (pageDefinition) {
            const { privileges } = pageDefinition;
            isAllowedAccess =
              Array.isArray(privileges) &&
              !!privileges.filter((privilege) =>
                userPrivileges.includes(privilege)
              ).length;
          }
        } else {
          isAllowedAccess = true;
        }

        if (!token) {
          const query = location.pathname
            ? `?continue=${process.env.PUBLIC_URL + location.pathname}`
            : '';

          return <Redirect to={`/login${query}`} />;
        }

        if (!isAllowedAccess) {
          if (pageDefinition && match.isExact) {
            const { blankPageOnUnauthorized } = pageDefinition;
            if (blankPageOnUnauthorized) {
              return <Box data-testid="empty-box" />;
            }
          }
          return <Redirect to="/403" />;
        }

        if (render) {
          return render(renderProps);
        }

        return children;
      },
      [
        checkAuthorization,
        children,
        pagesDefinition,
        render,
        token,
        userPrivileges,
      ]
    ) as (props: RouteComponentProps<any>) => React.ReactNode;

  // @ts-ignore
  return <Route {...rest} render={renderComponent} />;
};

export default ProtectedRoute;
