import React from 'react';
import Grid from '@mui/material/Grid';

import WarningIcon from 'components/WarningIcon';
import { useTranslation } from 'react-i18next';
import { StatusText } from './components';
import ImportStatusDescription from './ImportStatusDescription';

export default function ImportFailed() {
  const { t } = useTranslation();

  return (
    <>
      <Grid flexWrap="nowrap" alignItems="center" container>
        <WarningIcon
          sx={(theme) => ({ fontSize: theme.typography.pxToRem(25) })}
        />
        <StatusText>{t('reuse.errors.replacementsImportFailed')}</StatusText>
      </Grid>
      <ImportStatusDescription>
        {t('reuse.descriptions.replacementsImportFailed')}
      </ImportStatusDescription>
    </>
  );
}
