import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { TargetIndexItem } from 'types/types';
import { TargetNameBlock } from '../../styles';
import { useComment } from 'sections/Targets/hooks/useComment';
import CommonOutlinedMultilineTextField from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextField';
import { MAX_TARGET_EVENT_COMMENT_LENGTH } from 'sections/Targets/constants';

interface DeleteTargetProps {
  onClose: (deleteIt: boolean, target: TargetIndexItem) => void;
  target: TargetIndexItem;
}

export default function DeleteTargetDialog({
  onClose,
  target,
}: DeleteTargetProps): ReactElement {
  const { t } = useTranslation();
  const { changeCommentHandler, error, textValueCount, textValue } =
    useComment('');
  const commentHelperText = t(
    error
      ? 'capture.descriptions.commentTooLong'
      : 'capture.hints.commentOptional',
    { limit: MAX_TARGET_EVENT_COMMENT_LENGTH }
  );

  return (
    <ConfirmationDialog
      title={t('capture.titles.deleteTarget')}
      buttonOKLabel={t('shared.actions.delete')}
      buttonCancelLabel={t('shared.actions.cancel')}
      open={!!target}
      onClose={(ok: boolean) =>
        onClose(ok, error ? target : { ...target, comment: textValue })
      }
      ContentComponent={Grid}
    >
      {t('capture.descriptions.deleteTarget')}
      <TargetNameBlock>{target?.displayName}</TargetNameBlock>
      <Grid
        container
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
          width: theme.spacing(68),
          maxWidth: theme.spacing(68),
        })}
      >
        <CommonOutlinedMultilineTextField
          sx={(theme) => ({
            marginTop: theme.spacing(4),
          })}
          label={t('capture.labels.comment')}
          text={textValue}
          error={error}
          charCount={textValueCount}
          maxCharCount={MAX_TARGET_EVENT_COMMENT_LENGTH}
          onChange={changeCommentHandler}
          helperText={commentHelperText}
          rows="6"
          fullWidth
          id="deleteTargetComment"
        />
      </Grid>
    </ConfirmationDialog>
  );
}
