import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { visuallyHidden } from '@mui/utils';
import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { ActivityText, ActivityType, DateText, UserIcon } from './components';
import DisplayDate from 'components/DisplayDate';
import { UserName } from 'components/AdministrativeInformation';
import TargetIcon from 'icons/TargetIcon';
import { RecentActivityData } from './types';
import theme from 'theme';

export interface ActivitySummaryProps {
  activity: RecentActivityData;
}

export default function ActivitySummary({ activity }: ActivitySummaryProps) {
  const { t } = useTranslation();
  const maxWidth = activity.comment ? '240px' : undefined;

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <DateText>
          <DisplayDate
            notExistingDateReplacement={t(
              'capture.descriptions.recentActivities.unavailable'
            )}
            tooltipVisible
            date={activity.info.date}
            showOnlyExisting
          />
        </DateText>
        <ActivityType type={activity.type}>
          {t(
            `capture.descriptions.recentActivities.${activity.type.toLowerCase()}`
          )}
        </ActivityType>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={(theme) => ({
          paddingBottom: theme.spacing(2.2),
          overflow: 'hidden',
          width: '100%',
        })}
        wrap="nowrap"
      >
        <TargetIcon fill={theme.palette.custom.midDarkGrey} />
        <Box sx={visuallyHidden as unknown as SxProps<Theme>}>
          {t('capture.a11y.iconLabels.target')}
        </Box>
        <ActivityText
          sx={(theme) => ({
            overflowWrap: 'anywhere',
            marginLeft: theme.spacing(2),
          })}
        >
          {activity.displayName}
        </ActivityText>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={(theme) => ({ paddingBottom: theme.spacing(1.2) })}
      >
        <UserIcon />
        <Box sx={visuallyHidden as unknown as SxProps<Theme>}>
          {t('capture.a11y.iconLabels.username')}
        </Box>
        <ActivityText>
          <UserName
            maxWidth={maxWidth}
            data={activity.info}
            defaultNameReplacement={t(
              'capture.descriptions.recentActivities.unavailable'
            )}
          />
        </ActivityText>
      </Grid>
    </Grid>
  );
}
