import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  padding: theme.spacing(0.4),
  marginBottom: theme.spacing(0.2),
  color: theme.palette.primary.main,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.light,
  },
  '& svg': {
    height: theme.spacing(4.4),
    width: theme.spacing(4.4),
  },
  '&[aria-disabled="true"] svg': {
    color: theme.palette.disabled.primary,
    backgroundColor: 'transparent',
  },
}));

export const UnderText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  padding: theme.spacing(0.8, 2),
  lineHeight: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.24),
  color: theme.palette.custom.doveGrey,
}));

export const ErrorText = styled(UnderText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.custom.whiteSmoke,
  border: `1px solid ${theme.palette.custom.whiteSmoke}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.2, 1.2, 1, 2),
  margin: 0,
  '&:hover, &:focus': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    '&.Mui-disabled': {
      borderColor: theme.palette.custom.whiteSmoke,
      backgroundColor: theme.palette.custom.whiteSmoke,
    },
  },
  '& input': {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.14),
    height: theme.spacing(4.2),
    color: theme.palette.common.black,
    padding: 0,
    '&::placeholder': {
      color: theme.palette.custom.doveGrey,
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: theme.typography.pxToRem(0.28),
      opacity: 1,
    },
    // https://stackoverflow.com/a/12250084
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    '&:hover': {},
    '& input[aria-invalid="true"]': {
      color: theme.palette.error.main,
      '&::placeholder': {
        color: theme.palette.error.main,
      },
    },
    '& svg': {
      height: '19px',
      width: '19px',
    },
  },
}));
