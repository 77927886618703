import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useExpandCollapse(defaultValue = false) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(defaultValue);

  const expandIconTooltipText = expanded
    ? t('capture.actions.tooltips.collapse')
    : t('capture.actions.tooltips.expand');

  return {
    expanded,
    expandIconTooltipText,
    setExpanded,
  };
}
