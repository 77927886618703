import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ManageAccountsIcon } from './manage-accounts-icon.svg';
import { PrivilegesStructure } from 'hooks/usePrivileges';
import SplashBasicTile from './SplashBasicTile';

export default function UserManagementTile({
  privileges,
}: {
  privileges: PrivilegesStructure;
}) {
  const { t } = useTranslation();

  return (
    <SplashBasicTile
      href="/user-management/users"
      headerText={t('splashHomePage.userManagement.header')}
      descriptionText={t(
        privileges.editRights
          ? 'splashHomePage.userManagement.descriptionEdit'
          : 'splashHomePage.userManagement.descriptionRead'
      )}
      goToText={t('splashHomePage.userManagement.goToText')}
      Icon={ManageAccountsIcon}
      localLink
    />
  );
}
