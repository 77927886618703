import {
  useAnalyticsPrivileges,
  useTargetsPrivileges,
  useTerminologyPrivileges,
  useUserManagementPrivileges,
} from 'hooks/usePrivileges';

export function useHomeSplashPage() {
  const targetPrivileges = useTargetsPrivileges();
  const analyticsPrivileges = useAnalyticsPrivileges();
  const terminologyPrivileges = useTerminologyPrivileges();
  const userManagementPrivileges = useUserManagementPrivileges();
  const analyticsTileIsVisible =
    analyticsPrivileges.editRights || analyticsPrivileges.readRights;
  const targetTileIsVisible =
    targetPrivileges.editRights || targetPrivileges.readRights;
  const terminologyTileIsVisible =
    terminologyPrivileges.editRights || terminologyPrivileges.readRights;
  const userManagementTileIsVisible =
    userManagementPrivileges.editRights || userManagementPrivileges.readRights;

  return {
    analyticsPrivileges,
    analyticsTileIsVisible,
    targetPrivileges,
    targetTileIsVisible,
    terminologyPrivileges,
    terminologyTileIsVisible,
    userManagementPrivileges,
    userManagementTileIsVisible,
  };
}
