/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */

import React from 'react';
import { Trans, TransProps } from 'react-i18next';

// https://acrolinx.atlassian.net/browse/DEV-28737
// https://acrolinx.atlassian.net/wiki/spaces/DLT/pages/1268058808/Style+Guidelines+for+String+Text#HTML-and-Parameters-in-Strings
export function TransWrapper(props: TransProps<any>): JSX.Element {
  const predefinedComponents = {
    b: <b />,
    em: <em />,
    i: <i />,
    h1: <h1 />,
    h2: <h2 />,
    h3: <h3 />,
    h4: <h4 />,
    h5: <h5 />,
    h6: <h6 />,
    hr: <hr />,
    ul: <ul />,
    ol: <ol />,
    li: <li />,
    br: <br />,
    p: <p />,
    a: <a />,
    strong: <strong />,
  };

  const components = props.components
    ? Object.assign({}, predefinedComponents, props.components)
    : predefinedComponents;

  return <Trans {...props} components={components} shouldUnescape={true} />;
}

export default TransWrapper;
