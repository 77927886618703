import React, { useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { TooltipProps } from '@mui/material/Tooltip';

import { BlackTooltip, YellowTooltip } from 'components/CommonTooltip';
import { StyledSelectMenuItem, StyledTextField } from './styles';

interface SelectOption {
  label: string;
  value: string;
}

type StandardDropdownProps = TextFieldProps & {
  options?: SelectOption[];
};

export default React.forwardRef(function StandardDropdown(
  { options, ...props }: StandardDropdownProps,
  ref
) {
  const [visibleTooltipFor, setVisibleTooltipFor] = useState<boolean | string>(
    false
  );

  let TooltipComponent = YellowTooltip,
    title = props.value,
    placement: TooltipProps['placement'] = 'bottom-start',
    additionalTooltipProps = {
      PopperProps: {
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [6, -30],
              },
            },
          ],
        },
      },
    };

  if (visibleTooltipFor === 'LABEL') {
    TooltipComponent = BlackTooltip;
    title = props.label;
    placement = 'top';

    additionalTooltipProps = {
      PopperProps: {
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [5, -6],
              },
            },
          ],
        },
      },
    };
  }

  return (
    <TooltipComponent
      title={<>{title}</>}
      open={!!visibleTooltipFor}
      placement={placement}
      {...additionalTooltipProps}
    >
      <StyledTextField
        variant="filled"
        select
        inputRef={ref}
        InputLabelProps={{
          onMouseEnter: (event: React.MouseEvent<HTMLLabelElement>) => {
            const target = event.target as HTMLElement;

            setVisibleTooltipFor(
              /* istanbul ignore next */
              target.scrollWidth > target.clientWidth && target.tagName
            );
          },
          onMouseLeave: () => setVisibleTooltipFor(false),
        }}
        SelectProps={{
          onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
            const target = event.target as HTMLElement;

            setVisibleTooltipFor(
              /* istanbul ignore next */
              target.scrollWidth > target.clientWidth && target.tagName
            );
          },
          onMouseLeave: () => setVisibleTooltipFor(false),
          MenuProps: {
            style: {
              maxWidth: 'fit-content',
            },
          },
        }}
        FormHelperTextProps={{
          ...props?.FormHelperTextProps,
        }}
        {...props}
      >
        {(Array.isArray(options) &&
          options.map(({ value, label }) => (
            <StyledSelectMenuItem key={value} value={value}>
              {label}
            </StyledSelectMenuItem>
          ))) ||
          props.children}
      </StyledTextField>
    </TooltipComponent>
  );
});
