import React, { forwardRef } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { StyledRecommendedRequiredButton } from './styles';

export type RecommendedRequiredButtonProps = ButtonProps & {
  readOnly: boolean;
};

export default forwardRef(function RecommendedRequiredButton(
  props: RecommendedRequiredButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return <StyledRecommendedRequiredButton {...props} ref={ref} />;
});
