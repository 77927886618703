import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import SuggestionDeprecatedPhrase from './SuggestionDeprecatedPhrase';
import { Phrase, PhraseBreak, SuggestionDetailLabel } from './components';
import { ReuseDomainCluster } from 'sections/Reuse/types';

export interface SuggestionUpdateDetailsProps {
  data: ReuseDomainCluster;
  deprecatedPhrases: string[];
}

export default function SuggestionUpdateDetails({
  data,
  deprecatedPhrases,
}: SuggestionUpdateDetailsProps) {
  const { t } = useTranslation();

  return (
    <>
      {deprecatedPhrases.map((dPhrase, index) => (
        <div key={index}>
          {!!index && <PhraseBreak />}
          <SuggestionDeprecatedPhrase
            lang={data.language}
            deprecatedPhrase={dPhrase}
          />
        </div>
      ))}
      <Grid
        sx={(theme) => ({
          paddingLeft: theme.spacing(8.4),
          paddingTop: theme.spacing(2),
        })}
      >
        <SuggestionDetailLabel>
          {t('reuseInbox.labels.addToReplacement')}
        </SuggestionDetailLabel>
        <br />
        <Phrase lang={data.language}>{data.preferredPhrase.phrase}</Phrase>
      </Grid>
    </>
  );
}
