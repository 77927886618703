import { styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';

export const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: '0.14px',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(7 / 5),
  paddingBottom: theme.spacing(7 / 5),
}));

export const DeleteMenuItem = styled(StyledMenuItem)({
  color: red['A700'],
});

export const MenuMoreIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:focus': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  height:
    'calc(100vh - 64px - 19px - 28px - 15px - 33px - 40px - 131px - 25px - 95px)',
});

export const StyledTable = styled(Table)({
  borderCollapse: 'collapse',
  width: '100%',
});
