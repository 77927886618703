import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.custom.botticelli,
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1 / 5),
  fontSize: theme.typography.pxToRem(18),
}));

export default function InformationOutlinedRoundedIcon(props: SvgIconProps) {
  return (
    <StyledSvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      data-testid="InformationOutlinedRoundedIcon"
    >
      <path
        id="Icon_Info"
        data-name="Icon Info"
        d="M89.071,189.5a.646.646,0,0,0,.48-.194.658.658,0,0,0,.191-.481v-4.05a.651.651,0,0,0-.2-.481.659.659,0,0,0-.484-.194.646.646,0,0,0-.48.194.658.658,0,0,0-.191.481v4.05a.651.651,0,0,0,.2.481A.659.659,0,0,0,89.071,189.5ZM89,182.435a.731.731,0,0,0,.529-.207.685.685,0,0,0,.214-.513.752.752,0,0,0-.213-.545.746.746,0,0,0-1.058,0,.751.751,0,0,0-.214.545.686.686,0,0,0,.213.513A.73.73,0,0,0,89,182.435ZM89.006,194a8.711,8.711,0,0,1-3.5-.709,9.091,9.091,0,0,1-4.8-4.8,9.008,9.008,0,0,1,0-7,9.005,9.005,0,0,1,1.935-2.854,9.238,9.238,0,0,1,2.865-1.924,9.008,9.008,0,0,1,7,0,9,9,0,0,1,4.778,4.781,9,9,0,0,1,0,7,9.192,9.192,0,0,1-1.924,2.859,9.086,9.086,0,0,1-2.858,1.935A8.705,8.705,0,0,1,89.006,194Zm.005-1.35a7.644,7.644,0,1,0-5.423-2.239A7.348,7.348,0,0,0,89.011,192.65ZM89,185Z"
        transform="translate(-80 -176)"
      />
    </StyledSvgIcon>
  );
}
