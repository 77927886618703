import { useEffect, useState } from 'react';

export interface UseOverflowTextResult {
  setElementRef: (ref: HTMLElement) => void;
  isOverflowed: boolean;
}

export function useOverflowText(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  additionalRefreshDependencies: any[] = []
): UseOverflowTextResult {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (elementRef) {
      setIsOverflow(
        (elementRef as unknown as HTMLElement)?.scrollWidth >
          (elementRef as unknown as HTMLElement)?.clientWidth
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [elementRef].concat(additionalRefreshDependencies));

  return {
    isOverflowed,
    setElementRef,
  };
}
