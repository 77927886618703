import React, { PropsWithChildren, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useResize from 'hooks/useResize';

interface FloatingWindowProps {
  title: string;
  onClose: () => void;
}

const FloatingWindow: React.FC<FloatingWindowProps & PropsWithChildren> = ({
  children,
  title,
  onClose,
}) => {
  const { resizeElement, resizedHeight, setResizeElement } = useResize();
  const [assignedTargetsHeight, setAssignedTargetsHeight] = useState<number>(0);

  useEffect(() => {
    if (resizeElement) {
      const rect = (
        resizeElement as unknown as HTMLDivElement
      )?.getBoundingClientRect();
      setAssignedTargetsHeight(window.innerHeight - rect.top + 40);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    resizedHeight,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    (resizeElement as unknown as HTMLDivElement)?.getBoundingClientRect().top,
  ]);

  return (
    <Draggable handle=".handle" cancel=".cancel">
      <div
        className="handle"
        style={{
          zIndex: 1200,
          position: 'absolute',
          overflowY: 'scroll',
          height: `${assignedTargetsHeight}px`,
          width: '50%',
          top: 90,
          left: '49%',
          boxShadow: '0px 3px 16px #00000033',
          borderRadius: '6px',
        }}
      >
        <Paper
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1200,
            height: 'auto',
            //width: '300px', // Set your initial width
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <div>{title}</div>
            <IconButton color="primary" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div ref={setResizeElement}>{children}</div>
        </Paper>
      </div>
    </Draggable>
  );
};

export default FloatingWindow;
