import React from 'react';

import TransWrapper from 'components/TransWrapper';
import SuccessIconAndText from './SuccessIconAndText';
import ImportStatusDescription from './ImportStatusDescription';

export interface ImportFullySuccessfulProps {
  numberOfReplacements: number;
}

export default function ImportFullySuccessful({
  numberOfReplacements,
}: ImportFullySuccessfulProps) {
  return (
    <>
      <SuccessIconAndText />
      <ImportStatusDescription>
        <TransWrapper
          i18nKey="reuse.descriptions.replacementsImportFullSuccess"
          values={{
            numberOfReplacements,
          }}
        />
      </ImportStatusDescription>
    </>
  );
}
