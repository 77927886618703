import React, { Dispatch, SetStateAction } from 'react';

import IndexTable from 'components/IndexTable';
import { useDomainTable } from './hooks';
import { ReuseIndexDomain } from 'sections/Reuse/types';
import { UseSortResult } from 'hooks/useSort';
import createDomainIcons from './createDomainIcons';

export interface DomainTableProps {
  setDomainForDeleteConfirmation: Dispatch<
    SetStateAction<ReuseIndexDomain | undefined>
  >;
  showIcons: boolean;
  sortedDomains: ReuseIndexDomain[];
  sortState: UseSortResult;
}

export default function DomainTable({
  setDomainForDeleteConfirmation,
  showIcons,
  sortedDomains,
  sortState,
}: DomainTableProps) {
  const { reuseTableConfig } = useDomainTable(sortState);

  return (
    <IndexTable
      tableConfig={reuseTableConfig}
      data={sortedDomains}
      {...(showIcons && {
        Icons: createDomainIcons({
          actions: {
            setDomainForDeleteConfirmation,
          },
        }),
      })}
    />
  );
}
