import React, { ForwardedRef, forwardRef, ReactElement } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { StyledTextField } from './styles';

function OutlinedDenseTextField(
  props: TextFieldProps,
  ref: ForwardedRef<HTMLDivElement | null>
): ReactElement {
  return <StyledTextField variant="outlined" {...props} ref={ref} />;
}

export default forwardRef<HTMLDivElement, TextFieldProps>(
  OutlinedDenseTextField
);
