import React, { ReactElement } from 'react';
import BusyIndicator from 'components/BusyIndicator';
import useGetAuthenticationToken from 'hooks/useGetAuthenticationToken';
import { Redirect } from 'react-router-dom';

export default function LoginPage(): ReactElement {
  // Prevent loop for case that user is redirected to this page after login
  const { accessToken, isLoading, isError } = useGetAuthenticationToken();

  if (isLoading) {
    return <BusyIndicator />;
  }

  // Redirect to login outside
  if (!isLoading && !isError && accessToken) {
    return <Redirect to={'/'} />;
  }

  window.location.replace(`/dashboard.html${window.location.search}`);
  return <></>;
}
