import {
  LanguageFull,
  LanguageSimple,
  LanguageSimpleEnhanced,
} from 'types/types';

export function createLanguagesStructure(
  languages: LanguageFull[]
): LanguageFull[] {
  if (!languages?.length) {
    return [];
  }

  let numberOfSubheaders = 0;
  const structure = languages.reduce(
    (acc: LanguageFull[], languageObj, index) => {
      const newAcc = [...acc];
      if (languageObj.displayName !== languages[index - 1]?.displayName) {
        numberOfSubheaders++;
        newAcc.push({
          ...languageObj,
          languageVariantDisplayName: '',
        });
      }
      newAcc.push(languageObj);

      return newAcc;
    },
    []
  );

  return numberOfSubheaders === 1 || numberOfSubheaders === languages?.length
    ? languages
    : structure;
}

export function createListOfSupportedLanguages<
  T extends LanguageFull | LanguageSimpleEnhanced | LanguageSimple
>(languages: T[] | undefined, supportedLanguageAbbreviations: string[]): T[] {
  if (!languages?.length) {
    return [];
  }
  if (!supportedLanguageAbbreviations?.length) {
    return languages;
  }
  return languages.filter((lng) =>
    supportedLanguageAbbreviations.includes(lng.abbreviation)
  );
}
