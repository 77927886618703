import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton';

import DomainAddButton from 'components/DomainAddButton';
import DomainMenu from 'components/DomainMenu';
import useMenuState from 'hooks/useMenuState';
import { TerminologyGuidelineParameterValueObject } from './hooks';
import { EnumContentObject } from 'types/types';
import { HandleChangeDomainCheckboxFn } from 'components/DomainMenu/types';

export interface ShowDomainMenuButtonProps extends IconButtonProps {
  disabled?: boolean;
  domainData: EnumContentObject[];
  handleChangeDomainCheckbox: HandleChangeDomainCheckboxFn;
  openWarningModal?: () => void;
  parameterValueObj: TerminologyGuidelineParameterValueObject;
  presetWarningModalShouldAppear?: boolean;
  setWarningModalOKCallback?: (callback: () => void) => void;
  addIconTooltipText: string;
}

export default function ShowDomainMenuButton({
  disabled,
  domainData,
  handleChangeDomainCheckbox,
  openWarningModal,
  parameterValueObj,
  presetWarningModalShouldAppear,
  setWarningModalOKCallback,
  addIconTooltipText,
  ...props
}: ShowDomainMenuButtonProps) {
  const { anchorEl, open, handleClose, handleClick } = useMenuState();

  function onChangeCallback(checked: boolean, affectedDomains: string[]) {
    if (
      presetWarningModalShouldAppear &&
      openWarningModal &&
      setWarningModalOKCallback
    ) {
      setWarningModalOKCallback(() =>
        handleChangeDomainCheckbox.bind(null, checked, affectedDomains)
      );
      openWarningModal();
      return;
    }

    handleChangeDomainCheckbox(checked, affectedDomains);
  }

  return (
    <>
      <DomainAddButton
        aria-controls={!props['aria-disabled'] ? 'add-domain-menu' : undefined}
        aria-haspopup={!props['aria-disabled']}
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        onClick={open ? handleClose : handleClick}
        tooltipText={addIconTooltipText}
        {...props}
      />
      {!props['aria-disabled'] && (
        <DomainMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          domainData={domainData}
          selectedDomains={parameterValueObj?.domainNames || []}
          handleChangeDomainCheckbox={onChangeCallback}
        />
      )}
    </>
  );
}
