import React, { ReactElement } from 'react';
import { ChipProps } from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

import { StyledChip } from './styles';

export interface DomainChipProps extends ChipProps {
  deleteIcon?: ReactElement;
  domainName: string;
}

export default function DomainChip({ domainName, ...props }: DomainChipProps) {
  const { t } = useTranslation();

  return (
    <StyledChip
      deleteIcon={
        <CancelIcon
          role="img"
          aria-label={t('capture.labels.removeDomain', {
            domainName,
          })}
          sx={(theme) => ({
            fill: theme.palette.grey[600],
            '&:hover': {
              fill: theme.palette.custom.darkGrey,
            },
          })}
        />
      }
      {...props}
    />
  );
}
