import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

export const TextBlock = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.8),
  marginRight: theme.spacing(4),
}));

export const StyledSearchTextFieldWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(63.6),
  padding: theme.spacing(0, 0.8, 4, 0.8),
}));

export const RadioGroupContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const Divider = styled('div')(({ theme }) => ({
  height: theme.spacing(1 / 5),
  padding: 0,
  margin: 0,
  backgroundColor: blueGrey[100],
  marginBottom: theme.spacing(3),
}));
