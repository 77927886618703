import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import Grid from '@mui/material/Grid';

import {
  GridRow,
  SideGrid,
  StyledFormControlLabel,
  StyledTypography,
} from '../../styles';
import CommonSelectDense, {
  BootstrapInput,
  menuProps,
  CommonMenuItem,
} from 'components/CommonSelectDense/CommonSelectDense';
import AdvancedSettings from './AdvancedSettings';
import ChosenDomainsChips from './ChosenDomainsChips';
import ShowDomainMenuButton from './ShowDomainMenuButton';
import { StyledRadio as Radio } from 'components/CommonRadioGroup';
import {
  TERM_FILTER_PREFIX,
  WORDS_AND_PHRASES_GOAL_NAME,
} from '../../constants';
import {
  UseTerminologyGuidelineRestResult,
  TerminologyGuidelineParameterValueObject,
  UseTerminologyGuidelineProps,
} from './hooks';
import { GoalConfig, GoalGroup, GuidelineValue } from '../../types';
import { useGuideline } from '../../hooks';
import GuidelineToggleActive from '../GuidelineSection/GuidelineToggleActive';
import CustomPresetChangeWarningDialog from '../CustomPresetChangeWarningDialog';
import ContextualSettings from '../GuidelineSection/ContextualSettings';
import GuidelineHelp from '../GuidelineSection/GuidelineHelp';

export function TerminologyGuideline(
  props: UseTerminologyGuidelineProps &
    UseTerminologyGuidelineRestResult & {
      expandedGoals: boolean;
      goalGroup?: GoalGroup;
      guidelineConfig?: GuidelineValue;
      languageIdShort: string;
      modified: boolean;
      parameterValueObj: TerminologyGuidelineParameterValueObject;
      readOnly: boolean;
      targetGoal: GoalConfig;
    }
): JSX.Element {
  const { t } = useTranslation();

  const disabled =
    props.goal.identifier.toLowerCase() === WORDS_AND_PHRASES_GOAL_NAME ||
    props.readOnly;

  const {
    cancelFn,
    closeWarningModal,
    guidelineIsActive,
    okFn,
    openWarningModal,
    performOpenEditContextsDrawer,
    presetWarningModalShouldAppear,
    setWarningModalOKCallback,
    warningModalIsOpen,
  } = useGuideline(props);

  return (
    <Grid container>
      <CustomPresetChangeWarningDialog
        open={warningModalIsOpen}
        closeFn={closeWarningModal}
        okFn={okFn}
        cancelFn={cancelFn}
      />
      <GridRow
        item
        container
        xs={12}
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item>
          <GuidelineToggleActive
            guideline={props.guideline}
            guidelineIsActive={guidelineIsActive}
            goal={props.goal}
            languageIdShort={props.languageIdShort}
            targetActions={props.targetActions}
            presetWarningModalShouldAppear={presetWarningModalShouldAppear}
            openWarningModal={openWarningModal}
            setWarningModalOKCallback={setWarningModalOKCallback}
            disabled={disabled}
          />
        </Grid>
        <Grid
          className="guidelineIconsContainer"
          justifyContent="flex-end"
          alignItems="center"
          item
          xs={3}
          container
          sx={(theme) => ({ height: theme.spacing(6.8) })}
        >
          <GuidelineHelp helpUrl={props.guideline.helpUrl} />
          <ContextualSettings
            contextsExist={!!props.guidelineConfig?.contexts}
            goal={props.goal}
            guideline={props.guideline}
            guidelineIsActive={guidelineIsActive}
            guidelineConfig={props.guidelineConfig}
            openWarningModal={openWarningModal}
            performOpenEditContextsDrawer={performOpenEditContextsDrawer}
            presetWarningModalShouldAppear={presetWarningModalShouldAppear}
            readOnly={props.readOnly}
            setWarningModalOKCallback={setWarningModalOKCallback}
            targetActions={props.targetActions}
          />
        </Grid>
      </GridRow>
      <GridRow
        item
        container
        xs={12}
        sx={{ display: 'flex', alignItems: 'flex-start' }}
      >
        <SideGrid item container wrap="nowrap" xs={5}>
          <StyledFormControlLabel
            disabled={!guidelineIsActive || props.readOnly}
            control={
              <Radio
                checked={!props.termFilterChecked}
                value="domains"
                size="small"
              />
            }
            label={
              <StyledTypography variant="body2" id="aria-labelledby">
                {t('capture.labels.useTermsFromDomains')}
              </StyledTypography>
            }
            onChange={(evt) => {
              evt.persist();
              if (presetWarningModalShouldAppear) {
                setWarningModalOKCallback(() =>
                  props.radioChangeHandler.bind(null, evt)
                );
                openWarningModal();
                return;
              }

              props.radioChangeHandler(evt);
            }}
          />
        </SideGrid>
        <Grid
          item
          xs={7}
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
        >
          <Grid justifyContent="flex-start" width="auto">
            {!props.readOnly && (
              <ShowDomainMenuButton
                aria-label={t('capture.labels.selectDomains')}
                disabled={props.termFilterChecked || !guidelineIsActive}
                domainData={props.domainData}
                handleChangeDomainCheckbox={props.handleChangeDomainCheckbox}
                openWarningModal={openWarningModal}
                parameterValueObj={props.parameterValueObj}
                presetWarningModalShouldAppear={presetWarningModalShouldAppear}
                setWarningModalOKCallback={setWarningModalOKCallback}
                addIconTooltipText={t('capture.actions.tooltips.addDomains')}
              />
            )}
          </Grid>
          <Grid sx={(theme) => ({ marginTop: theme.spacing(-0.4) })}>
            <ChosenDomainsChips
              disabled={props.termFilterChecked || !guidelineIsActive}
              domainData={props.domainData}
              parameterValueObj={props.parameterValueObj}
              onDelete={props.targetActions.setGuidelineParameterValue.bind(
                null,
                props.goal,
                props.guideline
              )}
              openWarningModal={openWarningModal}
              presetWarningModalShouldAppear={presetWarningModalShouldAppear}
              readOnly={props.readOnly}
              setWarningModalOKCallback={setWarningModalOKCallback}
            />
          </Grid>
        </Grid>
      </GridRow>
      <GridRow item container xs={12}>
        <SideGrid item xs={5}>
          <StyledFormControlLabel
            disabled={props.readOnly}
            control={
              <Radio
                checked={props.termFilterChecked}
                value="filter"
                size="small"
                disabled={!guidelineIsActive}
              />
            }
            label={
              <StyledTypography variant="body2">
                {t('capture.labels.useTermsFromTermFilter')}
              </StyledTypography>
            }
            onChange={(evt) => {
              evt.persist();
              if (presetWarningModalShouldAppear) {
                setWarningModalOKCallback(() =>
                  props.radioChangeHandler.bind(null, evt)
                );
                openWarningModal();
                return;
              }

              props.radioChangeHandler(evt);
            }}
          />
        </SideGrid>
        <Grid item xs={7}>
          <CommonSelectDense
            sx={(theme) => ({ marginLeft: theme.spacing(1.4) })}
            MenuProps={{
              ...menuProps,
              id: 'termFilterSelect',
            }}
            displayEmpty
            input={<BootstrapInput />}
            value={props.parameterValueObj.termFilter}
            disabled={
              !props.termFilterChecked || !guidelineIsActive || props.readOnly
            }
            onChange={(ev) => {
              const value = ev.target.value as string;
              function onChangeHandler() {
                props.targetActions.setGuidelineParameterValue(
                  props.goal,
                  props.guideline,
                  JSON.stringify({
                    ...props.parameterValueObj,
                    termFilter: value,
                  })
                );
              }

              if (presetWarningModalShouldAppear) {
                setWarningModalOKCallback(() => onChangeHandler);
                openWarningModal();
                return;
              }

              onChangeHandler();
            }}
            onClick={(ev) => ev.stopPropagation()}
            inputProps={{
              'aria-label': props.parameterValueObj.termFilter
                ? props.parameterValueObj.termFilter.replace(
                    TERM_FILTER_PREFIX,
                    ''
                  )
                : t('capture.labels.selectTermFilter'),
              disabled: !props.termFilterChecked,
            }}
          >
            <CommonMenuItem
              className="Do-not-expand"
              key="default_option"
              value=""
              text={t('capture.labels.selectTermFilter')}
            >
              {t('capture.labels.selectTermFilter')}
            </CommonMenuItem>
            {props.termFilterData.map((filterObj) => (
              <CommonMenuItem
                className="Do-not-expand"
                key={filterObj.internalId}
                value={filterObj.internalId}
                text={filterObj.displayName}
              >
                {filterObj.displayName}
              </CommonMenuItem>
            ))}
          </CommonSelectDense>
        </Grid>
      </GridRow>
      <GridRow
        container
        sx={(theme) => ({
          paddingTop: theme.spacing(2),
        })}
      >
        <Grid
          sx={(theme) => ({ paddingLeft: theme.spacing(7) })}
          item
          container
        >
          <AdvancedSettings
            disabled={!guidelineIsActive}
            expandedGoals={props.expandedGoals}
            parameterValueObj={props.parameterValueObj}
            preserveChanges={(evt) => {
              const value = evt.target.value as string;
              function onChangeHandler() {
                props.targetActions.setGuidelineParameterValue(
                  props.goal,
                  props.guideline,
                  JSON.stringify({
                    ...props.parameterValueObj,
                    scoreAdmittedTerms: value[0] === '1',
                    suggestAdmittedTerms: value[1] === '1',
                    markAdmittedTerms: value[2] === '1',
                  })
                );
              }

              if (presetWarningModalShouldAppear) {
                setWarningModalOKCallback(() => onChangeHandler);
                openWarningModal();
                return;
              }

              onChangeHandler();
            }}
            readOnly={props.readOnly}
          />
        </Grid>
      </GridRow>
    </Grid>
  );
}

export default memo(TerminologyGuideline, isEqual);
