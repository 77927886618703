import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import customFocus from 'styles/customFocus';
import CommonTooltip from '../CommonTooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Grid, Typography, TypographyProps } from '@mui/material';
import { ElementType } from 'react';

export const BackIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(3),
  '&:focus, &:hover, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
    '& svg': {
      color: theme.palette.common.black,
    },
  },
  '&.MuiIconButton-root': {
    padding: 0,
    marginRight: theme.spacing(3),
  },
}));

export const StyledLink = styled(Link)(() => ({
  '&:focus': {
    outline: 'none',
  },
}));

export const DetailsSection = styled('section')(({ theme }) => ({
  overflowY: 'scroll',
}));

export const StyledEditButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
  '&:focus, &:hover, &.Mui-focusVisible': {
    ...customFocus(theme),
    color: theme.palette.primary.main,
  },
}));

export const StyledBackIconButtonTooltip = styled(CommonTooltip)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(-1),
      },
    },
  })
);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(-2),
  marginTop: theme.spacing(-2),
}));

export const StyledReplacementTitle = styled(Typography)<
  TypographyProps<ElementType, { component?: ElementType }>
>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(6),
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.18),
}));
