import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { GoalId, TargetNew } from 'sections/Targets/pages/TargetPage/types';
import { MAX_TARGET_DESCRIPTION_LENGTH } from 'sections/Targets/pages/TargetPage/constants';
import { useSet } from 'utils/utils';
import {
  CreateOrEditTargetDrawerHooksProps,
  CreateOrEditTargetDrawerHooksReturn,
} from './types';
import { getOriginalSelectedGoals } from './helpers';

import { LanguageFull } from 'types/types';
import { MAX_TARGET_NAME_LENGTH } from '../../constants';

export function useInnerDrawer({
  close,
  submit,
  target,
  targetsNames,
}: CreateOrEditTargetDrawerHooksProps): CreateOrEditTargetDrawerHooksReturn {
  const history = useHistory();
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>(
    target?.languageId ?? ''
  );
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageFull[]>([]);

  const [modifiedByUser, setModifiedByUser] = useState<boolean>(false);

  const [displayName, setDisplayName] = useState<string>(
    target?.displayName ?? ''
  );
  const [description, setDescription] = useState<string>(
    target?.description ?? ''
  );

  const [nameRequired, setNameRequired] = useState<boolean>(false);
  const [repeatedName, setRepeatedName] = useState<boolean>(false);
  const [tooLongName, setTooLongName] = useState<boolean>(false);
  const [originalSelectedGoals, setOriginalSelectedGoals] = useState<
    Set<GoalId>
  >(getOriginalSelectedGoals(target));
  const selectedGoals = useSet(originalSelectedGoals);

  useEffect(() => {
    if (modifiedByUser) {
      setNameRequired(!displayName.trim());
      setTooLongName(displayName.trim().length > MAX_TARGET_NAME_LENGTH);
    }
    if (targetsNames) {
      setRepeatedName(targetsNames.includes(displayName.trim()));
    }
  }, [displayName, modifiedByUser, targetsNames]);

  useEffect(() => {
    if (target && !originalSelectedGoals.size) {
      const originalGoals = getOriginalSelectedGoals(target);
      setOriginalSelectedGoals(originalGoals);
      selectedGoals.setSet(originalGoals);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [originalSelectedGoals.size, target]);

  useEffect(() => {
    setSelectedLanguageId(target?.languageId ?? '');
    setDisplayName(target?.displayName ?? '');
    setDescription(target?.description ?? '');
    const originalGoals = getOriginalSelectedGoals(target);
    setOriginalSelectedGoals(originalGoals);
    selectedGoals.setSet(originalGoals);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [target]);

  useEffect(() => {
    if (selectedLanguage.length) {
      setSelectedLanguageId(selectedLanguage[0].languageId);
    }
  }, [selectedLanguage]);

  const goals = selectedLanguage.length
    ? (selectedLanguage as [LanguageFull])[0].languageGoalInfos
    : [];

  function changeLanguage() {
    selectedGoals.setSet(new Set());
  }

  const nameIsValid = displayName.trim().length > 0;
  const descriptionIsValid =
    description.length <= MAX_TARGET_DESCRIPTION_LENGTH;

  async function onSubmit() {
    const newTarget: TargetNew = {
      displayName,
      description,
      languageId: selectedLanguageId,
      goalIds: [...selectedGoals.set],
    };
    await submit(newTarget).then<void>((t): void => {
      if (t) {
        history.push('/targets/' + t.uuid);
      }
    });
    close();
  }

  function onCancel() {
    setDisplayName(target?.displayName ?? '');
    setDescription(target?.description ?? '');
    setSelectedLanguageId(target?.languageId ?? '');
    const originalGoals = getOriginalSelectedGoals(target);
    setOriginalSelectedGoals(originalGoals);
    selectedGoals.setSet(originalGoals);
    close();
  }

  const languagesDisabled = !!target;
  const goalSelectionIsValid = selectedGoals.set.size > 0;

  const targetIsModified = target
    ? target.description !== description ||
      target.displayName !== displayName ||
      !isEqual(selectedGoals.set, originalSelectedGoals)
    : true; // new target is by default modified as language is set to default

  const targetIsValid =
    nameIsValid &&
    !repeatedName &&
    goalSelectionIsValid &&
    descriptionIsValid &&
    !tooLongName;

  return {
    changeLanguage,
    created: target?.created || null,
    description,
    descriptionIsValid,
    displayName,
    goals,
    goalSelectionIsValid,
    languagesDisabled,
    modified: target?.modified || null,
    nameRequired,
    onCancel,
    onSubmit,
    repeatedName,
    selectedGoals,
    selectedLanguageId,
    setDescription,
    setDisplayName,
    setModifiedByUser,
    setSelectedLanguage,
    setSelectedLanguageId,
    targetIsModified,
    targetIsValid,
    tooLongName,
  };
}
