import React, { ForwardedRef } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CommonTooltip from '../CommonTooltip';
import { useOverflowText } from 'hooks/useOverflowText';

interface CommonMenuItemProps extends MenuItemProps {
  type?: 'normal' | 'sub';
  text?: string;
}

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<CommonMenuItemProps>(({ theme, disabled }) => ({
  padding: theme.spacing(1.8, 1.8, 2, 2),
  letterSpacing: theme.typography.pxToRem(0.28),
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: disabled
        ? 'transparent'
        : theme.palette.custom.brightGray,
    },
    '& p': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  '&:hover, &:focus': {
    backgroundColor: disabled ? 'transparent' : theme.palette.custom.brightGray,
  },
}));

const StyledMenuSubItem = styled(StyledMenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<CommonMenuItemProps>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.custom.marcia : theme.palette.common.black,
  opacity: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

function CommonMenuItem(
  { children, text, type = 'normal', ...props }: CommonMenuItemProps,
  ref: ForwardedRef<HTMLLIElement>
) {
  const { setElementRef, isOverflowed } = useOverflowText();

  const MenuItemComponent =
    type === 'normal' ? StyledMenuItem : StyledMenuSubItem;

  return (
    <MenuItemComponent
      ref={ref}
      {...props}
      {...(props.disabled && {
        onClick: (evt) => {
          evt.stopPropagation();
        },
      })}
    >
      <CommonTooltip
        placement="top"
        title={<>{text || children}</>}
        disableHoverListener={!isOverflowed}
      >
        <StyledTypography
          variant="body2"
          ref={(node: HTMLSpanElement | null) => {
            node && setElementRef(node);
          }}
          disabled={props.disabled}
        >
          {children}
        </StyledTypography>
      </CommonTooltip>
    </MenuItemComponent>
  );
}

export default React.forwardRef<HTMLLIElement, CommonMenuItemProps>(
  CommonMenuItem
);
