import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material';

// styling functions
export const iconSx: SxProps<Theme> = function (theme: Theme) {
  return { fontSize: theme.typography.pxToRem(22) };
};

export const staticIconSx: SxProps<Theme> = function (theme: Theme) {
  return {
    fontSize: theme.typography.pxToRem(22),
    marginRight: theme.spacing(2),
  };
};

export const buttonSx: SxProps<Theme> = function (theme: Theme) {
  return { marginRight: theme.spacing(3 / 5) };
};
