import React, { useCallback, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CommonSelectStandard from 'components/CommonSelectStandard';
import GeneratePasswordDialog from '../GeneratePasswordDialog';
import LicenseTypeInput from '../LicenseTypeInput';
import PrivilegesContext from 'contexts/PrivilegesContext';
import FeaturesContext from 'contexts/FeaturesContext';
import { useFetch } from 'utils/fetch';
import {
  USER_AND_ROLES_EDIT_USER,
  USER_AND_ROLES_READ_USER,
} from 'constants/privileges';
import { User } from 'sections/Users/types';
import { canGenerateNewPassword } from './utils';
import {
  StyledCommonMenuItem,
  StyledFilledTextField,
  StyledLabel,
  StyledTitle,
  StyledValue,
} from './styles';

import { useError } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import { ApiError } from 'errors/ApiError';
import CurrentUserContext from 'contexts/CurrentUserContext';
import { FormAction } from '../../utils';

type UserUpdateBody = {
  fullName?: string;
  customFields?: ReadonlyArray<{ key: string; value: string }>;
};

type Props = {
  user: User;
  dispatch: React.Dispatch<FormAction>;
  editingAllowed: boolean;
};

export default function GeneralInfo({
  user,
  dispatch,
  editingAllowed,
}: Readonly<Props>) {
  const { t } = useTranslation();
  const { username, idpUser } = user;
  const currentUser = useContext(CurrentUserContext);
  const userPrivileges = useContext(PrivilegesContext);
  const features = useContext(FeaturesContext);
  const http = useFetch();
  const { setError } = useError();
  const [resetState, setResetState] = useState(user);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeValue = useCallback(
    async (name: keyof User, customFields?: User['customFields']) => {
      let updateBody: UserUpdateBody = { [name]: user[name] };
      const updatedCustomFields = customFields ?? user.customFields;

      if (name === 'customFields' && updatedCustomFields.length) {
        updateBody = {
          customFields: updatedCustomFields
            .filter(({ value, inputType, type }) => {
              // If required it can't have "" value and should be removed from output
              if (inputType === 'required' && !value) {
                return false;
              }

              // If read-only it can't be in output
              return inputType !== 'externallyProvided';
            })
            .map(({ key, value }) => ({
              key,
              value,
            })),
        };
      }

      try {
        const updatedUser = await http.put<{ data: User }, UserUpdateBody>(
          `/api/v1/user/${user.id}`,
          updateBody
        );

        // Display notification
        switch (name) {
          case 'fullName':
            enqueueSnackbar(t('user.notification.fullNameUpdatedSuccessfully'));
            break;

          case 'customFields':
            enqueueSnackbar(
              t('users.notification.customFieldUpdatedSuccessfully')
            );
            break;
        }

        setResetState(updatedUser.data);
      } catch (error: unknown) {
        setError({
          value: error as ApiError,
          origin: 'setCustomField.userDetails',
        });
        dispatch({ type: 'RESET', payload: resetState });
      }
    },
    [enqueueSnackbar, user, dispatch, t, resetState, http, setError]
  );

  const isFieldEditable =
    userPrivileges.includes(USER_AND_ROLES_EDIT_USER) ||
    (!userPrivileges.includes(USER_AND_ROLES_EDIT_USER) &&
      userPrivileges.includes(USER_AND_ROLES_READ_USER) &&
      !!currentUser?.username &&
      currentUser.username === username);

  return (
    <form autoComplete="off">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box mr={10} width={300}>
          <StyledTitle variant="h2" mt={31 / 5} mb={4}>
            {t('users.columns.details')}
          </StyledTitle>
          <Box mb={4} tabIndex={0}>
            <StyledLabel>{t('users.labels.username')}</StyledLabel>
            <StyledValue>{username}</StyledValue>
          </Box>
          <Box>
            {(isFieldEditable && !idpUser && (
              <StyledFilledTextField
                label={t('users.labels.fullName')}
                value={user.fullName}
                helperText={
                  (user.fullName?.length === 254 &&
                    t('users.hints.maxCharacterLengthReached')) ||
                  t('users.hints.optional')
                }
                inputProps={{ maxLength: 254 }}
                hasCharacterCounter
                onChange={(event) => {
                  dispatch({
                    type: 'SET_FIELD_VALUE',
                    payload: {
                      fieldName: 'fullName',
                      fieldValue: event.target.value,
                    },
                  });
                }}
                onBlur={() =>
                  resetState.fullName !== user.fullName &&
                  handleChangeValue('fullName')
                }
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    event.target &&
                    resetState.fullName !== user.fullName
                  ) {
                    handleChangeValue('fullName');
                  }
                }}
              />
            )) || (
              <Box tabIndex={0}>
                <StyledLabel>{t('users.labels.fullName')}</StyledLabel>
                <StyledValue>
                  {user.fullName ? (
                    user.fullName
                  ) : (
                    <em>{t('users.fields.none')}</em>
                  )}
                </StyledValue>
              </Box>
            )}
          </Box>
          {canGenerateNewPassword(editingAllowed, username, features) && (
            <Box mt={22 / 5}>
              <GeneratePasswordDialog
                userId={user.id}
                username={username}
                onClose={(ok) => {
                  if (ok) {
                    // Display notification on success
                    enqueueSnackbar(
                      t('user.notification.passwordSavedSuccessfully'),
                      {
                        snackbarContentProps: {
                          role: 'status',
                        },
                      }
                    );
                  }
                }}
              />
            </Box>
          )}
        </Box>
        <Box mr={10} width={300}>
          <StyledTitle variant="h2" mt={31 / 5} mb={4}>
            {t('users.columns.userInformation')}
          </StyledTitle>
          {user.customFields?.map(
            ({ key, displayName, inputType, value, ...rest }, index) => {
              const elementId = `input-customField-${encodeURIComponent(key)}`;

              if (!isFieldEditable || inputType === 'externallyProvided') {
                return (
                  <Box key={elementId} mb={4} tabIndex={0}>
                    <StyledLabel>{displayName}</StyledLabel>
                    <StyledValue>
                      {value ? value : <em>{t('users.fields.none')}</em>}
                    </StyledValue>
                  </Box>
                );
              }

              if (!rest.type) {
                return <></>;
              }

              return (
                <Box key={elementId} mb={4}>
                  {rest.type === 'text' && (
                    <StyledFilledTextField
                      value={value}
                      label={displayName}
                      InputLabelProps={{ required: false }} // Removes the asterisk in the label from required fields
                      inputProps={{ maxLength: 254 }}
                      hasCharacterCounter
                      required={inputType === 'required'}
                      error={value.length === 0 && inputType === 'required'}
                      helperText={
                        (value.length === 254 &&
                          t('users.hints.maxCharacterLengthReached')) ||
                        (value.length === 0 &&
                          inputType === 'required' &&
                          t('users.hints.requiredInput'))
                      }
                      id={elementId}
                      onChange={(event) => {
                        dispatch({
                          type: 'SET_CUSTOM_FIELD_VALUE',
                          payload: {
                            fieldName: key,
                            fieldValue: event.target.value,
                          },
                        });
                      }}
                      onBlur={() => {
                        if (inputType === 'required' && value === '') {
                          dispatch({
                            type: 'SET_CUSTOM_FIELD_VALUE',
                            payload: {
                              fieldName: key,
                              fieldValue: resetState.customFields[index]
                                ?.value as string,
                            },
                          });
                        }
                        if (
                          resetState.customFields[index].value !==
                            user.customFields[index]?.value &&
                          !(inputType === 'required' && value === '')
                        ) {
                          handleChangeValue('customFields');
                        }
                      }}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (
                          event.key === 'Enter' &&
                          event.target &&
                          resetState.customFields[index].value !==
                            user.customFields[index]?.value &&
                          !(inputType === 'required' && value === '')
                        ) {
                          handleChangeValue('customFields');
                        }
                      }}
                    />
                  )}
                  {rest.type === 'list' && (
                    <CommonSelectStandard
                      id={elementId}
                      label={displayName}
                      value={value}
                      sx={{ width: 300 }}
                      onChange={(event) => {
                        const fieldValue = event.target.value as string;
                        dispatch({
                          type: 'SET_CUSTOM_FIELD_VALUE',
                          payload: {
                            fieldName: key,
                            fieldValue,
                          },
                        });
                        handleChangeValue(
                          'customFields',
                          user.customFields.map((customField) =>
                            customField.key === key
                              ? { ...customField, value: fieldValue }
                              : customField
                          )
                        );
                      }}
                      displayTooltipForLongLabels
                    >
                      {inputType !== 'required' && (
                        <StyledCommonMenuItem value="">
                          <em>{t('users.fields.none')}</em>
                        </StyledCommonMenuItem>
                      )}
                      {rest.possibleValues?.map((option) => (
                        <StyledCommonMenuItem value={option} key={option}>
                          {option}
                        </StyledCommonMenuItem>
                      ))}
                    </CommonSelectStandard>
                  )}
                </Box>
              );
            }
          )}
        </Box>
        <Box pt={5}>
          <LicenseTypeInput
            user={user}
            isEditingAllowed={isFieldEditable}
            dispatch={dispatch}
          />
        </Box>
      </Box>
    </form>
  );
}
