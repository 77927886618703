import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Grid from '@mui/material/Grid';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  marginLeft: theme.spacing(4),
}));

export const StyledUploadIcon = styled(FileUploadOutlinedIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

export const StyledDownloadIcon = styled(FileDownloadOutlinedIcon)(
  ({ theme }) => ({
    fill: theme.palette.primary.main,
  })
);

export const StyledButtonTab = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
