import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export const ClusterRow = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 0.6),
  borderRadius: '6px',
  '& button': {
    padding: theme.spacing(0.6),
    '& svg': {
      opacity: 0,
    },
  },
  '&:focus-within, &:hover': {
    backgroundColor: theme.palette.primary.light,
    '& button': {
      '&:hover, &:focus': {
        backgroundColor: theme.palette.background.paper,
      },
      '& svg': {
        opacity: 1,
      },
    },
  },
}));

export const ReplacementText = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  paddingTop: theme.spacing(1.2),
  letterSpacing: theme.typography.pxToRem(0.14),
  '& p': {
    margin: 0,
    padding: 0,
  },
}));

export const StyledReadonlyReplacement = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1.4),
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  '&:hover, & p:hover, & p:focus': {
    outline: 'none',
    textDecoration: 'underline',
  },
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
  marginLeft: theme.spacing(0.6),
}));

export const DeleteButton = styled(EditButton)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.primary.main,
    '&:hover': {
      fill: theme.palette.error.main,
    },
  },
}));
