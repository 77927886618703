import React from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import CommonErrorComponent from 'components/CommonErrorComponent';
import { ReactComponent as EmptyUserListErrorImage } from './emptyUserList.svg';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(6),
}));

export interface InnerEmptyUserListErrorPageContentProps {
  sx?: BoxProps['sx'];
}

export default function InnerEmptyUserListErrorPageContent({
  sx,
}: InnerEmptyUserListErrorPageContentProps) {
  return (
    <StyledBox width={1} textAlign="center" sx={sx}>
      <CommonErrorComponent
        ErrorImage={EmptyUserListErrorImage}
        errorTitle="users.emptyUserListErrorPage.title"
        errorDescription="users.emptyUserListErrorPage.description"
      />
    </StyledBox>
  );
}
