import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));
