import React, { memo, forwardRef } from 'react';
import clsx from 'clsx';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ComponentClasses = {
  SnackbarContainer: 'notistack-SnackbarContainer',
  Snackbar: 'notistack-Snackbar',
  CollapseWrapper: 'notistack-CollapseWrapper',
  MuiContent: 'notistack-MuiContent',
};

const classes = {
  lessPadding: {},
  default: {},
  polite: {},
  success: {},
  error: {},
  warning: {},
  info: {},
};

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.custom.nightSky,
  boxShadow: '0px 3px 6px #00000080',
  borderRadius: '6px',
  opacity: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const MessageBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  lineHeight: '18px',
  color: theme.palette.common.white,
  padding: theme.spacing(3),
}));

interface NotistackThemeResponsiveSnackbarProps extends CustomContentProps {
  snackbarContentProps?: React.HTMLAttributes<HTMLDivElement>;
}

const NotistackThemeResponsiveSnackbar = forwardRef<
  HTMLDivElement,
  NotistackThemeResponsiveSnackbarProps
>((props, forwardedRef) => {
  const {
    id,
    message,
    action: componentOrFunctionAction,
    iconVariant,
    variant,
    hideIconVariant,
    style,
    snackbarContentProps,
  } = props;

  const icon = iconVariant[variant];

  let action = componentOrFunctionAction;
  if (typeof action === 'function') {
    action = action(id);
  }

  return (
    <StyledSnackbarContent
      ref={forwardedRef}
      role="alert"
      style={style}
      className={clsx(
        ComponentClasses.MuiContent,
        { [classes.lessPadding as string]: !hideIconVariant && icon },
        classes[variant]
      )}
      {...snackbarContentProps}
    >
      <MessageBox id="notistack-snackbar">
        {!hideIconVariant ? icon : null}
        {message}
      </MessageBox>
      {action && <Box p={8 / 5}>{action}</Box>}
    </StyledSnackbarContent>
  );
});

NotistackThemeResponsiveSnackbar.displayName =
  'NotistackThemeResponsiveSnackbar';

export default memo(NotistackThemeResponsiveSnackbar);
