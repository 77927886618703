import React from 'react';
import FormControl from '@mui/material/FormControl';
import { InputBaseProps } from '@mui/material/InputBase';
import { ErrorText, StyledInputBase, UnderText } from './components';

export interface CommonFilledDenseTextFieldProps extends InputBaseProps {
  errorText?: string;
  underText?: string;
  uniqueAriaKey?: string;
}

export default function CommonFilledDenseTextField({
  errorText,
  underText,
  uniqueAriaKey = '',
  ...props
}: CommonFilledDenseTextFieldProps) {
  const ariaId = `field-description${uniqueAriaKey}`;

  return (
    <>
      <FormControl fullWidth={props.fullWidth}>
        <StyledInputBase
          {...props}
          inputProps={{
            'aria-label': props['aria-label'] || props.placeholder,
            ...props.inputProps,
          }}
        />
      </FormControl>
      {errorText && <ErrorText id={ariaId}>{errorText}</ErrorText>}
      {underText && !errorText && <UnderText>{underText}</UnderText>}
    </>
  );
}
