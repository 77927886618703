import { TerminologyGuidelineParameterValueObject } from '../TerminologyGuideline/hooks';
import { compareParameterValueObjects } from '../../helpers';
import { EnumContentObject } from 'types/types';

export function createFromAndToForStrings(
  originalParameterValue = '',
  parameterValue = ''
): { from: string; to: string } {
  return {
    from: originalParameterValue,
    to: parameterValue,
  };
}

export function findEnumContentNameById(
  enumContent: EnumContentObject[],
  id: string
) {
  return enumContent?.find(({ internalId }) => internalId === id)?.displayName;
}

export function createFromAndTo(
  originalParameterValue: TerminologyGuidelineParameterValueObject,
  parameterValue: TerminologyGuidelineParameterValueObject,
  noDomainOrFilterText: string,
  enumContent: EnumContentObject[]
): { from: string; to: string } {
  let from = '';
  let to = '';

  const diff: string[] = compareParameterValueObjects(
    originalParameterValue,
    parameterValue
  );

  const originalDomains = originalParameterValue.domainNames?.map((domainId) =>
    findEnumContentNameById(enumContent, domainId)
  );
  const newDomains = parameterValue?.domainNames?.map((domainId) =>
    findEnumContentNameById(enumContent, domainId)
  );
  const originalFilter = findEnumContentNameById(
    enumContent,
    originalParameterValue?.termFilter
  ) as string;
  const newFilter = findEnumContentNameById(
    enumContent,
    parameterValue?.termFilter
  ) as string;

  if (
    diff.includes('useTermFilter') &&
    !originalParameterValue.useTermFilter &&
    parameterValue?.useTermFilter
  ) {
    from = originalParameterValue.domainNames?.length
      ? originalDomains.join(', ')
      : noDomainOrFilterText;
    to = parameterValue?.termFilter ? newFilter : noDomainOrFilterText;
  }

  if (
    diff.includes('useTermFilter') &&
    originalParameterValue.useTermFilter &&
    !parameterValue.useTermFilter
  ) {
    from = originalParameterValue.termFilter
      ? originalFilter
      : noDomainOrFilterText;
    to = parameterValue.domainNames?.length
      ? newDomains.join(', ')
      : noDomainOrFilterText;
  }

  if (!diff.includes('useTermFilter') && diff.includes('domainNames')) {
    from = originalParameterValue.domainNames?.length
      ? originalDomains.join(', ')
      : noDomainOrFilterText;
    to = parameterValue?.domainNames?.length
      ? newDomains.join(', ')
      : noDomainOrFilterText;
  }

  if (!diff.includes('useTermFilter') && diff.includes('termFilter')) {
    from = originalParameterValue?.termFilter
      ? originalFilter
      : noDomainOrFilterText;
    to = parameterValue?.termFilter ? newFilter : noDomainOrFilterText;
  }

  return { from, to };
}
