import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SelectTypeA from 'components/SelectTypeA';

export const SearchByTextInputStyledClearIcon = styled(SearchIcon)(
  ({ theme }) => ({
    color: theme.palette.grey[600],
  })
);

export const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const StyledSelectTypeA = styled(SelectTypeA)(({ theme }) => ({
  width: theme.spacing(13),
}));

export const ExportIcon = styled(SaveAltIcon)(() => ({
  transform: 'scale(0.889)',
}));
