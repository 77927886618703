import range from 'lodash/range';
import { useTranslation } from 'react-i18next';

const fromCharCodeToString = (start: number, end: number) =>
  range(start, end)
    .map((x) => String.fromCharCode(x))
    .join('');

export const generatePassword = () => {
  const UPPERCASE_LETTERS = fromCharCodeToString(65, 91);
  const LOWERCASE_LETTERS = fromCharCodeToString(97, 123);
  const DIGITS = fromCharCodeToString(48, 58);
  const SPECIAL_SYMBOLS = range(33, 48)
    .concat(range(58, 65))
    .concat(range(91, 97))
    .concat(range(123, 127))
    .map((x) => String.fromCharCode(x))
    .join('');

  const chars = [
    UPPERCASE_LETTERS,
    LOWERCASE_LETTERS,
    DIGITS,
    SPECIAL_SYMBOLS,
    UPPERCASE_LETTERS + LOWERCASE_LETTERS + DIGITS + SPECIAL_SYMBOLS,
  ];

  return [1, 1, 1, 1, 6]
    .map((length, index) =>
      Array(length)
        .fill(chars[index])
        .map(
          (x) =>
            x[
              Math.floor(
                (window.crypto.getRandomValues(new Uint32Array(1))[0] /
                  2 ** 32) *
                  x.length
              )
            ]
        )
        .join('')
    )
    .concat()
    .join('');
};

export const useLicenseTypeOptions = (isEditingAllowed = true) => {
  const { t } = useTranslation();

  return [
    {
      key: 'named',
      label: t('users.labels.licenseNamedUser'),
      value: 'named',
      disabled: !isEditingAllowed,
    },
    {
      key: 'concurrent',
      label: t('users.labels.licenseConcurrentUser'),
      value: 'concurrent',
      disabled: !isEditingAllowed,
    },
  ];
};
