import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CommonOutlinedMultilineTextFieldWithInnerIcons from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextFieldWithInnerIcons';
import Box from '@mui/system/Box';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.18),
  padding: 0,
  fontWeight: 400,
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.primary,
  width: 400,
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledOutlinedMultilineTextField = styled(
  CommonOutlinedMultilineTextFieldWithInnerIcons
)(() => ({
  '& .MuiIconButton-root': {
    opacity: 0,
  },
  '&:hover, &:focus-within': {
    '.MuiIconButton-root': {
      opacity: 1,
    },
  },
}));
