/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import orderBy from 'lodash/orderBy';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

//TODO cLAUS BE revert unsecure cors authorization

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export function revertSortOrder(sortOrder: SortOrder) {
  return sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
}

function lowerCaseGetter<T>(key: keyof T) {
  return (el: T) => {
    const value = el[key];
    return typeof value === 'string' ? value.toLowerCase() : value;
  };
}

export function sortBy<T>(
  array: T[],
  field: keyof T,
  order: SortOrder,
  secondarySortField: keyof T
) {
  return orderBy(
    array,
    [lowerCaseGetter(field), lowerCaseGetter(secondarySortField)],
    [order, SortOrder.asc]
  );
}

export type UseSetResult<K = string> = {
  set: Set<K>;
  setKey: (key: K, isIn: boolean) => void;
  setSet: Dispatch<SetStateAction<Set<K>>>;
};

export function useSet<K = string>(
  initialSet?: Set<K> | undefined
): UseSetResult<K> {
  const [set, setSet] = useState<Set<K>>(initialSet || new Set<K>());

  function setKey(key: K, isIn: boolean) {
    setSet((oldSet) => {
      const clonedSet = new Set([...oldSet]);
      if (isIn) {
        clonedSet.add(key);
      } else {
        clonedSet.delete(key);
      }
      return clonedSet;
    });
  }

  return { set, setKey, setSet };
}

export function findById<T extends { id: string }>(
  array: T[],
  id: string
): T | undefined {
  return array.find((it) => it.id === id);
}

export interface SelectOption {
  id: string;
  displayName: string;
}

export function pluralize(englishSingular: string, number: number) {
  return number === 1 ? englishSingular : englishSingular + 's';
}

export function pathWithId(path: string, id: string) {
  return path.replace(':id', id);
}

// See: https://github.com/microsoft/TypeScript/issues/17002
export const isReadonlyArray = (value: any): value is readonly unknown[] =>
  Array.isArray(value);

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
