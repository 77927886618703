import React, { ForwardedRef, forwardRef } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const StyledWarningIcon = styled(WarningRoundedIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}));

function WarningIconComponent(
  props: SvgIconProps,
  ref: ForwardedRef<SVGSVGElement> | null | undefined
) {
  return (
    <StyledWarningIcon
      sx={(theme) => ({ fontSize: theme.typography.pxToRem(22.6) })}
      {...props}
      color="warning"
      ref={ref}
    />
  );
}

export default forwardRef(WarningIconComponent);
