import React, { ReactElement, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  MainPageLayoutComponent,
  PageHeaderBox,
  MainPageMainBox,
  StyledHelpIcon,
} from './components';
import CommonTooltip from '../CommonTooltip';

export interface PageLayoutProps {
  children: React.ReactElement | React.ReactElement[];
  focusHeading?: boolean;
  heading?: string;
  headerRightSideContent?: ReactElement | null;
  helpIconText?: string;
}

export default function UnifiedMainPageLayout({
  children,
  focusHeading,
  heading,
  headerRightSideContent,
  helpIconText,
}: Readonly<PageLayoutProps>) {
  const headingRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusHeading) {
      headingRef.current?.focus();
    }
  }, [focusHeading]);

  return (
    <MainPageLayoutComponent>
      {heading && (
        <PageHeaderBox>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Typography
              variant="h1"
              ref={headingRef}
              tabIndex={-1}
              sx={(theme) => ({
                paddingRight: theme.spacing(0.6),
                letterSpacing: theme.typography.pxToRem(0.24),
                '&:focus': {
                  outline: 'none',
                },
              })}
            >
              {heading}
            </Typography>
            {helpIconText && (
              <CommonTooltip
                title={helpIconText}
                type="yellow"
                placement="right-start"
              >
                <StyledHelpIcon tabIndex={0} aria-hidden={false} />
              </CommonTooltip>
            )}
          </Box>
          {headerRightSideContent}
        </PageHeaderBox>
      )}
      <MainPageMainBox>{children}</MainPageMainBox>
    </MainPageLayoutComponent>
  );
}
