import React, { ReactElement, useEffect, useRef, useState } from 'react';
import CommonTooltip from 'components/CommonTooltip';
import { User } from 'sections/Users/types';
import { LongRolesBox, MoreRolesBox, TooltipRolesList } from './styles';

interface Props {
  roles: User['roles'];
}

export default function RolesTableCellValue({
  roles = [],
}: Props): ReactElement {
  const sortedRoles = [...roles].sort((roleA, roleB) =>
    roleA.name.localeCompare(roleB.name)
  );

  const ref = useRef<HTMLElement>(null);
  const [displayValue, setDisplayValue] = useState('contents');

  useEffect(() => {
    if (
      displayValue === 'contents' &&
      ref?.current &&
      ref.current.scrollWidth > ref.current.clientWidth
    ) {
      setDisplayValue('initial');
    }
  }, [displayValue]);

  return (
    <LongRolesBox display={'flex'} flexDirection={'row'} {...{ ref }}>
      <LongRolesBox
        flexGrow={1}
        display={displayValue}
        {...(displayValue === 'initial' && {
          overflow: 'hidden',
        })}
      >
        <LongRolesBox sx={{ display: 'contents' }}>
          {sortedRoles[0].name}
        </LongRolesBox>
        <span className={'visually-hidden'}>
          {sortedRoles
            .slice(1)
            .map(({ name }) => name)
            .join(', ')}
        </span>
      </LongRolesBox>
      {sortedRoles.length > 1 && (
        <CommonTooltip
          type="yellow"
          title={
            sortedRoles.length === 2 ? (
              sortedRoles[1].name
            ) : (
              <TooltipRolesList>
                {sortedRoles.slice(1).map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </TooltipRolesList>
            )
          }
          placement="bottom-start"
        >
          <MoreRolesBox
            component={'span'}
            ml={4 / 5}
            px={6 / 5}
            aria-hidden={true}
          >
            +{roles.length - 1}
          </MoreRolesBox>
        </CommonTooltip>
      )}
    </LongRolesBox>
  );
}
