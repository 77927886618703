import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export type { CheckboxProps };

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.main,
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.Mui-disabled, &[aria-disabled="true"]': {
    color: theme.palette.disabled.primary,
  },
}));

export default function CommonCheckbox(props: CheckboxProps) {
  return <StyledCheckbox disableRipple {...props} />;
}
