import { GeneralParameterProps } from './Parameter';
import { GuidelineVoiceParameter } from '../../types';

export function parseNumberParameter(
  value: string,
  oldValue: string | number
): string | number {
  if (value === '') {
    return '';
  } else {
    const parsedInputValue = parseInt(value);
    if (isNaN(parsedInputValue)) {
      return oldValue;
    } else {
      return parsedInputValue;
    }
  }
}

export function createUpdate(
  props: GeneralParameterProps,
  validatedValue: string
) {
  function onChangeHandler() {
    props.targetActions.setGuidelineParameterValue(
      props.goal,
      props.guideline,
      validatedValue
    );
  }

  if (props.presetWarningModalShouldAppear) {
    props.setWarningModalOKCallback(() => onChangeHandler);
    props.openWarningModal();
    return;
  }

  onChangeHandler();
}

export function validateAgainstRange(
  parameterObj: GuidelineVoiceParameter,
  parsedValue: string | number | [number, number]
): string | [number, number] {
  if (typeof parsedValue === 'number') {
    if (parsedValue < parameterObj?.rangeMin) {
      return parameterObj?.rangeMin.toString();
    }

    if (parsedValue > parameterObj?.rangeMax) {
      return parameterObj?.rangeMax.toString();
    }
  }

  if (Array.isArray(parsedValue)) {
    const newValue = [...(parsedValue as [number, number])];

    if (parsedValue[0] < parameterObj?.rangeMin) {
      newValue[0] = parameterObj?.rangeMin;
    }
    if (parsedValue[0] > parameterObj?.rangeMax) {
      newValue[0] = parameterObj?.rangeMax;
    }

    if (parsedValue[1] < parameterObj?.rangeMin) {
      newValue[1] = parameterObj?.rangeMin;
    }
    if (parsedValue[1] > parameterObj?.rangeMax) {
      newValue[1] = parameterObj?.rangeMax;
    }

    return newValue as [number, number];
  }

  return parsedValue.toString();
}
