import React, { ReactElement } from 'react';
import { ReactComponent as SVG } from './GoalColor.svg';
import { styled } from '@mui/material/styles';

const ColorWrapper = styled('span')(({ theme }) => ({
  display: 'block',
  height: theme.spacing(8),
}));

function GoalColorSvg(props: { color: string }): ReactElement {
  return (
    <ColorWrapper>
      <SVG
        role="img"
        aria-hidden="true"
        style={{ fill: props.color, height: '1rem', width: '1rem' }}
      />
    </ColorWrapper>
  );
}

export default GoalColorSvg;
