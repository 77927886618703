import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

import CommonTooltip from 'components/CommonTooltip';
import { ActionIconsWrapper } from 'components/IndexTable/components';
import { TargetIndexItem } from 'types/types';

interface TargetIconsProps {
  actions: {
    setForCopyTargetDialog: (target: TargetIndexItem) => void;
    setTargetForDeleteConfirmation: (target: TargetIndexItem) => void;
  };
}

export default function createTargetIcons({ actions }: TargetIconsProps) {
  return function ({ element: target }: { element: TargetIndexItem }) {
    const { t } = useTranslation();

    return (
      <ActionIconsWrapper>
        {target.reuseHarvesting ? (
          <CommonTooltip
            type="yellow"
            placement="bottom-end"
            title={<>{t('capture.actions.tooltips.reuseHarvest')}</>}
          >
            <IconButton
              aria-label={t('capture.actions.tooltips.reuseHarvest')}
              disableRipple
              size="large"
              sx={(theme) => ({
                backgroundColor: 'transparent',
                fill: theme.palette.primary.main,
                '&:hover, &:focus': {
                  fill: theme.palette.custom.darkGrey,
                  backgroundColor: 'transparent',
                },
              })}
            >
              <BuildOutlinedIcon />
            </IconButton>
          </CommonTooltip>
        ) : (
          <>
            <CommonTooltip
              title={<>{t('capture.actions.tooltips.copyTarget')}</>}
            >
              <IconButton
                aria-label={t('capture.actions.tooltips.copyTarget')}
                onClick={() => actions.setForCopyTargetDialog(target)}
                disableRipple
                size="large"
              >
                <ContentCopyIcon />
              </IconButton>
            </CommonTooltip>
            <CommonTooltip
              title={<>{t('capture.actions.tooltips.deleteTarget')}</>}
            >
              <IconButton
                aria-label={t('capture.actions.tooltips.deleteTarget')}
                onClick={() => actions.setTargetForDeleteConfirmation(target)}
                disableRipple
                size="large"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </CommonTooltip>
          </>
        )}
      </ActionIconsWrapper>
    );
  };
}
