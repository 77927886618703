import React from 'react';
import CommonTooltip from 'components/CommonTooltip';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export interface CommonLockIconButtonProps {
  sx?: IconButtonProps['sx'];
  tooltipText: string;
}

export default function CommonLockIconButton({
  sx,
  tooltipText,
}: CommonLockIconButtonProps) {
  return (
    <CommonTooltip title={tooltipText} placement="left">
      <IconButton sx={sx}>
        <LockOutlinedIcon
          sx={(theme) => ({ fontSize: theme.typography.pxToRem(18) })}
        />
      </IconButton>
    </CommonTooltip>
  );
}
