import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import InformationOutlinedRoundedIcon from 'icons/InformationOutlinedRoundedIcon';

export interface FormInformationBoxProps {
  informationText: string;
}

const StyledInformationContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.light,
  border: `1px solid ${blue[100]}`,
  margin: theme.spacing(3, 0, 2, 0),
  borderRadius: theme.shape.borderRadius,
}));

export default function FormInformationBox({
  informationText,
}: FormInformationBoxProps) {
  return (
    <StyledInformationContainer
      container
      alignItems="flex-start"
      flexWrap="nowrap"
    >
      <InformationOutlinedRoundedIcon
        sx={(theme) => ({
          color: blue[900],
        })}
      />
      <Typography
        variant="body2"
        sx={(theme) => ({
          letterSpacing: theme.typography.pxToRem(0.14),
        })}
      >
        {informationText}
      </Typography>
    </StyledInformationContainer>
  );
}
