import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { RecentActivityClientType, RecentActivityData } from './types';
import { ActivityContainer } from './components';
import ActivitySummary from './ActivitySummary';
import ActivityComment from './ActivityComment';

export interface RecentActivityProps {
  activity: RecentActivityData;
}

export default function RecentActivity({ activity }: RecentActivityProps) {
  const { t } = useTranslation();

  return (
    <ActivityContainer>
      <Grid container flexDirection="column">
        <ActivitySummary activity={activity} />
        {activity.comment &&
          activity.clientType === RecentActivityClientType.FRONTEND && (
            <ActivityComment comment={activity.comment} />
          )}
        {(activity.clientType === RecentActivityClientType.GUIDANCEWIZARD ||
          activity.clientType === RecentActivityClientType.TEGRAT) && (
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              lineHeight: theme.typography.pxToRem(20),
              letterSpacing: theme.typography.pxToRem(0.26),
              margin: theme.spacing(2, 0, 0),
              fontWeight: theme.typography.fontWeightMedium,
            })}
            variant="body2"
          >
            {t(
              `capture.descriptions.serverComment.${activity.clientType.toLowerCase()}`
            )}
          </Typography>
        )}
      </Grid>
    </ActivityContainer>
  );
}
