import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { blueGrey } from '@mui/material/colors';

import CommonTooltip from 'components/CommonTooltip';
import { SaveTargetAssignment } from './types';
import {
  StyledChip,
  StyledAccordionDetails,
  UnassignedTargetsTitle,
  UnassignedTargetsAccordionSummary,
  UnassignedTargetsCardContainer,
} from './styles';
import { useIsOpen } from 'hooks/useIsOpen';
import { TargetAssignment } from '../../../../types';
import UnassignedTargetListItem from './UnassignedTargetListItem';

export interface UnassignedTargetsProps {
  openDialogTargetAssignment: () => void;
  readOnly: boolean;
  refreshAssignedTargets: () => void;
  saveTargetAssignment: SaveTargetAssignment;
  setTargetInEdit: (target: TargetAssignment) => void;
  setTargetType: () => void;
  unassignedTargets: TargetAssignment[];
}

const UnassignedTargets = forwardRef<HTMLDivElement, UnassignedTargetsProps>(
  (
    {
      openDialogTargetAssignment,
      readOnly,
      refreshAssignedTargets,
      saveTargetAssignment,
      setTargetInEdit,
      setTargetType,
      unassignedTargets = [],
    }: UnassignedTargetsProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation();
    const { elementIsOpen, setElementIsOpen } = useIsOpen();
    const unassignedTargetsCardContainer = React.useRef<HTMLDivElement>(null);
    const [targetListMaxLength, setTargetListMaxLength] = useState<
      Record<string, unknown>
    >({});

    const insideRef = useRef<HTMLDivElement | null>(null);

    function mergeRefs(element: HTMLDivElement) {
      if (typeof ref === 'function') {
        ref(element);
      } else {
        (ref as React.MutableRefObject<HTMLDivElement>).current = element;
      }

      insideRef.current = element;
    }

    useEffect(() => {
      if (
        elementIsOpen &&
        insideRef.current &&
        insideRef.current.scrollHeight > insideRef.current.clientHeight
      ) {
        setTargetListMaxLength({
          maxHeight: `${insideRef.current.clientHeight - 50}px`,
          paddingBottom: '10px',
        });
      }
      if (!elementIsOpen || !insideRef.current) {
        setTargetListMaxLength({});
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [insideRef.current?.clientHeight]);

    return (
      <Grid
        sx={(theme) => ({
          position: 'fixed',
          bottom: 0,
          width: theme.spacing(70),
          maxHeight: '50%',
          borderTop: `1px solid ${blueGrey[100]}`,
        })}
        ref={mergeRefs}
      >
        <Accordion
          expanded={elementIsOpen}
          onChange={(__, expanded: boolean) => setElementIsOpen(expanded)}
          square={true}
          elevation={0}
        >
          <UnassignedTargetsAccordionSummary
            expandIcon={
              <CommonTooltip
                title={
                  <>
                    {elementIsOpen
                      ? t('capture.actions.tooltips.collapse')
                      : t('capture.actions.tooltips.expand')}
                  </>
                }
              >
                <ExpandLessIcon />
              </CommonTooltip>
            }
          >
            {!readOnly && <StyledChip label={unassignedTargets.length} />}
            <UnassignedTargetsTitle variant="h6">
              {readOnly
                ? t('capture.titles.unassignedTargetsWithNumber', {
                    numberOfTargets: unassignedTargets.length,
                  })
                : t('capture.titles.unassignedTargets')}
            </UnassignedTargetsTitle>
          </UnassignedTargetsAccordionSummary>
          <StyledAccordionDetails>
            <UnassignedTargetsCardContainer
              ref={unassignedTargetsCardContainer}
              style={{ ...targetListMaxLength }}
            >
              {unassignedTargets.map((target) => (
                <UnassignedTargetListItem
                  key={target.targetUuid}
                  openDialogTargetAssignment={openDialogTargetAssignment}
                  readOnly={readOnly}
                  refreshAssignedTargets={refreshAssignedTargets}
                  saveTargetAssignment={saveTargetAssignment}
                  setTargetInEdit={setTargetInEdit}
                  setTargetType={setTargetType}
                  target={target}
                />
              ))}
            </UnassignedTargetsCardContainer>
          </StyledAccordionDetails>
        </Accordion>
      </Grid>
    );
  }
);

UnassignedTargets.displayName = 'UnassignedTargets';

export default UnassignedTargets;
