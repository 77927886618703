import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import StyledExpandMoreIcon from 'icons/ExpandMoreIcon';
import CommonTooltip from '../CommonTooltip';

export interface ExpandMoreIconWithTooltipProps extends SvgIconProps {
  expandIconTooltipText: string;
}

export default function ExpandMoreIconWithTooltip({
  expandIconTooltipText,
  ...props
}: ExpandMoreIconWithTooltipProps) {
  return (
    <CommonTooltip title={expandIconTooltipText}>
      <StyledExpandMoreIcon
        tabIndex={0}
        aria-hidden={false}
        fontSize="medium"
        color="primary"
        aria-label={expandIconTooltipText}
        role="button"
        {...props}
      />
    </CommonTooltip>
  );
}
