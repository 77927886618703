import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { BlackTooltip } from 'components/CommonTooltip';
import { ReactComponent as IconLock } from './IconLock.svg';
import { ReactComponent as IconAlertError } from './IconAlertError.svg';
import { useTranslation } from 'react-i18next';
import {
  OverflowingValueStyledTooltip,
  StyledTextField,
  StyledReadOnlyIconButton,
} from './styles';

type AugmentedTextFieldProps = Omit<TextFieldProps, 'variant'> & {
  readOnly?: boolean;
};

type FilledTextFieldPropsWithoutCharacterCounter = AugmentedTextFieldProps & {
  hasCharacterCounter?: never;
};

type FilledTextFieldPropsWithCharacterCounter = AugmentedTextFieldProps & {
  hasCharacterCounter: boolean;
  inputProps: InputBaseComponentProps & {
    maxLength: number;
  };
};

export type FilledTextFieldProps =
  | FilledTextFieldPropsWithoutCharacterCounter
  | FilledTextFieldPropsWithCharacterCounter;

export default React.forwardRef(function FilledTextField(
  {
    defaultValue,
    hasCharacterCounter = false, // !!! If "hasCharacterCounter" is defined then "inputProps={{ maxLength: # }}" must be defined to. The TS above is to require this.
    ...props
  }: FilledTextFieldProps,
  ref
) {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue || '');
  const [visibleTooltipFor, setVisibleTooltipFor] = useState<boolean | string>(
    false
  );

  let TooltipComponent = OverflowingValueStyledTooltip,
    title = props.value,
    additionalTooltipProps = {};

  if (visibleTooltipFor === 'LABEL') {
    TooltipComponent = BlackTooltip;
    title = props.label;

    additionalTooltipProps = {
      PopperProps: {
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      },
    };
  }

  const { readOnly } = props;

  return (
    <TooltipComponent
      title={<>{title}</>}
      open={
        visibleTooltipFor !== 'LABEL'
          ? !!readOnly && !!visibleTooltipFor
          : !!visibleTooltipFor
      }
      onOpen={(event: React.SyntheticEvent) => {
        const target = event.target as HTMLElement;
        setVisibleTooltipFor(
          /* istanbul ignore next */
          target.scrollWidth > target.clientWidth && target.tagName
        );
      }}
      onClose={() => {
        setVisibleTooltipFor(false);
      }}
      placement="bottom-start"
      {...additionalTooltipProps}
    >
      <StyledTextField
        variant="filled"
        inputRef={ref}
        {...(!props?.value && {
          value,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            setValue(event.target?.value),
        })}
        {...props}
        defaultValue={!props?.value ? undefined : defaultValue}
        {...(hasCharacterCounter && {
          helperText: (
            <>
              <Box component="span" display="block" mr={4}>
                {props?.helperText ?? ''}
              </Box>
              <Box
                component="span"
                display="block"
                aria-describedby={props.id}
                aria-live="polite"
              >
                {((props?.value as string) ?? value).length}&nbsp;/&nbsp;
                {props?.inputProps?.maxLength}
              </Box>
            </>
          ),
        })}
        InputProps={{
          ...(readOnly && {
            readOnly,
            endAdornment: (
              <InputAdornment position="end">
                <BlackTooltip
                  title={<>{t('users.tooltips.readOnlyTextField')}</>}
                  aria-label={t('users.tooltips.readOnlyTextField')}
                  placement={'bottom-end'}
                >
                  <StyledReadOnlyIconButton disableRipple>
                    <IconLock />
                  </StyledReadOnlyIconButton>
                </BlackTooltip>
              </InputAdornment>
            ),
          }),
          ...(props?.error && {
            endAdornment: (
              <InputAdornment position="end">
                <IconAlertError />
              </InputAdornment>
            ),
          }),
          ...props?.InputProps,
        }}
        FormHelperTextProps={{
          'aria-live': 'polite',
          role: 'alert',
          ...props?.FormHelperTextProps,
        }}
      />
    </TooltipComponent>
  );
});
