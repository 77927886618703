import React, { ReactElement, useState, useEffect, useContext } from 'react';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ButtonPrimary from 'components/ButtonPrimary';
import { LogoutButton } from 'components/LogoutButton';
import FeaturesContext from 'contexts/FeaturesContext';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    top: '-15%',
    minWidth: theme.spacing(68),
    boxShadow: '0 5px 49px rgba(85, 85, 85, 0.2)',
    padding: theme.spacing(5, 6),
    borderRadius: theme.typography.pxToRem(6),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(6),
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.18),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  '& > :not(:first-of-type)': {
    marginLeft: theme.spacing(2),
  },
}));

export interface SessionTimeoutDialogProps {
  deadlineTimestamp?: number;
  onClose: () => void;
  onLogout: () => void;
}

const SessionTimeoutDialog = ({
  deadlineTimestamp = 0,
  onClose,
  onLogout,
}: SessionTimeoutDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(0);
  const history = useHistory();
  const features = useContext(FeaturesContext);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(deadlineTimestamp - +new Date());
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    setTimeLeft(Math.max(0, deadlineTimestamp - +new Date()));
  }, [deadlineTimestamp]);

  useEffect(() => {
    if (deadlineTimestamp && timeLeft < 0) {
      window.localStorage.setItem('logout-reason', 'session-timeout');

      const parsedUrl = new URL(window.location.href);
      history.push(
        `/${features.enableKeycloak ? 'sign-in' : 'logout'}?continue=${
          parsedUrl.pathname
        }`
      );
    }
  }, [deadlineTimestamp, onClose, onLogout, timeLeft, history, features]);

  // Don't display number less than 0
  const count = Math.max(0, Math.floor((timeLeft / 1000) % 60) + 1);

  return (
    <StyledDialog
      open={timeLeft > 0}
      aria-labelledby="session-timeout-title"
      aria-describedby="session-timeout-content"
    >
      <StyledDialogTitle id="session-timeout-title">
        {t('sessions.timeout.title')}
      </StyledDialogTitle>
      <StyledDialogContent id="session-timeout-content">
        <Trans i18nKey="sessions.timeout.description" count={count}>
          Due to inactivity, the current session will expire in
          <strong>{{ count } as any}</strong> seconds. Do you want to stay
          signed in?
        </Trans>
      </StyledDialogContent>
      <StyledDialogActions>
        <LogoutButton
          text="sessions.timeout.logoutNowButton"
          onAfterClick={onLogout}
          variant="outlined"
        />
        <ButtonPrimary variant="contained" onClick={onClose}>
          {t('sessions.timeout.staySignedInButton')}
        </ButtonPrimary>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default SessionTimeoutDialog;
