import React from 'react';
import Typography from '@mui/material/Typography';

import { addAllChildren } from '../../sections/Targets/pages/TargetPage/components/TerminologyGuideline/helpers';
import { addAllParents } from './helpers';
import { DomainStructureProps } from './types';
import cloneDeep from 'lodash/cloneDeep';
import CheckboxWithLabel from 'components/CommonCheckboxWithLabel';

export default function DomainStructure({
  domainObj,
  fullDisplayStructure,
  handleChangeDomainCheckbox,
}: DomainStructureProps) {
  return (
    <CheckboxWithLabel
      key={domainObj.internalId}
      checked={domainObj.checked}
      indeterminate={domainObj.indeterminate}
      sx={(theme) => ({
        paddingLeft: theme.spacing(domainObj.level * 4),
      })}
      value={domainObj.internalId}
      onChange={(evt, checked) => {
        const affectedDomains: string[] = [];
        const copyDomainObj = cloneDeep(domainObj);

        if (checked) {
          addAllChildren(copyDomainObj, fullDisplayStructure, affectedDomains);
        } else {
          addAllChildren(copyDomainObj, fullDisplayStructure, affectedDomains);
          addAllParents(copyDomainObj, fullDisplayStructure, affectedDomains);
        }

        affectedDomains.push(copyDomainObj.internalId);

        handleChangeDomainCheckbox(checked, affectedDomains);
      }}
      label={
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(14),
            letterSpacing: theme.typography.pxToRem(0.14),
          })}
          variant="body2"
        >
          {domainObj.displayName}
        </Typography>
      }
    />
  );
}
