import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ButtonPrimary from 'components/ButtonPrimary/ButtonPrimary';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import {
  StyledDialog,
  StyledMoreButton,
  StyledIconWarning,
  StyledDialogTitle,
  StyledMoreBox,
} from './styles';

import { useTranslation } from 'react-i18next';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

interface ErrorNotificationDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  error?: string | JSX.Element;
  clipboardText?: string;
}

export const ErrorNotificationDialogControlled = ({
  open,
  onClose,
  title,
  description,
  error,
  clipboardText,
}: ErrorNotificationDialogProps) => {
  const { t } = useTranslation();
  const [isMoreVisible, setIsMoreVisible] = useState<boolean>(false);
  const [copyToClipboardText, setCopyToClipboardText] = useState('');

  useEffect(() => {
    if (error) {
      if (clipboardText) {
        setCopyToClipboardText(clipboardText);
      } else if (typeof error === 'string') {
        setCopyToClipboardText(error);
      } else {
        queueMicrotask(() => {
          // without queueing, flushSync throws "flushSync was called from inside a lifecycle method. React cannot flush when React is already rendering. Consider moving this call to a scheduler task or micro task."
          const div = document.createElement('div');
          const root = createRoot(div);
          flushSync(() => {
            root.render(error);
          });
          setCopyToClipboardText(div.innerHTML);
        });
      }
    }
  }, [error, clipboardText]);

  const onCloseInternal = () => {
    onClose();
    setIsMoreVisible(false);
    setCopyToClipboardText('');
  };

  const toggleVisibility = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  return (
    <StyledDialog
      open={open}
      aria-labelledby={title}
      aria-describedby={description}
      data-testid="error-notification-dialog"
    >
      <StyledDialogTitle>
        <StyledIconWarning />
        {title}
      </StyledDialogTitle>
      <DialogContent
        sx={{
          padding: '0 30px 30px 35px',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogContentText>{description}</DialogContentText>
        {error && (
          <Box
            mt={4}
            ml={6}
            minHeight={0}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Box>
              <StyledMoreButton onClick={toggleVisibility} disableRipple>
                {isMoreVisible
                  ? t('shared.actions.less')
                  : t('shared.actions.more')}
              </StyledMoreButton>
            </Box>
            {isMoreVisible && (
              <StyledMoreBox mt={4}>
                {error}
                <CopyToClipboardButton text={copyToClipboardText} />
              </StyledMoreBox>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '0 30px 30px 0' }}>
        <ButtonPrimary variant="contained" onClick={onCloseInternal}>
          {t('shared.actions.close')}
        </ButtonPrimary>
      </DialogActions>
    </StyledDialog>
  );
};

const ErrorNotificationDialog = ({
  onClose,
  ...props
}: Omit<ErrorNotificationDialogProps, 'open' | 'onClose'> & {
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <ErrorNotificationDialogControlled
      open={open}
      onClose={() => {
        setOpen(false);
        onClose?.();
      }}
      {...props}
    />
  );
};

export default ErrorNotificationDialog;
