import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';

export interface ContentSpaceProps extends GridProps {
  spacingParam?: number;
}

export const ContentVerticalSpace = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'spacingParam',
})<ContentSpaceProps>(({ theme, spacingParam = 2 }) => ({
  paddingTop: theme.spacing(spacingParam),
}));
