import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FilledTextField from 'components/FilledTextField/FilledTextField';
import { CommonMenuItem } from 'components/CommonSelectStandard';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.18),
  padding: 0,
  fontWeight: 400,
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  letterSpacing: theme.typography.pxToRem(0.39),
  fontWeight: 500,
  color: theme.palette.custom.gray36,
  paddingBottom: theme.spacing(1.5),
  textTransform: 'uppercase',
}));

export const StyledValue = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.31),
  wordWrap: 'break-word',
}));

export const StyledFilledTextField = styled(FilledTextField)(({ theme }) => ({
  width: theme.typography.pxToRem(300),
}));

export const StyledCommonMenuItem = styled(CommonMenuItem)(({ theme }) => ({
  width: theme.typography.pxToRem(300),
  '& em': {
    paddingRight: '5px',
  },
}));
