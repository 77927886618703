import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  StyledInfoLabel,
  StyledInfoText,
} from '../../../../components/AdministrativeInformation/components';
import { CompletePhrase } from '../../types';
import { StyledReadOnlyPhraseDivider } from './components';
import { StyledEmptyField } from '../CreateOrEditDomainDrawer/styles';

export interface ReadOnlySettingsProps {
  deprecatedPhrases: CompletePhrase[];
}

export default function ReadOnlySettings({
  deprecatedPhrases,
}: ReadOnlySettingsProps) {
  const { t } = useTranslation();

  return (
    <AccordionDetails
      sx={(theme) => ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      })}
    >
      <StyledInfoLabel
        sx={(theme) => ({ paddingTop: 0, paddingBottom: theme.spacing(2) })}
      >
        {t('reuse.labels.deprecatedPhrase', { count: 2 })}
      </StyledInfoLabel>
      {deprecatedPhrases.length <= 1 && (
        <StyledEmptyField>{t('reuse.fields.none')}</StyledEmptyField>
      )}
      {deprecatedPhrases.map(
        ({ phrase, uuid }, index) =>
          uuid && (
            <div key={uuid}>
              {!!index && <StyledReadOnlyPhraseDivider />}
              <StyledInfoText>{phrase}</StyledInfoText>
            </div>
          )
      )}
    </AccordionDetails>
  );
}
