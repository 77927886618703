import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { User } from 'sections/Users/types';
import { USER_API_PATH } from 'constants/constants';
import { useFetch } from 'utils/fetch';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';

export const useGetUser = (uid?: string) => {
  const { id } = useParams<{ id: string }>();
  const userId = uid ?? id;
  const { open } = useErrorNotificationDialog();
  const http = useFetch();

  return useQuery<
    {
      data: User;
    },
    Error
  >({
    queryKey: ['user', userId],
    queryFn: async () => {
      try {
        return await http.get(`${USER_API_PATH}/${userId}`);
      } catch (error) {
        open(apiErrorToNotification(error));
        throw error;
      }
    },
    cacheTime: 0,
  });
};

type FormState = User;

export type FormAction =
  | {
      type: 'SET_FIELD_VALUE' | 'SET_CUSTOM_FIELD_VALUE';
      payload: {
        fieldName: string;
        fieldValue: string;
      };
    }
  | {
      type: 'RESET';
      payload: User;
    };

export const reducer = (
  prevState: FormState,
  action: FormAction
): FormState => {
  switch (action.type) {
    case 'RESET':
      return { ...action.payload };
    case 'SET_FIELD_VALUE':
      return {
        ...prevState,
        [action.payload.fieldName]: action.payload.fieldValue,
      };
    case 'SET_CUSTOM_FIELD_VALUE':
      return {
        ...prevState,
        ...(prevState?.customFields && {
          customFields: prevState.customFields.map((customFieldData) => {
            if (customFieldData.key === action.payload.fieldName) {
              return {
                ...customFieldData,
                value: action.payload.fieldValue,
              };
            }

            return customFieldData;
          }),
        }),
      };
    default:
      return prevState;
  }
};

export const a11yProps = (index: number) => ({
  id: `user-details-tab-${index}`,
  'aria-controls': `user-details-tabpanel-${index}`,
});
