import React, { ReactNode, useEffect, useRef, useState } from 'react';

import CommonTooltip from '../CommonTooltip';

export interface TextWithEllipsisAndTooltipProps {
  maxWidth: string;
  text: ReactNode | string;
  tooltipText?: ReactNode | string;
}

export default function TextWithEllipsisAndTooltip({
  maxWidth,
  text,
  tooltipText,
}: TextWithEllipsisAndTooltipProps) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOverflow(
      (elementRef.current as unknown as HTMLDivElement)?.scrollWidth >
        (elementRef.current as unknown as HTMLDivElement)?.clientWidth
    );
  }, []);

  return (
    <CommonTooltip
      placement="top"
      title={tooltipText || text}
      disableHoverListener={!isOverflowed}
    >
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth,
          display: 'block',
        }}
        ref={elementRef}
      >
        {text}
      </span>
    </CommonTooltip>
  );
}
