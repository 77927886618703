// Source: https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
import { ReactElement } from 'react';
interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): ReactElement => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
