import { useEffect, useMemo } from 'react';

import {
  createLanguagesStructure,
  createListOfSupportedLanguages,
} from './helpers';
import { LanguageDropdownProps } from './types';
import {
  Language,
  LanguageFull,
  LanguageSimple,
  LanguageSimpleEnhanced,
} from 'types/types';

export interface UseLanguagesResult {
  languageStructure: LanguageFull[] | LanguageSimpleEnhanced[];
  selectedLanguage: [LanguageFull | LanguageSimpleEnhanced];
}

export type UseLanguageProps = Pick<
  LanguageDropdownProps,
  | 'languages'
  | 'selectedLanguageId'
  | 'setSelectedLanguage'
  | 'type'
  | 'supportedLanguageAbbreviations'
> & {
  // as this hook is used also for only generating structure setters are optional
  setSelectedLanguageId?: (arg: string) => void;
};

export function useLanguages({
  languages,
  selectedLanguageId,
  setSelectedLanguage,
  setSelectedLanguageId,
  type,
  supportedLanguageAbbreviations,
}: UseLanguageProps): UseLanguagesResult {
  const languageData = useMemo(() => {
    const languagesIsFullStructure = languages[0]?.hasOwnProperty(
      'languageVariantDisplayName'
    );

    if (type === 'simple') {
      return (languages as LanguageSimple[]).reduce((acc, langObj) => {
        const matchingLangObj = acc.find(
          (l) => l.languageId === langObj.abbreviation
        );
        if (!matchingLangObj) {
          acc.push({
            ...langObj,
            languageId: langObj.abbreviation,
            languageVariantDisplayName: langObj.displayName,
          } as unknown as LanguageFull);
        }

        return acc;
      }, [] as LanguageFull[] | LanguageSimpleEnhanced[]);
    }

    return languagesIsFullStructure
      ? (languages as LanguageFull[])
      : ((languages as LanguageSimple[]).map(
          (lang): LanguageSimpleEnhanced => ({
            ...lang,
            languageId: lang.abbreviation,
            languageVariantDisplayName: lang.displayName,
          })
        ) as LanguageSimpleEnhanced[]);
  }, [languages, type]);

  const filteredLanguageData = useMemo<
    LanguageFull[] | LanguageSimpleEnhanced[]
  >(
    () =>
      supportedLanguageAbbreviations?.length
        ? createListOfSupportedLanguages(
            languageData,
            supportedLanguageAbbreviations
          )
        : languageData,
    [languageData, supportedLanguageAbbreviations]
  );

  const languageStructure = useMemo<LanguageFull[] | LanguageSimpleEnhanced[]>(
    () =>
      type === 'simple'
        ? filteredLanguageData
        : createLanguagesStructure(filteredLanguageData as LanguageFull[]),
    [filteredLanguageData, type]
  );

  const selectedLanguage: [Language] = useMemo<[Language]>(() => {
    if (!selectedLanguageId && filteredLanguageData.length) {
      return [filteredLanguageData[0]] as [Language];
    }

    return filteredLanguageData.filter(
      (langObj) => langObj.languageId === selectedLanguageId
    ) as [Language];
  }, [filteredLanguageData, selectedLanguageId]);

  useEffect(() => {
    if (typeof setSelectedLanguage === 'function') {
      setSelectedLanguage(selectedLanguage);
    }
  }, [selectedLanguage, setSelectedLanguage]);

  useEffect(() => {
    if (
      typeof setSelectedLanguageId === 'function' &&
      !selectedLanguageId &&
      languageStructure.length
    ) {
      setSelectedLanguageId(languageStructure[0].languageId); // Select first language by default
    }
  }, [languageStructure, selectedLanguageId, setSelectedLanguageId]);

  return {
    languageStructure,
    selectedLanguage,
  };
}
