import React, { forwardRef, Ref } from 'react';
import IconButton, {
  iconButtonClasses,
  IconButtonProps,
} from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${iconButtonClasses['root']}`]: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    fontSize: theme.typography.pxToRem(18),
    padding: theme.spacing(1.4),
    marginRight: theme.spacing(2),
  },
}));

export function ReuseHarvestingRunningIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      htmlColor={props.color}
      data-testid="ReuseHarvestingRunningIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fontSize="inherit"
    >
      <path
        id="Icon_Reuse_Harvesting_Running"
        d="M176.313-790.916a6.748,6.748,0,0,1-.661,3.08,7.216,7.216,0,0,1-1.786,2.264l-.844.591v-2.644a.82.82,0,0,0-.239-.6.82.82,0,0,0-.6-.239.82.82,0,0,0-.6.239.82.82,0,0,0-.239.6v4.781a.82.82,0,0,0,.239.6.82.82,0,0,0,.6.239h4.781a.82.82,0,0,0,.6-.239.82.82,0,0,0,.239-.6.82.82,0,0,0-.239-.6.82.82,0,0,0-.6-.239h-2.812l.422-.337a9.133,9.133,0,0,0,2.616-3.122,8.548,8.548,0,0,0,.816-3.769,8.777,8.777,0,0,0-1.392-4.823,8.947,8.947,0,0,0-3.727-3.3.655.655,0,0,0-.591,0,.78.78,0,0,0-.394.45.747.747,0,0,0,0,.633,1,1,0,0,0,.45.492,7.425,7.425,0,0,1,2.883,2.714A7.182,7.182,0,0,1,176.313-790.916Zm-14.625-.169a6.36,6.36,0,0,1,.661-2.742,8.771,8.771,0,0,1,1.73-2.461l.816-.731v2.644a.82.82,0,0,0,.239.6.82.82,0,0,0,.6.239.82.82,0,0,0,.6-.239.82.82,0,0,0,.239-.6v-4.781a.82.82,0,0,0-.239-.6.82.82,0,0,0-.6-.239h-4.781a.82.82,0,0,0-.6.239.82.82,0,0,0-.239.6.82.82,0,0,0,.239.6.82.82,0,0,0,.6.239H163.8l-.422.394a11.071,11.071,0,0,0-2.531,3.375,8.142,8.142,0,0,0-.844,3.459,8.789,8.789,0,0,0,1.35,4.767,9.208,9.208,0,0,0,3.628,3.333.717.717,0,0,0,.633.042.808.808,0,0,0,.464-.464.747.747,0,0,0,0-.633,1,1,0,0,0-.45-.492,6.958,6.958,0,0,1-2.883-2.7A7.4,7.4,0,0,1,161.688-791.084Z"
        transform="translate(-160 800)"
      />
    </SvgIcon>
  );
}

function ReuseHarvestingRunningIconWrappedInButton(
  props: IconButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <StyledIconButton {...props} ref={ref}>
      <ReuseHarvestingRunningIcon color="primary" />
    </StyledIconButton>
  );
}

export const ReuseHarvestingRunningIconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(ReuseHarvestingRunningIconWrappedInButton);

export function ReuseHarvestingFailedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      htmlColor={props.color}
      data-testid="ReuseHarvestingFailedIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 21.513"
      width="24"
      height="21.513"
      fontSize="inherit"
    >
      <path
        id="Icon_Harvesting_Failed"
        d="M139.832-782.588a.968.968,0,0,1-.706-.3.968.968,0,0,1-.3-.706.968.968,0,0,1,.3-.706.968.968,0,0,1,.706-.3.968.968,0,0,1,.706.3.968.968,0,0,1,.3.706.968.968,0,0,1-.3.706A.968.968,0,0,1,139.832-782.588Zm.061-4.975a.965.965,0,0,1-.717-.29.983.983,0,0,1-.286-.718v-6.353a.973.973,0,0,1,.292-.719.985.985,0,0,1,.723-.29.965.965,0,0,1,.717.29.984.984,0,0,1,.286.719v6.353a.973.973,0,0,1-.292.718A.985.985,0,0,1,139.893-787.563Zm-9.876-1.58a8.521,8.521,0,0,0,.924,3.944,8.327,8.327,0,0,0,3.008,3.148v-3.16a.973.973,0,0,1,.292-.719.984.984,0,0,1,.723-.29.965.965,0,0,1,.717.29.983.983,0,0,1,.286.719v5.714a.976.976,0,0,1-.29.719.976.976,0,0,1-.718.29h-5.714a.973.973,0,0,1-.718-.292.984.984,0,0,1-.29-.723.965.965,0,0,1,.29-.717.983.983,0,0,1,.718-.286h3.361a12.931,12.931,0,0,1-3.378-3.647A9.5,9.5,0,0,1,128-789.143a10.866,10.866,0,0,1,1.563-5.63,10.249,10.249,0,0,1,4.487-4.05.859.859,0,0,1,.723-.017.923.923,0,0,1,.5.521,1.036,1.036,0,0,1,.017.756.915.915,0,0,1-.5.555,8.849,8.849,0,0,0-3.429,3.181A8.523,8.523,0,0,0,130.017-789.143Zm19.966-.2a8.52,8.52,0,0,0-.924-3.944,8.325,8.325,0,0,0-3.008-3.148v3.16a.973.973,0,0,1-.292.719.985.985,0,0,1-.723.29.965.965,0,0,1-.717-.29.983.983,0,0,1-.286-.719v-5.714a.976.976,0,0,1,.29-.719.975.975,0,0,1,.718-.29h5.714a.973.973,0,0,1,.718.292.984.984,0,0,1,.29.723.965.965,0,0,1-.29.717.983.983,0,0,1-.718.286h-3.361a12.931,12.931,0,0,1,3.378,3.647A9.5,9.5,0,0,1,152-789.344a10.955,10.955,0,0,1-1.563,5.647,10.218,10.218,0,0,1-4.487,4.067.819.819,0,0,1-.723,0,.989.989,0,0,1-.5-.538,1.036,1.036,0,0,1-.017-.756.915.915,0,0,1,.5-.555,8.851,8.851,0,0,0,3.429-3.181A8.523,8.523,0,0,0,149.983-789.344Z"
        transform="translate(-128 800)"
      />
    </SvgIcon>
  );
}

export const ReuseHarvestingFailedIconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
  return (
    <StyledIconButton {...props} ref={ref}>
      <ReuseHarvestingFailedIcon color="primary" />
    </StyledIconButton>
  );
});

export function ReuseHarvestingFinishedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      htmlColor={props.color}
      data-testid="ReuseHarvestingFinishedIcon"
      fontSize="inherit"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path
        id="Icon_Harvesting_Finished"
        d="M96.921-876.213a8.82,8.82,0,0,1,5.033,3.152,8.995,8.995,0,0,1,1.981,5.827,8.158,8.158,0,0,1-.912,3.748,11.206,11.206,0,0,1-2.451,3.2h1.965a.757.757,0,0,1,.557.22.748.748,0,0,1,.221.553.748.748,0,0,1-.221.553.757.757,0,0,1-.557.22H98.589a.757.757,0,0,1-.557-.22.748.748,0,0,1-.221-.553v-3.925a.748.748,0,0,1,.221-.553.757.757,0,0,1,.557-.22.757.757,0,0,1,.557.22.748.748,0,0,1,.221.553v2.107a9.011,9.011,0,0,0,2.2-2.684,6.745,6.745,0,0,0,.813-3.22,7.45,7.45,0,0,0-1.639-4.838,7.325,7.325,0,0,0-4.17-2.6.7.7,0,0,1-.421-.274.758.758,0,0,1-.165-.466.785.785,0,0,1,.284-.644A.739.739,0,0,1,96.921-876.213Zm-3.972,17.958a8.775,8.775,0,0,1-5.033-3.116,9,9,0,0,1-1.981-5.862,8.158,8.158,0,0,1,.912-3.748,11.16,11.16,0,0,1,2.456-3.2h-1.97a.757.757,0,0,1-.557-.22.748.748,0,0,1-.221-.553.749.749,0,0,1,.221-.554.757.757,0,0,1,.557-.22h3.947a.757.757,0,0,1,.557.22.749.749,0,0,1,.221.554v3.925a.748.748,0,0,1-.221.553.757.757,0,0,1-.557.22.757.757,0,0,1-.557-.22.748.748,0,0,1-.221-.553v-2.107a8.7,8.7,0,0,0-2.2,2.672,6.812,6.812,0,0,0-.813,3.231A7.45,7.45,0,0,0,89.13-862.4a7.325,7.325,0,0,0,4.17,2.6.7.7,0,0,1,.421.274.758.758,0,0,1,.165.466.789.789,0,0,1-.287.647A.72.72,0,0,1,92.949-858.255Zm.684-5.364a.945.945,0,0,1-.287-.042.693.693,0,0,1-.264-.167l-2.526-2.518a.707.707,0,0,1-.222-.518.707.707,0,0,1,.222-.518.709.709,0,0,1,.523-.226.727.727,0,0,1,.529.226l2.025,2.013,4.634-4.6a.731.731,0,0,1,.509-.209.689.689,0,0,1,.532.209.692.692,0,0,1,.225.52.7.7,0,0,1-.225.515l-5.13,5.107a.65.65,0,0,1-.258.167A.945.945,0,0,1,93.633-863.619Z"
        transform="translate(-85.935 876.233)"
      />
    </SvgIcon>
  );
}

export const ReuseHarvestingFinishedIconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
  return (
    <StyledIconButton {...props} ref={ref}>
      <ReuseHarvestingFinishedIcon color="primary" />
    </StyledIconButton>
  );
});
