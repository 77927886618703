import React, { useEffect, useState } from 'react';

import FloatingWindow from 'components/FloatingWindow';
// @ts-ignore
import backendHtmlString from './html_source.txt';

interface TargetAssignmentDocsProps {
  close: () => void;
  targetAssignmentDocsIsOpen: boolean;
}

export default function TargetAssignmentDocs({
  close,
  targetAssignmentDocsIsOpen,
}: TargetAssignmentDocsProps) {
  const [html, setHTML] = useState({ __html: '' });

  useEffect(() => {
    async function createMarkup() {
      // const response = await fetch(``)
      // const backendHtmlString = await response.text();

      return { __html: backendHtmlString };
    }
    createMarkup().then((result) => setHTML(result));
  }, []);

  return targetAssignmentDocsIsOpen ? (
    <FloatingWindow onClose={close} title={'Target Assignment Docs'}>
      <div dangerouslySetInnerHTML={html} />
    </FloatingWindow>
  ) : (
    <></>
  );
}
