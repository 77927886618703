import React, { ReactElement, useEffect, useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FilledTextField from 'components/FilledTextField';
import CommonTooltip from 'components/CommonTooltip';

import { useTranslation } from 'react-i18next';

import type { FilledTextFieldProps } from 'components/FilledTextField';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import { StyledAugmentedIconButton } from './styles';

const CustomizedInput = React.forwardRef<typeof HTMLInputElement, any>(
  (props, ref) => {
    const [obfuscatedValue, setObfuscatedValue] = useState(
      '•'.repeat(props.value.length)
    );

    useEffect(() => {
      setObfuscatedValue('•'.repeat(props.value.length));
    }, [props.value.length]);

    return (
      <input
        ref={ref}
        {...props}
        value={obfuscatedValue}
        onChange={(e) => {
          const newValue =
            e.target.value === '•'.repeat(e.target.value.length)
              ? props.value.slice(0, e.target.value.length)
              : e.target.value.replace(obfuscatedValue, props.value);

          props.onChange(newValue);

          setObfuscatedValue('•'.repeat(newValue.length));
        }}
      />
    );
  }
);

export type FilledTextFieldPasswordProps = FilledTextFieldProps & {
  onChange?: (value: string) => void;
};

const FilledTextFieldPassword = (
  props: FilledTextFieldPasswordProps
): ReactElement => {
  const { t } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  return (
    <FilledTextField
      id={'input-password'}
      sx={{
        '&	.MuiFilledInput-root': {
          paddingRight: '2px',
        },
      }}
      type={isPasswordShown ? 'text' : 'password'}
      label={t('users.labels.password')}
      fullWidth
      {...props}
      helperText={
        (props?.helperText &&
          ['pattern', 'matches'].includes(`${props?.helperText}`) &&
          t('users.hints.passwordRestrictions')) ||
        t('users.hints.required')
      }
      InputProps={{
        endAdornment: (
          <>
            {(props?.error && (
              <InputAdornment position="end">
                <ErrorOutlineIcon
                  sx={{
                    color: 'error.main',
                    marginRight: (theme) => theme.spacing(6 / 5),
                  }}
                />
              </InputAdornment>
            )) || (
              <>
                <InputAdornment position="end">
                  <CommonTooltip
                    title={
                      isPasswordShown
                        ? t('users.tooltips.hidePassword')
                        : t('users.tooltips.showPassword')
                    }
                    placement={'bottom-end'}
                  >
                    <StyledAugmentedIconButton
                      disableRipple
                      aria-label={
                        isPasswordShown
                          ? t('users.tooltips.hidePassword')
                          : t('users.tooltips.showPassword')
                      }
                      color="primary"
                      onClick={() => {
                        setIsPasswordShown(!isPasswordShown);
                      }}
                    >
                      {isPasswordShown ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </StyledAugmentedIconButton>
                  </CommonTooltip>
                </InputAdornment>
                <InputAdornment position="end">
                  <CopyToClipboardButton text={props?.value as string} />
                </InputAdornment>
              </>
            )}
          </>
        ),
        ...(!isPasswordShown && { inputComponent: CustomizedInput }),
        ...props?.InputProps,
      }}
    />
  );
};

export default React.forwardRef<
  typeof FilledTextFieldPassword,
  FilledTextFieldPasswordProps
>((props, ref) => <FilledTextFieldPassword inputRef={ref} {...props} />);
