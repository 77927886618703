import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  ReuseHarvestingFailedIcon,
  ReuseHarvestingFinishedIcon,
  ReuseHarvestingRunningIcon,
} from '../ReuseHarvestingStatusIcons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HarvestingStatus, ReuseHarvestingState } from './hooks';
import Typography, { TypographyProps } from '@mui/material/Typography';
import DisplayDate from 'components/DisplayDate';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { createProgressBarValues, SentencesReadyStatus } from './helpers';
import { blueGrey } from '@mui/material/colors';
import Box from '@mui/material/Box';

const SentencesForHarvestingBar = styled(LinearProgress)(({ theme }) => ({
  height: theme.typography.pxToRem(10),
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
  },
  [`&.${linearProgressClasses.root}`]: {
    backgroundColor: blueGrey[100],
  },
}));
const SentencesForHarvestingBarLeftPart = styled(SentencesForHarvestingBar)(
  ({ theme }) => ({
    borderTopLeftRadius: theme.typography.pxToRem(10),
    borderBottomLeftRadius: theme.typography.pxToRem(10),
    borderTopRightRadius: 0,
    marginRight: theme.typography.pxToRem(2),
    borderBottomRightRadius: 0,
  })
);

const SentencesForHarvestingBarRightPart = styled(SentencesForHarvestingBar)(
  ({ theme }) => ({
    borderTopRightRadius: theme.typography.pxToRem(10),
    borderBottomRightRadius: theme.typography.pxToRem(10),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  })
);
const StyledTypography = styled((props: TypographyProps) => (
  <Typography variant="body2" component="span" {...props} />
))(({ theme }) => ({
  paddingLeft: theme.typography.pxToRem(15),
}));
interface HarvestingStatusDialogProps {
  open: boolean;
  onClose: () => void;
  harvestingStatus: HarvestingStatus | undefined;
}

const NumericLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(18),
}));
export default function HarvestingStatusDialog(
  props: HarvestingStatusDialogProps
) {
  const { open, onClose, harvestingStatus } = props;
  const { t } = useTranslation();
  const {
    leftProgressBarValue,
    rightProgressBarValue,
    leftProgressBarRatio,
    sentencesReadyStatus,
  } = useMemo(
    () =>
      createProgressBarValues(
        harvestingStatus?.sentencesReady,
        harvestingStatus?.sentenceCountMid,
        harvestingStatus?.sentenceCountMax
      ),
    [harvestingStatus]
  );
  const theme = useTheme();
  const barColor =
    sentencesReadyStatus === SentencesReadyStatus.NOT_ENOUGH_CONTENT
      ? 'warning'
      : 'success';

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('reuseInbox.titles.statusDialog')}
      ContentComponent={Box}
      buttonOKLabel={t('shared.actions.close')}
    >
      <Grid
        container
        justifyContent="left"
        alignItems="center"
        sx={(theme) => ({ fontSize: theme.typography.pxToRem(24) })}
      >
        {harvestingStatus?.reuseHarvestingState ===
          ReuseHarvestingState.HARVESTING && (
          <>
            <ReuseHarvestingRunningIcon />
            <StyledTypography>
              {t('reuseInbox.descriptions.status.harvesting')}
            </StyledTypography>
          </>
        )}
        {harvestingStatus?.reuseHarvestingState ===
          ReuseHarvestingState.IDLE && (
          <>
            <ReuseHarvestingFinishedIcon
              data-testid="ReuseHarvestingFinishedIcon"
              color="success"
            />
            <StyledTypography>
              {t('reuseInbox.descriptions.status.success')}
            </StyledTypography>
            <Typography
              variant="body2"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              :{' '}
              <DisplayDate
                date={harvestingStatus.dateOfLastSuccessfulRun}
              ></DisplayDate>
            </Typography>
          </>
        )}
        {harvestingStatus?.reuseHarvestingState ===
          ReuseHarvestingState.FAILURE && (
          <>
            <ReuseHarvestingFailedIcon color="error" />
            <StyledTypography>
              {t('reuseInbox.descriptions.status.failure')}
            </StyledTypography>
          </>
        )}
      </Grid>
      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(12),
          color: blueGrey[800],
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(2),
        })}
        id="harvesting_pages_checked"
      >
        {t('reuseInbox.labels.pagesChecked')}
      </Typography>
      <Grid
        container
        role="progressbar"
        aria-valuemax={harvestingStatus?.sentenceCountMax}
        aria-valuenow={harvestingStatus?.sentencesReady}
        aria-labelledby="harvesting_pages_checked"
      >
        <Grid item xs={leftProgressBarRatio}>
          <SentencesForHarvestingBarLeftPart
            aria-hidden={true}
            value={leftProgressBarValue}
            variant={'determinate'}
            color={barColor}
          ></SentencesForHarvestingBarLeftPart>
        </Grid>
        <Grid item xs={12 - leftProgressBarRatio}>
          <SentencesForHarvestingBarRightPart
            aria-hidden={true}
            value={rightProgressBarValue}
            variant={'determinate'}
            color={barColor}
          ></SentencesForHarvestingBarRightPart>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        marginTop={theme.spacing(1)}
      >
        <NumericLabel>0</NumericLabel>
        <NumericLabel>{harvestingStatus?.sentenceCountMax}</NumericLabel>
      </Grid>
      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(13),
          color: blueGrey[800],
          marginTop: theme.spacing(2),
        })}
      >
        {sentencesReadyStatus === SentencesReadyStatus.NOT_ENOUGH_CONTENT &&
          t('reuseInbox.descriptions.notEnoughContentChecked')}
        {sentencesReadyStatus === SentencesReadyStatus.ENOUGH_FOR_SCHEDULED &&
          t('reuseInbox.descriptions.enoughContentForScheduledHarvestingRun')}
        {sentencesReadyStatus === SentencesReadyStatus.ENOUGH_FOR_IMMEDIATE &&
          t('reuseInbox.descriptions.enoughContentForImmediateHarvestingRun')}
      </Typography>
    </ConfirmationDialog>
  );
}
