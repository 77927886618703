import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import {
  ExpandMoreIconWithTooltip,
  StyledAccordionSummary,
} from 'components/FixedAccordion';
import { AdvancedSettingsSectionTitle } from 'components/CommonDrawer';
import CommonTooltip from 'components/CommonTooltip';
import { StyledHelpIcon } from '../CreateOrEditReplacementDrawer/styles';

export interface AdvancedSettingsSummaryProps {
  expandIconTooltipText: string;
  readOnly: boolean;
}

export default function AdvancedSettingsSummary({
  expandIconTooltipText,
  readOnly,
}: AdvancedSettingsSummaryProps) {
  const { t } = useTranslation();

  return (
    <StyledAccordionSummary
      expandIcon={
        <ExpandMoreIconWithTooltip
          expandIconTooltipText={expandIconTooltipText}
        />
      }
    >
      <Grid container alignItems="center">
        <AdvancedSettingsSectionTitle>
          {t('reuse.titles.replacementAdvancedSettings')}
        </AdvancedSettingsSectionTitle>
        <CommonTooltip
          title={t(
            readOnly
              ? 'reuse.tooltips.advancedSettingsReadOnly'
              : 'reuse.tooltips.advancedSettings'
          )}
          type="yellow"
          placement="right-start"
        >
          <StyledHelpIcon tabIndex={0} aria-hidden={false} />
        </CommonTooltip>
      </Grid>
    </StyledAccordionSummary>
  );
}
