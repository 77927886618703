import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import InputBase, { inputBaseClasses } from '@mui/material/InputBase';
import Select, { selectClasses } from '@mui/material/Select';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';

export const BootstrapInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.primary,
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.grey[100],
  borderWidth: 1,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
  },
  [`&.${inputBaseClasses.focused}`]: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,
  },
}));

export const BootstrapSelect = styled(Select)(({ theme }) => ({
  '&:focus': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
  },
  [`& .${selectClasses.select}`]: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
  '&:focus': {
    backgroundColor: '#EFEFEF',
  },
  [`& .${menuItemClasses.selected}`]: {
    backgroundColor: 'transparent !important',
  },
});
