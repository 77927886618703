import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'components/ConfirmationDialog';
import TransWrapper from 'components/TransWrapper';
import { ReuseIndexDomain } from 'sections/Reuse/types';
import { DeleteDomainDescription } from './components';

export interface DeleteDomainDialogProps {
  domain: ReuseIndexDomain;
  onClose: (ok: boolean, domain: ReuseIndexDomain) => void;
}

export default function DeleteDomainDialog({
  domain,
  onClose,
}: DeleteDomainDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('reuse.titles.deleteDomain')}
      buttonOKLabel={t('shared.actions.delete')}
      buttonCancelLabel={t('shared.actions.cancel')}
      open={!!domain}
      onClose={(ok: boolean) => onClose(ok, domain)}
    >
      <DeleteDomainDescription>
        <TransWrapper
          i18nKey="reuse.descriptions.deleteDomain"
          values={{ domainName: domain.displayName }}
          components={{ span: <span /> }}
        />
      </DeleteDomainDescription>
    </ConfirmationDialog>
  );
}
