import { Fade, Box } from '@mui/material';
import BusyIndicator from 'components/BusyIndicator';
import React from 'react';

export const Loading = () => (
  <Fade in={true}>
    <Box
      sx={{
        height:
          'calc(100vh - 64px - 19px - 28px - 15px - 33px - 40px - 131px - 25px - 150px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BusyIndicator />
    </Box>
  </Fade>
);
