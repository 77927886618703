import React from 'react';
import Grid from '@mui/material/Grid';

import { NewDeprecatedPhraseIndicator } from './components';
import DeprecatedPhrase from './DeprecatedPhrase';
import { ClusterPhrase } from '../../types';
import { useTranslation } from 'react-i18next';

export interface DeprecatedPhraseWrapperProps {
  clusterId: string | undefined;
  dPhrase: ClusterPhrase;
  duplicatedPhrases: number[];
  index: number;
  newAddedDeprecatedPhrases: string[];
  preferredPhrase: string;
  selectedLanguageId: string;
  updateDeprecatedPhrases: (
    value: string,
    index: number,
    toDelete?: boolean
  ) => void;
}

export default function DeprecatedPhraseWrapper({
  clusterId,
  dPhrase,
  duplicatedPhrases,
  index,
  newAddedDeprecatedPhrases,
  preferredPhrase,
  selectedLanguageId,
  updateDeprecatedPhrases,
}: DeprecatedPhraseWrapperProps) {
  const { t } = useTranslation();

  const newAddition = newAddedDeprecatedPhrases.includes(dPhrase.phrase);

  return (
    <Grid
      sx={(theme) => ({
        paddingBottom: theme.spacing(3),
      })}
    >
      {newAddition && clusterId && (
        <NewDeprecatedPhraseIndicator>
          {t('reuse.descriptions.newDeprecatedPhrase')}
        </NewDeprecatedPhraseIndicator>
      )}
      <DeprecatedPhrase
        value={dPhrase.phrase}
        index={index}
        updateDeprecatedPhrases={updateDeprecatedPhrases}
        duplicated={duplicatedPhrases.includes(index)}
        clusterId={clusterId}
        selectedLanguageId={selectedLanguageId}
        preferredPhrase={preferredPhrase}
      />
    </Grid>
  );
}
