import React from 'react';
import Grid from '@mui/material/Grid';
import WarningIcon from '../WarningIcon/WarningIcon';
import WarningText from '../WarningText';

export interface CommonWarningMessageProps {
  text: string;
  textContainerId?: string;
}
export default function CommonWarningMessage({
  text,
  textContainerId,
}: CommonWarningMessageProps) {
  return (
    <Grid
      sx={{ display: 'inline-flex' }}
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="flex-start"
    >
      <WarningIcon />
      <WarningText
        sx={(theme) => ({
          paddingTop: theme.spacing(3 / 5),
          marginLeft: theme.spacing(2),
        })}
        id={textContainerId}
      >
        {text}
      </WarningText>
    </Grid>
  );
}
