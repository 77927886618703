import FormControl from '@mui/material/FormControl';
import { StyledAccordionSummary as AccordionSummary } from 'components/FixedAccordion';
import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { blueGrey, grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { RecommendedRequiredButtonProps } from './RecommendedRequiredButton';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${blueGrey[100]}`,
  marginBottom: theme.spacing(4),
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(0.3),
  marginLeft: theme.spacing(0.3),

  '&:hover, &:focus, &:focus-within': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.custom.midDarkGrey}`,
    boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.15)',
  },
  '&::before': {
    backgroundColor: 'transparent',
  },
  '&.Mui-expanded': {
    margin: theme.spacing(0, 0.3, 4, 0.3),
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  margin: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
  border: 0,
  paddingLeft: theme.spacing(1),
  '&:hover, &:focus, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    border: 0,
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: 0,
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  borderRadius: '4px',
  display: 'inline-block',
}));

export const StyledPresetText = styled(Grid)(({ theme }) => ({
  width: theme.spacing(60),
  lineHeight: theme.typography.pxToRem(18),
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.14),
  border: `1px solid ${theme.palette.custom.linkWater}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.2),
  display: 'inline-block',
}));

export const WordsPhrasesSummaryContainer = styled(Grid)(({ theme }) => ({
  width: theme.spacing(70.4),
}));

export const GoalDisplayName = styled(Typography)<
  TypographyProps & { component: string }
>(({ theme }) => ({
  textAlign: 'left',
  letterSpacing: theme.typography.pxToRem(0.36),
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(18),
  margin: theme.spacing(0, 2),
}));

export const PresetLabel = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  color: theme.palette.custom.doveGrey,
  margin: theme.spacing(0, 2, 0, 0),
}));

export const SelectedParameter = styled(Typography)(({ theme }) => ({
  color: grey[600],
  letterSpacing: theme.typography.pxToRem(0.24),
  fontSize: theme.typography.pxToRem(12),
  margin: theme.spacing(0, 2, 0, 0),
  fontWeight: theme.typography.fontWeightBold,
}));

export const TerminologySummary = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0),
  lineHeight: theme.typography.pxToRem(15),
  fontSize: theme.typography.pxToRem(14),
}));

export const StyledRecommendedRequiredButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'readOnly',
})<RecommendedRequiredButtonProps>(({ theme, readOnly }) => ({
  border: theme.spacing(0),
  padding: 0,
  height: theme.typography.pxToRem(42),
  width: theme.typography.pxToRem(42),
  fontSize: theme.typography.pxToRem(18),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  '&:focus-visible': {
    outline: 'none',
  },
  '&:hover, &:focus': {
    backgroundColor: readOnly ? 'transparent' : blueGrey[50],
  },
  display: 'inline-block',
}));
