import React, { ReactElement } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import BusyIndicator from 'components/BusyIndicator';

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
}));

interface Props {
  open?: boolean;
}

export default function BusyOverlay({ open = true }: Props): ReactElement {
  return (
    <CustomBackdrop open={open} role="alert">
      <BusyIndicator />
    </CustomBackdrop>
  );
}
