import { DataForCheckboxes, Options } from './types';
import { NewAddedText } from 'sections/Targets/pages/TargetPage/types';
import {
  calculateAllChecked,
  calculateIndeterminate,
  handleChangeForCustomFields,
} from './helpers';
import React, { ChangeEvent } from 'react';
import OptionCheckbox from './OptionCheckbox';
import { TargetAssignment } from '../../../../types';

function AllOption({
  element,
  data,
  target,
  setNewTarget,
  newAddedTextList,
  calculateDisplayedChange,
}: {
  element: Options;
  data: DataForCheckboxes;
  target: TargetAssignment;
  setNewTarget: (target: TargetAssignment) => void;
  newAddedTextList: NewAddedText[];
  calculateDisplayedChange: (
    arrayWithOptions: string[],
    checked: boolean
  ) => void;
}): JSX.Element {
  const checked = calculateAllChecked(data, target);

  const indeterminate = calculateIndeterminate(
    checked,
    data,
    target,
    newAddedTextList
  );

  function changeHandler(
    event: ChangeEvent<{ value?: string }>,
    checked: boolean
  ) {
    if (data.customFieldName && data.propertyName) {
      handleChangeForCustomFields({
        checked,
        data,
        target,
        setNewTarget,
        all: true,
        value: event.target.value as string,
      });
      return;
    }

    calculateDisplayedChange(
      data.options.map((optObj) => optObj.id),
      checked
    );
  }

  return (
    <OptionCheckbox
      controlProps={{ indeterminate }}
      element={element}
      checked={checked}
      onChange={changeHandler}
    />
  );
}

export default AllOption;
