import React, { ChangeEvent, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';

import { RowGrid, RowGridWithSmallBottomPadding } from './styles';
import OptionCheckbox from './OptionCheckbox';
import { useTranslation } from 'react-i18next';
import { NewAddedText } from 'sections/Targets/pages/TargetPage/types';
import { CommonFilledDenseTextFieldWithActionAdornment } from 'components/CommonFilledDenseTextField';
import CommonTooltip from 'components/CommonTooltip/CommonTooltip';

export interface TextFieldAndNewAdditionsProps {
  addNewTextToList: (newText: string) => void;
  newAddedTextList: NewAddedText[];
  setNewAddedTextList: (list: NewAddedText[]) => void;
  textFieldError: string;
  onNewTextChange: (text: string) => void;
  assignedTexts: Set<string>;
  chosenOption: string;
}

export function TextFieldAndNewAdditions({
  addNewTextToList,
  newAddedTextList,
  setNewAddedTextList,
  textFieldError,
  onNewTextChange,
  assignedTexts,
  chosenOption,
}: TextFieldAndNewAdditionsProps): JSX.Element {
  const { t } = useTranslation();
  const [text, setText] = useState<string>('');

  const documentCustomField = t(
    'capture.descriptions.addRegexDocumentCustomField'
  );
  const documentReference = t('capture.descriptions.addRegexDocumentReference');

  return (
    <>
      <RowGridWithSmallBottomPadding>
        <CommonFilledDenseTextFieldWithActionAdornment
          error={!!textFieldError}
          errorText={textFieldError}
          placeholder={
            chosenOption === 'documentReference'
              ? documentReference
              : documentCustomField
          }
          fullWidth
          value={text}
          onChange={(evt: ChangeEvent<{ value: string }>) => {
            setText(evt.target.value);
            typeof onNewTextChange === 'function' &&
              onNewTextChange(evt.target.value);
          }}
          adornmentComponent={
            <CommonTooltip title={documentReference}>
              <IconButton
                disabled={!text.length || !!textFieldError}
                aria-label={t('capture.a11y.addRegex')}
                onClick={() => {
                  addNewTextToList(text);
                  setText('');
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </CommonTooltip>
          }
          underText={!textFieldError ? t('capture.descriptions.regexps') : ''}
        />
      </RowGridWithSmallBottomPadding>
      {!!newAddedTextList.length && (
        <RowGrid>
          {newAddedTextList.map((textObj, index) => (
            <OptionCheckbox
              key={textObj.text}
              checked={textObj.checked}
              element={{ id: textObj.text, label: textObj.text }}
              deletable={!assignedTexts.has(textObj.text)}
              onChange={(evt, checked) => {
                const newTextObj = { ...newAddedTextList[index] };
                newTextObj.checked = checked;
                const newList = [...newAddedTextList];
                newList[index] = newTextObj;

                setNewAddedTextList(newList);
              }}
            />
          ))}
        </RowGrid>
      )}
    </>
  );
}

export default TextFieldAndNewAdditions;
