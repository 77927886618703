export const REUSE_CLUSTERS_PATH = 'clusters';
export const REUSE_DOMAIN_DETAILS_PATH = 'reuse-domains/with-full-clusters';
export const REUSE_HARVESTING_TARGETS_PATH = 'targets/harvesting';
export const REUSE_IMPORTS_PATH = 'import';
export const REUSE_EXPORT_PATH = 'export';
export const REUSE_EQUALITY_CHECK = 'phrases/equality-check';
export const REUSE_SIMILARITY_CHECK = 'phrases/similarity-check';
export const REUSE_INBOX_PATH = 'reuse-inbox';
export const REUSE_ASSISTANT_PATH = 'reuse-assistant';
export const REUSE_SUGGESTIONS_STATUS_PATH = 'suggestions/status';
export const REUSE_SUPPORTED_LANGUAGES = ['en', 'de'];
export const REUSE_HARVESTING_STATUS_PATH = 'harvesting/info';
export const REUSE_HARVESTING_STATUS_KEY = 'harvesting status';

export const REUSE_PREFERRED_PHRASE_MAX_LENGTH = 500;
export const REUSE_DEPRECATED_PHRASE_MAX_LENGTH = 500;
export const REUSE_REPLACEMENT_DESCRIPTION_MAX_LENGTH = 150;

export const REUSE_EDIT_PRIVILEGE = 'ReuseManagement.edit';
export const REUSE_READ_PRIVILEGE = 'ReuseManagement.read';

export const REUSE_NOT_SHOW_DISMISS_SUGGESTION_DIALOG =
  'do-not-show-dismiss-reuse-suggestion-dialog';

export const SUGGESTIONS_PER_PAGE = 15;

export const DOMAIN_QUERY_KEY = 'fetch domain by id';

export const REFETCHING_INTERVAL_HARVESTING_STATUS = 60000;
