import React from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Grid, StandardTextFieldProps } from '@mui/material';
import CommonTooltip from 'components/CommonTooltip';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import {
  StyledErrorIcon,
  StyledFormControl,
  StyledGrid,
  StyledHelperTextContainer,
  StyledIconButton,
  StyledInputAdornment,
  StyledTextField,
} from '../styles';

export interface CommonOutlinedMultilineTextFieldWithInnerIconsProps
  extends Omit<StandardTextFieldProps, 'id'> {
  charCount?: number;
  fullWidth?: boolean;
  id: string;
  maxCharCount?: number;
  copyToClipboard?: boolean;
  deleteText?: boolean;
  deleteButtonClickHandler?: (evt: any) => void;
  value: string;
}

export default function CommonOutlinedMultilineTextFieldWithInnerIcons({
  charCount = 0,
  fullWidth = false,
  maxCharCount,
  copyToClipboard,
  deleteText,
  deleteButtonClickHandler,
  value,
  ...props
}: CommonOutlinedMultilineTextFieldWithInnerIconsProps) {
  const helperText = maxCharCount ? '' : props.helperText;
  const ariaId = `field-description-${props.id}`;
  const { t } = useTranslation();

  return (
    <StyledFormControl {...(fullWidth && { fullWidth })}>
      {/* @ts-ignore */}
      <StyledTextField
        {...props}
        multiline
        helperText={helperText}
        value={value}
        InputProps={{
          'aria-describedby': ariaId,
          ...(deleteText &&
            props.error && {
              endAdornment: (
                <StyledInputAdornment position="end">
                  <Grid
                    container
                    flexDirection="column"
                    alignItems="center"
                    sx={(theme) => ({ marginTop: theme.spacing(12) })}
                  >
                    <ErrorOutlineRoundedIcon color="error" />
                    <CopyToClipboardButton text={value} />
                    <CommonTooltip
                      placement="bottom-end"
                      title={<>{t('shared.tooltips.deleteDeprecatedPhrase')}</>}
                    >
                      <StyledIconButton
                        color="error"
                        size="small"
                        aria-label={t('shared.tooltips.deleteDeprecatedPhrase')}
                        onClick={deleteButtonClickHandler}
                      >
                        <DeleteOutlineIcon />
                      </StyledIconButton>
                    </CommonTooltip>
                  </Grid>
                </StyledInputAdornment>
              ),
            }),
        }}
        {...(copyToClipboard && {
          InputProps: {
            'aria-describedby': ariaId,
            endAdornment: (
              <StyledInputAdornment position="end">
                <CopyToClipboardButton text={value} />
              </StyledInputAdornment>
            ),
          },
        })}
        {...(copyToClipboard &&
          props.error && {
            InputProps: {
              'aria-describedby': ariaId,
              endAdornment: (
                <StyledInputAdornment position="end">
                  <StyledGrid container flexDirection="column">
                    <StyledErrorIcon color="error" />
                    <CopyToClipboardButton text={value} />
                  </StyledGrid>
                </StyledInputAdornment>
              ),
            },
          })}
        {...(deleteText && {
          InputProps: {
            'aria-describedby': ariaId,
            endAdornment: (
              <StyledInputAdornment position="end">
                <StyledGrid
                  container
                  flexDirection="column"
                  alignItems="center"
                >
                  <CopyToClipboardButton text={value} />
                  <CommonTooltip
                    placement="bottom-end"
                    title={<>{t('shared.tooltips.deleteDeprecatedPhrase')}</>}
                  >
                    <StyledIconButton
                      color="error"
                      size="small"
                      aria-label={t('shared.tooltips.deleteDeprecatedPhrase')}
                      onClick={deleteButtonClickHandler}
                    >
                      <DeleteOutlineIcon />
                    </StyledIconButton>
                  </CommonTooltip>
                </StyledGrid>
              </StyledInputAdornment>
            ),
          },
        })}
      />
      {maxCharCount && (
        <StyledHelperTextContainer
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="flex-start"
          flexWrap="nowrap"
          error={!!props.error}
        >
          {!props.error && <div>{props.helperText}</div>}
          {props.error && (
            <div role="alert" id={ariaId} aria-live="polite">
              {props.error && props.helperText}
            </div>
          )}
          <div className="charCount">
            {charCount} / {maxCharCount}
          </div>
        </StyledHelperTextContainer>
      )}
    </StyledFormControl>
  );
}
