import React, { memo } from 'react';

import GoalAccordion from '../GoalAccordion/GoalAccordion';
import GoalColorSvg from '../GoalColorSvg';
import { WordsPhrasesGoalProps } from '../../types';
import {
  useTerminologyGuideline,
  UseTerminologyGuidelineResult,
} from '../TerminologyGuideline/hooks';
import TerminologyGuideline from '../TerminologyGuideline';
import { useTargetGoal } from '../../hooks';
import { StyledWPAccordionDetails } from '../../styles';
import { useTranslation } from 'react-i18next';

function WordsPhrasesGoal(props: WordsPhrasesGoalProps) {
  const { t } = useTranslation();
  const { goal, goalConfig, expandedGoals } = props;
  const targetGoal = useTargetGoal(props.target, props.goal);

  const {
    domainSummary,
    filter,
    parameterValueObj,
    presetsSelectOptions,
    ...rest
  }: UseTerminologyGuidelineResult = useTerminologyGuideline(props);

  const selectedPreset = presetsSelectOptions.find(
    (x) => x.identifier === goalConfig.selectedPresetId
  );

  const goalWithWarning = props.guidelinesWithWarning[goal.identifier];

  const parameterSummary = !goalWithWarning?.length
    ? {
        summary: parameterValueObj.useTermFilter ? filter : domainSummary,
        translation: parameterValueObj.useTermFilter
          ? t('capture.labels.selectedFilter')
          : t('capture.labels.selectedDomains', {
              count: parameterValueObj?.domainNames?.length,
              interpolation: { escapeValue: false },
            }),
      }
    : {};

  return (
    <GoalAccordion
      goalColor={<GoalColorSvg color={goal.color} />}
      expandedGoals={expandedGoals}
      languageIdShort={props.languageIdShort}
      presets={false}
      recommended={goalConfig.recommended}
      goal={goal}
      parameterSummary={parameterSummary}
      presetsSelectOptions={presetsSelectOptions}
      setPreset={props.targetActions.setPreset}
      toggleRequiredRecommended={() => {
        props.targetActions.setRecommended(goal, !goalConfig.recommended);
      }}
      presetId={goalConfig.selectedPresetId}
      goalWithWarning={goalWithWarning}
      warningTranslationString={props.warningTranslationString}
      readOnly={props.readOnly}
    >
      <StyledWPAccordionDetails onClick={(ev) => ev.stopPropagation()}>
        {targetGoal && (
          <TerminologyGuideline
            {...props}
            {...rest}
            expandedGoals={props.expandedGoals}
            parameterValueObj={parameterValueObj}
            targetActions={props.targetActions}
            targetGoal={targetGoal}
            guidelineConfig={selectedPreset?.guidelineValues?.find(
              (x) => x.guidelineId === rest.guideline.identifier
            )}
          />
        )}
      </StyledWPAccordionDetails>
    </GoalAccordion>
  );
}

export default memo(WordsPhrasesGoal);
