import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { useFetch } from 'utils/fetch';

import { Trans, useTranslation } from 'react-i18next';

import type { UserTypeExtended } from '../UsersTable/components/UsersTableBodyRow';
import { BulkOperationResponse, User } from 'sections/Users/types';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { ApiError } from 'errors/ApiError';

interface ConfirmationDialogBulkRequestInformationUpdateProps {
  open: boolean;
  onClose: ({
    success,
    showNotification,
  }: {
    success: boolean;
    showNotification?: boolean;
  }) => void;
  items: UserTypeExtended[] | undefined;
}

export default function ConfirmationDialogBulkRequestInformationUpdate({
  items,
  onClose,
  open,
}: Readonly<ConfirmationDialogBulkRequestInformationUpdateProps>): ReactElement {
  const { t } = useTranslation();
  const http = useFetch();
  const { open: openErrorDialog } = useErrorNotificationDialog();

  const handleRequestUpdate = async (proceed: boolean) => {
    if (!items || !proceed) {
      onClose({ success: false });
      return;
    }

    try {
      const response = await http.put<
        BulkOperationResponse,
        ReadonlyArray<User['id']>
      >(
        '/api/v1/user/bulk/_request_cf_update',
        items.map(({ id }) => id)
      );

      if (response.errors.length) {
        openErrorDialog({
          ...apiErrorToNotification(
            new ApiError({
              title: t('users.apiError.bulkRequestUpdateUsersTitle'),
              detail: t(
                `users.apiError.${
                  response.errors.length === items.length
                    ? 'bulkRequestUpdateUsersDescriptionAllFailed'
                    : 'bulkRequestUpdateUsersDescription'
                }`,
                {
                  selection: items.length,
                  success: response.results.length,
                  failure: response.errors.length,
                }
              ),
              status: 207,
              type: 'BulkRequestUpdateUsersError',
              log: {
                request: {
                  url: '/api/v1/user/bulk/_request_cf_update',
                  method: 'PUT',
                },
                response: {
                  status: 207,
                  type: 'BulkRequestUpdateUsersError',
                  payload: response.errors,
                },
              },
            })
          ),
        });
        onClose({
          success: response.results.length > 0,
        });
      } else {
        onClose({
          success: true,
          showNotification: true,
        });
      }
    } catch (error) {
      openErrorDialog({
        ...apiErrorToNotification(error),
        onClose: () => {
          if (
            error instanceof ApiError &&
            (error.status === 403 || error.status === 404)
          ) {
            window.location.reload();
          }
        },
      });
      onClose({ success: false });
    }
  };

  if (!items) {
    return <></>;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={handleRequestUpdate}
      title={t('users.headers.requestInformationUpdate')}
      buttonOKLabel={t('users.actions.requestUpdateSubmitButton')}
      buttonCancelLabel={t('shared.actions.cancel')}
      ContentComponent={Box}
    >
      <Typography variant="body2">
        <Trans
          i18nKey="users.descriptions.requestInformationUpdate"
          count={items.length}
        >
          You're about to ask
          <Box
            component={'span'}
            display={'inline-block'}
            sx={{
              py: 2 / 5,
              px: 8 / 5,
              borderRadius: 1,
              backgroundColor: pink[50],
              color: pink.A700,
            }}
          >
            <>{{ count: items.length }}</>
          </Box>
          users to re-enter their user information the next time they connect to
          Acrolinx.
        </Trans>
      </Typography>
    </ConfirmationDialog>
  );
}
