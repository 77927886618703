import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)({
  minHeight: 'auto',
});

export const StyledTab = styled((props: { label: string }) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  letterSpacing: theme.typography.pxToRem(0.56),
  lineHeight: theme.typography.pxToRem(19),
  minHeight: 'auto',
  color: theme.palette.custom.darkBrownishGrey,
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: 'transparent',
  '&:hover': {
    borderBottomColor: theme.palette.primary.main,
  },
  '&:focus-visible': {
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
}));
