import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

interface AugmentedIconButtonProps extends IconButtonProps {
  active?: boolean;
}

const AugmentedIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<AugmentedIconButtonProps>(({ active = false, theme }) => ({
  height: theme.typography.pxToRem(32),
  width: theme.typography.pxToRem(32),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.light,
  },
  ...(active && {
    backgroundColor: theme.palette.action.selected,
  }),
  '& svg': {
    fill: theme.palette.primary.main,
  },
}));

export default AugmentedIconButton;
