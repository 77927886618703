import React, { useRef } from 'react';

import { UseGuidelineResult } from '../../hooks';
import { GuidelineSectionProps } from './GuidelineSection';
import {
  GuidelineRangeVoiceParameter,
  GuidelineValue,
  GuidelineVoiceParameter,
} from '../../types';
import { parseParameterValue } from '../SliderWithTextFields/helpers';
import EditableParameter from './EditableParameter';
import ReadOnlyParameter from './ReadOnlyParameter';
import SliderWithTextFields from '../SliderWithTextFields';

export type GeneralParameterProps = Omit<
  GuidelineSectionProps,
  'languageIdShort'
> &
  UseGuidelineResult;

export interface ParameterProps extends GeneralParameterProps {
  lastValue: React.MutableRefObject<string | [number, number]>;
  parameterObj: GuidelineRangeVoiceParameter | GuidelineVoiceParameter;
  parameterValue: string;
}

export default function Parameter(
  props: GeneralParameterProps
): JSX.Element | null {
  const parameterObj = props.guideline.parameter as
    | GuidelineVoiceParameter
    | GuidelineRangeVoiceParameter;
  const parameterValue = (props.guidelineConfig as GuidelineValue)
    .parameterValue as string;
  const lastValue = useRef<string | [number, number]>(
    parseParameterValue(parameterValue)
  );

  if (parameterObj?.type === 'integerrange') {
    const textFields = {
      tooltips: (parameterObj as GuidelineRangeVoiceParameter).tooltips,
      labels: (parameterObj as GuidelineRangeVoiceParameter).tooltips,
      values: JSON.parse(parameterValue) as [number, number],
    };

    return (
      <SliderWithTextFields textFields={textFields} parameterProps={props} />
    );
  }

  if (props.readOnly) {
    return (
      <ReadOnlyParameter
        lastValue={lastValue}
        parameterObj={parameterObj}
        parameterValue={parameterValue}
        {...props}
      />
    );
  }

  return (
    <EditableParameter
      lastValue={lastValue}
      parameterObj={parameterObj}
      parameterValue={parameterValue}
      {...props}
    />
  );
}
