import { useEffect, useState } from 'react';

export interface UseTextResult {
  setTextValue: (arg: string) => void;
  textValue: string;
  textValueCount: number;
}

export default function useText(
  defaultValue: string | number | boolean | null | undefined
): UseTextResult {
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    const valueWithoutNumber: string | boolean | null | undefined =
      typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue;
    const value: string | null | undefined =
      typeof valueWithoutNumber === 'boolean' ? '' : valueWithoutNumber;

    setTextValue(value ?? '');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    setTextValue,
    textValue,
    textValueCount: textValue.length,
  };
}
