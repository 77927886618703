import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

export const StyledWarningText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.pxToRem(0.24),
  marginLeft: theme.spacing(1),
  lineHeight: theme.typography.pxToRem(18),
}));

export default function WarningText({
  children,
  ...props
}: PropsWithChildren<TypographyProps & { component?: string }>) {
  return <StyledWarningText {...props}>{children}</StyledWarningText>;
}
