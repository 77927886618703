import React, { ForwardedRef, forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { ReactComponent as HelpIcon } from './helpIcon.svg';

export const HeaderBox = forwardRef(
  (props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        component="header"
        display="flex"
        alignItems="center"
        py={4}
        {...props}
        sx={(theme) => ({
          padding: theme.spacing(0.4, 0, 3.4),
        })}
      />
    );
  }
);

export const MainBoxPart = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<BoxProps & { type?: 'index' | 'details' }>(({ theme, type = 'index' }) => ({
  padding: theme.spacing(0, type === 'index' ? 6 : 4),
  display: 'block', // needed for IE11 compatibility
  overflowX: 'scroll',
}));

export const MainBox = styled('main')(({ theme }) => ({
  minHeight: 'fit-content',
  padding: theme.spacing(6, 0),
  background: theme.palette.background.paper,
  borderRadius: '6px',
  boxShadow: '0px 2px 4px #00000029',
  display: 'block', // needed for IE11 compatibility
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: 'fit-content',
  padding: theme.spacing(4, 0),
  display: 'block', // needed for IE11 compatibility
}));

export const MainBoxDivider = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
  margin: 0,
  borderBottom: `1px solid ${theme.palette.custom.lighterGrey}`,
}));

export const IndexPageLayoutComponent = styled('section')(({ theme }) => ({
  padding: theme.spacing(8),
  paddingTop: theme.spacing(4),
}));

export const MainPageLayoutComponent = styled('section')(({ theme }) => ({
  padding: theme.spacing(4, 8),
  display: 'flex',
  flexDirection: 'column',
  width: 'fill-available',
  height: '100%',
}));

export const PageHeaderBox = forwardRef(
  (props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        component="header"
        display="flex"
        alignItems="center"
        py={4}
        {...props}
        sx={(theme) => ({
          padding: theme.spacing(0.4, 0, 4),
          borderBottom: `1px solid ${theme.palette.custom.midLightGrey}`,
          '& h1': {
            lineHeight: theme.typography.pxToRem(32),
            letterSpacing: theme.typography.pxToRem(0.2),
          },
        })}
      />
    );
  }
);

export const MainPageMainBox = styled('main')(({ theme }) => ({
  minHeight: 'fit-content',
  padding: theme.spacing(4, 0),
  marginTop: theme.spacing(2),
  background: theme.palette.background.paper,
  display: 'block', // needed for IE11 compatibility
}));

export const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  fill: blueGrey[200],
  backgroundColor: 'transparent',
  borderRadius: '50%',
  '&:hover, &:focus': {
    fill: theme.palette.common.black,
    backgroundColor: blueGrey[50],
  },
}));

export const MainPageH2ContentHeader = styled((props: TypographyProps) => (
  <Typography component="h2" variant="h2" {...props} />
))(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.2),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

export const MainPageH3ContentHeader = styled((props: TypographyProps) => (
  <Typography component="h3" variant="h3" {...props} />
))(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.3),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const MainPageContentText = styled((props: TypographyProps) => (
  <Typography component="div" variant="body2" {...props} />
))(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
  letterSpacing: theme.typography.pxToRem(0.2),
  '& ul': {
    paddingLeft: theme.spacing(5),
    marginBottom: 0,
    listStyleType: 'disc',
    '& li': {
      padding: theme.spacing(1, 0),
    },
  },
}));

export const MainPageLearnMoreText = styled((props: TypographyProps) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  padding: theme.spacing(4, 0),
  letterSpacing: theme.typography.pxToRem(0.3),
}));
