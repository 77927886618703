import React, { useCallback } from 'react';

import ButtonPrimary from 'components/ButtonPrimary/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import {
  StyledDialogActionsContent,
  StyledWarningDialogDescription,
  StyledWarningDialogTitle,
  StyledWideDialogContent,
} from '../GuidelineSection/styles';
import TransWrapper from 'components/TransWrapper';
import DialogBox from 'components/DialogBox';
import { DialogReloadLSData, Goal, Guideline } from '../../types';
import { TerminologyGuidelineParameterValueObject } from '../TerminologyGuideline/hooks';
import { createFromAndTo, createFromAndToForStrings } from './helpers';
import { findGuidelineInGoals } from '../../helpers';
import { EnumContentObject } from 'types/types';

export function ReloadLSDialog({
  closeFn,
  okFn,
  open,
  data,
  configuredGoals,
  lang,
}: {
  closeFn: () => void;
  okFn: () => Promise<void>;
  open: boolean;
  data: DialogReloadLSData[];
  configuredGoals: Goal[];
  lang: string;
}): JSX.Element {
  const { t } = useTranslation();
  let component = null;

  const List = useCallback(
    ({
      changedGoals,
      lang,
    }: {
      changedGoals: DialogReloadLSData[];
      lang: string;
    }) => (
      <ul lang={lang}>
        {changedGoals.map((element) => (
          <li key={element.goalDisplayName}>{element.goalDisplayName}</li>
        ))}
      </ul>
    ),
    []
  );

  if (data.length > 1) {
    component = (
      <TransWrapper
        i18nKey="capture.descriptions.reloadRequiredManyGoals"
        components={{
          span: <span />,
          List: <List changedGoals={data} lang={lang} />,
        }}
      />
    );
  } else if (data.length) {
    const guideline = findGuidelineInGoals(
      configuredGoals,
      data[0].goalId,
      data[0].guidelineId
    ) as Guideline;

    const originalParameterValue:
      | TerminologyGuidelineParameterValueObject
      | string = data[0]?.parameterValueIsJSON
      ? JSON.parse(data[0]?.originalParameterValue || '{}')
      : data[0]?.originalParameterValue;
    const parameterValue: TerminologyGuidelineParameterValueObject | string =
      data[0]?.parameterValueIsJSON
        ? JSON.parse(data[0]?.parameterValue || '{}')
        : data[0]?.parameterValue;

    const { from, to } = data[0]?.parameterValueIsJSON
      ? createFromAndTo(
          originalParameterValue as TerminologyGuidelineParameterValueObject,
          parameterValue as TerminologyGuidelineParameterValueObject,
          t('capture.hints.noDomainOrFilter'),
          guideline.parameter?.enumContent as EnumContentObject[]
        )
      : createFromAndToForStrings(
          originalParameterValue as string,
          parameterValue as string
        );

    component = (
      <TransWrapper
        i18nKey="capture.descriptions.reloadRequired"
        values={{
          count: data.length,
          goalName: data[0]?.goalDisplayName,
          guidelineText: data[0]?.guidelineDisplayName,
          from,
          to,
        }}
        components={{ span: <span />, b: <b lang={lang} /> }}
      />
    );
  }

  // test-id was added to be able to find rendered element in tests
  // after mui5 changes tests see 2 presentation containers
  return (
    <DialogBox open={open} onClose={closeFn} data-testid="ReloadLSDialog">
      <StyledWarningDialogTitle variant="h6">
        {t('capture.titles.restartRequired')}
      </StyledWarningDialogTitle>
      <StyledWideDialogContent>
        <StyledWarningDialogDescription variant="body2" component="div">
          {component}
        </StyledWarningDialogDescription>
      </StyledWideDialogContent>
      <StyledDialogActionsContent>
        <ButtonPrimary
          onClick={() => {
            closeFn();
          }}
          variant="outlined"
          size="small"
        >
          {t('capture.actions.reloadLater')}
        </ButtonPrimary>
        <ButtonPrimary
          onClick={async () => {
            await okFn();
            closeFn();
          }}
          variant="contained"
          size="small"
        >
          {t('capture.actions.reloadNow')}
        </ButtonPrimary>
      </StyledDialogActionsContent>
    </DialogBox>
  );
}

export default ReloadLSDialog;
