import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export const StyledSelectMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: theme.typography.pxToRem(14),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  '&$selected': {
    backgroundColor: theme.palette.custom.brightGray,
  },
  '&:hover': {
    backgroundColor: `${theme.palette.custom.brightGray} !important`,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    lineHeight: theme.typography.pxToRem(18),
    fontSize: theme.typography.pxToRem(12),
  },
}));
