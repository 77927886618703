import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default styled(ExpandMoreIcon)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: theme.spacing(5),
  width: theme.spacing(8.4),
  height: theme.spacing(8.4),
  padding: theme.spacing(9 / 5),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.action.selected,
    outline: 'none',
  },
}));
