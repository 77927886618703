import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InnerCreateOrEditDomainDrawer from './InnerCreateOrEditDomainDrawer';
import CommonDrawer, { DrawerContent } from 'components/CommonDrawer';
import { CreateOrEditDomainDrawerProps } from 'sections/Reuse/types';
import { useInnerDrawer } from './hooks';

export default function CreateOrEditDomainDrawer({
  close,
  domain,
  domainsNames,
  drawerIsOpen,
  refresh,
}: CreateOrEditDomainDrawerProps) {
  const { t } = useTranslation();
  const { onSubmit, ...props } = useInnerDrawer({
    close,
    domain,
    domainsNames,
    refresh,
    drawerIsOpen,
  });
  const submitDisabled = !props.domainIsValid || !props.domainWasModified;
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );
  return (
    <CommonDrawer
      drawerTitle={t(
        domain ? 'reuse.titles.editDomain' : 'reuse.titles.addDomain',
        {
          domainName: domain?.displayName,
          interpolation: { escapeValue: false },
        }
      )}
      drawerIsOpen={drawerIsOpen}
      submitButtonClickHandler={onSubmit}
      submitButtonLabel={t(
        domain ? 'shared.actions.apply' : 'shared.actions.create'
      )}
      cancelAndCloseHandler={close}
      cancelButtonLabel={t('shared.actions.cancel')}
      submitErrorText={
        props.domainNameErrorMessage || props.domainDescriptionErrorMessage
      }
      submitDisabled={submitDisabled}
      setFooterRect={setDrawerFooterRect}
    >
      <DrawerContent>
        <InnerCreateOrEditDomainDrawer
          footerRect={drawerFooterRect}
          domainsNames={domainsNames}
          {...props}
        />
      </DrawerContent>
    </CommonDrawer>
  );
}
