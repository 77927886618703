import React from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';

export const StyledAllLabel = styled((props: TypographyProps) => (
  <Typography component="span" {...props} />
))(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.32),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
}));
