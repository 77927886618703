import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditSquareIcon } from './edit-square-icon.svg';
import { PrivilegesStructure } from 'hooks/usePrivileges';
import SplashBasicTile from './SplashBasicTile';

export default function TerminologyTile({
  privileges,
}: {
  privileges: PrivilegesStructure;
}) {
  const { t } = useTranslation();

  return (
    <SplashBasicTile
      href="/dashboard.html#_terminology"
      headerText={t('splashHomePage.terminology.header')}
      descriptionText={t(
        privileges.editRights
          ? 'splashHomePage.terminology.descriptionEdit'
          : 'splashHomePage.terminology.descriptionRead'
      )}
      goToText={t('splashHomePage.terminology.goToText')}
      Icon={EditSquareIcon}
    />
  );
}
