import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as LogoImage } from './icon-acrolinx-logo.svg';

import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactElement;
};

export default function LayoutForUnauthorized({ children, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <AppBar
        position="fixed"
        color={'inherit'}
        sx={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)' }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box pl={2}>
            <LogoImage
              title={t('shared.components.header.altLogo')}
              height={26.14}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component={'main'}
        sx={{
          mt: (theme) => `${theme.mixins.toolbar.minHeight}px`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
