import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import CommonErrorComponent from 'components/CommonErrorComponent';
import ButtonPrimary from 'components/ButtonPrimary';
import { MainPageLayoutComponent } from '../../components/PageLayout/components';

export interface APIErrorPageProps {
  errorCode?: '403' | '404';
}

function APIErrorPage({ errorCode = '404' }: APIErrorPageProps): ReactElement {
  const { t } = useTranslation();

  return (
    <MainPageLayoutComponent
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        width={1}
        textAlign="center"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(6),
        })}
      >
        <CommonErrorComponent
          errorTitle={`apiErrorPage.${errorCode}.header`}
          errorDescription={`apiErrorPage.${errorCode}.content`}
        />
        <ButtonPrimary
          variant="contained"
          {...{ component: Link, to: '/home' }}
        >
          {t(`apiErrorPage.${errorCode}.buttonLabel`)}
        </ButtonPrimary>
      </Box>
    </MainPageLayoutComponent>
  );
}

export default APIErrorPage;
