import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  DrawerContent,
  InnerDivider,
  ScrollableDrawerContent,
} from 'components/CommonDrawer';
import { ContentVerticalSpace } from './components';
import CommonSearchFilterTextField from 'components/CommonSearchFilterTextField';
import CheckboxList from 'components/CheckboxList/CheckboxList';
import { UseTargetSettingsResult } from './hooks';

export interface InnerTargetSettingsDrawerProps {
  checkboxesHeight: UseTargetSettingsResult['checkboxesHeight'];
  filterByText: UseTargetSettingsResult['filterByText'];
  filteredTargets: UseTargetSettingsResult['filteredTargets'];
  handleChange: UseTargetSettingsResult['handleChange'];
  refCheckboxes: UseTargetSettingsResult['refCheckboxes'];
  targetsForHarvesting: UseTargetSettingsResult['targetsForHarvesting'];
}

export default function InnerTargetSettingsDrawer({
  checkboxesHeight,
  filterByText,
  filteredTargets,
  handleChange,
  refCheckboxes,
  targetsForHarvesting,
}: InnerTargetSettingsDrawerProps) {
  const { t } = useTranslation();

  return (
    <>
      <DrawerContent sx={(theme) => ({ marginRight: theme.spacing(3.2) })}>
        <Typography variant="body2">
          {t('reuseInbox.descriptions.targetSettings')}
        </Typography>
        <ContentVerticalSpace />
        <CommonSearchFilterTextField
          fullWidth
          placeholder={t('shared.actions.search')}
          onChange={filterByText}
        />
        <ContentVerticalSpace spacingParam={4} />
      </DrawerContent>
      <InnerDivider />
      <ContentVerticalSpace spacingParam={5} />
      <ScrollableDrawerContent style={{ height: `${checkboxesHeight - 5}px` }}>
        <CheckboxList
          allCheckboxLabel={t('reuseInbox.labels.allTargets')}
          values={targetsForHarvesting}
          filteredValues={filteredTargets}
          handleValueChange={handleChange}
          valuesWereModified={false}
          ref={refCheckboxes}
        />
      </ScrollableDrawerContent>
      <ContentVerticalSpace />
    </>
  );
}
