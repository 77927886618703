import { createTheme } from '@mui/material/styles';
import {
  amber,
  blue,
  grey,
  blueGrey,
  pink,
  green,
  red,
} from '@mui/material/colors';
import React from 'react';
import LinkBehavior from 'components/LinkBehavior';
import { LinkProps } from '@mui/material/Link';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      [key: string]: React.CSSProperties['color'];
    };
    disabled: {
      [key: string]: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    custom: {
      [key: string]: React.CSSProperties['color'];
    };
    disabled: {
      [key: string]: React.CSSProperties['color'];
    };
  }
}

const PRIMARY_COLOR = blue[900];
const PRIMARY_COLOR_DARK = '#062A62';
const DEFAULT_SPACING = 5;
const CUSTOM_COLORS = {
  whiteSmoke: grey[100],
  whiteSmokeGrey: '#F7F7F7',
  fogGrey: '#F4F4F4',
  lightGrey: '#AFBDC5',
  darkGrey: '#373737',
  doveGrey: '#636363',
  lighterGrey: grey[300],
  green: '#00AA47',
  lightBluishGrey: `${blueGrey[100]}65`,
  bluishGrey: blueGrey[500],
  darkBluishGrey: blueGrey[700],
  midBluishGrey: '#778F9B',
  darkBrownishGrey: '#707070',
  greenSuccessHover: '#00933D',
  brownTextColor: '#524C00',
  oysterBay: '#E0F2F1',
  cyprus: '#004D40',
  niagara: '#25A58F',
  morningGlory: '#9ED1CE',
  lightYellow: '#FCFCEC',
  yellow: '#EFD50F',
  botticelli: '#90A4AE',
  freeSpeechRed: red['A700'],
  brightGray: '#EFEFEF',
  midGrey: '#8E8E8E',
  gray36: '#5C5C5C',
  gray55: '#555555',
  redViolet: pink['A700'],
  offWhite: '#FFFFFF67',
  aliceBlue: '#ECEFF1',
  skyBlue: `${PRIMARY_COLOR}67`,
  charcoal: '#767676',
  gray93: '#EDEDED',
  grey94: '#4E4E4E',
  grey95: '#F2F2F2',
  grey96: '#C3C3C3',
  grey97: '#F7F8FA',
  green50: green[50],
  nightSky: blueGrey[900],
  nightSkyWith6Opacity: `${blueGrey[900]}99`,
  selago: '#EBEAEB',
  skinner: '#00000066',
  marcia: '#00000065',
  cornflower: '#99C5EC',
  matterhorn: '#505050',
  lavenderBlush: '#FEEBEE',
  pennebaker: '#00C853',
  mediumSpringGreen: '#22F4A3',
  linkWater: '#CED4DA',
  darkGreen: '#003300',
  marengo: '#445963',
  grayishBlue: '#455A64', // remove marengo
  oceanDrive: '#B0BEC5',
  lightBlue: '#91B8F5',
  // unified ui colors
  midDarkGrey: '#606060',
  midLightGrey: '#E0E0E0',
};

// TODO: Think about color theming (primary/secondary) a bit more. Ask people who know our style guide.
const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  spacing: (factor: number) => `${(DEFAULT_SPACING / 16) * factor}rem`, // 16px is the standard rem value
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: blue[50], // #E3F2FD
      dark: PRIMARY_COLOR_DARK,
      contrastText: '#ffffff',
    },
    secondary: {
      main: PRIMARY_COLOR,
    },
    error: {
      main: red['A700'],
      light: red[50],
      dark: '#910000',
    },
    warning: {
      main: amber[600],
    },
    success: {
      main: green['A700'],
    },
    background: {
      paper: '#fff',
      default: blueGrey[50],
    },
    text: {
      primary: '#000',
      secondary: CUSTOM_COLORS.doveGrey,
    },
    action: {
      selected: blue[50],
    },
    custom: CUSTOM_COLORS,
    disabled: {
      primary: `${PRIMARY_COLOR}65`, // #0D47A165
      primarySolid: '#9FB6DA',
      error: `${red[900]}67`,
      contrastText: '#ffffff65',
      secondary: '#D3DADC',
    },
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 400,
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: theme.typography.pxToRem(18),
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: theme.typography.pxToRem(16),
};

theme.typography.body2 = {
  ...theme.typography.body2,
  lineHeight: theme.typography.pxToRem(21),
};

theme.mixins.toolbar = {
  minHeight: 70,
};

const exportableTheme = createTheme(theme, {
  /**
   * !!! IMPORTANT:
   * All changes in this section should be agreed first because they have
   * reach to whole project and also because these changes can cause
   * productivity decrease of productivity of whole team. Each implementation
   * should be a separate task.
   */
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          // Styles for scrollbars on Firefox
          scrollbarWidth: 'thin',
          scrollbarColor: `${grey[400]} ${theme.palette.common.white}`,
        },
        // Styles for scrollbars on Chrome, Safari, EDGE
        '*::-webkit-scrollbar': {
          width: 10,
          height: 10,
          backgroundColor: theme.palette.common.white,
        },
        '*::-webkit-scrollbar-thumb': {
          transition: 'all 0.3s ease-out',
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: theme.palette.common.white,
          backgroundClip: 'padding-box',
          borderRadius: 10,
          backgroundColor: grey[400],
          '&:hover': {
            borderWidth: 1,
            backgroundColor: grey[500],
          },
        },
        /**
         * Hide content visually while keeping it accessible to assistive technologies
         *
         * See: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
         * See: https://kittygiraudel.com/2016/10/13/css-hide-and-seek/
         *
         * !!! This class should NOT be used in v5, use https://mui.com/system/screen-readers/#heading-visually-hidden-elements instead.
         */
        '.visually-hidden': {
          position: 'absolute !important' as 'absolute',
          width: '1px!important',
          height: '1px!important',
          padding: '0!important',
          margin: '-1px!important',
          overflow: 'hidden!important',
          clip: 'rect(0,0,0,0)!important',
          whiteSpace: 'nowrap!important',
          border: '0!important',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(245, 245, 245, 0.9)',
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 2px 4px #0000004D',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 80,
          lineHeight: 1,
          letterSpacing: 0.14,
          '&[aria-disabled="true"]': {
            cursor: 'not-allowed', // Source: https://www.smashingmagazine.com/2021/08/frustrating-design-patterns-disabled-buttons/#making-disabled-buttons-more-inclusive
          },
        },
        contained: {
          padding: 10,
          paddingTop: 11,
          paddingBottom: 11,
          height: 36,
        },
        containedPrimary: {
          backgroundColor: PRIMARY_COLOR,
          '&:hover': {
            backgroundColor: PRIMARY_COLOR_DARK,
          },
          '&:focus, &$focusVisible': {
            backgroundColor: PRIMARY_COLOR_DARK,
          },
          '&:disabled, &$disabled': {
            color: 'white',
            backgroundColor: PRIMARY_COLOR,
            opacity: 0.4,
          },
        },
        outlined: {
          padding: 10,
          height: 36,
        },
        outlinedPrimary: {
          color: PRIMARY_COLOR,
          borderColor: PRIMARY_COLOR,
          '&:hover': {
            backgroundColor: blue[50],
          },
          '&:focus, &$focusVisible': {
            backgroundColor: blue[50],
          },
          '&$disabled': {
            color: PRIMARY_COLOR,
            borderColor: PRIMARY_COLOR,
            opacity: 0.4,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          '&:disabled, &$disabled': {
            color: PRIMARY_COLOR,
            opacity: 0.4,
          },
          '&:hover': {
            backgroundColor: blue[50],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          opacity: 1,
          width: '38px',
          height: '28px',
          padding: 0,
          position: 'relative',
          '&.Mui-checked .MuiSwitch-thumb': {
            opacity: 1,
          },
          '&:focus-within .MuiSwitch-switchBase, & .MuiSwitch-switchBase:hover':
            {
              backgroundColor: `${grey[400]}45`,
            },
          '&:focus-within .MuiSwitch-switchBase.Mui-checked, & .MuiSwitch-switchBase.Mui-checked:hover':
            {
              backgroundColor: blue[100],
            },
        },
        input: {
          left: 'auto',
          width: 'auto',
        },
        track: {
          backgroundColor: CUSTOM_COLORS.oceanDrive,
          opacity: 1,
          width: '23px',
          height: '10px',
          position: 'absolute',
          left: '9px',
          top: '9px',
        },
        thumb: {
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 1px 3px #0000004D;',
          width: '16px',
          height: '16px',
        },
        colorPrimary: {
          '&.MuiSwitch-switchBase.MuiButtonBase-root': {
            padding: '6px',
            '&.Mui-checked': {
              transform: 'translateX(9px)',
              '&:hover, &:focus-within': {
                backgroundColor: `${blue[300]}80`,
              },
            },
          },
          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
          },
          '&.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: CUSTOM_COLORS.lightBlue,
            opacity: 1,
            left: 'auto',
            right: '9px',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#E2E2E2',
            opacity: 1,
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            backgroundColor: theme.palette.custom.fogGrey,
          },
          '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
            backgroundColor: `${CUSTOM_COLORS.lightBlue}65`,
            opacity: 1,
          },
          '&.Mui-disabled.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.disabled.primarySolid,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          fontSize: theme.typography.pxToRem(14),
          transform: `translate(${theme.typography.pxToRem(
            10
          )}, ${theme.typography.pxToRem(14)}) scale(1)`,
          lineHeight: theme.typography.pxToRem(18),
          '&.Mui-disabled': {
            color: grey[400],
          },
        },
        shrink: {
          transform: `translate(${theme.typography.pxToRem(
            10
          )},${theme.typography.pxToRem(5)}) scale(0.857) !important`,
          maxWidth: `calc(100% - ${theme.typography.pxToRem(20)})`,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(14),
          overflow: 'hidden',
          backgroundColor: CUSTOM_COLORS.whiteSmoke,
          '&:hover, &:focus': {
            backgroundColor: CUSTOM_COLORS.whiteSmoke,
          },
          '&.Mui-disabled': {
            color: grey[400],
            backgroundColor: CUSTOM_COLORS.whiteSmoke,
          },
        },
        input: {
          fontSize: theme.typography.pxToRem(14),
          height: theme.spacing(3.6),
          lineHeight: theme.typography.pxToRem(18),
          paddingTop: theme.spacing(4.6),
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(1.4),
        },
        error: {},
        underline: {
          '&:before': {
            borderBottomColor: CUSTOM_COLORS.midGrey,
          },
          '&:hover': {
            '&:after': {
              transform: 'scaleX(1)',
            },
          },
          '&.Mui-error': {
            '&:before': {
              borderBottomColor: theme.palette.error.main,
            },
            '&:after': {
              transform: 'scaleX(0)',
            },
          },
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'solid',
              borderBottomColor: grey[400],
            },
            '&:after': {
              borderBottomColor: grey[400],
            },
          },
        },
        adornedEnd: {
          paddingRight: theme.spacing(2),
        },
        focused: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          fontSize: theme.typography.pxToRem(13),
          lineHeight: theme.typography.pxToRem(18),
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          '&.Mui-disabled': {
            color: grey[400],
          },
        },
        disabled: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          '&:focus': {
            backgroundColor: CUSTOM_COLORS.whiteSmoke,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18),
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '& svg': {
            width: theme.typography.pxToRem(22),
            height: theme.typography.pxToRem(22),
          },
        },
        colorPrimary: {
          color: theme.palette.primary.main,
          '&.Mui-disabled': {
            color: CUSTOM_COLORS.cornflower,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: CUSTOM_COLORS.cornflower,
          },
        },
      },
    },
  },
});

export default exportableTheme;
