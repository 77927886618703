import React from 'react';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { DeprecatedPhraseContainer, Phrase } from './components';
import { staticIconSx } from './helpers';

export interface SuggestionDeprecatedPhraseProps {
  deprecatedPhrase: string;
  lang: string;
}

export default function SuggestionDeprecatedPhrase({
  deprecatedPhrase,
  lang,
}: SuggestionDeprecatedPhraseProps) {
  return (
    <DeprecatedPhraseContainer>
      <HighlightOffRoundedIcon color="error" sx={staticIconSx} />
      <Phrase lang={lang}>{deprecatedPhrase}</Phrase>
    </DeprecatedPhraseContainer>
  );
}
