import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { listClasses, menuClasses } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import { useIsOpen } from 'hooks/useIsOpen';
import { BootstrapInput, StyledInputLabel } from './components';

export interface SmallOutlinedSelectCssProps {
  defaultInputWidth?: number;
  fixedWidth?: number;
  fullWidth?: boolean;
}

export type SmallOutlinedSelectProps = SmallOutlinedSelectCssProps &
  SelectProps;

export default function SmallOutlinedSelect({
  defaultInputWidth = 160,
  fullWidth = false,
  fixedWidth,
  ...selectProps
}: SmallOutlinedSelectProps) {
  const {
    elementIsOpen: dropdownIsOpen,
    open: openDropdown,
    close: closeDropdown,
    toggle: toggleDropdown,
  } = useIsOpen();
  const [hovered, setHovered] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLLabelElement>(null);
  const inputWidth = React.useRef<number>(defaultInputWidth);
  const id = selectProps.id || uuidv4();

  React.useEffect(() => {
    if (ref.current && ref.current?.clientWidth > 160 && !inputWidth.current) {
      inputWidth.current = ref.current.clientWidth;
    }
  }, [ref]);

  return (
    <FormControl
      size="small"
      fullWidth={fixedWidth ? false : fullWidth}
      variant="standard"
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      onBlur={() => {
        setHovered(false);
      }}
      sx={(theme) => ({
        ...(fixedWidth ? { width: theme.typography.pxToRem(fixedWidth) } : {}),
      })}
    >
      <StyledInputLabel
        ref={ref}
        id={`small-outlined-select-label-${id}`}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseOut={() => {
          setHovered(false);
        }}
        onClick={toggleDropdown}
        hovered={selectProps.disabled ? false : hovered}
        className={`smallOutlinedSelectLabel ${
          selectProps.disabled ? 'Mui-disabled' : ''
        }`}
        value={(selectProps.value as string | [])?.length}
      >
        {selectProps.label}
      </StyledInputLabel>
      <Select
        labelId={`small-outlined-select-label-${id}`}
        id={`small-outlined-select-${id}`}
        onChange={() => {}}
        open={dropdownIsOpen}
        onClose={closeDropdown}
        onOpen={openDropdown}
        input={<BootstrapInput width={inputWidth.current} hovered={hovered} />}
        MenuProps={{
          sx: (theme) => ({
            [`& .${listClasses.root}.${listClasses.padding}.${menuClasses.list}`]:
              {
                boxShadow: '0px 2px 4px #0000004D',
                borderRadius: '6px',
                padding: theme.spacing(2, 0),
              },
          }),
        }}
        {...selectProps}
      />
    </FormControl>
  );
}
