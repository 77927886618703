import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonAddIcon from 'icons/ButtonAddIcon';
import ButtonPrimary from 'components/ButtonPrimary';
import { ButtonProps } from '@mui/material/Button';

export interface AddReplacementButtonProps extends ButtonProps {
  onClickHandler: () => void;
}

export default React.forwardRef<HTMLButtonElement, AddReplacementButtonProps>(
  function AddReplacementButton(
    { onClickHandler, ...props }: AddReplacementButtonProps,
    ref
  ) {
    const { t } = useTranslation();

    return (
      <ButtonPrimary
        ref={ref}
        variant="contained"
        onClick={() => onClickHandler()}
        aria-label={t('reuse.actions.addReplacement')}
        {...props}
      >
        <ButtonAddIcon />
        {t('reuse.actions.addReplacement')}
      </ButtonPrimary>
    );
  }
);
