import {
  FormControl,
  InputAdornment,
  InputBase,
  InputBaseProps,
} from '@mui/material';
import React, { ReactElement } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';

export const DenseInputBase = styled(InputBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.6, 2, 1.6, 3),
  borderColor: grey[100],
  borderWidth: 1,
  borderStyle: 'solid',
  '&:hover:not($disabled)': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  backgroundColor: grey[100],
  [`& .${inputBaseClasses.input}`]: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(3.8),
    letterSpacing: theme.typography.pxToRem(0.14),
    color: theme.palette.custom.doveGrey,
    padding: 0,
    '&::placeholder': {
      color: theme.palette.custom.doveGrey,
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: theme.typography.pxToRem(0.28),
      opacity: 1,
    },
    // https://stackoverflow.com/a/12250084
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },
  [`& .${inputBaseClasses.focused}`]: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
  },
  '&.MuiInputBase-formControl': {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.custom.grey97,
  },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.custom.doveGrey,
  width: theme.spacing(3.1),
  height: theme.spacing(3.1),
}));

export default function SearchTextField(props: InputBaseProps): ReactElement {
  const { t } = useTranslation();
  const placeholder =
    props.placeholder || t('shared.components.input.placeholderSearch');

  return (
    <FormControl fullWidth={props.fullWidth}>
      <DenseInputBase
        endAdornment={
          <InputAdornment position="end">
            <StyledSearchIcon />
          </InputAdornment>
        }
        placeholder={placeholder}
        aria-label={placeholder}
        {...props}
      />
    </FormControl>
  );
}
