import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { Trans, useTranslation } from 'react-i18next';
import theme from 'theme';
import {
  Main,
  ErrorDescription,
  NextStepInstructionsTitle,
  RedirectImage,
  NextStepInstructionListItem,
} from './styles';

const InternalServerErrorComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Main role="main">
      <Box mt={7} mb={3}>
        <Box width={500} display="block" ml={'auto'} mr={'auto'}>
          <Box px={9} textAlign={'center'}>
            <RedirectImage
              title={t('shared.serverError.images.altServerError')}
            />
            <Box pt={5} pb={2}>
              <Typography variant="h1">
                {t('shared.serverError.title')}
              </Typography>
            </Box>
            <Box>
              <ErrorDescription variant="body1" gutterBottom>
                {t('shared.serverError.description')}
              </ErrorDescription>
            </Box>
          </Box>
          <hr style={{ border: '0.5px solid #E0E0E0' }} />
          <Box textAlign={'left'} ml={10} mt={2} width={380}>
            <NextStepInstructionsTitle gutterBottom>
              {t('shared.serverError.suggestions.title')}
            </NextStepInstructionsTitle>
            <ul style={{ margin: 0, paddingLeft: theme.spacing(3) }}>
              <NextStepInstructionListItem>
                {t('shared.serverError.suggestions.suggestion1')}
              </NextStepInstructionListItem>
              <NextStepInstructionListItem>
                {t('shared.serverError.suggestions.suggestion2')}
              </NextStepInstructionListItem>
              <NextStepInstructionListItem>
                <Trans i18nKey="shared.serverError.suggestions.suggestion3">
                  Email Acrolinx Support at
                  <Link
                    href="mailto:support@acrolinx.com"
                    onClick={(event: React.SyntheticEvent) =>
                      event.preventDefault()
                    }
                  >
                    support@acrolinx.com
                  </Link>
                  if you{"'"}re still having trouble.
                </Trans>
              </NextStepInstructionListItem>
            </ul>
          </Box>
        </Box>
      </Box>
    </Main>
  );
};

export default InternalServerErrorComponent;
