export interface CreateStringErrorMessageArgs {
  maxLength?: number;
  minLength?: number;
  stringLength: number;
  tooLongErrorText?: string;
  tooShortErrorText?: string;
}

export default function createStringErrorMessage({
  maxLength = Number.MAX_SAFE_INTEGER,
  minLength = 0,
  stringLength,
  tooLongErrorText = '',
  tooShortErrorText = '',
}: CreateStringErrorMessageArgs) {
  if (minLength > stringLength) {
    return tooShortErrorText;
  }

  if (maxLength < stringLength) {
    return tooLongErrorText;
  }

  return '';
}
