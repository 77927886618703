import React, { ChangeEvent } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRadioGroup = styled(RadioGroup)({
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(0.6),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.light,
  },
  alignSelf: 'flex-start',
  marginTop: theme.spacing(-0.8),
  '&.Mui-disabled, &[aria-disabled="true"]': {
    color: theme.palette.disabled.primary,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(0),
  marginTop: theme.spacing(0.8),
  marginBottom: theme.spacing(1.2),
  '& .MuiTypography-root': {
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(0.2),
  },
}));

export interface CommonRadioGroupDataItem {
  label: JSX.Element | string;
  value: string;
  key: string;
  disabled?: boolean;
}

export type CommonRadioGroupProps = RadioGroupProps & {
  changeHandler: (
    event: ChangeEvent<{ value?: unknown }>,
    checked: boolean
  ) => void;
  data: CommonRadioGroupDataItem[];
  numberOfColumns?: number;
  'aria-label'?: string;
} & ({ defaultValue: string | undefined } | { value: string | undefined });

export function CommonRadioGroup({
  changeHandler,
  data,
  numberOfColumns = 1,
  sx,
  ...rest
}: CommonRadioGroupProps): JSX.Element {
  const props: RadioGroupProps = {
    name: rest.name ?? 'radio-group',
    sx,
    ...(rest['aria-label'] && { 'aria-label': rest['aria-label'] }),
  };

  if ('value' in rest) {
    props.value = rest.value;
  } else if ('defaultValue' in rest) {
    props.defaultValue = rest.defaultValue;
  }

  return (
    <StyledRadioGroup {...props}>
      {data.map((element) => (
        <StyledFormControlLabel
          sx={{
            width: `${(1 / numberOfColumns) * 100 - 3}%`,
          }}
          key={element.key}
          value={element.value}
          disabled={element.disabled}
          label={
            <Typography
              variant="body2"
              sx={(theme) => ({
                letterSpacing: theme.typography.pxToRem(0.28),
              })}
            >
              {element.label}
            </Typography>
          }
          onChange={changeHandler}
          control={<StyledRadio color="primary" />}
        />
      ))}
    </StyledRadioGroup>
  );
}

export default CommonRadioGroup;
