import { styled } from '@mui/material/styles';
import Slider, { sliderClasses } from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import ParameterTextField from '../ParameterTextField';
import { blueGrey } from '@mui/material/colors';

export const StyledSlider = styled(Slider)(({ theme }) => ({
  [`& .${sliderClasses.valueLabelOpen}`]: {
    backgroundColor: theme.palette.common.black,
  },
  [`& .${sliderClasses.rail}`]: {
    color: blueGrey[100],
    opacity: 1,
  },
  '&.Mui-disabled': {
    [`& .${sliderClasses.track}`]: {
      color: theme.palette.custom.oceanDrive,
    },
  },
  [`& .${sliderClasses.thumb}`]: {
    '&:hover': {
      boxShadow: '0px 0px 0px 5px rgba(30, 136, 229, 0.16)',
    },
    '&.Mui-disabled': {
      color: theme.palette.custom.oceanDrive,
    },
  },
}));

export const StyledSpace = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

export const StyledParameterTextField = styled(ParameterTextField)(
  ({ theme }) => ({
    minWidth: theme.spacing(12),
    maxWidth: theme.spacing(12),
  })
);
