import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

import ButtonPrimary from 'components/ButtonPrimary';
import { ReactComponent as IconWarning } from 'icons/IconWarning.svg';
import { LIMIT_COUNT_SELECTED_ITEMS } from '../../constants';

import { useTranslation } from 'react-i18next';

const StyledIconWarning = styled(IconWarning)<SvgIconProps>(({ theme }) => ({
  verticalAlign: 'middle',
  marginRight: theme.spacing(2),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& 	.MuiDialog-paper': {
    marginTop: theme.spacing(4),
    boxShadow: '0px 3px 6px #00000029',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledDialogContentText = styled(DialogContentText)({
  fontSize: 14,
  lineHeight: '20px',
  width: '470px',
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
}));

type Props = {
  selectionCount: number;
};

export default function SelectionLimitAlertDialog({ selectionCount }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(
    selectionCount >= LIMIT_COUNT_SELECTED_ITEMS
  );

  React.useEffect(() => {
    if (selectionCount >= LIMIT_COUNT_SELECTED_ITEMS) {
      setIsOpen(true);
    }
  }, [selectionCount]);

  return (
    <StyledDialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      hideBackdrop={true}
      aria-describedby="modal-selection-limit-dialog-description"
      PaperProps={{
        elevation: 0,
      }}
    >
      <StyledDialogContent
        sx={{
          marginBottom: '4px',
        }}
      >
        <StyledDialogContentText id="modal-selection-limit-dialog-description">
          <StyledIconWarning />
          {t('users.descriptions.selectionLimit')}
        </StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <ButtonPrimary
          variant="contained"
          onClick={() => setIsOpen(false)}
          autoFocus
        >
          {t('users.actions.selectionLimitAcknowledgement')}
        </ButtonPrimary>
      </StyledDialogActions>
    </StyledDialog>
  );
}
