import React, { ReactElement, useCallback } from 'react';

import DateComponent from './DateComponent';
import ConditionalWrapper from 'components/ConditionalWrapper';
import CommonTooltip from 'components/CommonTooltip';
import { getFormattedDateTime } from './helpers';

export type DisplayDateProps = {
  date: string | null | undefined;
  tooltipVisible?: boolean;
  showOnlyExisting?: boolean;
  notExistingDateReplacement?: string;
};

export default function DisplayDate({
  date,
  notExistingDateReplacement,
  showOnlyExisting,
  tooltipVisible,
}: DisplayDateProps) {
  const dateExists = !!(date && new Date(date).getTime());

  const { formattedDate } = getFormattedDateTime(date);

  const wrapper = useCallback(
    (children: ReactElement) => {
      return (
        <CommonTooltip title={formattedDate}>
          <span>{children}</span>
        </CommonTooltip>
      );
    },
    [formattedDate]
  );

  if (showOnlyExisting && !dateExists) {
    return <>{notExistingDateReplacement}</>;
  }

  return (
    <ConditionalWrapper
      condition={!!tooltipVisible && dateExists}
      wrapper={wrapper}
    >
      <>
        <DateComponent date={date as string} />
      </>
    </ConditionalWrapper>
  );
}
