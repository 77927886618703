import React, { ReactElement } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

export interface TimeoutLogoutNotificationProps {
  open: boolean;
  onClose: () => void;
}

const TimeoutLogoutNotification = ({
  open,
  onClose,
}: TimeoutLogoutNotificationProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('sessions.timeout.logout.title')}
      buttonOKLabel={t('shared.actions.ok')}
    >
      {t('sessions.timeout.logout.description')}
    </ConfirmationDialog>
  );
};

export default TimeoutLogoutNotification;
