import React from 'react';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';

import DomainChip from 'components/DomainChip';
import { TerminologyGuidelineParameterValueObject } from './hooks';
import { EnumContentObject } from 'types/types';

export interface ChosenDomainsChipsProps {
  disabled?: boolean;
  domainData: EnumContentObject[];
  onDelete: (arg: string) => void;
  openWarningModal?: () => void;
  parameterValueObj: TerminologyGuidelineParameterValueObject;
  presetWarningModalShouldAppear?: boolean;
  readOnly: boolean;
  setWarningModalOKCallback?: (callback: () => void) => void;
}

export default function ChosenDomainsChips({
  disabled = false,
  domainData,
  onDelete,
  openWarningModal,
  parameterValueObj,
  presetWarningModalShouldAppear,
  readOnly,
  setWarningModalOKCallback,
}: ChosenDomainsChipsProps) {
  const { t } = useTranslation();

  return (
    <>
      {parameterValueObj.domainNames?.map((domainId, index) => {
        const domainName = (
          domainData.find((domain) => domain.internalId === domainId) || {}
        )?.displayName;

        return (
          <DomainChip
            domainName={domainName as string}
            deleteIcon={
              readOnly ? (
                <></>
              ) : (
                <CancelIcon
                  role="img"
                  aria-label={t('capture.labels.removeDomain', {
                    domainName,
                  })}
                  sx={(theme) => ({
                    fill: theme.palette.grey[600],
                    '&:hover': {
                      fill: theme.palette.custom.darkGrey,
                    },
                  })}
                />
              )
            }
            disabled={disabled}
            key={domainId}
            label={domainName}
            onDelete={() => {
              function onDeleteHandler() {
                const domainNames = [...(parameterValueObj.domainNames || [])];
                domainNames.splice(index, 1);
                onDelete(
                  JSON.stringify({
                    ...parameterValueObj,
                    domainNames: domainNames,
                  })
                );
              }

              if (
                presetWarningModalShouldAppear &&
                setWarningModalOKCallback &&
                openWarningModal
              ) {
                setWarningModalOKCallback(() => onDeleteHandler);
                openWarningModal();
                return;
              }

              onDeleteHandler();
            }}
          />
        );
      })}
    </>
  );
}
