import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactComponent as LogoutImage } from './logout.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link, { LinkProps } from '@mui/material/Link';

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const StyledAppBar = styled(AppBar)(() => ({
  zIndex: 1201,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
}));

export const AcrolinxLogo = styled('img')(({ theme }) => ({
  width: theme.spacing(30),
}));

export const PageContainerLogout = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(20),
}));

export const StyledLink = styled(Link)<LinkProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(21),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledLogoutImage = styled(LogoutImage)(({ theme }) => ({
  height: theme.typography.pxToRem(200),
  width: theme.typography.pxToRem(200),
}));

export const LogoutTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  lineHeight: theme.typography.pxToRem(30),
  letterSpacing: theme.typography.pxToRem(0.48),
  fontWeight: theme.typography.fontWeightRegular as number,
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(4),
}));

export const LogoutDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightRegular as number,
  lineHeight: theme.typography.pxToRem(24),
}));

export const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  minHeight: 'fit-content',
  margin: theme.spacing(26, 5, 6, 5),
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(9),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  display: 'block', // needed for IE11 compatibility
}));
