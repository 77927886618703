import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { DO_NOT_EXPAND_CLASS } from 'constants/constants';

export default function GoalRequiredIcon() {
  return (
    <SvgIcon
      viewBox="0 0 20 18"
      className={DO_NOT_EXPAND_CLASS}
      sx={(theme) => ({ fill: theme.palette.custom.grayishBlue })}
      fontSize="inherit"
    >
      <path
        className={DO_NOT_EXPAND_CLASS}
        d="M7.5,21H2V9H7.5ZM14.75,3H9.25V21h5.5ZM22,11H16.5V21H22Z"
        transform="translate(-2 -3)"
      />
    </SvgIcon>
  );
}
