import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { DO_NOT_EXPAND_CLASS } from 'constants/constants';

export default function GoalRecommendedIcon() {
  return (
    <SvgIcon
      viewBox="0 0 20.86 20.267"
      className={DO_NOT_EXPAND_CLASS}
      sx={(theme) => ({ fill: theme.palette.custom.grayishBlue })}
      fontSize="inherit"
    >
      <path
        className={DO_NOT_EXPAND_CLASS}
        d="M-.341.891-1.748,2.283,3.466,7.437H-.741V19.216h5.46V8.676l1.735,1.718v8.822h5.46V15.791l1.738,1.718v1.707h1.725l1.962,1.942,1.407-1.391Zm6.8.656V4.862l5.46,5.4V1.547Zm7.2,7.853v2.578l5.46,5.4V9.4Z"
        transform="translate(1.748 -0.891)"
      />
    </SvgIcon>
  );
}
