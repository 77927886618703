import { useContext, useState } from 'react';

import UserContext from 'contexts/UserContext';
import { useFetch } from 'utils/fetch';
import { ApiError } from 'errors/ApiError';
import { apiErrorToNotification } from 'errors/utils';
import { useHistory } from 'react-router-dom';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';

interface CreateApiTokenHooksProps {
  username?: string;
  userId: string;
}

interface CreateApiTokenHooksReturn {
  generateToken: () => Promise<boolean>;
  newApiToken: string;
  isUserEditingSelf: boolean;
}

type GenerateAPITokenResponse = {
  links: {};
  data: {
    type: string;
    issuedAt: string;
    expiresAt: string;
    token: string;
  };
};

export function useApiToken({
  userId,
  username,
}: CreateApiTokenHooksProps): CreateApiTokenHooksReturn {
  const user = useContext(UserContext);

  const [newApiToken, setNewApiToken] = useState<string>('');
  const history = useHistory();
  const { open } = useErrorNotificationDialog();
  const http = useFetch();

  const isUserEditingSelf = username === user?.id;

  const generateToken = async () => {
    try {
      const data = await http.post<GenerateAPITokenResponse, void>(
        `/api/v1/user/${isUserEditingSelf ? 'self' : userId}/tokens`
      );

      setNewApiToken(data?.data.token);
    } catch (error) {
      open({
        ...apiErrorToNotification(error),
        onClose: () => {
          if (error instanceof ApiError) {
            if (error.status === 403) {
              window.location.reload();
            } else if (error.status === 404) {
              history.replace({ pathname: '/user-management/users' });
            }
          }
        },
      });
      return false;
    }

    return true;
  };

  return {
    generateToken,
    newApiToken,
    isUserEditingSelf,
  };
}
