import React from 'react';
import { useTranslation } from 'react-i18next';

import { SplashPageContentWrapper, SplashTileContainer } from './styles';

import { useHomeSplashPage } from './hooks';
import AnalyticsTile from './AnalyticsTile';
import TargetsTile from './TargetsTile';
import TerminologyTile from './TerminologyTile';
import UserManagementTile from './UserManagementTile';

export default function Home() {
  const { t } = useTranslation();
  const {
    analyticsPrivileges,
    analyticsTileIsVisible,
    targetPrivileges,
    targetTileIsVisible,
    terminologyPrivileges,
    terminologyTileIsVisible,
    userManagementPrivileges,
    userManagementTileIsVisible,
  } = useHomeSplashPage();

  const anyElementIsVisible =
    analyticsTileIsVisible ||
    targetTileIsVisible ||
    terminologyTileIsVisible ||
    userManagementTileIsVisible;

  return (
    <SplashPageContentWrapper pageTitle={t('splashHomePage.title')}>
      {anyElementIsVisible && (
        <SplashTileContainer>
          {targetTileIsVisible && <TargetsTile privileges={targetPrivileges} />}
          {terminologyTileIsVisible && (
            <TerminologyTile privileges={terminologyPrivileges} />
          )}
          {analyticsTileIsVisible && (
            <AnalyticsTile privileges={analyticsPrivileges} />
          )}
          {userManagementTileIsVisible && (
            <UserManagementTile privileges={userManagementPrivileges} />
          )}
        </SplashTileContainer>
      )}
    </SplashPageContentWrapper>
  );
}
