import React, { ReactElement } from 'react';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Animation } from './icon-busy-indicator.svg';

const IconWrapper = styled(Box)(({ theme }) => ({
  boxShadow: '0px 3px 5px #00000034',
  backgroundColor: theme.palette.background.paper,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export default function BusyIndicator({
  title,
}: {
  title?: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <Fade in={true} timeout={1000}>
      <Box display="flex" justifyContent="center" flexDirection={'column'}>
        <Box display="flex" justifyContent="center">
          <IconWrapper
            p={1.4}
            display="inline-block"
            borderRadius="50%"
            width={50}
            height={50}
            role="progressbar"
            aria-labelledby="busyLabel"
          >
            <Animation />
          </IconWrapper>
        </Box>
        <Box pt={3}>
          <Title variant="body2" gutterBottom align={'center'} id="busyLabel">
            {title || t('shared.loading.title')}
          </Title>
        </Box>
      </Box>
    </Fade>
  );
}
