import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';

export const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: 'white',
  p: 0,
}));

export const CustomTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3, 2),
  lineHeight: 1,
  transition: theme.transitions.create(['background-color']),
  '&:focus': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
  },
  '&:hover': {
    color: theme.palette.text.primary,
  },
  [`& .${tableSortLabelClasses.icon}`]: {
    color: `${theme.palette.common.black} !important`,
  },
}));
