import React from 'react';

import {
  StyledGuidelineParameterLabel,
  StyledGuidelineParameterLabelReadOnly,
} from './styles';
import { ParameterProps } from './Parameter';

export default function ReadOnlyParameter(props: ParameterProps) {
  switch (props.parameterObj?.type) {
    case 'number':
    case 'integer':
      return (
        <>
          <StyledGuidelineParameterLabelReadOnly>
            {props.parameterValue}
          </StyledGuidelineParameterLabelReadOnly>
          {!!props.parameterObj.label && (
            <StyledGuidelineParameterLabel variant="body2">
              {props.parameterObj.label}
            </StyledGuidelineParameterLabel>
          )}
        </>
      );
    case 'enum': {
      const valueToDisplay = props.parameterObj.enumContent.find(
        (po) => po.internalId === props.parameterValue
      );
      return (
        <StyledGuidelineParameterLabelReadOnly>
          {valueToDisplay?.displayName}
        </StyledGuidelineParameterLabelReadOnly>
      );
    }
    default:
      return null;
  }
}
