import React, { ReactElement, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CommonTooltip from 'components/CommonTooltip';
import ButtonPrimary from 'components/ButtonPrimary';
import AugmentedIconButton from '../AugmentedIconButton';
import { ReactComponent as FilterListIcon } from 'icons/icon-filter.svg';
import { User } from 'sections/Users/types';
import { LS_USERS_CURRENT_PAGE_KEY } from '../../constants';
import {
  ListPanel,
  StyledChip,
  StyledDoneIcon,
  StyledLongTextOptionTooltip,
  StyledPopover,
} from './styles';

// Helper to toggle element in array
// https://stackoverflow.com/questions/17153238/how-to-toggle-an-element-in-array-using-javascript
const addOrRemove = (arr: string[], item: string) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

interface RolesSelectorProps {
  roles?: User['roles'];
  selectedRoles: User['roles'];
  onChange: (selectedRoles: User['roles']) => void;
}

const RolesSelector = ({
  roles = [],
  selectedRoles,
  onChange,
}: RolesSelectorProps): ReactElement => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(selectedRoles.map(({ id }) => id));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    setSelected(selectedRoles.map(({ id }) => id));
  }, [selectedRoles, anchorEl]);

  // Sort roles by name in alphabetical and ascending
  const sortedRoles = [...roles].sort((roleA, roleB) =>
    roleA.name.localeCompare(roleB.name)
  );

  const [visibleTooltipId, setVisibleTooltipId] = useState<string | boolean>(
    false
  );

  return (
    <>
      <CommonTooltip title={<>{t('users.tooltips.openRolesFilter')}</>}>
        <AugmentedIconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          role="combobox"
          aria-label={t('users.tooltips.openRolesFilter')}
          aria-controls="roles-filter-popover"
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          active={Boolean(anchorEl)}
        >
          <FilterListIcon color="primary" />
        </AugmentedIconButton>
      </CommonTooltip>
      <StyledPopover
        id="roles-filter-popover"
        role="dialog"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ListPanel overflow="hidden" p={3} pt={4} maxHeight={220}>
          {sortedRoles.map(({ id, name }) => (
            <StyledLongTextOptionTooltip
              key={id}
              title={name}
              placement="bottom-start"
              open={visibleTooltipId === id}
              onOpen={(event: React.SyntheticEvent) => {
                const target = event.target as HTMLElement;
                setVisibleTooltipId(
                  target.scrollWidth > target.clientWidth ? id : false
                );
              }}
              onClose={() => {
                setVisibleTooltipId(false);
              }}
              disableFocusListener
              disableTouchListener
            >
              <StyledChip
                role="checkbox"
                aria-checked={
                  !!selected.find((selectedId) => selectedId === id)
                }
                label={name}
                clickable
                onClick={() => {
                  setSelected((prevState) => addOrRemove(prevState, id));
                }}
                {...(!!selected.find((selectedId) => selectedId === id) && {
                  icon: <StyledDoneIcon data-testid="done-checkmark-icon" />,
                })}
              />
            </StyledLongTextOptionTooltip>
          ))}
        </ListPanel>
        <Box
          display="flex"
          justifyContent="flex-end"
          borderTop={1}
          p={3}
          borderColor={'custom.lighterGrey'}
        >
          <ButtonPrimary
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          >
            {t('shared.actions.cancel')}
          </ButtonPrimary>
          <Box ml={2}>
            <ButtonPrimary
              variant="contained"
              disabled={!selected.length}
              onClick={() => {
                onChange(roles.filter(({ id }) => selected.includes(id)));
                localStorage.removeItem(LS_USERS_CURRENT_PAGE_KEY);

                handleClose();
              }}
            >
              {t('users.actions.setFilter')}
            </ButtonPrimary>
          </Box>
        </Box>
      </StyledPopover>
    </>
  );
};

export default RolesSelector;
