import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { useFetch } from 'utils/fetch';

import { useTranslation } from 'react-i18next';

import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { ApiError } from 'errors/ApiError';
import { User } from 'sections/Users/types';

interface ConfirmationDialogRequestInformationUpdateProps {
  open: boolean;
  onClose: ({
    success,
    showNotification,
  }: {
    success: boolean;
    showNotification?: boolean;
  }) => void;
  user?: User;
}

export default function ConfirmationDialogRequestInformationUpdate({
  user,
  onClose,
  open,
}: Readonly<ConfirmationDialogRequestInformationUpdateProps>): ReactElement {
  const { t } = useTranslation();
  const http = useFetch();
  const { open: openErrorDialog } = useErrorNotificationDialog();

  const handleRequestUpdate = async (proceed: boolean) => {
    if (!user || !proceed) {
      onClose({ success: false });
      return;
    }

    try {
      await http.put(`/api/v1/user/${user.id}/_request_cf_update`);
      onClose({
        success: true,
        showNotification: true,
      });
    } catch (error) {
      openErrorDialog({
        ...apiErrorToNotification(error),
        onClose: () => {
          if (
            error instanceof ApiError &&
            (error.status === 403 || error.status === 404)
          ) {
            window.location.reload();
          }
        },
      });
      onClose({ success: false });
    }
  };

  if (!user) {
    return <></>;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={handleRequestUpdate}
      title={t('users.headers.requestInformationUpdate')}
      buttonOKLabel={t('users.actions.requestUpdateSubmitButton')}
      buttonCancelLabel={t('shared.actions.cancel')}
      ContentComponent={Box}
    >
      <Box pb={1}>
        <Box mb={4}>
          <Typography variant="body2">
            {t('users.descriptions.requestInformationUpdate', { count: 1 })}
          </Typography>
        </Box>
        <Box
          component={'span'}
          display={'inline-block'}
          sx={{
            backgroundColor: pink[50],
            color: pink.A700,
            display: 'inline-block',
            px: 2,
            py: 1,
            borderRadius: 1,
            fontSize: 14,
          }}
        >
          {user.username}
        </Box>
      </Box>
    </ConfirmationDialog>
  );
}
