import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { REUSE_DEPRECATED_PHRASE_MAX_LENGTH } from 'sections/Reuse/constants';

import { UpdateDeprecatedPhrasesFn } from './AdvancedSettings';
import WarningDuplicatedPhrase from '../WarningDuplicatedPhrase/WarningDuplicatedPhrase';

import CommonOutlinedMultilineTextFieldWithInnerIcons from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextFieldWithInnerIcons';
import FormInformationBox from 'components/FormInformationBox';
import { useEqualityCheck, useSimilarityCheck } from './hooks';

export interface DeprecatedPhraseProps {
  clusterId: string | undefined;
  duplicated: boolean;
  index: number;
  preferredPhrase: string;
  selectedLanguageId: string;
  updateDeprecatedPhrases: UpdateDeprecatedPhrasesFn;
  value: string;
}

export default function DeprecatedPhrase({
  clusterId,
  duplicated,
  index,
  preferredPhrase,
  selectedLanguageId,
  updateDeprecatedPhrases,
  value,
}: DeprecatedPhraseProps) {
  const { t } = useTranslation();
  const { equalityState: replacementsWithTheSameDeprecatedPhrase } =
    useEqualityCheck({
      phrase: value,
      language: selectedLanguageId,
      preferred: false,
      clusterId,
    });
  const duplicatedErrorMessage = duplicated
    ? t('reuse.errors.deprecatedPhraseIsTheSameAsPreferredPhrase')
    : '';
  const tooLongErrorMessage =
    value.length > REUSE_DEPRECATED_PHRASE_MAX_LENGTH
      ? t('reuse.errors.replacementDeprecatedPhraseIsTooLong', {
          maxAllowedLength: REUSE_DEPRECATED_PHRASE_MAX_LENGTH,
        })
      : '';
  const errorMessage = duplicatedErrorMessage || tooLongErrorMessage;
  const phraseIsSimilarToPreferred = useSimilarityCheck({
    phrase: value,
    duplicated,
    preferredPhrase,
    language: selectedLanguageId,
  });

  const onChangeHandler = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      updateDeprecatedPhrases(evt.target.value, index);
    },
    [index, updateDeprecatedPhrases]
  );

  const onDeleteHandler = useCallback(
    () => updateDeprecatedPhrases(value, index, true),
    [index, updateDeprecatedPhrases, value]
  );

  return (
    <>
      <CommonOutlinedMultilineTextFieldWithInnerIcons
        deleteText={!!value}
        id={`deprecatedPhrase${index}`}
        charCount={value.trim().length}
        maxCharCount={REUSE_DEPRECATED_PHRASE_MAX_LENGTH}
        label={t('reuse.labels.deprecatedPhrase')}
        fullWidth
        error={!!errorMessage}
        helperText={errorMessage || t('reuse.hints.optional')}
        onChange={onChangeHandler}
        rows="4"
        value={value}
        deleteButtonClickHandler={onDeleteHandler}
      />
      {!!replacementsWithTheSameDeprecatedPhrase.length && (
        <WarningDuplicatedPhrase />
      )}
      {phraseIsSimilarToPreferred && (
        <Grid>
          <FormInformationBox
            informationText={t(
              'reuse.descriptions.deprecatedPhraseIsSimilarToPreferred'
            )}
          />
        </Grid>
      )}
    </>
  );
}
