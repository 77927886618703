import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageTargetFilterDropdownsProps } from './LanguageTargetFilterDropdowns';
import { CheckboxValue } from 'types/types';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import {
  createLanguageListFromFullLanguages,
  flattenLanguageTargetStructure,
  getListStatus,
} from './helpers';

export type TargetLanguageStructure = Record<string, CheckboxValue[]>;

export interface UseLanguageTargetFilterResult {
  allLanguagesCheckbox: CheckboxValue;
  allTargetsCheckbox: CheckboxValue;
  languageDropdownValue: string[];
  languageList: CheckboxValue[];
  targetDropdownValue: string[];
  targetList: CheckboxValue[];
  updateLanguage: (newListObject: CheckboxValue, index: number) => void;
  updateTarget: (newListObject: CheckboxValue, index: number) => void;
  updateAll: (checked: boolean) => void;
}

export function useLanguageTargetFilter({
  languages = [],
  setFilteredSuggestionList,
  sortedTargets,
  suggestionList,
}: LanguageTargetFilterDropdownsProps): UseLanguageTargetFilterResult {
  const { t } = useTranslation();
  const [targetLanguageStructure, setTargetLanguageStructure] =
    useState<TargetLanguageStructure | null>(null);
  const [languageList, setLanguageList] = useState<CheckboxValue[]>([]);
  const [targetList, setTargetList] = useState<CheckboxValue[]>([]);
  const [allLanguagesCheckbox, setAllLanguagesCheckbox] =
    useState<CheckboxValue>({
      label: t('reuseInbox.labels.allLanguagesFilter'),
      id: t('reuseInbox.labels.allLanguagesFilter'),
      checked: true,
    });
  const [allTargetsCheckbox, setAllTargetsCheckbox] = useState<CheckboxValue>({
    label: t('reuseInbox.labels.allTargetsFilter'),
    id: t('reuseInbox.labels.allTargetsFilter'),
    checked: true,
  });

  const updateFilteredSuggestionListAllOrNone = useCallback(
    (allChecked: boolean) => {
      if (allChecked) {
        setFilteredSuggestionList(suggestionList);
      }

      if (!allChecked) {
        setFilteredSuggestionList([]);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [suggestionList]
  );

  const updateTarget = useCallback(
    (newListObject: CheckboxValue, index: number) => {
      const newTargetList = [...targetList];
      newTargetList[index] = newListObject;
      setTargetList(newTargetList);

      const { allChecked, someChecked, noneChecked } =
        getListStatus(newTargetList);

      setAllTargetsCheckbox({
        ...allTargetsCheckbox,
        checked: allChecked,
        indeterminate: !noneChecked && someChecked,
      });

      if (allChecked || noneChecked) {
        const newLanguagesList = languageList.map((l) => ({
          ...l,
          checked: allChecked,
        }));

        setLanguageList(newLanguagesList);

        const {
          allChecked: allLanguagesChecked,
          someChecked: someLanguagesChecked,
          noneChecked: noneLanguagesChecked,
        } = getListStatus(newLanguagesList);

        setAllLanguagesCheckbox({
          ...allLanguagesCheckbox,
          checked: allLanguagesChecked,
          indeterminate: !noneLanguagesChecked && someLanguagesChecked,
        });
      } else {
        const allLanguages = Object.keys(
          targetLanguageStructure as TargetLanguageStructure
        );
        const checkedLanguages = allLanguages.filter((languageName) =>
          (targetLanguageStructure as TargetLanguageStructure)[
            languageName
          ].some((target) =>
            newTargetList.find((t) => t.id === target.id && t.checked)
          )
        );
        const newLanguagesList = createLanguageListFromFullLanguages(
          targetLanguageStructure as TargetLanguageStructure,
          languages,
          allLanguages,
          checkedLanguages
        );

        setLanguageList(newLanguagesList);

        const {
          allChecked: allLanguagesChecked,
          someChecked: someLanguagesChecked,
          noneChecked: noneLanguagesChecked,
        } = getListStatus(newLanguagesList);

        setAllLanguagesCheckbox({
          ...allLanguagesCheckbox,
          checked: allLanguagesChecked,
          indeterminate: !noneLanguagesChecked && someLanguagesChecked,
        });
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [allTargetsCheckbox, languageList, targetLanguageStructure, targetList]
  );

  const updateLanguage = useCallback(
    (newListObject: CheckboxValue, index: number) => {
      const newLanguageList = [...languageList];
      newLanguageList[index] = { ...newListObject };
      setLanguageList(newLanguageList);

      const { allChecked, someChecked, noneChecked } =
        getListStatus(newLanguageList);

      setAllLanguagesCheckbox({
        ...allLanguagesCheckbox,
        checked: allChecked,
        indeterminate: !noneChecked && someChecked,
      });

      if (allChecked || noneChecked) {
        const newTargetList = targetList.map((t) => ({
          ...t,
          checked: allChecked,
        }));
        setTargetList(newTargetList);
        const {
          allChecked: allTargetsChecked,
          someChecked: someTargetsChecked,
          noneChecked: noneTargetsChecked,
        } = getListStatus(newTargetList);
        setAllTargetsCheckbox({
          ...allTargetsCheckbox,
          checked: allTargetsChecked,
          indeterminate: !noneTargetsChecked && someTargetsChecked,
        });
      } else {
        const languageDisplayName = newLanguageList[index].label.match(
          /^(.*?)(?=\s\(|$)/g
        ) ?? [''];

        const affectedTargets = (
          targetLanguageStructure as TargetLanguageStructure
        )[languageDisplayName[0]];

        const newTargetList = targetList.map((target) => ({
          ...target,
          checked: affectedTargets.find((at) => at.id === target.id)
            ? newListObject.checked
            : target.checked,
        }));

        setTargetList(newTargetList);

        const {
          allChecked: allTargetsChecked,
          someChecked: someTargetsChecked,
          noneChecked: noneTargetsChecked,
        } = getListStatus(newTargetList);

        setAllTargetsCheckbox({
          ...allTargetsCheckbox,
          checked: allTargetsChecked,
          indeterminate: !noneTargetsChecked && someTargetsChecked,
        });
      }
    },
    [
      allLanguagesCheckbox,
      allTargetsCheckbox,
      languageList,
      targetLanguageStructure,
      targetList,
    ]
  );

  const updateAll = useCallback(
    (checked: boolean) => {
      setLanguageList(languageList.map((l) => ({ ...l, checked })));
      setTargetList(targetList.map((l) => ({ ...l, checked })));
      setAllLanguagesCheckbox({
        ...allLanguagesCheckbox,
        checked: checked,
        indeterminate: false,
      });
      setAllTargetsCheckbox({
        ...allTargetsCheckbox,
        checked: checked,
        indeterminate: false,
      });
      updateFilteredSuggestionListAllOrNone(checked);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [languageList, targetList]
  );

  // update filteredSuggestionList on change of targetList
  useDidMountEffect(() => {
    setFilteredSuggestionList(
      suggestionList.filter((suggestion) =>
        targetList.find(
          (target) =>
            suggestion.targetDisplayNames.includes(
              target.originalLabel as string
            ) && target.checked
        )
      )
    );
  }, [targetList]);

  useDidMountEffect(() => {
    if (languages.length && sortedTargets) {
      const newStructure = sortedTargets.reduce((acc, target) => {
        const lngObj = { ...acc };
        const language = languages.find(
          (language) =>
            target.languageVariantDisplayName ===
            language.languageVariantDisplayName
        )?.displayName as string;

        if (language) {
          if (!lngObj[language]) {
            lngObj[language] = [];
          }

          const relatedSuggestions = suggestionList.filter((suggestion) =>
            suggestion.targetDisplayNames.includes(target.displayName)
          ).length;

          if (relatedSuggestions) {
            lngObj[language].push({
              // for the first calculation everything is checked
              checked: true,
              id: target.uuid,
              label: `${target.displayName} (${relatedSuggestions})`,
              lang: target.languageId.split(':')[0],
              disabled: false,
              originalLabel: target.displayName,
              data: relatedSuggestions,
            });
          }

          if (!lngObj[language].length) {
            delete lngObj[language];
          }
        }

        return lngObj;
      }, {} as TargetLanguageStructure);

      const newLanguages = Object.keys(newStructure);

      const languagesArray = createLanguageListFromFullLanguages(
        newStructure,
        languages,
        newLanguages,
        true
      );

      setTargetLanguageStructure(newStructure);

      const sortedTargetsWithSuggestions = flattenLanguageTargetStructure(
        newStructure
      ).sort((a, b) =>
        (a.originalLabel as string).localeCompare(b.originalLabel as string)
      );

      setAllLanguagesCheckbox({
        ...allLanguagesCheckbox,
        checked: true,
        indeterminate: false,
      });
      setAllTargetsCheckbox({
        ...allTargetsCheckbox,
        checked: true,
        indeterminate: false,
      });
      setLanguageList(languagesArray);
      setTargetList(sortedTargetsWithSuggestions);
    }
  }, [languages, sortedTargets, suggestionList]);

  const languageDropdownValue = useMemo(() => {
    if (languageList.length === 1) {
      return [languageList[0].id];
    }

    if (!targetList.length) {
      return [];
    }

    return (
      allLanguagesCheckbox.checked ? [allLanguagesCheckbox.id] : []
    ).concat(languageList.filter((l) => l.checked).map((l) => l.id));
  }, [
    allLanguagesCheckbox.checked,
    allLanguagesCheckbox.id,
    languageList,
    targetList,
  ]);
  const targetDropdownValue = useMemo(() => {
    const base = allTargetsCheckbox.checked ? [allTargetsCheckbox.id] : [];

    return targetList.length
      ? base.concat(targetList.filter((l) => l.checked).map((l) => l.id))
      : [];
  }, [allTargetsCheckbox.checked, allTargetsCheckbox.id, targetList]);

  return {
    allLanguagesCheckbox,
    allTargetsCheckbox,
    languageDropdownValue,
    languageList,
    targetDropdownValue,
    targetList,
    updateLanguage,
    updateTarget,
    updateAll,
  };
}
