import React, { KeyboardEvent } from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

import { DetailsPageLayout } from 'components/PageLayout';
import InformationOutlinedRoundedIcon from 'icons/InformationOutlinedRoundedIcon';
// import CommonSearchFilterTextField from 'components/CommonSearchFilterTextField';
import { MainBoxDivider } from 'components/PageLayout/components';
import BusyIndicator from 'components/BusyIndicator';
import CreateOrEditReplacementDrawer from 'sections/Reuse/components/CreateOrEditReplacementDrawer';

import CommonTooltip from 'components/CommonTooltip';
import {
  useDomainDetailPage,
  useEditDomain,
  useDeleteReplacement,
  useUpdateReplacementActive,
} from './hooks';
import {
  StyledEditButton,
  StyledGrid,
  StyledReplacementTitle,
} from 'components/PageLayout/styles';

import CommonStartPageNoData from 'components/CommonStartPage/CommonStartPageNoData';
import { ReuseIndexDomain } from 'sections/Reuse/types';
import CreateOrEditDomainDrawer from 'sections/Reuse/components/CreateOrEditDomainDrawer';
import useTitle from 'hooks/useTitle';
import DeleteReplacementDialog from './components/DeleteReplacementDialog';
import AddReplacementButton from 'sections/Reuse/components/AddReplacementButton';
import usePrivileges from 'sections/Reuse/hooks/usePrivileges';
import { A11Y_KEYBOARD_CODES_FOR_CLICK } from 'constants/constants';
import CommonLockIconButton from 'components/CommonLockIconButton';
import EditableClusterRow from './EditableClusterRow';
import ReadonlyClusterRow from './ReadonlyClusterRow';
import { useFetchLanguages } from 'hooks/useFetchLanguages';
import { LanguageFull } from 'types/types';

export default function DomainDetailPage() {
  const { t } = useTranslation();
  const {
    closePhraseDrawer,
    clusterForEdit,
    domain,
    domainList,
    openPhraseDrawer,
    phraseDrawerIsOpen,
    refreshDomain,
    reuseDomainResult,
    setClusterForEdit,
  } = useDomainDetailPage();

  const {
    replacementForDeleteConfirmation,
    setReplacementForDeleteConfirmation,
    closeDeleteReplacementConfirmationDialog,
  } = useDeleteReplacement(refreshDomain);

  const { updateActive } = useUpdateReplacementActive(refreshDomain);

  const { closeDomainDrawer, drawerDomainIsOpen, openDomainDrawer } =
    useEditDomain();

  const { data: languages } = useFetchLanguages<LanguageFull>('full');

  const { editRights, readRights } = usePrivileges();

  useTitle(
    `${domain?.displayName} - ${t('reuse.title')} - ${t('shared.app.name')}`
  );

  if (!editRights && !readRights) {
    return <Redirect to="/403" />;
  }

  return (
    <DetailsPageLayout
      backLink="/reuse"
      backIconText={t('reuse.a11y.backIcon')}
      heading={domain?.displayName}
      rightSideHeaderComponent={
        editRights && (
          <CommonTooltip
            title={<> {t('reuse.tooltips.editDomain')} </>}
            placement="bottom-end"
          >
            <StyledEditButton
              color="primary"
              onClick={() => openDomainDrawer()}
              aria-label={t('reuse.tooltips.editDomain')}
              disableFocusRipple
            >
              <EditIcon />
            </StyledEditButton>
          </CommonTooltip>
        )
      }
    >
      <Box my={2}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection="row"
          flexWrap="nowrap"
          aria-label={`${domain?.displayName} ${t('reuse.a11y.reuseInfo')}`}
          sx={{ height: '100%' }}
        >
          <Grid
            display="inline-flex"
            alignItems="center"
            sx={{ height: '100%' }}
          >
            {editRights && (
              <AddReplacementButton
                onClickHandler={() => {
                  setClusterForEdit(null);
                  openPhraseDrawer();
                }}
                sx={(theme) => ({
                  marginRight: theme.spacing(10),
                })}
              />
            )}

            {domain?.description && (
              <Grid display="inline-flex">
                <InformationOutlinedRoundedIcon />
                <Typography
                  sx={{
                    maxWidth: '370px',
                  }}
                  variant="body2"
                >
                  {domain?.description}
                </Typography>
              </Grid>
            )}
          </Grid>
          {/*<Grid display="inline-flex">
            <CommonSearchFilterTextField placeholder="Search Replacements" sx={(theme: Theme) => ({ width: theme.spacing(50) })} />
          </Grid>*/}
          {!editRights && readRights && (
            <StyledGrid>
              <CommonLockIconButton
                tooltipText={t('reuse.tooltips.readonly')}
              />
            </StyledGrid>
          )}
        </Grid>
      </Box>
      {!reuseDomainResult.data ? (
        <Box pt={20} pb={17}>
          <BusyIndicator />
        </Box>
      ) : (
        <>
          {/*<MainBoxDivider />
          <MainBoxPart></MainBoxPart>*/}
          <MainBoxDivider />

          {!editRights && readRights && (
            <StyledReplacementTitle component="h2">
              {t('reuse.titles.replacements')}
            </StyledReplacementTitle>
          )}

          {!!domain?.clusters.length ? (
            <div>
              {domain?.clusters.map((cluster) => {
                function openReplacementDrawer() {
                  setClusterForEdit(cluster);
                  openPhraseDrawer();
                }

                function openDeleteDialog() {
                  setReplacementForDeleteConfirmation(cluster);
                }

                function openOnKeyboardEvent(
                  event: KeyboardEvent<HTMLDivElement>
                ) {
                  if (
                    A11Y_KEYBOARD_CODES_FOR_CLICK.includes(
                      event.code.toLowerCase()
                    ) &&
                    !phraseDrawerIsOpen
                  ) {
                    openReplacementDrawer();
                  }
                }

                return editRights ? (
                  <EditableClusterRow
                    key={cluster.uuid}
                    cluster={cluster}
                    openDeleteDialog={openDeleteDialog}
                    openReplacementDrawer={openReplacementDrawer}
                    updateActive={updateActive}
                  />
                ) : (
                  <ReadonlyClusterRow
                    key={cluster.uuid}
                    cluster={cluster}
                    openReplacementDrawer={openReplacementDrawer}
                    openOnKeyboardEvent={openOnKeyboardEvent}
                  />
                );
              })}
            </div>
          ) : (
            <>
              <CommonStartPageNoData
                title={t('reuse.hints.almostThere')}
                description={t('reuse.hints.addReplacement')}
              />
            </>
          )}
        </>
      )}
      <>
        {!!domain && !!domainList && phraseDrawerIsOpen && (
          <CreateOrEditReplacementDrawer
            cluster={clusterForEdit}
            drawerIsOpen={phraseDrawerIsOpen}
            closeDrawer={closePhraseDrawer}
            domain={domain}
            domainList={domainList}
            refresh={refreshDomain}
            editRights={editRights}
            languages={languages}
          />
        )}
      </>
      <>
        {editRights && (
          <>
            {replacementForDeleteConfirmation && (
              <DeleteReplacementDialog
                replacement={replacementForDeleteConfirmation}
                onClose={closeDeleteReplacementConfirmationDialog}
              />
            )}
            {!!domain && (
              <CreateOrEditDomainDrawer
                drawerIsOpen={drawerDomainIsOpen}
                close={closeDomainDrawer}
                domainsNames={domainList.map(
                  (d: ReuseIndexDomain) => d.displayName
                )}
                domain={domain}
                refresh={refreshDomain}
              />
            )}
          </>
        )}
      </>
    </DetailsPageLayout>
  );
}
