import React from 'react';
import { useTranslation } from 'react-i18next';

import SaveCommentButton from '../../components/SaveCommentButton';
import { UseCommentResult } from '../../hooks/useComment';

export interface EditTargetWithCommentProps {
  commentData: UseCommentResult;
  disabled: boolean;
  submitAction: () => void;
}

export default function EditTargetWithComment({
  commentData,
  disabled,
  submitAction,
}: EditTargetWithCommentProps) {
  const { t } = useTranslation();

  return (
    <SaveCommentButton
      cancelButtonLabel={t('capture.actions.cancel')}
      submitButtonClickHandler={submitAction}
      submitButtonLabel={t('capture.actions.save')}
      disabled={disabled}
      tooltipText={t('capture.tooltips.saveWithComment')}
      {...commentData}
    />
  );
}
