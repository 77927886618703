import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { BlackTooltip } from 'components/CommonTooltip';
import Chip, { ChipProps } from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { User } from 'sections/Users/types';

const StyledRoleChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.custom.oysterBay,
  color: theme.palette.custom.cyprus,
  marginRight: theme.spacing(1),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.custom.morningGlory,
  },
  '.MuiChip-deleteIcon': {
    color: theme.palette.custom.niagara,
    '&:hover': {
      color: theme.palette.custom.cyprus,
    },
  },
}));

interface Props {
  activeRoles: User['roles'];
  onRemove(roleId: User['id']): void;
}

export default function RolesFilterStatePanel({
  activeRoles,
  onRemove,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {activeRoles.length > 0 && (
        <>
          <Typography
            variant="body2"
            style={{ paddingRight: 5, fontWeight: 500 }}
            component="span"
          >
            {t('roles.title')}:
          </Typography>
          {activeRoles.map(({ id, name }) => (
            <StyledRoleChip
              size="small"
              key={id}
              label={name}
              aria-label={t('users.a11y.removeFilter', { roleName: name })}
              onClick={() => onRemove(id)}
              onDelete={() => onRemove(id)}
              deleteIcon={
                <BlackTooltip title={<>{t('users.tooltips.removeFilter')}</>}>
                  <CancelIcon
                    fontSize="small"
                    aria-label={t('users.tooltips.removeFilter')}
                  />
                </BlackTooltip>
              }
            />
          ))}
        </>
      )}
    </>
  );
}
