import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { revertSortOrder, sortBy, SortOrder } from 'utils/utils';

export interface UseSortResult {
  sortBy: <T>(
    array: T[],
    field: keyof T,
    order: SortOrder,
    secondarySortField: keyof T
  ) => T[];
  sortField: any;
  sortOrder: SortOrder;
  setSorting: (newSortField: string) => void;
}

export default function useSort(defaultSortField: string): UseSortResult {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [sortField, setSortField] = useState<string>(
    query.get('sortOrder') || defaultSortField
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    (query.get('sortOrder') as SortOrder) || SortOrder.asc
  );

  const setSorting = useCallback(
    (newSortField: string) => {
      setSortField(newSortField);
      const newSortOrder =
        sortField === newSortField ? revertSortOrder(sortOrder) : SortOrder.asc;
      setSortOrder(newSortOrder);

      history.replace({
        search: `sortOrder=${newSortOrder}&sortField=${newSortField}`,
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [sortField, sortOrder]
  );

  return {
    sortBy,
    sortField,
    sortOrder,
    setSorting,
  };
}
