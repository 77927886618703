import React from 'react';

import DialogBox from 'components/DialogBox';
import ButtonPrimary from 'components/ButtonPrimary/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import {
  StyledDialogActionsContent,
  StyledDialogContent,
  StyledWarningDialogDescription,
  StyledWarningDialogTitle,
} from '../GuidelineSection/styles';

export default function CustomPresetChangeWarningDialog({
  cancelFn,
  closeFn,
  okFn,
  open,
}: {
  cancelFn: () => void;
  closeFn: () => void;
  okFn: () => void;
  open: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <DialogBox open={open} onClose={closeFn}>
      <StyledWarningDialogTitle variant="h6">
        {t('capture.titles.updateCustomPreset')}
      </StyledWarningDialogTitle>
      <StyledDialogContent>
        <StyledWarningDialogDescription variant="body2">
          {t('capture.descriptions.updateCustomPreset')}
        </StyledWarningDialogDescription>
      </StyledDialogContent>
      <StyledDialogActionsContent>
        <ButtonPrimary
          onClick={() => {
            cancelFn();
            closeFn();
          }}
          variant="outlined"
          size="small"
        >
          {t('shared.actions.cancel')}
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            okFn();
            closeFn();
          }}
          variant="contained"
          size="small"
        >
          {t('shared.actions.replace')}
        </ButtonPrimary>
      </StyledDialogActionsContent>
    </DialogBox>
  );
}
