import React, { ReactNode } from 'react';
import Chip, { chipClasses, ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import TextWithEllipsisAndTooltip from 'components/TextWithEllipsisAndTooltip';

const StyledChip = styled(Chip)(({ theme }) => ({
  height: theme.typography.pxToRem(26),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  maxWidth: '270px',
  [`& .${chipClasses.label}`]: {
    letterSpacing: theme.typography.pxToRem(0.26),
    fontSize: theme.typography.pxToRem(13),
  },
  '&:focus': {
    border: '1px solid black',
  },
}));
const ChipTextEllipsis = ({ children }: { children?: ReactNode }) => {
  return <TextWithEllipsisAndTooltip maxWidth="270px" text={children} />;
};

export interface ChipWithMaxWidthProps extends ChipProps {
  labelText: string;
}

export default React.forwardRef<HTMLDivElement, ChipWithMaxWidthProps>(
  function ChipWithMaxWidth(
    { labelText, ...props }: Readonly<ChipWithMaxWidthProps>,
    ref
  ): JSX.Element {
    return (
      <StyledChip
        label={<ChipTextEllipsis>{labelText}</ChipTextEllipsis>}
        {...props}
        ref={ref}
      />
    );
  }
);
