import React, { SVGProps } from 'react';
import Grid from '@mui/material/Grid';

import {
  SplashLinkTile,
  SplashRoutingTile,
  SplashTileGoToText,
  SplashTileIconContainer,
  SplashTileTextContainer,
  SplashTileTextDescription,
  SplashTileTextHeader,
} from './styles';
import { ReactComponent as ArrowIcon } from './arrow.svg';

export interface SplashTileProps {
  descriptionText: string;
  goToText: string;
  headerText: string;
  href: string;
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  localLink?: boolean;
}

export default function SplashBasicTile({
  descriptionText,
  goToText,
  headerText,
  href,
  Icon,
  localLink,
}: SplashTileProps) {
  const TileComponent = localLink ? SplashRoutingTile : SplashLinkTile;

  return (
    <Grid container component="li" padding={0} margin={0} flexBasis="50%">
      <TileComponent href={href}>
        <Grid item sx={{ width: '100%' }}>
          <Grid container>
            <SplashTileIconContainer aria-hidden={true}>
              <Icon />
            </SplashTileIconContainer>
            <SplashTileTextContainer>
              <Grid item>
                <SplashTileTextHeader>{headerText}</SplashTileTextHeader>
              </Grid>
              <Grid item>
                <SplashTileTextDescription>
                  {descriptionText}
                </SplashTileTextDescription>
              </Grid>
            </SplashTileTextContainer>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <SplashTileGoToText>{goToText}</SplashTileGoToText>
            <ArrowIcon color="primary" />
          </Grid>
        </Grid>
      </TileComponent>
    </Grid>
  );
}
