import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { blueGrey } from '@mui/material/colors';

export const PaddingGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.6, 4, 0, 0.8),
}));

export const BreakGrid = styled(Grid)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0, 0, 6, 0),
}));

export const StyledHelpIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  color: theme.palette.custom.botticelli,
  margin: theme.spacing(0, 0, 0, 2),
  padding: theme.spacing(1),
  width: theme.spacing(6.4),
  height: theme.spacing(6.4),
  borderRadius: theme.spacing(5),
  '&:hover, &:focus': {
    backgroundColor: blueGrey[50],
  },
  outline: 'none',
}));
