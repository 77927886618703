import { SortOrder } from 'sections/Users/types';
import { HeadCellType } from './components/UsersTable';

export const LIMIT_COUNT_SELECTED_ITEMS = 100;

export const SUPER_ADMINISTRATOR = 'Super Administrator';

export const LS_USERS_TEXT_FILTER_KEY = 'Users-TextFilter';
export const LS_USERS_CURRENT_PAGE_KEY = 'Users-CurrentPage';
export const LS_USERS_VISIBLE_COLUMNS_KEY = 'Users-VisibleColumns';

export const PAGINATION_DEFAULT_LIMIT = 10;

export const PAGINATION_LIMIT_OPTIONS = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];
export const DEFAULT_SORT_DIRECTION: SortOrder = 'asc';

export const DEFAULT_SORT_COLUMN = 'username';

export const DEFAULT_COLUMNS: HeadCellType[] = [
  {
    key: 'username',
    label: 'users.columns.username',
    sortable: true,
    visible: true,
  },
  {
    key: 'fullName',
    label: 'users.columns.fullName',
    sortable: true,
    visible: false,
  },
  {
    key: 'roles',
    label: 'users.columns.roles',
    sortable: false,
    visible: true,
  },
  {
    key: 'lastIntegrationAccess',
    label: 'users.columns.lastAccess',
    sortable: true,
    visible: false,
  },
  {
    key: 'checkingFrequency',
    label: 'users.columns.checks',
    sortable: true,
    visible: true,
  },
  {
    key: 'licenseType',
    label: 'users.columns.licenseType',
    sortable: true,
    visible: false,
  },
];
