import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InnerCreateOrEditTargetDrawer from './InnerCreateOrEditTargetDrawer';
import CommonDrawer, { DrawerContent } from 'components/CommonDrawer';
import { useInnerDrawer } from './hooks';
import { CreateOrEditTargetDrawerProps } from './types';
import { MAX_TARGET_NAME_LENGTH } from 'sections/Targets/constants';

export default function CreateOrEditTargetDrawer({
  close,
  drawerIsOpen,
  languages,
  target,
  targetsNames,
  submit,
  drawerTitle,
  submitButtonLabel,
}: Readonly<CreateOrEditTargetDrawerProps>): ReactElement {
  const { t } = useTranslation();
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );
  const { onSubmit, onCancel, ...props } = useInnerDrawer({
    close,
    submit,
    target,
    targetsNames,
  });

  const submitDisabled = !(props.targetIsModified && props.targetIsValid);
  const mustSelectAtLeastOne = !props.selectedGoals?.set?.size
    ? t('capture.tooltips.mustSelectAtLeastOne')
    : '';
  const nameCannotRepeat = props.repeatedName
    ? t('capture.hints.duplicatedName')
    : '';

  const nameTooLong = props.tooLongName
    ? t('capture.hints.tooLongTargetName', {
        targetNameMaxLength: MAX_TARGET_NAME_LENGTH,
      })
    : '';
  return (
    <CommonDrawer
      drawerTitle={drawerTitle}
      drawerIsOpen={drawerIsOpen}
      submitButtonClickHandler={onSubmit}
      submitButtonLabel={submitButtonLabel}
      cancelAndCloseHandler={onCancel}
      cancelButtonLabel={t('capture.actions.cancel')}
      setFooterRect={setDrawerFooterRect}
      submitDisabled={submitDisabled}
      submitErrorText={nameTooLong || nameCannotRepeat || mustSelectAtLeastOne}
    >
      <DrawerContent
        sx={(theme) => ({
          paddingLeft: theme.spacing(4),
          margin: 0,
        })}
      >
        <InnerCreateOrEditTargetDrawer
          {...props}
          targetExists={!!target}
          targetsNames={targetsNames}
          footerRect={drawerFooterRect}
          languages={languages}
        />
      </DrawerContent>
    </CommonDrawer>
  );
}
