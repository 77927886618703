import React from 'react';
import { useTranslation } from 'react-i18next';

import { TargetTableProps } from 'sections/Targets/types';
import IndexTable, { IndexTableConfig } from 'components/IndexTable';
import createTargetIcons from './createTargetIcons';

export default function TargetTable({
  setForCopyTargetDialog,
  setSorting,
  setTargetForDeleteConfirmation,
  sortedTargets,
  sortField,
  sortOrder,
  showIcons = false,
}: TargetTableProps): JSX.Element {
  const { t } = useTranslation();

  const tableConfig: IndexTableConfig[] = [
    {
      title: t('capture.columns.names.targetFor'),
      active: sortField === 'displayName',
      direction: sortOrder,
      onClick: () => setSorting('displayName'),
      renderConfig: {
        header: true,
        idPropertyName: 'uuid',
        langAttr: true,
        urlBase: './targets/',
        valuePropertyName: 'displayName',
      },
    },
    {
      title: t('capture.columns.names.language'),
      active: sortField === 'languageVariantDisplayName',
      direction: sortOrder,
      onClick: () => setSorting('languageVariantDisplayName'),
      renderConfig: {
        valuePropertyName: 'languageVariantDisplayName',
        langAttr: true,
      },
    },
    {
      title: t('capture.columns.names.description'),
      renderConfig: {
        valuePropertyName: 'description',
        langAttr: true,
      },
    },
    {
      renderConfig: {
        icons: true,
      },
    },
  ];

  return (
    <IndexTable
      tableConfig={tableConfig}
      data={sortedTargets}
      Icons={
        showIcons
          ? createTargetIcons({
              actions: {
                setForCopyTargetDialog,
                setTargetForDeleteConfirmation,
              },
            })
          : undefined
      }
    />
  );
}
