import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ClusterPhrase, ReuseDomain, ReuseDomainCluster } from '../../types';

const newClusterObj: ReuseDomainCluster = {
  preferredPhrase: { phrase: '' },
  deprecatedPhrases: [],
  active: true,
  reuseDomains: [],
  description: '',
  language: '',
};

export function createNewClusterObject(
  cluster: ReuseDomainCluster | null
): ReuseDomainCluster {
  return cluster ? cloneDeep(cluster) : cloneDeep(newClusterObj);
}

export type UpdateNewClusterObjectArguments = {
  cluster: ReuseDomainCluster | null;
  newCluster: ReuseDomainCluster;
} & Partial<ReuseDomainCluster>;

type ReuseDomainClusterKeyof = keyof ReuseDomainCluster;

export function updateNewClusterObject({
  cluster,
  newCluster,
  ...props
}: UpdateNewClusterObjectArguments) {
  const currentCluster: ReuseDomainCluster = { ...newCluster };

  const propKey: keyof ReuseDomainCluster = (
    Object.keys(props) as ReuseDomainClusterKeyof[]
  )[0];

  (currentCluster[propKey] as ReuseDomainCluster[ReuseDomainClusterKeyof]) =
    props[propKey];

  return currentCluster;
}

export function getDeprecatedPhrases(
  cluster: ReuseDomainCluster | null,
  newAdditions: string[]
): ClusterPhrase[] {
  const deprecatedPhrasesFromCluster = cluster?.deprecatedPhrases || [];

  const uniqueNewAdditions =
    newAdditions
      ?.filter(
        (phrase) =>
          !deprecatedPhrasesFromCluster.find((dp) => dp.phrase === phrase)
      )
      .map((dPhrase) => ({ phrase: dPhrase })) || [];

  return [...uniqueNewAdditions.concat(deprecatedPhrasesFromCluster)];
}

export function doesPreferredPhraseExist(
  cluster: ReuseDomainCluster | null,
  newCluster: ReuseDomainCluster
): boolean {
  return (
    (cluster &&
      cluster.preferredPhrase.phrase &&
      newCluster.preferredPhrase?.phrase !== '') ||
    (!cluster && newCluster.preferredPhrase?.phrase !== '')
  );
}

export function checkIfReplacementIsValid(
  cluster: ReuseDomainCluster | null,
  newCluster: ReuseDomainCluster,
  errorMessages: Record<string, boolean>
) {
  return !!(
    doesPreferredPhraseExist(cluster, newCluster) &&
    !errorMessages.preferredPhraseHasError &&
    !errorMessages.descriptionIsTooLong &&
    !errorMessages.deprecatedPhraseIsTooLong &&
    ((cluster?.uuid &&
      cluster.language &&
      (newCluster.language === undefined || newCluster.language)) ||
      (!cluster?.uuid && newCluster.language)) &&
    ((cluster?.uuid &&
      cluster.reuseDomains?.length &&
      (!newCluster.reuseDomains || newCluster.reuseDomains?.length)) ||
      newCluster.reuseDomains?.length)
  );
}

export function removeDuplicatedValues(
  cluster: ReuseDomainCluster | null,
  value: any,
  key: keyof ReuseDomainCluster,
  clusterCandidate: ReuseDomainCluster
): ReuseDomainCluster {
  if (cluster) {
    const valueIsObject =
      value && !Array.isArray(value) && typeof value === 'object';
    const valueIsArray = Array.isArray(value);
    const oneValue =
      valueIsObject || typeof value === 'string' ? value : undefined;
    const valueIsPhrasesArray =
      valueIsArray && typeof value[0]?.phrase !== 'undefined';
    const phrasesList =
      valueIsPhrasesArray && removeEmptyDeprecatedPhrases(cloneDeep(value));
    const list = valueIsArray ? phrasesList || value : undefined;
    const areTheSame =
      valueIsObject || Array.isArray(value)
        ? isEqual(cluster[key], oneValue || list)
        : value === cluster[key];

    if (areTheSame) {
      delete clusterCandidate[key];
    }
  }

  return clusterCandidate;
}

export function removeEmptyDeprecatedPhrases(
  deprecatedPhrases: ReuseDomainCluster['deprecatedPhrases'] = []
) {
  return [...deprecatedPhrases].reduce(
    (acc: ClusterPhrase[], dPhrase): ClusterPhrase[] => {
      if (dPhrase.phrase.trim()) {
        const newPhraseObject: ClusterPhrase = {
          ...dPhrase,
          phrase: dPhrase.phrase.trim(),
        };
        acc.push(newPhraseObject);
      }

      return acc;
    },
    []
  );
}

export function getClusterDomains(
  cluster: ReuseDomainCluster | null,
  domain?: ReuseDomain
) {
  return (
    cluster?.reuseDomains ||
    ((!cluster && domain && [domain.uuid]) as string[]) ||
    []
  );
}
