import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DesignServicesIcon } from './design-services-icon.svg';
import { PrivilegesStructure } from 'hooks/usePrivileges';
import SplashBasicTile from './SplashBasicTile';

export default function TargetsTile({
  privileges,
}: {
  privileges: PrivilegesStructure;
}) {
  const { t } = useTranslation();

  return (
    <SplashBasicTile
      href="/targets"
      headerText={t('splashHomePage.targets.header')}
      descriptionText={t(
        privileges.editRights
          ? 'splashHomePage.targets.descriptionEdit'
          : 'splashHomePage.targets.descriptionRead'
      )}
      goToText={t('splashHomePage.targets.goToText')}
      Icon={DesignServicesIcon}
      localLink
    />
  );
}
