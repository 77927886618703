import React from 'react';
import { useTranslation } from 'react-i18next';

import DisplayDate from 'components/DisplayDate';
import UserName from './UserName';
import {
  StyledAdministrativeInformationTitle,
  StyledInfoLabel,
  StyledInfoText,
} from './components';
import { UpdateInformation } from 'types/types';

interface AdministrativeInformationProps {
  created: UpdateInformation | null | undefined;
  modified: UpdateInformation | null | undefined;
  readonly?: boolean;
  titleNotVisible?: boolean;
}

export default function AdministrativeInformation({
  created,
  modified,
  readonly = false,
  titleNotVisible = false,
}: AdministrativeInformationProps) {
  const { t } = useTranslation();

  return (
    <div>
      {!titleNotVisible && (
        <StyledAdministrativeInformationTitle component="h3" variant="body1">
          {t('shared.titles.administrativeInformation')}
        </StyledAdministrativeInformationTitle>
      )}
      {!readonly && (
        <>
          <StyledInfoLabel>{t('shared.labels.createdBy')}</StyledInfoLabel>
          <StyledInfoText>
            <UserName
              data={created}
              defaultNameReplacement={t('shared.descriptions.unavailable')}
            />
          </StyledInfoText>
        </>
      )}
      <StyledInfoLabel>{t('shared.labels.createdOn')}</StyledInfoLabel>
      <StyledInfoText>
        <DisplayDate
          date={created?.date}
          tooltipVisible
          showOnlyExisting
          notExistingDateReplacement={t('shared.descriptions.unavailable')}
        />
      </StyledInfoText>
      {!readonly && (
        <>
          <StyledInfoLabel>{t('shared.labels.modifiedBy')}</StyledInfoLabel>
          <StyledInfoText>
            <UserName
              data={modified}
              defaultNameReplacement={t('shared.descriptions.unavailable')}
            />
          </StyledInfoText>
        </>
      )}
      <StyledInfoLabel>{t('shared.labels.modifiedOn')}</StyledInfoLabel>
      <StyledInfoText>
        <DisplayDate
          date={modified?.date}
          tooltipVisible
          showOnlyExisting
          notExistingDateReplacement={t('shared.descriptions.unavailable')}
        />
      </StyledInfoText>
    </div>
  );
}
