import React, { useContext } from 'react';
import { getI18n } from 'react-i18next';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { AwtNavigationRail, AwtTopBar } from '@acrolinx/acrolinx-web-toolkit';
import CurrentUserContext from 'contexts/CurrentUserContext';
import FeaturesContext from 'contexts/FeaturesContext';
import PrivilegesContext from 'contexts/PrivilegesContext';

import NotistackCustomSnackbarProvider from './components/NotistackCustomSnackbarProvider';
import {
  FlexBox,
  MainContent,
  StyledAppBar,
  StyledNavigationRailContainer,
} from './styles';

declare module 'notistack' {
  interface VariantOverrides {
    default: {
      snackbarContentProps?: React.HTMLAttributes<HTMLDivElement>;
    };
  }
}

interface Props {
  children?: React.ReactNode;
}

const MainLayout = ({ children }: Props) => {
  // user is incompatible with the awtuser currently but they are coming from the same endpoint,
  // marking as any temporarily until the types are reconciled
  const user: any = useContext(CurrentUserContext);
  const features = useContext(FeaturesContext);
  const privileges = useContext(PrivilegesContext);

  return (
    <Fade in={true}>
      <FlexBox>
        <StyledAppBar>
          <AwtTopBar user={user} privileges={privileges} />
        </StyledAppBar>
        <StyledNavigationRailContainer>
          <AwtNavigationRail
            user={user}
            features={features}
            privileges={privileges}
            routingApp="dashboard"
            lng={getI18n().language}
          />
        </StyledNavigationRailContainer>
        <Box
          flexGrow={1}
          style={{
            overflow: 'hidden',
          }}
        >
          <NotistackCustomSnackbarProvider>
            <MainContent>{children}</MainContent>
          </NotistackCustomSnackbarProvider>
        </Box>
      </FlexBox>
    </Fade>
  );
};

export default MainLayout;
