import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material';

import { StyledListSubheader } from './styles';
import CommonSelectStandard, {
  CommonMenuItem,
  menuProps,
} from 'components/CommonSelectStandard';
import { Options } from './types';
import CommonTooltip from 'components/CommonTooltip';

interface CriteriaDropdownProps {
  chosenOption: string;
  setChosenOption: (option: string) => void;
  userCriteria: Options[];
  documentCriteria: Options[];
  targetIsAssignedToAll: boolean;
}

export function CriteriaDropdown({
  chosenOption,
  setChosenOption,
  userCriteria,
  documentCriteria,
  targetIsAssignedToAll,
}: CriteriaDropdownProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <CommonSelectStandard
      value={chosenOption}
      id="assignment-criteria"
      MenuProps={menuProps}
      label={t('capture.labels.selectCriteria')}
      onChange={(evt: SelectChangeEvent<unknown>) => {
        if (evt.target.value) {
          setChosenOption(evt.target.value as string);
        }
      }}
    >
      <StyledListSubheader
        disableGutters
        key="user-criteria-label"
        tabIndex={-1}
      >
        <Typography
          {...(targetIsAssignedToAll && {
            sx: (theme) => ({ color: theme.palette.custom.skinner }),
          })}
          variant="body2"
        >
          {t('capture.labels.user')}
        </Typography>
      </StyledListSubheader>
      {userCriteria.map((userCrit, index) => {
        const menuItem = (
          <CommonMenuItem
            key={userCrit.id}
            disableGutters
            text={userCrit.label}
            value={userCrit.id}
            type="sub"
            disabled={targetIsAssignedToAll}
            {...(targetIsAssignedToAll && { disableRipple: true })}
            {...(!targetIsAssignedToAll &&
              !index && {
                autoFocus: true,
              })}
          >
            {userCrit.label}
          </CommonMenuItem>
        );
        const tooltipText = targetIsAssignedToAll
          ? t('capture.tooltips.assignmentsDisabledUserCriteria')
          : '';

        return targetIsAssignedToAll ? (
          <CommonTooltip key={userCrit.id} title={tooltipText}>
            {menuItem}
          </CommonTooltip>
        ) : (
          menuItem
        );
      })}
      {documentCriteria.length && [
        <StyledListSubheader
          sx={(theme) => ({
            maxWidth: '246px',
            borderTop: '1px solid',
            paddingTop: theme.spacing(1),
            borderColor: theme.palette.custom.lighterGrey,
          })}
          aria-hidden={true}
          key="document-section"
        />,
        <StyledListSubheader
          disableGutters
          key="document-criteria-label"
          tabIndex={-1}
        >
          <Typography variant="body2">
            {t('capture.labels.document')}
          </Typography>
        </StyledListSubheader>,
        documentCriteria.map((docCrit, index) => (
          <CommonMenuItem
            key={docCrit.id}
            text={docCrit.label}
            value={docCrit.id}
            type="sub"
            {...(targetIsAssignedToAll &&
              !index && {
                autoFocus: true,
              })}
          >
            {docCrit.label}
          </CommonMenuItem>
        )),
      ]}
    </CommonSelectStandard>
  );
}

export default CriteriaDropdown;
