import React from 'react';
import FilledTextField, {
  FilledTextFieldProps,
} from 'components/FilledTextField';

type CommonDrawerTextFieldProps = FilledTextFieldProps & {
  errorText: string;
};

export default function CommonDrawerTextField({
  errorText,
  helperText,
  ...props
}: CommonDrawerTextFieldProps) {
  return (
    <FilledTextField
      fullWidth
      helperText={errorText || helperText}
      error={!!errorText}
      aria-invalid={!!errorText}
      {...props}
    />
  );
}
