import React from 'react';
import { useTranslation } from 'react-i18next';

import { WarningContainer, WarningText } from './components';

export default function WarningDuplicatedPhrase() {
  const { t } = useTranslation();

  return (
    <WarningContainer>
      <WarningText variant="body2">
        {t('reuse.descriptions.duplicatedPhraseInManyReplacements')}
      </WarningText>
    </WarningContainer>
  );
}
