import { TargetIndexItem, UpdateInformation } from 'types/types';

export enum RecentActivityClientType {
  FRONTEND = 'FRONTEND',
  GUIDANCEWIZARD = 'GUIDANCEWIZARD',
  OTHER = 'OTHER',
  TEGRAT = 'TEGRAT',
}

export enum RecentActivityType {
  CREATE = 'create',
  DELETE = 'delete',
  EDIT = 'edit',
}

export interface RecentActivityDelete {
  clientType: RecentActivityClientType;
  comment: string;
  displayName: TargetIndexItem['displayName'];
  info: UpdateInformation;
  targetUuid: TargetIndexItem['uuid'];
  type: RecentActivityType;
  uuid: string;
}

export interface RecentActivityData extends RecentActivityDelete {}
