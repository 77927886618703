import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';

import { requestResponseOrThrowError } from 'utils/fetch';
import {
  TARGET_CONTEXTS_QUERY_KEY,
  TARGET_CORE_SERVER_CONTEXTS,
} from 'constants/constants';
import { ContextConfig, Goal, Guideline, GuidelineContext } from '../../types';
import AuthContext from 'contexts/AuthContext';

export interface EditContextsDrawerForProps {
  goal: Goal;
  guideline: Guideline;
  contextConfig: ContextConfig;
  warningModal?: {
    presetWarningModalShouldAppear: boolean;
    openWarningModal: () => void;
    setWarningModalCancelCallback: (callback: (() => void) | null) => void;
    setWarningModalOKCallback: (callback: (() => void) | null) => void;
  };
}

export function useFetchContexts() {
  const [authToken] = useContext(AuthContext);

  async function fetchContexts() {
    return await requestResponseOrThrowError(
      authToken,
      TARGET_CORE_SERVER_CONTEXTS
    );
  }

  return useQuery<GuidelineContext[], Error>({
    queryKey: [TARGET_CONTEXTS_QUERY_KEY],
    queryFn: fetchContexts,
  });
}

export function useContexts(openFor: EditContextsDrawerForProps) {
  const [localContextConfig, setLocalContextConfig] = useState({
    ...cloneDeep(openFor.contextConfig),
    contexts: new Set(openFor.contextConfig.contexts),
  });
  const [contextConfigIsModified, setContextConfigIsModified] =
    useState<boolean>(false);
  const [drawerFooterRect, setDrawerFooterRect] = useState<DOMRect | null>(
    null
  );

  const contextResponse = useFetchContexts();

  return {
    contextConfigIsModified,
    contextResponse,
    drawerFooterRect,
    localContextConfig,
    setContextConfigIsModified,
    setDrawerFooterRect,
    setLocalContextConfig,
  };
}
