import React, { ReactElement } from 'react';
import { FieldContainer, Text, Title } from './styles';
import { ReadOnlyTextFieldProps } from './types';

export default function ReadOnlyTextField({
  title,
  text,
  lang,
}: ReadOnlyTextFieldProps): ReactElement {
  return (
    <>
      <FieldContainer>
        <Title>{title}</Title>
        <Text {...(lang && { lang })}>{text}</Text>
      </FieldContainer>
    </>
  );
}
