import { styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

export const DeleteReplacementDescription = styled('div')(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(4),
  color: theme.palette.common.black,

  '& span': {
    display: 'block',
    backgroundColor: pink[50],
    color: pink['A700'],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    maxWidth: theme.spacing(68),
    lineHeight: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
}));
