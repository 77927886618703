import React from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import { green, grey } from '@mui/material/colors';

import { RecentActivityType } from './types';
import { SvgIconProps } from '@mui/material';

export const ActivityContainer = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${grey[300]}`,
  padding: theme.spacing(3, 2.3, 3, 4),
}));

export const UserIcon = styled((props: SvgIconProps) => (
  <PermIdentityRoundedIcon {...props} viewBox="2 2 20 20" />
))(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(3 / 5),
  fontSize: theme.typography.pxToRem(18),
  fill: theme.palette.custom.doveGrey,
}));

export const DateText = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.26),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(20),
  display: 'inline-block',
  paddingBottom: theme.spacing(2.2),
}));

export const ActivityText = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(24),
  display: 'inline-block',
}));

export const ActivityType = styled((props: TypographyProps) => (
  <Typography variant="body2" {...props} />
))<TypographyProps & { type: RecentActivityType }>(({ theme, type }) => {
  let colors = {
    color: theme.palette.custom.darkBluishGrey,
    backgroundColor: theme.palette.custom.aliceBlue,
  };

  if (type === RecentActivityType.DELETE) {
    colors = {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
    };
  }

  if (type === RecentActivityType.CREATE) {
    colors = {
      color: theme.palette.custom.darkGreen,
      backgroundColor: green['A100'],
    };
  }

  return {
    letterSpacing: theme.typography.pxToRem(0.24),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 1, 0, 1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    display: 'inline-block',
    borderRadius: '2px',
    ...colors,
  };
});
