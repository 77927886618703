import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import AuthContext from 'contexts/AuthContext';
import { useIsOpen } from 'hooks/useIsOpen';
import { requestResponseOrThrowError } from 'utils/fetch';
import {
  REFETCHING_INTERVAL_HARVESTING_STATUS,
  REUSE_ASSISTANT_PATH,
  REUSE_HARVESTING_STATUS_KEY,
  REUSE_HARVESTING_STATUS_PATH,
} from 'sections/Reuse/constants';
import { REUSE_BASE_PATH } from 'constants/constants';

export enum ReuseHarvestingState {
  IDLE = 'IDLE',
  HARVESTING = 'HARVESTING',
  FAILURE = 'FAILURE',
}
export interface HarvestingStatus {
  reuseHarvestingState: ReuseHarvestingState;
  dateOfLastSuccessfulRun: string;
  sentenceCountMid: number;
  sentenceCountMax: number;
  sentencesReady: number;
}

export interface UseStatusInfoResult {
  closeStatusDialog: () => void;
  harvestingStatus: HarvestingStatus;
  openStatusDialog: () => void;
  elementIsOpen: boolean;
}

export function useStatusInfo() {
  const {
    close: closeStatusDialog,
    open: openStatusDialog,
    elementIsOpen: statusDialogIsOpen,
  } = useIsOpen();
  const [authToken] = useContext(AuthContext);

  async function fetchHarvestingStatus() {
    return await requestResponseOrThrowError(
      authToken,
      `${REUSE_BASE_PATH}/${REUSE_ASSISTANT_PATH}/${REUSE_HARVESTING_STATUS_PATH}`
    );
  }

  const { data: harvestingStatus } = useQuery<HarvestingStatus, Error>({
    queryKey: [REUSE_HARVESTING_STATUS_KEY],
    queryFn: fetchHarvestingStatus,
    refetchInterval: REFETCHING_INTERVAL_HARVESTING_STATUS, // for refetching every minute
    refetchIntervalInBackground: true,
  });

  return {
    closeStatusDialog,
    harvestingStatus,
    openStatusDialog,
    statusDialogIsOpen,
  };
}
