import React from 'react';
import { OutlinedTextFieldProps } from '@mui/material/TextField';

import CommonTooltip from 'components/CommonTooltip';
import OutlinedDenseTextField from 'components/OutlinedDenseTextField/OutlinedDenseTextField';
import { styled } from '@mui/material/styles';

export interface ParameterTextFieldProps extends OutlinedTextFieldProps {
  tooltipText: string;
}

const StyledOutlinedDenseTextField = styled(OutlinedDenseTextField)(
  ({ theme }) => ({
    width: theme.spacing(10),
  })
);

export default function ParameterTextField({
  tooltipText,
  ...props
}: ParameterTextFieldProps) {
  return (
    <CommonTooltip title={tooltipText} type="yellow">
      <StyledOutlinedDenseTextField {...props} />
    </CommonTooltip>
  );
}
