import { styled } from '@mui/material/styles';
import { YellowTooltip } from '../CommonTooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { FilledTextFieldProps } from './FilledTextField';

export const OverflowingValueStyledTooltip = styled(YellowTooltip)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      margin: 0,
      marginTop: `${theme.spacing(-2.7)} !important`,
      marginLeft: theme.spacing(2),
      maxWidth: '290px',
    },
  })
);

export const StyledReadOnlyIconButton = styled(IconButton)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(8 / 5),
  paddingBottom: theme.spacing(7 / 5),
  paddingLeft: theme.spacing(8 / 5),
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const StyledTextField = styled(TextField)<FilledTextFieldProps>(
  ({ theme, value, readOnly }) => ({
    ...(readOnly && {
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: theme.palette.custom.doveGrey,
        },
        ...(!value && {
          transform: 'translate(10px, 14px) scale(1) !important',
          '&.Mui-focused': {
            color: theme.palette.custom.doveGrey,
          },
        }),
      },
      '& .MuiFilledInput-root': {
        '&:hover': {
          backgroundColor: theme.palette.custom.selago,
          '&:after': {
            transform: 'scaleX(0)',
          },
        },
        '&.Mui-focused': {
          '&:after': {
            transform: 'scaleX(0)',
          },
        },
      },
    }),
    '& .Mui-error input.MuiFilledInput-input': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      lineHeight: theme.typography.pxToRem(18),
      fontSize: theme.typography.pxToRem(12),
      justifyContent: 'space-between',
      display: 'flex',
      marginRight: 0,
    },
  })
);
