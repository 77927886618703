import React from 'react';
import { useTranslation } from 'react-i18next';

import ParameterTextField from '../ParameterTextField';
import {
  createUpdate,
  parseNumberParameter,
  validateAgainstRange,
} from './helpers';
import { StyledGuidelineParameterLabel } from './styles';
import CommonSelectDense, {
  BootstrapInput,
  CommonMenuItem,
  menuProps,
} from 'components/CommonSelectDense';
import { ParameterProps } from './Parameter';

export default function EditableParameter({
  lastValue,
  parameterObj,
  parameterValue,
  ...props
}: Readonly<ParameterProps>) {
  const { t } = useTranslation();

  switch (parameterObj?.type) {
    case 'number':
    case 'integer':
      return (
        <>
          <ParameterTextField
            tooltipText={t(
              'capture.actions.tooltips.guidelineNumberParameterRange',
              {
                rangeMin: parameterObj?.rangeMin,
                rangeMax: parameterObj?.rangeMax,
              }
            )}
            aria-label={parameterObj.name}
            placeholder=""
            value={parameterValue}
            required={true}
            variant="outlined"
            onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
              const parsedValue = parseNumberParameter(
                ev.target.value,
                parameterValue
              );
              if (parsedValue === '') {
                createUpdate(props, lastValue.current as string);
                return;
              }

              const validatedValue = validateAgainstRange(
                parameterObj,
                parsedValue
              ) as string;
              lastValue.current = validatedValue;
              createUpdate(props, validatedValue);
            }}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              const parsedValue = parseNumberParameter(
                ev.target.value,
                parameterValue
              );
              createUpdate(props, parsedValue.toString());
            }}
          />
          {!!parameterObj.label && (
            <StyledGuidelineParameterLabel variant="body2">
              {parameterObj.label}
            </StyledGuidelineParameterLabel>
          )}
        </>
      );
    case 'enum':
      return parameterObj.enumContent.length > 1 ? (
        <CommonSelectDense
          MenuProps={menuProps}
          value={parameterValue}
          inputProps={{
            'aria-label': t('capture.a11y.guidelineParameterDropdown'),
          }}
          onChange={(ev) => {
            const value = ev.target.value as string;
            function onChangeHandler() {
              props.targetActions.setGuidelineParameterValue(
                props.goal,
                props.guideline,
                value
              );
            }

            if (props.presetWarningModalShouldAppear) {
              props.setWarningModalOKCallback(() => onChangeHandler);
              props.openWarningModal();
              return;
            }

            onChangeHandler();
          }}
          input={<BootstrapInput />}
        >
          {parameterObj.enumContent.map(({ internalId, displayName }) => (
            <CommonMenuItem
              text={displayName}
              key={internalId}
              value={internalId}
            >
              {displayName}
            </CommonMenuItem>
          ))}
        </CommonSelectDense>
      ) : null;
    default:
      return null;
  }
}
