import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& 	.MuiDialog-paper': {
    top: '-15%',
    minWidth: theme.spacing(80),
    boxShadow: '0 5px 49px rgba(85, 85, 85, 0.2)',
    padding: theme.spacing(5, 6),
    borderRadius: theme.typography.pxToRem(6),
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4),
  letterSpacing: theme.typography.pxToRem(0.4),
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(5),
}));

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.28),
    color: theme.palette.common.black,
  })
);

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: theme.spacing(1),
  paddingRight: 0,
}));
