import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

import CommonSelectDense, {
  BootstrapInput,
  menuProps,
  CommonMenuItem,
} from 'components/CommonSelectDense';
import { Goal, Preset, PresetId } from '../../types';
import CommonTooltip from 'components/CommonTooltip/CommonTooltip';
import WarningIcon from 'components/WarningIcon';
import {
  GoalDescriptionIcon,
  GoalDescriptionIconContainer,
} from '../../styles';
import {
  GoalDisplayName,
  PresetLabel,
  SelectedParameter,
  StyledAccordion,
  StyledAccordionSummary,
  StyledFormControl,
  StyledPresetText,
  TerminologySummary,
  WordsPhrasesSummaryContainer,
} from './styles';
import { PREDEFINED_CUSTOM } from '../../constants';
import { DO_NOT_EXPAND_CLASS } from 'constants/constants';
import useExpandCollapse from 'hooks/useExpandCollapse';
import { ExpandMoreIconWithTooltip } from 'components/FixedAccordion';
import RecommendedRequiredComponent from './RecommendedRequiredComponent';

interface GoalAccordionProps {
  children: JSX.Element;
  expandedGoals: boolean;
  goal: Goal;
  goalColor: JSX.Element;
  goalWithWarning: string[];
  parameterSummary?: {
    summary?: string;
    translation?: string;
  };
  languageIdShort: string;
  presetId: PresetId | undefined;
  presets: boolean;
  presetsSelectOptions: Preset[];
  readOnly: boolean;
  recommended: boolean;
  setPreset(goal: Goal, presetId: PresetId): void;
  toggleRequiredRecommended: () => void;
  warningTranslationString: string;
}

function GoalAccordion(props: GoalAccordionProps): JSX.Element {
  const { t } = useTranslation();
  const {
    goal: { displayName, description } = {},
    goal,
    goalWithWarning,
    parameterSummary,
    languageIdShort,
    presetId,
    presets: isPresets,
    presetsSelectOptions,
    readOnly,
    recommended: isRecommended,
    setPreset,
    warningTranslationString,
  } = props;

  const { expanded, expandIconTooltipText, setExpanded } = useExpandCollapse();
  useEffect(() => {
    if (props.expandedGoals) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [props.expandedGoals, setExpanded]);

  const onChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const chosenPreset = useMemo(
    () => presetsSelectOptions.find((p) => p.identifier === presetId),
    [presetId, presetsSelectOptions]
  );

  return (
    <>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        id={goal.identifier}
        expanded={expanded}
        onChange={onChange}
      >
        <StyledAccordionSummary
          id={displayName + '-summary'}
          expandIcon={
            <ExpandMoreIconWithTooltip
              expandIconTooltipText={expandIconTooltipText}
            />
          }
        >
          {props.goalColor}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={(ev) => {
              const classList = (ev.nativeEvent.target as HTMLElement)
                .classList;

              if (classList.contains(DO_NOT_EXPAND_CLASS)) {
                // Prevent opening/closing the Accordion accidentally by using the Select.
                ev.stopPropagation();
              }
            }}
          >
            <Grid
              alignItems="center"
              sx={{ display: 'inline-flex' }}
              lang={languageIdShort}
            >
              <GoalDisplayName variant="body2" component="h3">
                {displayName}
              </GoalDisplayName>
              <CommonTooltip
                className={DO_NOT_EXPAND_CLASS}
                title={description ?? ''}
                placement="right"
                type="yellow"
              >
                <GoalDescriptionIconContainer
                  alignItems="center"
                  justifyContent="center"
                  tabIndex={0}
                  className={DO_NOT_EXPAND_CLASS}
                >
                  <GoalDescriptionIcon className={DO_NOT_EXPAND_CLASS} />
                </GoalDescriptionIconContainer>
              </CommonTooltip>
            </Grid>
            <Grid
              alignItems="center"
              justifyContent="right"
              sx={{ display: 'inline-flex' }}
            >
              {goalWithWarning && (
                <CommonTooltip
                  title={
                    <>
                      {t(warningTranslationString, {
                        guidelineName: goalWithWarning.join(', '),
                        goalName: displayName,
                      })}
                    </>
                  }
                  type="yellow"
                >
                  <Box
                    mr={isPresets ? 4 : 0}
                    sx={(theme) => ({
                      height: theme.spacing(4),
                    })}
                  >
                    <WarningIcon
                      tabIndex={0}
                      sx={(theme: Theme) => ({
                        height: theme.spacing(4),
                        width: theme.spacing(4),
                      })}
                    />
                  </Box>
                </CommonTooltip>
              )}
              {isPresets && (
                <>
                  <PresetLabel
                    variant="body2"
                    id={'select_preset_label_' + goal.identifier}
                  >
                    {t('capture.labels.preset')}
                  </PresetLabel>{' '}
                  {readOnly ? (
                    <StyledPresetText>
                      {chosenPreset?.displayName}
                    </StyledPresetText>
                  ) : (
                    <StyledFormControl>
                      <CommonSelectDense
                        MenuProps={{
                          ...menuProps,
                          id: 'presetSelect',
                        }}
                        sx={(theme) => ({ width: theme.spacing(60) })}
                        input={<BootstrapInput />}
                        labelId={'select_preset_label_' + goal.identifier}
                        value={presetId}
                        className={DO_NOT_EXPAND_CLASS}
                        onChange={(ev) => {
                          setPreset(goal, ev.target.value as string);
                        }}
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        {presetsSelectOptions.map((preset) => {
                          if (
                            preset.identifier?.toLowerCase() ===
                            PREDEFINED_CUSTOM.toLowerCase()
                          ) {
                            // don't show predefined custom
                            return null;
                          }
                          return (
                            <CommonMenuItem
                              className={DO_NOT_EXPAND_CLASS}
                              key={preset.identifier}
                              text={preset.displayName}
                              value={preset.identifier}
                              sx={(theme) => ({ width: theme.spacing(60) })}
                              lang={languageIdShort}
                            >
                              {preset.displayName}
                            </CommonMenuItem>
                          );
                        })}
                      </CommonSelectDense>
                    </StyledFormControl>
                  )}
                </>
              )}
              {parameterSummary?.summary && parameterSummary?.translation && (
                <WordsPhrasesSummaryContainer flexDirection="column">
                  <SelectedParameter>
                    {' '}
                    {parameterSummary.translation}
                  </SelectedParameter>
                  <TerminologySummary>
                    {parameterSummary.summary}
                  </TerminologySummary>
                </WordsPhrasesSummaryContainer>
              )}

              <RecommendedRequiredComponent
                isRecommended={isRecommended}
                label={t(
                  isRecommended
                    ? 'capture.a11y.toggleGoalRecommended'
                    : 'capture.a11y.toggleGoalRequired',
                  {
                    goal: displayName,
                  }
                )}
                readOnly={props.readOnly}
                toggleRequiredRecommended={props.toggleRequiredRecommended}
                tooltip={t(
                  isRecommended
                    ? 'capture.a11y.toggleGoalRecommended'
                    : 'capture.a11y.toggleGoalRequired',
                  {
                    goal: displayName,
                  }
                )}
              />
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        {props.children}
      </StyledAccordion>
    </>
  );
}

export default GoalAccordion;
