import { styled } from '@mui/material/styles';
import {
  IconButton,
  InputAdornment,
  Grid,
  TextField,
  GridProps,
  FormControl,
} from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  alignSelf: 'flex-start',
  paddingTop: theme.spacing(2.6),
  marginRight: theme.spacing(-2.2),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&:hover`]: {
    backgroundColor: theme.palette.custom.lavenderBlush,
  },
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export const StyledErrorIcon = styled(ErrorOutlineRoundedIcon)(({ theme }) => ({
  marginLeft: theme.spacing(0.8),
}));

const focusedFilledTranslate = 'translate(0.375rem,-0.563rem)!important';

export const StyledTextField = styled(TextField)(({ theme, value }) => ({
  '& label.MuiInputLabel-root.MuiInputLabel-formControl': {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    fontSize: theme.typography.pxToRem(value ? 12 : 14),
    lineHeight: theme.typography.pxToRem(18),
    transform: value
      ? focusedFilledTranslate
      : `translate(${theme.typography.pxToRem(5)},${theme.typography.pxToRem(
          7
        )})`,
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  '& fieldset': {
    borderWidth: '1px',
    borderColor: theme.palette.custom.darkBrownishGrey,
    padding: `0 ${theme.typography.pxToRem(8)}`,
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root:hover fieldset': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.2),
  },
  '& label.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused': {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.main,
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  '& label.MuiInputLabel-root.MuiInputLabel-shrink': {
    transform: focusedFilledTranslate,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.28),
    paddingTop: theme.spacing(1),
  },
  '& fieldset legend': {
    fontSize: theme.typography.pxToRem(12),
  },
  '& .Mui-error': {
    color: theme.palette.error.main,
    '& fieldset, &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&.MuiOutlinedInput-root:hover fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& fieldset legend': {
      color: theme.palette.error.main,
    },
  },
}));

export const StyledHelperTextContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'error',
})<GridProps & { error: boolean }>(({ theme, error }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: error ? theme.palette.error.main : theme.palette.text.secondary,
  letterSpacing: theme.typography.pxToRem(0.24),
  marginTop: theme.spacing(1),
  padding: theme.spacing(0, 0, 0, 2),
  lineHeight: theme.typography.pxToRem(18),
  '& div:first-of-type': {
    paddingRight: theme.spacing(2),
  },
  '& div:last-of-type': {
    whiteSpace: 'nowrap',
    alignSelf: 'flex-start',
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));
