import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import { blueGrey, grey } from '@mui/material/colors';
import { StyledAccordionSummary } from '../../../../../../components/FixedAccordion';
import { accordionSummaryClasses } from '@mui/material';
import Typography from '@mui/material/Typography';

export const GoalGroupContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    height: theme.spacing(8),
  },
  borderWidth: 1,
  borderColor: grey[300],
  borderStyle: 'solid',
  backgroundColor: grey[50],
  '&:hover, &:focus': {
    borderWidth: 1,
    borderColor: theme.palette.custom.botticelli,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: grey[50],
  },
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '&::before': {
    backgroundColor: 'transparent',
  },
}));

export const StyledAccordionSummaryGuidelineGroup = styled(
  StyledAccordionSummary
)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  margin: `${theme.spacing(0.8)} 0`,
  [`& .${accordionSummaryClasses.content}`]: {
    margin: `${theme.spacing(0.8)} 0`,
    '&$expanded': {
      margin: `${theme.spacing(0.8)} 0`,
    },
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    borderRadius: 25,
    padding: 3,
    marginRight: -5,
    color: blueGrey[200],
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export const GuidelineAccordionSummaryLabel = styled(Typography)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.32),
    fontWeight: theme.typography.fontWeightMedium,
  })
);

export const GuidelineWrapper = styled('div')({
  width: '100%',
});
