import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Goal,
  GoalConfig,
  Guideline,
  GuidelineValue,
  Preset,
} from '../../types';
import { DOMAIN_PREFIX, TERM_FILTER_PREFIX } from '../../constants';
import { TargetActions } from '../GoalSection';
import { createDomainsSummary } from './helpers';
import { EnumContentObject } from 'types/types';
import { HandleChangeDomainCheckboxFn } from 'components/DomainMenu/types';

export interface UseTerminologyProps {
  goal: Goal;
  goalConfig: GoalConfig;
  guidelineObj?: Guideline;
}

export interface UseSimpleGuidelineResult {
  guideline: Guideline;
  presetsSelectOptions: Preset[];
}

export interface UseTerminologyGuidelineProps extends UseTerminologyProps {
  targetActions: TargetActions;
}

export interface TerminologyGuidelineParameterValueObject {
  useTermFilter: boolean;
  scoreAdmittedTerms: boolean;
  suggestAdmittedTerms: boolean;
  markAdmittedTerms: boolean;
  termFilter: string;
  domainNames: string[];
}

export interface UseTerminologyGuidelineMainResult {
  domainSummary: string;
  filter: string;
  parameterValueObj: TerminologyGuidelineParameterValueObject;
  presetsSelectOptions: Preset[];
}

export interface UseTerminologyGuidelineRestResult {
  domainData: EnumContentObject[];
  guideline: Guideline;
  guidelineValues: GuidelineValue[];
  handleChangeDomainCheckbox: HandleChangeDomainCheckboxFn;
  radioChangeHandler: (ev: ChangeEvent<{ value?: string }>) => void;
  termFilterChecked: boolean;
  termFilterData: EnumContentObject[];
}

export type UseTerminologyGuidelineResult = UseTerminologyGuidelineMainResult &
  UseTerminologyGuidelineRestResult;

export function useSimpleGuideline({
  goal,
  goalConfig,
  guidelineObj,
}: UseTerminologyProps): UseSimpleGuidelineResult {
  const guideline = guidelineObj || goal?.goalGroups[0]?.guidelines[0];

  const presetsSelectOptions = useMemo(() => {
    const predefined: Preset[] = [...goal.predefinedGoalPresets];

    if (goalConfig.customPreset) {
      predefined.push(goalConfig.customPreset);
    }

    return predefined;
  }, [goal.predefinedGoalPresets, goalConfig.customPreset]);

  return {
    guideline,
    presetsSelectOptions,
  };
}

export function useDomainSummary({
  goal,
  goalConfig,
  guidelineObj,
}: UseTerminologyProps) {
  const { t } = useTranslation();

  const { guideline, presetsSelectOptions } = useSimpleGuideline({
    goal,
    guidelineObj,
    goalConfig,
  });

  const parameter = guideline?.parameter;
  const enumContent = parameter?.enumContent || [];

  const presetObj = presetsSelectOptions.find(
    (preset: Preset) => preset.identifier === goalConfig.selectedPresetId
  );

  const guidelineValues = presetObj?.guidelineValues || [];

  const guidelineValueIndex = guidelineObj
    ? guidelineValues.findIndex(
        (gv) => gv.guidelineId === guidelineObj.identifier
      )
    : 0;

  let parameterValueObj: TerminologyGuidelineParameterValueObject;

  try {
    parameterValueObj = JSON.parse(
      (guidelineValues[guidelineValueIndex]?.parameterValue as string) || '{}'
    );
  } catch (err) {
    parameterValueObj = {} as TerminologyGuidelineParameterValueObject;
  }

  const domainData = enumContent.filter((listElement: EnumContentObject) =>
    listElement.internalId.startsWith(DOMAIN_PREFIX)
  );

  const tBoundToTranslationString = parameterValueObj?.domainNames?.length
    ? t.bind(null, 'capture.descriptions.selectedDomains')
    : t.bind(null, 'capture.labels.selectedDomainsPlaceholder');

  const domainSummary =
    guideline.guidelineType === 'reuse_sentence'
      ? ''
      : createDomainsSummary(
          domainData,
          parameterValueObj,
          tBoundToTranslationString
        );

  return {
    domainData,
    domainSummary,
    enumContent,
    guideline,
    guidelineValueIndex,
    presetObj,
    presetsSelectOptions,
  };
}

export function useTerminologyGuideline({
  goal,
  goalConfig,
  targetActions,
  guidelineObj,
}: UseTerminologyGuidelineProps): UseTerminologyGuidelineResult {
  const { t } = useTranslation();

  const {
    domainData,
    domainSummary,
    enumContent,
    guideline,
    guidelineValueIndex,
    presetObj,
    presetsSelectOptions,
  } = useDomainSummary({
    goal,
    goalConfig,
    guidelineObj,
  });

  const termFilterData = enumContent.filter((listElement: EnumContentObject) =>
    listElement.internalId.startsWith(TERM_FILTER_PREFIX)
  );

  const guidelineValues = presetObj?.guidelineValues || [];

  const parameterValueObj: TerminologyGuidelineParameterValueObject =
    JSON.parse(
      (guidelineValues[guidelineValueIndex]?.parameterValue as string) || '{}'
    );

  function radioChangeHandler(ev: ChangeEvent<{ value?: string }>) {
    parameterValueObj.useTermFilter =
      (ev.target as HTMLInputElement).value === 'filter';

    targetActions.setGuidelineParameterValue(
      goal,
      guideline,
      JSON.stringify(parameterValueObj)
    );
  }

  const termFilterChecked = !!parameterValueObj.useTermFilter;
  const filter =
    parameterValueObj.termFilter?.replace(TERM_FILTER_PREFIX, '') ||
    t('capture.labels.selectedFilterPlaceholder');

  function handleChangeDomainCheckbox(
    checked: boolean,
    affectedDomains: string[]
  ) {
    let domainNames = [...(parameterValueObj.domainNames || [])];

    if (checked) {
      domainNames = domainNames.concat(affectedDomains);
    } else {
      affectedDomains.forEach((id) => {
        const index = domainNames.findIndex((dName) => dName === id);
        if (index > -1) {
          domainNames.splice(index, 1);
        }
      });
    }

    targetActions.setGuidelineParameterValue(
      goal,
      guideline,
      JSON.stringify({
        ...parameterValueObj,
        domainNames: Array.from(new Set(domainNames)),
      })
    );
  }

  return {
    domainData,
    domainSummary,
    filter,
    guideline,
    guidelineValues,
    handleChangeDomainCheckbox,
    parameterValueObj,
    presetsSelectOptions,
    radioChangeHandler,
    termFilterChecked,
    termFilterData,
  };
}
