import React, { ElementType } from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const StyledAdministrativeInformationTitle = styled(Typography)<
  TypographyProps<ElementType, { component?: ElementType }>
>(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.32),
  lineHeight: theme.typography.pxToRem(21),
  fontWeight: theme.typography.fontWeightMedium,
  paddingBottom: theme.spacing(1),
}));

export const StyledInfoText = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography variant="body2" {...props}>
      {children}
    </Typography>
  )
)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  lineHeight: theme.typography.pxToRem(21),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledInfoLabel = styled(StyledInfoText)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.39),
  fontSize: theme.typography.pxToRem(13),
  color: theme.palette.custom.gray36,
  paddingBottom: 0,
  paddingTop: theme.spacing(2),
}));

export const BreakGrid = styled(Grid)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0, 0, 6, 0),
}));
