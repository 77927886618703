import { useState } from 'react';

export function useCopyToCliboardButton() {
  const [copied, setCopied] = useState<boolean>(false);

  async function copyText(text: string) {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 7000);
    navigator.clipboard.writeText(text);
  }

  return {
    copied,
    copyText,
  };
}
