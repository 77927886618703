import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import { ButtonProps } from '@mui/material/Button';

import ButtonPrimary from 'components/ButtonPrimary';
import CommonTooltip from 'components/CommonTooltip';
import ConditionalWrapper from 'components/ConditionalWrapper';
import DialogBox, {
  StyledButtonPrimary,
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from 'components/DialogBox';
import CommonOutlinedMultilineTextField from 'components/CommonOutlinedMultilineTextFields/CommonOutlinedMultilineTextField';
import { MAX_TARGET_EVENT_COMMENT_LENGTH } from 'sections/Targets/constants';
import { UseCommentResult } from '../../hooks/useComment';

export type SaveCommentButtonProps = ButtonProps &
  UseCommentResult & {
    cancelAndCloseHandler?: () => void;
    cancelButtonLabel: string;
    submitButtonClickHandler: () => void;
    submitButtonLabel: string;
    tooltipText?: string;
  };

export default function SaveCommentButton({
  cancelAndCloseHandler,
  cancelButtonLabel,
  changeCommentHandler,
  error,
  setTextValue,
  submitButtonClickHandler,
  submitButtonLabel,
  textValue,
  textValueCount,
  tooltipText,
  ...props
}: SaveCommentButtonProps) {
  const { t } = useTranslation();
  const [commentOpen, setCommentOpen] = useState(false);

  const closeAndClear = useCallback(() => {
    setTextValue('');
    setCommentOpen(false);
  }, [setTextValue]);

  const close = useCallback(() => {
    setCommentOpen(false);
  }, []);

  return (
    <>
      <ConditionalWrapper
        condition={!!tooltipText}
        wrapper={(children) => (
          <CommonTooltip title={tooltipText} placement="top-end">
            {children}
          </CommonTooltip>
        )}
      >
        <ButtonPrimary
          aria-haspopup="dialog"
          onClick={() => setCommentOpen(!commentOpen)}
          {...props}
        >
          <MarkChatReadOutlinedIcon />
        </ButtonPrimary>
      </ConditionalWrapper>
      {commentOpen && (
        <DialogBox open={commentOpen} onClose={closeAndClear}>
          <StyledDialogTitle
            id="form-dialog-title"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            {t('capture.titles.saveTargetWithComment')}
          </StyledDialogTitle>
          <StyledDialogContent
            sx={(theme) => ({
              paddingBottom: theme.spacing(1),
              paddingTop: theme.spacing(1),
              marginBottom: theme.spacing(4),
              width: theme.spacing(60),
              maxWidth: theme.spacing(60),
            })}
          >
            <CommonOutlinedMultilineTextField
              id="target-change-description"
              label={t('capture.labels.comment')}
              rows="6"
              fullWidth
              error={error}
              helperText={t(
                error
                  ? 'capture.descriptions.commentTooLong'
                  : 'capture.hints.commentRequired',
                { limit: MAX_TARGET_EVENT_COMMENT_LENGTH }
              )}
              margin="none"
              value={textValue}
              onChange={changeCommentHandler}
              charCount={textValueCount}
              maxCharCount={MAX_TARGET_EVENT_COMMENT_LENGTH}
            />
          </StyledDialogContent>
          <StyledDialogActions disableSpacing={true}>
            <ButtonPrimary
              onClick={cancelAndCloseHandler ?? closeAndClear}
              variant="outlined"
            >
              {cancelButtonLabel}
            </ButtonPrimary>
            <StyledButtonPrimary
              onClick={() => {
                submitButtonClickHandler();
                // don't clear comment, it will be cleared in submitButtonClickHandler (save)
                close();
              }}
              variant="contained"
              disabled={error}
            >
              {submitButtonLabel}
            </StyledButtonPrimary>
          </StyledDialogActions>
        </DialogBox>
      )}
    </>
  );
}
