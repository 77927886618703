import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

import { ReactComponent as EmailIcon } from './emailIcon.svg';

export const StyledButtonLink = styled('a')(({ theme }) => ({
  backgroundColor: blue[50],
  textDecoration: 'none',
  borderRadius: theme.spacing(6),
  display: 'inline-flex',
  padding: theme.spacing(9 / 5, 3),
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  '& span': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.32),
    textTransform: 'uppercase',
  },
  '&:hover, &:focus': {
    backgroundColor: blue[100],
  },
  '&:link, &:visited, &:focus, &:hover, &:active': {
    textDecoration: 'none',
    '& span': {
      textDecoration: 'none',
    },
  },
}));

export const StyledEmailIcon = styled(EmailIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));
