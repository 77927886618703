import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

export const FlexBox = styled(Box)({
  display: 'flex',
});

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

export const MainContent = styled('main')(({ theme }) => {
  const topNavHeight = theme.typography.pxToRem(
    typeof theme.mixins.toolbar.minHeight === 'number'
      ? theme.mixins.toolbar.minHeight
      : parseInt(theme.mixins.toolbar.minHeight ?? '70', 10)
  );

  return {
    flexGrow: 1,
    overflowY: 'auto',
    height: `calc(100vh - ${topNavHeight})`,
    marginTop: topNavHeight,
  };
});

export const StyledNavigationRailContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(14),
}));
