import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/system/Box';
import { blueGrey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const CreateUserSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.gray36,
  fontSize: theme.typography.pxToRem(13),
  paddingBottom: theme.spacing(3),
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: theme.typography.pxToRem(0.39),
}));

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  padding: theme.spacing(1 / 5),
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
    borderRadius: '50%',
  },
  outline: 'none',
}));

export const CreateUserSectionContent = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const StyledHelpOutlineOutlinedIcon = styled(HelpOutlineOutlinedIcon)(
  ({ theme }) => ({
    color: theme.palette.custom.botticelli,
    outline: 'none',
    marginRight: theme.spacing(1),
  })
);

export const StyledDrawerContent = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  padding: `0 ${theme.spacing(1.8)} 0 ${theme.spacing(4)}`,
  margin: 0,
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  flexDirection: 'column',
  padding: 0,
  paddingBottom: theme.spacing(4),
}));

export const AccordionWrapperBox = styled(Box)(() => ({
  '& > *:last-child': {
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 1,
      height: 1,
      backgroundColor: blueGrey[100],
    },
  },
}));
