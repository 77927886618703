import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { UnifiedMainPageLayout } from 'components/PageLayout';
import { CardBreak, SuggestionsTitle } from './components';
import SuggestionCard from './components/SuggestionCard';
import {
  useReuseInbox,
  useScrollableSuggestions,
  useShowDismissedSuggestions,
} from './hooks';
import BusyIndicator from 'components/BusyIndicator';
import CreateOrEditReplacementDrawer from 'sections/Reuse/components/CreateOrEditReplacementDrawer';
import TargetSettingsDrawer from './components/TargetSettingsDrawer';
import TargetSettingsIcon from 'icons/TargetSettingsIcon';
import CommonTooltip from 'components/CommonTooltip';
import CommonStartPageNoData from 'components/CommonStartPage';
import LanguageTargetFilterDropdowns from './components/LanguageTargetFilterDropdowns/LanguageTargetFilterDropdowns';
import useTitle from 'hooks/useTitle';
import TablePaginationCustom from 'components/TablePaginationCustom';
import HarvestingStatusButtonAndDialog from './components/HarvestingStatusButtonAndDialog/HarvestingStatusButtonAndDialog';
import SuggestionListToggle from './components/SuggestionListToggle';

export default function Inbox() {
  const { t } = useTranslation();

  useTitle(
    `${t('reuseInbox.title')} - ${t('reuse.title')} - ${t('shared.app.name')}`
  );

  // for test purposes for now in external hook
  const suggestionsToggleState = useShowDismissedSuggestions();

  const {
    closeReplacementDrawer,
    closeTargetSettingsDrawer,
    createOrEditReplacementDrawerIsOpen,
    data,
    deprecatedPhrases,
    fetchingTargets,
    filteredSuggestionList,
    languages,
    openReplacementDrawer,
    openTargetSettingsDrawer,
    page,
    refreshSuggestionList,
    refreshTargets,
    replacement,
    showLanguageLabelOnSuggestionCard,
    setFilteredSuggestionList,
    setPage,
    sortedDomains,
    sortedTargets,
    suggestionId,
    suggestionList,
    targetSettingsDrawerIsOpen,
    targetValues,
    totalPages,
  } = useReuseInbox(suggestionsToggleState.dismissedSuggestionsShown);

  const { setResizeElement, styles } = useScrollableSuggestions({
    dismissedSuggestionsShown: suggestionsToggleState.dismissedSuggestionsShown,
    filteredSuggestionList,
  });

  return (
    <UnifiedMainPageLayout
      heading={t('reuseInbox.title')}
      focusHeading
      helpIconText={t('reuseInbox.a11y.inboxInfo')}
      headerRightSideContent={
        <>
          <HarvestingStatusButtonAndDialog />
          <CommonTooltip title={t('reuseInbox.tooltips.targetSettingsIcon')}>
            <TargetSettingsIcon onClick={openTargetSettingsDrawer} />
          </CommonTooltip>
        </>
      }
    >
      {createOrEditReplacementDrawerIsOpen && replacement ? (
        <CreateOrEditReplacementDrawer
          closeDrawer={closeReplacementDrawer}
          cluster={replacement}
          domainList={sortedDomains}
          drawerIsOpen={createOrEditReplacementDrawerIsOpen}
          editRights={true}
          languages={languages}
          newAddedDeprecatedPhrases={deprecatedPhrases}
          refresh={refreshSuggestionList}
          suggestionId={suggestionId}
        />
      ) : (
        <></>
      )}
      {targetSettingsDrawerIsOpen ? (
        <TargetSettingsDrawer
          closeDrawer={closeTargetSettingsDrawer}
          drawerIsOpen={targetSettingsDrawerIsOpen}
          fetchingTargets={fetchingTargets}
          refreshTargets={refreshTargets}
          targetValues={targetValues}
        />
      ) : (
        <></>
      )}

      <Grid container justifyItems="center">
        <LanguageTargetFilterDropdowns
          languages={languages}
          setFilteredSuggestionList={setFilteredSuggestionList}
          sortedTargets={sortedTargets}
          suggestionList={suggestionList}
        />
        <SuggestionListToggle stateObject={suggestionsToggleState} />
      </Grid>
      <Divider sx={(theme) => ({ margin: theme.spacing(4, 0) })} />
      <div>
        <SuggestionsTitle>
          {t(
            suggestionsToggleState.dismissedSuggestionsShown
              ? 'reuseInbox.titles.blockedSuggestions'
              : 'reuseInbox.titles.newSuggestions'
          )}
        </SuggestionsTitle>
        {!data ? (
          <Box pt={20} pb={17}>
            <BusyIndicator />
          </Box>
        ) : data.length === 0 ? (
          <CommonStartPageNoData
            title={t(
              suggestionsToggleState.dismissedSuggestionsShown
                ? 'reuseInbox.titles.noDismissedSuggestionsData'
                : 'reuseInbox.titles.noData'
            )}
            description={t(
              suggestionsToggleState.dismissedSuggestionsShown
                ? 'reuseInbox.descriptions.noDismissedSuggestionsData'
                : 'reuseInbox.descriptions.noData'
            )}
          />
        ) : (
          <>
            <div style={styles} ref={setResizeElement}>
              {!!languages &&
                filteredSuggestionList.map((suggestion, index) => (
                  <div key={index}>
                    {!!index && <CardBreak />}
                    <SuggestionCard
                      data={suggestion}
                      languages={languages}
                      handleOnClick={openReplacementDrawer}
                      sortedDomains={sortedDomains}
                      refreshSuggestionList={refreshSuggestionList}
                      showLanguageLabelOnSuggestionCard={
                        showLanguageLabelOnSuggestionCard
                      }
                    />
                  </div>
                ))}
            </div>
            {suggestionsToggleState.dismissedSuggestionsShown &&
              !!filteredSuggestionList.length && (
                <Box
                  display="flex"
                  justifyContent="center"
                  minWidth={'fit-content'}
                  pt={5}
                >
                  <Box>
                    <TablePaginationCustom
                      page={page}
                      totalPages={totalPages}
                      onChangePage={setPage}
                    />
                  </Box>
                </Box>
              )}
          </>
        )}
      </div>
    </UnifiedMainPageLayout>
  );
}
