// the codes are lower case
export const A11Y_KEYBOARD_CODES_FOR_CLICK = ['enter', 'space'];

export const TARGET_SERVICE_PATH = '/target-service/api/v1';
export const TARGETS_INDEX_PATH = 'targets/index';
export const TARGET_CORE_SERVER_CONTEXTS = '/target-service/api/v1/contexts';
export const LANGUAGES_PATH = 'languages/index';
export const REUSE_BASE_PATH = '/reuse-service/api/v1';
export const SERVER_DATA_PATH = '/api/v1';
export const USER_API_PATH = '/api/v1/user';
export const USER_SELF_API_PATH = '/api/v1/user/self';
export const FEATURES_PATH = '/api/v1/configuration/features';
export const PRIVILEGES_PATH = '/dashboard/api/signin/privileges';
export const LICENSE_STATISTICS_API_PATH = '/api/v1/licenses/statistics';
export const ROLES_API_PATH = '/api/v1/roles';
export const CUSTOM_FIELDS_API_PATH = '/api/v1/custom-fields/user';
export const API_LANGUAGES_SIMPLE_PATH = 'languages/simple';
export const REUSE_DOMAINS_PATH = 'reuse-domains';

export const TARGET_INDEX_QUERY_KEY = 'targets index';
export const LANGUAGE_QUERY_KEY = 'languages';
export const REUSE_SUGGESTIONS_QUERY_KEY = 'reuse suggestions';
export const DOMAINS_QUERY_KEY = 'fetch reuse domains';
export const TARGET_CONTEXTS_QUERY_KEY = 'fetch reuse domains';
export const TARGET_DOCUMENT_REFERENCE_QUERY_KEY = 'fetch document reference';
export const TARGET_DOCUMENT_CUSTOM_FIELDS_QUERY_KEY =
  'fetch document custom fields';
export const TARGET_REGEXES_QUERY_KEY = 'fetch regexes';
export const ASSIGNED_TARGETS_QUERY_KEY = 'fetch assigned targets';
export const ASSIGNED_TARGET_QUERY_KEY = 'fetch assigned target';
export const USERS_QUERY_KEY = 'fetch users';
export const ROLES_QUERY_KEY = 'fetch roles';
export const CUSTOM_FIELDS_QUERY_KEY = 'fetch custom fields';
export const DO_NOT_EXPAND_CLASS = 'Do-not-expand';
