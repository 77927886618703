import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ConditionalWrapper from 'components/ConditionalWrapper';
import CommonTooltip from 'components/CommonTooltip';
import CustomCheckbox from 'components/CustomCheckbox';
import RolesSelector from '../../../RolesSelector';
import { MAX_COLUMN_WIDTH } from '../../UsersTable';
import { MenuMoreIconButton } from '../../styles';
import { ReactComponent as IconArrowDownward } from 'icons/icon-arrow-downward.svg';
import { ReactComponent as IconPadlock } from 'icons/icon-lock.svg';
import useCellRefs from '../../hooks/useCellRefs';
import type { HeadCellType } from '../../UsersTable';
import PrivilegesContext from 'contexts/PrivilegesContext';
import FeaturesContext from 'contexts/FeaturesContext';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';
import { SortOrder, User } from 'sections/Users/types';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { CustomTableSortLabel, StyledTableCell } from './styles';
import { useRolesQuery } from 'sections/Users/pages/UsersIndexPage/queries';

type Props = {
  columns: HeadCellType[];
  selectedAmount: number;
  isCheckboxToSelectAllDisabled?: boolean;
  isAllSelected?: boolean;
  onMenuMoreClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSelectAll: (checked: boolean) => void;
  orderBy: keyof User;
  orderDirection: SortOrder;
  onChangeOrder: (by: keyof User, direction: SortOrder) => void;
  filteredRoles: User['roles'];
  onChangeFilteredRoles: (filteredRoles: User['roles']) => void;
  isMoreMenuOpen: boolean;
};

export default function UsersTableHeadRow({
  columns = [],
  selectedAmount,
  isCheckboxToSelectAllDisabled = false,
  isAllSelected = false,
  onClickSelectAll,
  onMenuMoreClick,
  orderBy,
  orderDirection,
  onChangeOrder,
  filteredRoles,
  onChangeFilteredRoles,
  isMoreMenuOpen,
}: Props) {
  const { t } = useTranslation();
  const userPrivileges = useContext(PrivilegesContext);
  const features = useContext(FeaturesContext);

  const MAX_COLUMN_HEADER_WIDTH_FOR_CUSTOM_FIELD_OF_TYPE_EXTERNALLY_PROVIDED =
    MAX_COLUMN_WIDTH - 64;
  const MAX_COLUMN_HEADER_WITH_ICON_WIDTH = MAX_COLUMN_WIDTH - 36;

  const { data: rolesData } = useRolesQuery();

  const refs = useCellRefs(columns);

  return (
    <TableRow>
      {userPrivileges.includes(USER_AND_ROLES_EDIT_USER) && (
        <>
          <StyledTableCell padding="none" role="cell">
            <MenuMoreIconButton
              disabled={selectedAmount === 0}
              aria-label={t('users.a11y.label.moreOptionsMenu.expandMultiple')}
              aria-controls={
                isMoreMenuOpen ? 'more-options-menu-header' : undefined
              }
              aria-haspopup="true"
              size="small"
              onClick={onMenuMoreClick}
            >
              <MoreVertIcon />
            </MenuMoreIconButton>
          </StyledTableCell>
          <StyledTableCell padding="none" role="cell">
            <CustomCheckbox
              checked={selectedAmount > 0}
              indeterminate={selectedAmount > 0 && !isAllSelected}
              disabled={isCheckboxToSelectAllDisabled}
              onChange={(_event, checked) => onClickSelectAll(checked)}
              inputProps={{
                'aria-label': t('users.a11y.label.checkbox.selectAll'),
              }}
              name="selectAll"
              color="primary"
            />
          </StyledTableCell>
        </>
      )}
      <TransitionGroup component={null}>
        {columns.map(({ key, label, sortable, inputType }) => {
          let maxWidth: string | number = MAX_COLUMN_HEADER_WITH_ICON_WIDTH;

          if (inputType === 'externallyProvided') {
            maxWidth =
              MAX_COLUMN_HEADER_WIDTH_FOR_CUSTOM_FIELD_OF_TYPE_EXTERNALLY_PROVIDED;
          } else if (
            [
              'lastIntegrationAccess',
              'checkingFrequency',
              'licenseType',
            ].includes(key)
          ) {
            maxWidth = '100%';
          }

          const nodeRef = refs[key];

          if (!nodeRef) {
            return null;
          }

          return (
            <CSSTransition
              key={`head-cell-${key}`}
              nodeRef={nodeRef}
              timeout={400}
              classNames="table-cell"
            >
              <StyledTableCell
                ref={nodeRef}
                {...(!features.enableKeycloak &&
                  sortable && {
                    sortDirection: orderBy === key ? orderDirection : false,
                  })}
                padding={sortable ? 'none' : 'normal'}
                width={'100%'}
                sx={{
                  ...((key === 'roles' || features.enableKeycloak) && {
                    p: 2,
                    py: 8.5 / 5,
                  }),
                }}
              >
                <ConditionalWrapper
                  condition={!features.enableKeycloak && sortable}
                  wrapper={(children) => (
                    <CustomTableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? orderDirection : 'asc'}
                      onClick={() => {
                        onChangeOrder(
                          key,
                          orderBy === key && orderDirection === 'asc'
                            ? 'desc'
                            : 'asc'
                        );
                      }}
                      IconComponent={IconArrowDownward}
                      sx={{
                        ...(inputType === 'externallyProvided' && {
                          p: 2,
                          py: 10.5 / 5,
                        }),
                      }}
                    >
                      {children}
                    </CustomTableSortLabel>
                  )}
                >
                  <>
                    <Box
                      component={'div'}
                      maxWidth={maxWidth}
                      sx={{
                        verticalAlign: 'inherit',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          lineHeight: '19px',
                        }}
                      >
                        {key.startsWith('customFields.') ? label : t(label)}
                      </Box>
                      {!features.enableKeycloak && orderBy === key && (
                        <span className={'visually-hidden'}>
                          {t(
                            orderDirection === 'desc'
                              ? 'shared.sort.a11y.sortedDescending'
                              : 'shared.sort.a11y.sortedAscending'
                          )}
                        </span>
                      )}
                      {key === 'roles' && (
                        <Box ml={1} display={'inline-block'}>
                          <RolesSelector
                            roles={rolesData?.data}
                            selectedRoles={filteredRoles}
                            onChange={onChangeFilteredRoles}
                          />
                        </Box>
                      )}
                    </Box>
                    {inputType === 'externallyProvided' && (
                      <CommonTooltip
                        title={<>{t('users.tooltips.readOnlyColumn')}</>}
                        sx={{
                          lineHeight: 1,
                        }}
                      >
                        <IconButton
                          role="img"
                          aria-label={t('users.tooltips.readOnlyColumn')}
                          aria-hidden={false}
                          sx={{
                            height: 28,
                            width: 28,
                            marginLeft: 1,
                            color: (theme) => theme.palette.text.primary,
                            '&:hover': {
                              backgroundColor: (theme) =>
                                theme.palette.background.default,
                            },
                          }}
                        >
                          <IconPadlock fontSize="small" />
                        </IconButton>
                      </CommonTooltip>
                    )}
                  </>
                </ConditionalWrapper>
              </StyledTableCell>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </TableRow>
  );
}
