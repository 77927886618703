import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&$checked': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$disabled': { opacity: 0.4 },
  },
  '&.MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.MuiIconButton-colorSecondary': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default CustomCheckbox;
