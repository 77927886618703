import { FeatureSwitches } from 'types/types';

export const canGenerateNewPassword = (
  editingAllowed: boolean,
  username: string | undefined,
  features: FeatureSwitches
) => {
  if (!editingAllowed) {
    return false;
  }

  if (username === 'admin') {
    return features.allowAdminPasswordChange;
  }

  if (username === 'termcontribution') {
    return false;
  }

  return (
    !features.usesExternalAuth ||
    (features.usesExternalAuth &&
      [
        'termbrowser',
        'analyticsReadOnlyUser',
        'termtargetaccess',
        'termapiaccess',
      ].includes(username as string))
  );
};
