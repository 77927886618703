import React, { ChangeEvent } from 'react';
import FormGroup from '@mui/material/FormGroup';

import DomainStructure from './DomainStructure';
import SearchTextField from 'sections/Targets/components/SearchTextField';
import { useTranslation } from 'react-i18next';
import { useDomainMenu } from './hooks';
import { DomainsMenuProps } from './types';
import { StyledPopover } from './styles';

export default function DomainMenu({
  anchorEl,
  domainData,
  handleClose,
  open,
  selectedDomains,
  ...rest
}: DomainsMenuProps): JSX.Element {
  const { t } = useTranslation();
  const { domainStructure, fullDisplayStructure, setFilteredDomainData } =
    useDomainMenu({
      domainData,
      selectedDomains,
    });

  return (
    <StyledPopover
      open={open}
      onClose={() => {
        setFilteredDomainData(fullDisplayStructure.map((d) => d.internalId));
        handleClose();
      }}
      anchorEl={anchorEl}
      id="add-domain-menu"
    >
      <SearchTextField
        autoFocus
        fullWidth
        placeholder={t('shared.components.input.placeholderSearch')}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          setFilteredDomainData(
            fullDisplayStructure
              .filter((domainObj) =>
                domainObj.displayName
                  .toLowerCase()
                  .includes(evt.target.value?.toLowerCase() || '')
              )
              .map((d) => d.internalId)
          );
        }}
      />
      <FormGroup
        sx={(theme) => ({
          paddingTop: theme.spacing(2),
        })}
      >
        {domainStructure.map((domainObj, index) => (
          <DomainStructure
            {...rest}
            key={domainObj.internalId}
            domainObj={domainObj}
            fullDisplayStructure={fullDisplayStructure}
          />
        ))}
      </FormGroup>
    </StyledPopover>
  );
}
