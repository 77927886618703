import { styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

export const DeleteDomainDescription = styled('span')(({ theme }) => ({
  '& span': {
    display: 'inline-block',
    backgroundColor: pink[50],
    color: pink['A700'],
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(4),
  },
}));
