import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButtonProps } from '@mui/material/IconButton';

import DomainAddButton from 'components/DomainAddButton';
import DomainMenu from 'components/DomainMenu';
import useMenuState from 'hooks/useMenuState';
import { HandleChangeDomainCheckboxFn } from 'components/DomainMenu/types';
import { ReuseIndexDomain } from '../../types';
import { EnumContentObject } from 'types/types';

export interface ShowDomainMenuButtonProps extends IconButtonProps {
  disabled?: boolean;
  domainList: ReuseIndexDomain[];
  handleChangeDomainCheckbox: HandleChangeDomainCheckboxFn;
  selectedDomains: string[];
}

export default function ShowDomainMenuButton({
  disabled,
  domainList,
  handleChangeDomainCheckbox,
  selectedDomains,
  ...props
}: ShowDomainMenuButtonProps) {
  const { t } = useTranslation();
  const { anchorEl, open, handleClose, handleClick } = useMenuState();
  const domainData: EnumContentObject[] = useMemo(
    () =>
      domainList.map((d) => ({
        internalId: d.uuid,
        displayName: d.displayName,
      })),
    [domainList]
  );

  return (
    <>
      <DomainAddButton
        aria-controls="add-domain-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        onClick={open ? handleClose : handleClick}
        tooltipText={t('reuse.tooltips.addSetToReplacement')}
        {...props}
      />
      <DomainMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleChangeDomainCheckbox={handleChangeDomainCheckbox}
        domainData={domainData}
        selectedDomains={selectedDomains}
      />
    </>
  );
}
