import React, { PropsWithChildren, ReactElement } from 'react';
interface ConditionalWrapperProps extends PropsWithChildren<any> {
  condition: boolean;
  Wrapper: (props: PropsWithChildren<any>) => JSX.Element;
}

const ConditionalWrapperWithProps = ({
  condition,
  Wrapper,
  children,
  ...props
}: ConditionalWrapperProps): ReactElement => {
  return condition ? <Wrapper {...props}>{children}</Wrapper> : children;
};

export default ConditionalWrapperWithProps;
