import {
  ContextConfig,
  ContextsFilterType,
  DialogReloadLSData,
  Goal,
  GoalConfig,
  GoalId,
  Guideline,
  GuidelineId,
  GuidelineValue,
  Preset,
  PresetId,
  Target,
} from './types';
import { TFunction } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import { FILTER_TYPES } from './components/EditContextsDrawer/constants';
import {
  CUSTOM,
  PREDEFINED_CUSTOM,
  PRESET_TYPES,
  REUSE_GL,
  REUSE_GUIDELINE_TYPE,
  WAP_GL,
  WORDS_AND_PHRASES_GUIDELINE_TYPE,
} from './constants';
import { TerminologyGuidelineParameterValueObject } from './components/TerminologyGuideline/hooks';
import { EditContextsDrawerForProps } from './components/EditContextsDrawer';

function getGoalInfo(
  targetGoalInfos: GoalConfig[],
  goalId: string
): GoalConfig | undefined {
  return targetGoalInfos?.find((goalInfo) => goalInfo.goalId === goalId);
}

export function configureGuideline(
  targetGoalInfos: GoalConfig[],
  goal: Goal,
  guidelineId: GuidelineId,
  originalTargetGoalInfos: GoalConfig[],
  setSnackbarMessage: (message: string) => void,
  updateDraft: (guideline: GuidelineValue) => GuidelineValue,
  t: TFunction
): GoalConfig[] {
  const originalGoalInfo = getGoalInfo(
    originalTargetGoalInfos,
    goal.identifier
  );

  function getPredefinedPreset(presetId: PresetId): Preset {
    return goal.predefinedGoalPresets.find(
      (p) => p.identifier === presetId
    ) as Preset;
  }

  function createCustomPresetObj(presetId: PresetId) {
    const presetsSelectOptions: Preset[] = [...goal.predefinedGoalPresets];

    const selectedPreset = presetsSelectOptions.find(
      (x) => x.identifier === goalConfig.selectedPresetId
    );

    return {
      ...getPredefinedPreset(presetId),
      presetType: customPreset.presetType,
      identifier: CUSTOM.toUpperCase(),
      displayName: customPreset.displayName,
      edited: true,
      description: t('capture.defaults.customPresetDescription', {
        preset: selectedPreset?.displayName,
        goal: goal.displayName,
        interpolation: { escapeValue: false },
      }),
    };
  }

  const goalConfigIndex = targetGoalInfos.findIndex(
    (goalInfo) => goalInfo.goalId === goal.identifier
  );
  const goalConfig = { ...targetGoalInfos[goalConfigIndex] };

  let customPreset: Preset =
    (goalConfig.customPreset as Preset) ||
    goal.predefinedGoalPresets.find(
      (preset) => preset.identifier === PREDEFINED_CUSTOM
    );

  if (goalConfig.selectedPresetId?.toLowerCase() !== CUSTOM) {
    customPreset = createCustomPresetObj(
      goalConfig.selectedPresetId as PresetId
    );
  }

  const modifiedCustomPreset = ((): Preset => {
    const preset = cloneDeep(customPreset);
    const index = preset.guidelineValues?.findIndex(
      (x) => x.guidelineId === guidelineId
    ) as number;

    if (index > -1) {
      (preset.guidelineValues as GuidelineValue[])[index] = updateDraft(
        (preset.guidelineValues as GuidelineValue[])[index]
      );
    } else {
      if (!preset.guidelineValues) {
        preset.guidelineValues = [];
      }

      preset.guidelineValues.push(
        updateDraft({
          guidelineId: guidelineId,
          active: false,
          contexts: [],
          contextsActive: false,
          contextsFilterType: FILTER_TYPES[0] as ContextsFilterType,
        })
      );
    }

    return preset;
  })();

  const resetCustomPreset: Preset = {
    ...modifiedCustomPreset,
    description: originalGoalInfo?.customPreset?.description || '',
    edited: originalGoalInfo?.customPreset?.edited || false,
  };

  const modifiedCustomPresetIsTheSameAsOriginal = isEqual(
    originalGoalInfo?.customPreset,
    resetCustomPreset
  );

  modifiedCustomPreset.edited = modifiedCustomPresetIsTheSameAsOriginal
    ? !!originalTargetGoalInfos[goalConfigIndex]?.customPreset?.edited
    : true;

  const newTargetGoalInfos = [...targetGoalInfos];
  newTargetGoalInfos[goalConfigIndex] = {
    ...newTargetGoalInfos[goalConfigIndex],
  };

  newTargetGoalInfos[goalConfigIndex].selectedPresetId =
    modifiedCustomPresetIsTheSameAsOriginal
      ? originalGoalInfo?.selectedPresetId
      : modifiedCustomPreset.identifier;
  newTargetGoalInfos[goalConfigIndex].customPreset =
    modifiedCustomPresetIsTheSameAsOriginal
      ? originalGoalInfo?.customPreset
      : modifiedCustomPreset;

  return newTargetGoalInfos;
}

export function createNewGoalConfig(
  allGoals: Goal[],
  goalId: GoalId
): GoalConfig {
  const goal = allGoals.find((x) => x.identifier === goalId);
  if (!goal) {
    throw new Error(`Unknown goal "${goalId}".`);
  }
  return {
    goalId: goalId,
    recommended: false,
    selectedPresetId: goal.predefinedGoalPresets.find(
      (x) => x.presetType === PRESET_TYPES.predefinedDefault
    )?.identifier, // Choose default preset.
  };
}

export function compareParameterValueObjects(
  objectA: TerminologyGuidelineParameterValueObject,
  objectB: TerminologyGuidelineParameterValueObject
): string[] {
  return reduce(
    objectA,
    function (result: string[], value, key) {
      return isEqual(
        value,
        objectB[key as keyof TerminologyGuidelineParameterValueObject]
      )
        ? result
        : result.concat([key]);
    },
    []
  );
}

export function compareParameterValueAsString(
  stringA: string,
  stringB: string
): string[] {
  if (stringA !== stringB) {
    return [stringA];
  }

  return [];
}

export function setGoalRecommendedFlag({
  goal,
  recommended,
  setTarget,
  target,
}: {
  goal: Goal;
  recommended: boolean;
  setTarget: (v: Target) => void;
  target: Target;
}) {
  const newTarget = cloneDeep(target);
  const goalIndex = newTarget.targetGoalInfos.findIndex(
    (x: GoalConfig) => x.goalId === goal.identifier
  );

  const goalConfig = { ...newTarget.targetGoalInfos[goalIndex] };
  goalConfig.recommended = recommended;

  newTarget.targetGoalInfos[goalIndex] = goalConfig;

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }
}

export function setGoalPreset({
  goal,
  presetId,
  setTarget,
  target,
}: {
  goal: Goal;
  presetId: PresetId;
  setTarget: (v: Target) => void;
  target: Target;
}) {
  const newTarget = cloneDeep(target);
  const goalIndex = newTarget.targetGoalInfos.findIndex(
    (x: GoalConfig) => x.goalId === goal.identifier
  );

  const goalConfig = { ...newTarget.targetGoalInfos[goalIndex] };
  goalConfig.selectedPresetId = presetId;

  newTarget.targetGoalInfos[goalIndex] = goalConfig;

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }
}

export function setGoalGuidelineActive({
  active,
  goal,
  guidelineId,
  originalTarget,
  setSnackbarMessage,
  setTarget,
  t,
  target,
}: {
  active: boolean;
  goal: Goal;
  guidelineId: GuidelineId;
  originalTarget: Target | undefined;
  setSnackbarMessage: (v: string) => void;
  setTarget: (v: Target) => void;
  t: TFunction;
  target: Target;
}) {
  const newTarget = cloneDeep(target);
  newTarget.targetGoalInfos = configureGuideline(
    newTarget.targetGoalInfos,
    goal,
    guidelineId,
    originalTarget?.targetGoalInfos || [],
    setSnackbarMessage,
    (guideline) => {
      const newGuideline = { ...guideline };
      newGuideline.active = active;

      return isEqual(newGuideline, guideline) ? guideline : newGuideline;
    },
    t
  );

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }
}

export function setGoalGuidelineParameterValue({
  allGoals,
  dialogWithReloadLSData,
  goal,
  guideline,
  originalTarget,
  parameterValue,
  setDialogWithLSReloadIsActive,
  setDialogWithReloadLSData,
  setSnackbarMessage,
  setTarget,
  t,
  target,
}: {
  allGoals: Goal[];
  dialogWithReloadLSData: DialogReloadLSData[];
  goal: Goal;
  guideline: Guideline;
  originalTarget: Target | undefined;
  parameterValue: string;
  setDialogWithLSReloadIsActive: (v: boolean) => void;
  setDialogWithReloadLSData: (v: DialogReloadLSData[]) => void;
  setSnackbarMessage: (v: string) => void;
  setTarget: (v: Target) => void;
  t: TFunction;
  target: Target;
}) {
  const targetGoalInfos = originalTarget?.targetGoalInfos || [];

  const newTarget = cloneDeep(target);
  newTarget.targetGoalInfos = configureGuideline(
    newTarget.targetGoalInfos,
    goal,
    guideline.identifier,
    targetGoalInfos,
    setSnackbarMessage,
    (guidelineValue) => {
      const newGuideline = cloneDeep(guidelineValue);
      newGuideline.parameterValue = parameterValue;

      return isEqual(newGuideline, guidelineValue)
        ? guidelineValue
        : newGuideline;
    },
    t
  );

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }

  const reuseGuideline =
    guideline.guidelineType.toLowerCase() === REUSE_GUIDELINE_TYPE
      ? REUSE_GL
      : '';
  const guidelineType =
    guideline.guidelineType.toLowerCase() === WORDS_AND_PHRASES_GUIDELINE_TYPE
      ? WAP_GL
      : reuseGuideline;
  // const parameterValueIsString = guidelineType === REUSE_GL;
  const parameterValueIsJSON = guidelineType === WAP_GL;

  if (guidelineType) {
    const goalInfo = targetGoalInfos.find(
      (goalConfig) => goalConfig.goalId === goal.identifier
    );

    const originalParameterValue =
      goalInfo?.selectedPresetId?.toLowerCase() === CUSTOM
        ? (goalInfo?.customPreset?.guidelineValues?.find(
            (g) => g.guidelineId === guideline.identifier
          )?.parameterValue as string)
        : (allGoals
            .find((langGoal) => langGoal.identifier === goal.identifier)
            ?.predefinedGoalPresets.find(
              (preset) => preset.identifier === goalInfo?.selectedPresetId
            )
            ?.guidelineValues?.find(
              (g) => g.guidelineId === guideline.identifier
            )?.parameterValue as string);

    // find if the same goal and the same guideline already was added
    let index = dialogWithReloadLSData.findIndex(
      (data) =>
        data.goalDisplayName === goal.displayName &&
        data.guidelineDisplayName === guideline.displayName
    );
    // modify existing data or add new set
    index = index === -1 ? dialogWithReloadLSData.length : index;

    const newData = [...dialogWithReloadLSData];
    newData[index] = {
      goalId: goal.identifier,
      goalDisplayName: goal.displayName,
      guidelineId: guideline.identifier,
      guidelineDisplayName: guideline.displayName,
      originalParameterValue,
      parameterValue,
      parameterValueIsJSON,
    };

    setDialogWithReloadLSData(newData);

    const diff = parameterValueIsJSON
      ? compareParameterValueObjects(
          JSON.parse(originalParameterValue || '{}'),
          JSON.parse(parameterValue)
        )
      : compareParameterValueAsString(originalParameterValue, parameterValue);

    setDialogWithLSReloadIsActive(
      !diff.includes('scoreAdmittedTerms') &&
        !diff.includes('suggestAdmittedTerms') &&
        !diff.includes('markAdmittedTerms') &&
        !!diff.length
    );
  }
}

export function setGoalGuidelineContextConfig({
  contextConfig,
  goal,
  guidelineId,
  originalTarget,
  openContextDrawerFor,
  setOpenContextDrawerFor,
  setSnackbarMessage,
  setTarget,
  t,
  target,
}: {
  contextConfig: ContextConfig;
  goal: Goal;
  guidelineId: GuidelineId;
  openContextDrawerFor: EditContextsDrawerForProps | undefined;
  originalTarget: Target | undefined;
  setOpenContextDrawerFor: (v: EditContextsDrawerForProps) => void;
  setSnackbarMessage: (v: string) => void;
  setTarget: (v: Target) => void;
  t: TFunction;
  target: Target;
}) {
  const targetGoalInfos = originalTarget?.targetGoalInfos || [];

  const newTarget = cloneDeep(target);
  newTarget.targetGoalInfos = configureGuideline(
    newTarget.targetGoalInfos,
    goal,
    guidelineId,
    targetGoalInfos,
    setSnackbarMessage,
    (guideline) => {
      const newGuideline = cloneDeep(guideline);
      newGuideline.contextsActive = contextConfig.active;
      newGuideline.contextsFilterType = contextConfig.contextsFilterType;
      newGuideline.contexts = [...contextConfig.contexts];

      return isEqual(newGuideline, guideline) ? guideline : newGuideline;
    },
    t
  );

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }

  if (openContextDrawerFor) {
    const newOpenContextDrawerFor: EditContextsDrawerForProps = {
      ...openContextDrawerFor,
      contextConfig,
    };
    if (!isEqual(newOpenContextDrawerFor, openContextDrawerFor)) {
      setOpenContextDrawerFor(newOpenContextDrawerFor);
    }
  }
}

export function setGoalCustomPresetDescription({
  description,
  goal,
  setTarget,
  target,
}: {
  description: string;
  goal: Goal;
  setTarget: (v: Target) => void;
  target: Target;
}) {
  const newTarget = cloneDeep(target);
  const goalIndex = newTarget.targetGoalInfos.findIndex(
    (goalConfig: GoalConfig) => goalConfig.goalId === goal.identifier
  );
  const goalObject = {
    ...newTarget.targetGoalInfos[goalIndex],
  } as GoalConfig;

  if (goalObject.customPreset) {
    goalObject.customPreset.description = description;
  }

  newTarget.targetGoalInfos[goalIndex] = goalObject;

  if (!isEqual(target, newTarget)) {
    setTarget(newTarget);
  }
}

function getGuidelineValueWithWarning({
  configuredGoal,
  goalConfig,
  guideline,
}: {
  configuredGoal: Goal;
  goalConfig: GoalConfig;
  guideline: Guideline;
}): GuidelineValue | undefined {
  return goalConfig.selectedPresetId?.toLowerCase() === CUSTOM
    ? goalConfig.customPreset?.guidelineValues?.find(
        (guidelineValueObj) =>
          guidelineValueObj.guidelineId === guideline.identifier
      )
    : configuredGoal.predefinedGoalPresets
        .find((preset) => preset.identifier === goalConfig.selectedPresetId)
        ?.guidelineValues?.find(
          (guidelineValueObj) =>
            guidelineValueObj.guidelineId === guideline.identifier
        );
}

export function findGuidelinesWithWarnings({
  getGoalConfig,
}: {
  getGoalConfig: (goalId: string) => GoalConfig;
}) {
  return (acc: Record<string, string[]>, configuredGoal: Goal) => {
    const newAcc = { ...acc };
    const goalConfig = getGoalConfig(configuredGoal.identifier);

    configuredGoal.goalGroups.forEach((goalGroup) => {
      goalGroup.guidelines.forEach((guideline) => {
        if (
          guideline.guidelineType === WORDS_AND_PHRASES_GUIDELINE_TYPE ||
          goalConfig.goalId.toLowerCase() === 'reuse'
        ) {
          const guidelineValue = getGuidelineValueWithWarning({
            configuredGoal,
            goalConfig,
            guideline,
          });

          if (
            guidelineValue?.active &&
            goalConfig.goalId.toLowerCase() === 'reuse' &&
            !guidelineValue?.parameterValue
          ) {
            if (!newAcc[configuredGoal.identifier]) {
              newAcc[configuredGoal.identifier] = [];
            }
            newAcc[configuredGoal.identifier].push(guideline.displayName);
          }

          try {
            const parameterObj: TerminologyGuidelineParameterValueObject =
              JSON.parse(guidelineValue?.parameterValue as string);
            if (
              guidelineValue?.active &&
              ((parameterObj.useTermFilter && !parameterObj.termFilter) ||
                (!parameterObj.useTermFilter &&
                  !parameterObj.domainNames.length))
            ) {
              if (!newAcc[configuredGoal.identifier]) {
                newAcc[configuredGoal.identifier] = [];
              }
              newAcc[configuredGoal.identifier].push(guideline.displayName);
            }
          } catch {}
        }
      });
    });

    return newAcc;
  };
}

export function findGuidelineInGoals(
  goals: Goal[],
  goalId: string,
  guidelineId: string
) {
  const goal = goals.find((goalObj) => goalObj.identifier === goalId);

  const goalGroup = goal?.goalGroups.find((goalGroupObj) =>
    findGuidelineById(goalGroupObj.guidelines, guidelineId)
  );

  return (
    goalGroup?.guidelines &&
    findGuidelineById(goalGroup?.guidelines, guidelineId)
  );
}

export function findGuidelineById(
  guidelines: Guideline[],
  guidelineId: string
) {
  return guidelines.find(
    (guidelineObj) => guidelineObj.identifier === guidelineId
  );
}

export function getModernReuseGuidelineDomainNames(
  targetGoal: GoalConfig,
  defaultValue = '{}',
  index = 0
): string[] {
  let parsableString = defaultValue;
  if (targetGoal?.customPreset?.guidelineValues?.length) {
    parsableString =
      (targetGoal?.customPreset?.guidelineValues[index]
        ?.parameterValue as string) || defaultValue;
  }

  try {
    const parsed: TerminologyGuidelineParameterValueObject =
      JSON.parse(parsableString);
    return parsed.domainNames;
  } catch (err) {
    return [];
  }
}
