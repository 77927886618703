import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledTooltip from 'components/CommonTooltip/CommonTooltip';
import { GuidelineInfoIcon, InfoIconContainer } from './styles';

export function GuidelineHelp({ helpUrl }: { helpUrl: string }): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledTooltip
      title={<> {t('capture.actions.tooltips.moreInformation')} </>}
    >
      <InfoIconContainer
        aria-label={t('capture.a11y.guidelineMoreInformation')}
        href={helpUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <GuidelineInfoIcon />
      </InfoIconContainer>
    </StyledTooltip>
  );
}

export default GuidelineHelp;
