import React from 'react';
import Grid from '@mui/material/Grid';

import SuccessIconAndText from './SuccessIconAndText';
import TransWrapper from 'components/TransWrapper';
import ImportStatusDescription from './ImportStatusDescription';
import { UseUploadReplacementsResult } from '../../hooks';
import WarningIcon from 'components/WarningIcon';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export interface ImportPartiallySuccessfulProps {
  importState: UseUploadReplacementsResult['importState'];
}

export default function ImportPartiallySuccessful({
  importState,
}: ImportPartiallySuccessfulProps) {
  const { t } = useTranslation();

  return (
    <>
      <SuccessIconAndText />
      <ImportStatusDescription>
        <TransWrapper
          i18nKey="reuse.descriptions.replacementsImportPartialSuccess"
          values={{
            numberOfReplacements: importState.data?.replacements,
          }}
        />
      </ImportStatusDescription>
      <Grid
        container
        sx={(theme) => ({ paddingTop: theme.spacing(7) })}
        alignItems="center"
      >
        <WarningIcon />
        <Typography
          sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
          variant="body2"
        >
          <TransWrapper
            i18nKey="reuse.errors.replacementsImportPartialFailure"
            values={{
              numberOfReplacements: importState.data?.failed.length,
            }}
          />
        </Typography>
      </Grid>
      <Typography variant="body2" component="div">
        <ul>
          {importState.data?.failed.map((replacement, index) => (
            <li key={index}>
              {t('reuse.descriptions.replacementsImportPartialFailureDetail', {
                replacementId: replacement.id,
              })}
            </li>
          ))}
        </ul>
      </Typography>
    </>
  );
}
