import { ServerError } from 'errors/ServerError';

export enum HttpMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface FetchJsonReturnHeadersType {
  totalPages?: number;
  totalItems?: number;
}

export interface FetchJsonReturnType {
  links?: { [key: string]: unknown };
  headers?: FetchJsonReturnHeadersType;
  error?: Record<string, any>;
}

interface CustomRequestInit<RequestBodyType> {
  headers?: HeadersInit;
  method?: HttpMethod | string;
  body?: RequestBodyType;
}

// TODO: ? import fetch from 'isomorphic-unfetch'

export async function fetchWrapper<RequestBodyType>(
  resource: RequestInfo,
  {
    headers = {},
    method = HttpMethod.GET,
    body,
    ...init
  }: CustomRequestInit<RequestBodyType> = {}
): Promise<Response> {
  const response = await fetch(resource, {
    ...init,
    headers: {
      ...headers,
    },
    method,
    ...(body && {
      body:
        typeof body === 'string' ? (body as BodyInit) : JSON.stringify(body),
    }),
  });

  if (!response.ok) {
    if (response.status >= 500) {
      throw new ServerError('Server error occured');
    }
  }

  return response;
}

export default async function defaultFetcher<JSONdata = unknown>(
  resource: RequestInfo,
  init?: RequestInit
): Promise<JSONdata> {
  const res = await fetchWrapper(resource, init);
  return res.json();
}

export function fetchWrapperWithToken<RequestBodyType>(
  authToken: string | null
) {
  return async (
    resource: string,
    { headers = {}, ...init }: CustomRequestInit<RequestBodyType> = {}
  ): Promise<Response> => {
    const response = await fetchWrapper<RequestBodyType>(resource, {
      ...init,
      headers: {
        'X-Acrolinx-Auth': authToken,
        ...headers,
      },
    } as CustomRequestInit<RequestBodyType>);

    if (response.status === 401) {
      // Redirect to login
      window.location.assign('logout?continue=' + window.location.pathname);
    }

    return response;
  };
}

export async function requestResponseOrThrowError<RequestBodyType>(
  authToken: string | null,
  resource: string,
  init: CustomRequestInit<RequestBodyType> = {}
) {
  const response = await fetchWrapperWithToken(authToken)(resource, init);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return await response.json();
}

export async function processFetchJSONResponse<ResponseBodyType>(
  response: Response
): Promise<ResponseBodyType> {
  const totalPages = response.headers.get('X-Acrolinx-Total-Pages');
  const totalItems = response.headers.get('X-Acrolinx-Total');
  const headers =
    totalPages || totalItems
      ? {
          headers: {
            ...(totalPages && { totalPages: parseInt(totalPages, 10) }),
            ...(totalItems && { totalItems: parseInt(totalItems, 10) }),
          },
        }
      : {};

  const responseBody = await response.json();

  if (Array.isArray(responseBody) || typeof responseBody === 'string') {
    return responseBody as ResponseBodyType;
  }

  return {
    ...responseBody,
    ...headers,
  };
}

export async function fetchJsonFromAPI<
  RequestBodyType,
  ResponseBodyType = FetchJsonReturnType
>(
  authToken: string | null,
  resource: string,
  { headers = {}, ...init }: CustomRequestInit<RequestBodyType> = {}
): Promise<ResponseBodyType> {
  const response = await fetchWrapperWithToken<RequestBodyType>(authToken)(
    resource,
    {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }
  );

  return processFetchJSONResponse<ResponseBodyType>(response);
}

export async function fetchPlainResponse<RequestBodyType>(
  authToken: string | null,
  resource: string,
  { headers = {}, ...init }: CustomRequestInit<RequestBodyType> = {}
): Promise<Response> {
  return fetchWrapperWithToken<RequestBodyType>(authToken)(resource, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
}
