import { ApiErrorDetails } from 'types/types';

export const getErrorType = (status: number) => {
  if (status >= 400 && status < 500) {
    return 'Client';
  } else if (status >= 500) {
    return 'Server';
  }
  return 'Unknown';
};

export class ApiError extends Error {
  title: string;
  description: string;
  status: number;
  type: string;
  reference: string | undefined;
  details: Record<string, unknown> | undefined;

  constructor(details: ApiErrorDetails) {
    super(details.title);
    this.name = `${getErrorType(details.status)}Error`;

    this.title = details.title;
    this.description = details.detail;
    this.status = details.status;
    this.reference = details.reference;
    this.type = details.type;
    this.details = details.log;
  }

  isClientError() {
    return this.status >= 400 && this.status < 500;
  }

  isServerError() {
    return this.status >= 500;
  }

  toString() {
    return JSON.stringify(this.details, null, 2);
  }
}
