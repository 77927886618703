import React from 'react';
import Select, { selectClasses, SelectProps } from '@mui/material/Select';
import { InputBase } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export { CommonMenuItem, menuProps } from '../CommonSelectStandard';

const INLINE_BLOCK = 'inline-block';

const StyledSelect = styled(Select)(({ theme }) => ({
  padding: theme.spacing(0.2, 0, 0),
  backgroundColor: theme.palette.background.paper,

  [`& .${selectClasses.icon}`]: {
    fill: 'black',
    fontSize: theme.typography.pxToRem(28),
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  },
  [`& .${selectClasses.select}`]: {
    paddingLeft: theme.spacing(2),
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  border: `1px solid ${blueGrey[100]}`,
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  fontSize: theme.typography.pxToRem(14),

  '& [role="button"].MuiSelect-select': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(9),
  },
  '&.Mui-focused, &:hover': {
    borderColor: theme.palette.custom.midBluishGrey,
  },
}));

export const StyledInlineSelectLabel = styled(Typography)(({ theme }) => ({
  display: INLINE_BLOCK,
  letterSpacing: '0.28px',
  paddingRight: theme.spacing(3),
}));

export const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  display: INLINE_BLOCK,
  letterSpacing: '0.14px',
  color: theme.palette.text.secondary,
}));

export const StyledSelectedValue = styled(Typography)(({ theme }) => ({
  display: INLINE_BLOCK,
  letterSpacing: '0.14px',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(18),
  color: theme.palette.text.primary,
}));

export default function CommonSelectDense({ sx, ...props }: SelectProps) {
  return (
    <StyledSelect
      {...props}
      sx={(theme) => {
        let styles = {};

        if (typeof sx === 'function') {
          styles = sx(theme) || {};
        } else {
          styles = sx || {};
        }

        return {
          width: theme.spacing(50),
          ...styles,
        };
      }}
    />
  );
}
