import { useCallback, useState } from 'react';

export interface UseIsOpenResult {
  close: () => void;
  elementIsOpen: boolean;
  open: () => void;
  setElementIsOpen(arg: boolean): void;
  toggle: () => void;
}

export function useIsOpen(initialState = false): UseIsOpenResult {
  const [elementIsOpen, setElementIsOpen] = useState<boolean>(initialState);

  const close = useCallback(() => {
    setElementIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setElementIsOpen(true);
  }, []);

  const toggle = useCallback(() => {
    setElementIsOpen(!elementIsOpen);
  }, [elementIsOpen]);

  return {
    close,
    elementIsOpen,
    open,
    setElementIsOpen,
    toggle,
  };
}
