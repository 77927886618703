import React from 'react';
import { useTranslation } from 'react-i18next';
import CardActions from '@mui/material/CardActions';
import cloneDeep from 'lodash/cloneDeep';
import CreateIcon from '@mui/icons-material/Create';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { SaveTargetAssignment } from './types';
import { TargetAssignment } from '../../../../types';
import { useOverflowText } from 'hooks/useOverflowText';
import {
  StyledEditUnassignedButton,
  StyledUnassignedCardContent,
  StyleUnassignedCard,
  UnassignedTargetName,
} from './styles';
import CommonTooltip from 'components/CommonTooltip';

export interface UnassignedTargetListItemProps {
  openDialogTargetAssignment: () => void;
  readOnly: boolean;
  refreshAssignedTargets: () => void;
  saveTargetAssignment: SaveTargetAssignment;
  setTargetInEdit: (target: TargetAssignment) => void;
  setTargetType: () => void;
  target: TargetAssignment;
}

export default function UnassignedTargetListItem({
  openDialogTargetAssignment,
  readOnly,
  refreshAssignedTargets,
  saveTargetAssignment,
  setTargetInEdit,
  setTargetType,
  target,
}: Readonly<UnassignedTargetListItemProps>) {
  const { t } = useTranslation();
  const { setElementRef, isOverflowed } = useOverflowText();

  return (
    <StyleUnassignedCard>
      <StyledUnassignedCardContent>
        <CommonTooltip
          placement="top"
          title={<>{target.targetName}</>}
          disableHoverListener={!isOverflowed}
        >
          <UnassignedTargetName
            variant="body1"
            ref={(node) => {
              node && setElementRef(node);
            }}
            lang={target.targetLanguageAbbreviation}
          >
            {target.targetName}
          </UnassignedTargetName>
        </CommonTooltip>
      </StyledUnassignedCardContent>
      {!readOnly && (
        <CardActions>
          <CommonTooltip placement="top" title={t('shared.actions.edit')}>
            <StyledEditUnassignedButton
              color="primary"
              size="small"
              aria-label={t('capture.a11y.editAssignment')}
              onClick={() => {
                setTargetType();
                setTargetInEdit(cloneDeep(target));
                openDialogTargetAssignment();
              }}
            >
              <CreateIcon />
            </StyledEditUnassignedButton>
          </CommonTooltip>
          <CommonTooltip
            placement="top"
            title={t('capture.actions.tooltips.assignToAll')}
          >
            <StyledEditUnassignedButton
              color="primary"
              size="small"
              onClick={async () => {
                await saveTargetAssignment({
                  ...target,
                  assignedToAll: true,
                });
                refreshAssignedTargets();
              }}
              aria-label={t('capture.actions.tooltips.assignToAll')}
            >
              <GroupAddIcon />
            </StyledEditUnassignedButton>
          </CommonTooltip>
        </CardActions>
      )}
    </StyleUnassignedCard>
  );
}
