import React, { ReactElement, useCallback } from 'react';
import { IconButtonProps } from '@mui/material/IconButton';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import { StyledIconButton } from './styles';
import CommonTooltip from '../CommonTooltip';
import ConditionalWrapper from '../ConditionalWrapper';

export interface DomainAddButtonProps extends IconButtonProps {
  tooltipText: string;
}

export default function DomainAddButton({
  tooltipText,
  ...props
}: DomainAddButtonProps) {
  const tooltipVisible = !props['aria-disabled'];
  const wrapper = useCallback(
    (children: ReactElement) => {
      return <CommonTooltip title={tooltipText}>{children}</CommonTooltip>;
    },
    [tooltipText]
  );

  return (
    <ConditionalWrapper condition={tooltipVisible} wrapper={wrapper}>
      <StyledIconButton
        size="small"
        color="primary"
        component="span"
        disableRipple={!!props['aria-disabled']}
        {...props}
      >
        <AddCircleRoundedIcon />
      </StyledIconButton>
    </ConditionalWrapper>
  );
}
