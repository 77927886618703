import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import { CloseIconButton } from './styles';
import CommonTooltip from '../CommonTooltip';
import { CommonDrawerInterface } from './';

export interface CommonDrawerCloseButtonProps {
  onClose: CommonDrawerInterface['cancelAndCloseHandler'];
}

export default function CommonDrawerCloseButton({
  onClose,
}: CommonDrawerCloseButtonProps) {
  const { t } = useTranslation();

  return (
    <CommonTooltip
      sx={(theme) => ({ marginRight: theme.spacing(0) })}
      title={t('shared.actions.close')}
      placement="bottom-end"
    >
      <CloseIconButton
        data-testid="close-button"
        aria-label={t('shared.actions.close')}
        onClick={onClose}
      >
        <CloseIcon />
      </CloseIconButton>
    </CommonTooltip>
  );
}
