import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvancedSettingsSectionTitle,
  InnerDivider,
} from 'components/CommonDrawer';
import AdministrativeInformation from 'components/AdministrativeInformation';
import {
  ExpandMoreIconWithTooltip,
  StyledAccordionPlain,
  StyledAccordionSummary,
} from 'components/FixedAccordion';
import useExpandCollapse from 'hooks/useExpandCollapse';
import { BreakGrid } from './components';
import { UpdateInformation } from 'types/types';

interface AdministrativeInformationAccordionProps {
  created: UpdateInformation | null | undefined;
  modified: UpdateInformation | null | undefined;
  readonly?: boolean;
}
export default function AdministrativeInformationAccordion({
  created,
  modified,
  readonly,
}: AdministrativeInformationAccordionProps) {
  const { t } = useTranslation();
  const { expanded, expandIconTooltipText, setExpanded } = useExpandCollapse();

  return (
    <>
      <InnerDivider
        key="divider"
        sx={(theme) => ({
          margin: theme.spacing(1, 0, 3, 0),
        })}
      />
      <StyledAccordionPlain
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <StyledAccordionSummary
          expandIcon={
            <ExpandMoreIconWithTooltip
              expandIconTooltipText={expandIconTooltipText}
            />
          }
        >
          <AdvancedSettingsSectionTitle>
            {t('shared.titles.administrativeInformation')}
          </AdvancedSettingsSectionTitle>
        </StyledAccordionSummary>
        <AdministrativeInformation
          key="administrativeInformation"
          created={created}
          modified={modified}
          titleNotVisible
          readonly={readonly}
        />
      </StyledAccordionPlain>
      <BreakGrid sx={(theme) => ({ paddingBottom: theme.spacing(2) })} />
    </>
  );
}
