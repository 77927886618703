import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import BusyOverlay from 'components/BusyOverlay';
import MainLayout from 'components/MainLayout';
import ProtectedRoute from 'components/ProtectedRoute';
import SessionTimeout from 'components/SessionTimeout';
import ReuseIndexPage from 'sections/Reuse/pages/ReuseIndexPage';
import DomainDetailPage from 'sections/Reuse/pages/DomainDetailPage';
import TargetPage from 'sections/Targets/pages/TargetPage';
import TargetIndexPage from 'sections/Targets/pages/TargetIndexPage';
import UsersIndexPage from 'sections/Users/pages/UsersIndexPage';
import UserDetails from 'sections/Users/pages/UserDetails';
import APIErrorPage from 'pages/APIErrorPage';
import FeaturesContext from 'contexts/FeaturesContext';
import PrivilegesContext from 'contexts/PrivilegesContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import UserContext from 'contexts/UserContext';
import ReuseInbox from '../../sections/Reuse/pages/Inbox';
import { ErrorNotificationDialogProvider } from 'sections/Users/components/ErrorNotificationDialog';
import { ErrorProvider } from 'sections/Users/components/ErrorNotificationDialog/ErrorContext';
import Home from 'pages/Home';
import ReportingAPI from 'pages/ReportingAPI';
import OSSLicensesPage from 'pages/OSSLicensesPage';

import { usePrivateContent } from './hooks';
// import { LDFlagSet, withLDConsumer } from 'launchdarkly-react-client-sdk';
// import useAppcues from 'hooks/useAppcues';
import pageDefinitions from 'hooks/usePages/pages.json';

const pageDefinitionsMap = pageDefinitions.reduce(
  (previousValue, currentValue) => {
    if (currentValue.routePath && currentValue.mainMenu.linkToClassicView) {
      previousValue[currentValue.routePath] =
        currentValue.mainMenu.linkToClassicView;
    }

    return previousValue;
  },
  {} as { [key: string]: string }
);

const PrivateContent =
  (): // { flags }: LDFlagSet commenting out until DEV-37764 will be picked up again
  ReactElement => {
    const {
      currentUserData,
      errorGetFeatures,
      errorGetPrivileges,
      featuresData,
      privilegesData,
    } = usePrivateContent();

    // useAppcues(flags);

    if (errorGetPrivileges || errorGetFeatures) {
      return <Redirect to="/403" />;
    }

    if (!featuresData || !privilegesData || !currentUserData) {
      return <BusyOverlay />;
    }

    return (
      <CurrentUserContext.Provider value={currentUserData.data}>
        <UserContext.Provider value={{ id: currentUserData.data.username }}>
          <PrivilegesContext.Provider
            value={privilegesData?.data?.privileges || []}
          >
            <FeaturesContext.Provider
              value={featuresData?.data?.features || {}}
            >
              <SessionTimeout />
              <MainLayout>
                <Switch>
                  <ProtectedRoute exact path="/servers">
                    <Redirect to={pageDefinitionsMap['/servers']} />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/maintenance">
                    <Redirect to={pageDefinitionsMap['/maintenance']} />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/license">
                    <Redirect to={pageDefinitionsMap['/license']} />
                  </ProtectedRoute>
                  {!featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute exact path="/users">
                      <Redirect to={pageDefinitionsMap['/users']} />
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute exact path="/user-management/roles">
                      <Redirect
                        to={pageDefinitionsMap['/user-management/roles']}
                      />
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute exact path="/user-management/privacy">
                      <Redirect
                        to={pageDefinitionsMap['/user-management/privacy']}
                      />
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute
                      exact
                      path="/user-management/browser-sessions"
                    >
                      <Redirect
                        to={
                          pageDefinitionsMap[
                            '/user-management/browser-sessions'
                          ]
                        }
                      />
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute
                      exact
                      path="/user-management/users/:id/details"
                    >
                      <ErrorNotificationDialogProvider>
                        <ErrorProvider>
                          <UserDetails />
                        </ErrorProvider>
                      </ErrorNotificationDialogProvider>
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute exact path="/user-management/users">
                      <ErrorNotificationDialogProvider>
                        <ErrorProvider>
                          <UsersIndexPage />
                        </ErrorProvider>
                      </ErrorNotificationDialogProvider>
                    </ProtectedRoute>
                  )}
                  {featuresData?.data?.features?.enableNewUserManagement && (
                    <ProtectedRoute exact path="/user-management">
                      <Redirect to="/user-management/users" />
                    </ProtectedRoute>
                  )}
                  <ProtectedRoute exact path="/terminology">
                    <Redirect to={pageDefinitionsMap['/terminology']} />
                  </ProtectedRoute>
                  {featuresData?.data?.features?.enableNewReuseHarvesting && (
                    <ProtectedRoute exact path="/reuse/assistant">
                      <ReuseInbox />
                    </ProtectedRoute>
                  )}
                  {(featuresData?.data?.features?.oldReuse ||
                    featuresData.data?.features?.enableNewReuse) && [
                    (featuresData.data?.features?.enableNewReuse && (
                      <ProtectedRoute exact path="/reuse/:id" key="detailsPage">
                        <DomainDetailPage />
                      </ProtectedRoute>
                    )) ||
                      null,
                    <ProtectedRoute exact path="/reuse" key="indexPage">
                      {featuresData.data?.features?.enableNewReuse ? (
                        <ReuseIndexPage />
                      ) : (
                        <Redirect to={pageDefinitionsMap['/reuse']} />
                      )}
                    </ProtectedRoute>,
                  ]}
                  <ProtectedRoute exact path="/analytics">
                    <Redirect to={pageDefinitionsMap['/analytics']} />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/guidance-settings">
                    <Redirect to={pageDefinitionsMap['/guidance-settings']} />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/targets/:id">
                    <TargetPage />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/targets">
                    <TargetIndexPage />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/reporting/api">
                    <ReportingAPI />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/opensource">
                    <OSSLicensesPage />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/home">
                    <Home />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/403">
                    <APIErrorPage errorCode="403" />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/404">
                    <APIErrorPage />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/">
                    <Home />
                  </ProtectedRoute>
                  {featuresData.data?.features?.enableContentCube && (
                    <ProtectedRoute exact path="/content-cube">
                      <Redirect to={pageDefinitionsMap['/content-cube']} />
                    </ProtectedRoute>
                  )}
                  <ProtectedRoute>
                    <APIErrorPage />
                  </ProtectedRoute>
                </Switch>
              </MainLayout>
            </FeaturesContext.Provider>
          </PrivilegesContext.Provider>
        </UserContext.Provider>
      </CurrentUserContext.Provider>
    );
  };

// export default withLDConsumer()(PrivateContent);
export default PrivateContent;
