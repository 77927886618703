import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';

import { UnifiedMainPageLayout } from 'components/PageLayout';
import { useFetch } from 'utils/fetch';

export default function OSSLicensesPage() {
  const [indexHtml, setIndexHtml] = useState({ __html: '' });
  const [bomHtml, setBomHtml] = useState({ __html: '' });
  const [page, setPage] = useState('index');
  const fetcher = useFetch();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    if ((event.target as HTMLAnchorElement)?.id === 'localLink') {
      event.stopPropagation();
      event.preventDefault();
      setPage(page === 'index' ? 'bom' : 'index');
    }
  }

  useEffect(() => {
    function handleClick(this: HTMLElement, ev: MouseEvent) {
      // check if the collapsible button was clicked
      if (
        (ev.target as HTMLButtonElement)?.type.toLowerCase() === 'button' &&
        (ev.target as HTMLButtonElement)?.className === 'collapsible'
      ) {
        // stop native events
        ev.stopPropagation();
        ev.preventDefault();

        // this section works on div.content which is a sibling of clicked button
        const divContent = (
          (ev.target as HTMLButtonElement).parentElement as HTMLDivElement
        ).querySelector('div.content') as HTMLDivElement;

        if (!divContent) {
          return;
        }

        // if plain styles were not modified ('none' is set by mui, so it's in the class)
        if (!divContent.style.display) {
          // set display to 'block' to show related content
          divContent.style.display = 'block';
          // in any other case (if display has any value)
        } else {
          // set display to empty value, so it's value wil be read from mui styles ('none')
          divContent.style.display = '';
        }
      }
    }

    document
      .getElementById('opensource_content')
      ?.addEventListener('click', handleClick);

    return () => {
      document
        .getElementById('opensource_content')
        ?.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const element = document.body.querySelector('main > section');
    (element as Element).addEventListener(
      'click',
      handleClick as unknown as EventListener
    );
    const indexPage = page === 'index';

    if ((indexPage && indexHtml.__html) || (!indexPage && bomHtml.__html)) {
      return;
    }

    const path = indexPage
      ? '/about/opensource/index.html'
      : '/about/opensource/bom.html';
    fetcher
      .get(path, {
        config: { headers: { 'content-type': 'text/html' } },
      })
      .then((result) => {
        if (typeof result === 'string') {
          const bodyStartIndex = result.indexOf('<body>') + 6;
          const bodyEndIndex = result.indexOf('</body>');
          const body = result.slice(bodyStartIndex, bodyEndIndex);

          const bodyWithId = body.replace('<a', '<a id="localLink"');

          indexPage && setIndexHtml({ __html: bodyWithId });
          !indexPage && setBomHtml({ __html: bodyWithId });
        }
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [page]);

  return (
    <UnifiedMainPageLayout>
      <Box
        sx={{
          '& div.content': {
            display: 'none',
          },
        }}
        id="opensource_content"
        dangerouslySetInnerHTML={page === 'bom' ? bomHtml : indexHtml}
      />
    </UnifiedMainPageLayout>
  );
}
