import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function useDidMountEffect(func: () => any, deps: any[]) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);
}
