import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const WarningContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0),
}));
export const WarningText = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.13),
  fontSize: theme.typography.pxToRem(13),
  margin: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.custom.lightYellow,
  border: `1px solid ${theme.palette.custom.yellow}`,
  borderRadius: '4px',
}));
