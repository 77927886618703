import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(8 / 5),
  paddingBottom: theme.spacing(7 / 5),
  [`& .${menuItemClasses.disabled}`]: {
    borderColor: theme.palette.primary.main,
  },
}));

interface Props {
  id: string;
  displayName: string;
  possibleValues: ReadonlyArray<string>;
  value: string;
  inputType: string;
  onSubmit: (selected: string) => void;
}

const CustomFieldList = ({
  id,
  displayName,
  possibleValues,
  value,
  inputType,
  onSubmit,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <FormControl
      sx={{
        display: 'flex',
        cursor: 'initial',
      }}
    >
      <InputLabel id={`${id}-label`} className={'visually-hidden'}>
        {displayName}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={(event: SelectChangeEvent<unknown>) => {
          if (value !== event.target.value) {
            onSubmit(event.target.value as string);
          }
        }}
        displayEmpty
        renderValue={(selected) => (
          <>{!selected ? <em>{t('users.fields.select')}</em> : selected}</>
        )}
        variant="standard"
        sx={{
          marginTop: '0 !important',
          fontSize: (theme) => theme.typography.pxToRem(14),
          '&:before, &:after': {
            borderBottom: 0,
          },
          '&:hover': {
            '&:before, &:after': {
              borderBottom: '0 !important',
            },
          },
          [`& .${selectClasses.select}`]: {
            '&:focus': {
              backgroundColor: 'transparent !important',
            },
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            elevation: 1,
            square: true,
            sx: { borderRadius: '6px' },
          },
        }}
      >
        {inputType !== 'required' && (
          <StyledMenuItem value="">
            <em>{t('users.fields.none')}</em>
          </StyledMenuItem>
        )}
        {possibleValues.map((v) => (
          <StyledMenuItem key={v} value={v}>
            {v}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomFieldList;
