import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import Logo from './acrolinx-logo.svg';
import TimeoutLogoutNotification from './components/TimeoutLogoutNotification';
import useTitle from 'hooks/useTitle';
import useSignOut from 'hooks/useSignOut';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  AcrolinxLogo,
  LogoutDescription,
  LogoutTitle,
  Main,
  PageContainerLogout,
  StyledAppBar,
  StyledLink,
  StyledLogoutImage,
  StyledToolbar,
} from './styles';

const LogoutPage = (): ReactElement => {
  const { t } = useTranslation();
  const KEY_LOGOUT_REASON = 'logout-reason';

  const [logoutReason, setLogoutReason] = useState(
    window.localStorage.getItem(KEY_LOGOUT_REASON)
  );

  useTitle(t('shared.app.name'));

  const handleStorageEvent = useCallback(
    async (event: StorageEvent): Promise<void> => {
      if (event.storageArea !== window.localStorage) {
        return;
      }
      if (event.key === KEY_LOGOUT_REASON) {
        setLogoutReason(event.newValue);
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [handleStorageEvent]);

  const { handleResetUserSessionState } = useSignOut();

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      try {
        const response = await fetch('/dashboard/api/signin/authenticate', {
          method: 'POST',
        });

        if (!response.ok) {
          throw Error(response.statusText);
        }

        setIsLoggedIn(true);
        window.location.replace('/');
      } catch (error) {
        setIsLoggedIn(false);

        handleResetUserSessionState();
      }
    };

    checkIsLoggedIn();
  }, [handleResetUserSessionState]);

  if (isLoggedIn) {
    return <></>;
  }

  return (
    <>
      <TimeoutLogoutNotification
        open={logoutReason === 'session-timeout'}
        onClose={() => {
          window.localStorage.removeItem(KEY_LOGOUT_REASON);
          setLogoutReason(null);
        }}
      />
      <PageContainerLogout>
        <StyledAppBar position="fixed" color={'inherit'}>
          <StyledToolbar>
            <Box display="flex" alignItems="center" width={1}>
              <Box p={1}>
                <AcrolinxLogo
                  src={Logo}
                  alt={t('shared.components.header.altLogo')}
                />
              </Box>
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <Main role="main">
          <Box display="flex" justifyContent="center" width="1">
            <Box textAlign="center">
              <StyledLogoutImage
                title={t('shared.signedOut.images.altSignedOut')}
              />
              <LogoutTitle variant="h1">
                {t('shared.signedOut.title')}
              </LogoutTitle>
              <LogoutDescription variant="body1">
                <StyledLink component="a" href="/" underline={'always'}>
                  {t('shared.signedOut.description')}
                </StyledLink>
              </LogoutDescription>
            </Box>
          </Box>
        </Main>
      </PageContainerLogout>
    </>
  );
};

export default LogoutPage;
