import { useContext, useMemo } from 'react';

import { ReuseIndexDomain } from '../types';
import PrivilegesContext from 'contexts/PrivilegesContext';
import useSort, { UseSortResult } from 'hooks/useSort';
import useFetchReuseDomains, {
  UseFetchReuseDomainsResult,
} from 'hooks/useFetchReuseDomains';

export interface UseReuseDomainsResult {
  privileges: string[];
  refreshIndexDomains: UseFetchReuseDomainsResult['refetch'];
  reuseData: ReuseIndexDomain[];
  reuseDomainsResult: UseFetchReuseDomainsResult;
  sortedDomains: ReuseIndexDomain[];
  sortState: UseSortResult;
}

export function useReuseDomains(): UseReuseDomainsResult {
  const sortState = useSort('displayName');
  const { sortBy, sortField, sortOrder } = sortState;
  const reuseDomainsResult = useFetchReuseDomains();

  function refreshIndexDomains() {
    return reuseDomainsResult.refetch();
  }

  const sortedDomains = useMemo(
    () =>
      sortBy(
        reuseDomainsResult.data || [],
        sortField,
        sortOrder,
        'displayName'
      ),
    [sortBy, sortField, sortOrder, reuseDomainsResult.data]
  );

  const privileges = useContext(PrivilegesContext);

  return {
    privileges,
    refreshIndexDomains,
    reuseData: Array.isArray(reuseDomainsResult.data)
      ? reuseDomainsResult.data
      : [],
    reuseDomainsResult,
    sortedDomains,
    sortState,
  };
}
