import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDrawerContent = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  overflowY: 'scroll',
}));

export const StyledEmptyField = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(21),
  letterSpacing: theme.typography.pxToRem(0.14),
  color: theme.palette.custom.doveGrey,
  marginTop: theme.spacing(-2),
  fontStyle: 'italic',
}));
