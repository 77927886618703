import { styled } from '@mui/material/styles';
import ButtonPrimary from 'components/ButtonPrimary';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export const StyledButtonPrimary = styled(ButtonPrimary)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(2),
  letterSpacing: theme.typography.pxToRem(0.4),
}));

export const StyledDialogActions = styled(DialogActions)({
  paddingRight: 0,
  paddingTop: 0,
});

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(2.2),
}));
