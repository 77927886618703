import React, { PropsWithChildren } from 'react';
import { TableSortLabelProps } from '@mui/material/TableSortLabel';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  DataRow,
  StyledTable,
  StyledTableSortLabel,
  TableHeader,
  TableLink,
} from './components';
import { SortOrder } from 'utils/utils';
import TableHead from '@mui/material/TableHead';

export interface IndexTableConfig extends TableSortLabelProps {
  renderConfig?: {
    header?: boolean;
    idPropertyName?: string;
    urlBase?: string;
    valuePropertyName?: string;
    icons?: boolean;
    langAttr?: boolean; // for the columns, where the cells need a lang attribute
  };
}
export interface IndexTableProps {
  data: any[];
  tableConfig: IndexTableConfig[];
  Icons?: React.FunctionComponent<{ element: any }>;
}

export default function IndexTable({
  tableConfig,
  data,
  Icons,
}: IndexTableProps) {
  const { t } = useTranslation();

  return (
    <StyledTable>
      <TableHead>
        <tr>
          {tableConfig.map(({ title, renderConfig, ...props }, index) => {
            const titleComponent = title ? (
              <Typography variant="body2">{title}</Typography>
            ) : null;

            const a11yText =
              props.direction === SortOrder.desc
                ? t('shared.sort.a11y.sortedDescending')
                : t('shared.sort.a11y.sortedAscending');

            return (
              <TableHeader
                key={title + index.toString()}
                {...(props.active && {
                  sortDirection: props.direction,
                })}
              >
                {Object.keys(props).length ? (
                  <StyledTableSortLabel {...props}>
                    {titleComponent}
                    {props.active && (
                      <span className={'visually-hidden'}>{a11yText}</span>
                    )}
                  </StyledTableSortLabel>
                ) : (
                  titleComponent
                )}
              </TableHeader>
            );
          })}
        </tr>
      </TableHead>
      <tbody>
        {data.map((element) => {
          const idPropertyName = tableConfig[0]?.renderConfig?.idPropertyName;
          const id = element[idPropertyName as string];

          if (!id) {
            return null;
          }

          return (
            <DataRow key={id} className={element.displayName}>
              {tableConfig.map((configElement) => {
                const renderConfig = configElement.renderConfig;

                if (renderConfig) {
                  const Cell = (
                    props: PropsWithChildren<
                      React.ThHTMLAttributes<HTMLTableCellElement>
                    >
                  ) =>
                    renderConfig?.header ? (
                      <th {...props} />
                    ) : (
                      <td {...props} />
                    );
                  const dataText =
                    element[renderConfig?.valuePropertyName as string];
                  const text = dataText ? (
                    <Typography variant="body2">{dataText}</Typography>
                  ) : null;
                  const className = renderConfig?.valuePropertyName || 'icons';

                  return (
                    <Cell
                      key={id + className}
                      className={className}
                      {...(renderConfig.langAttr && {
                        lang: element.languageId.split(/[:-]/)[0],
                      })}
                    >
                      {renderConfig?.urlBase && text ? (
                        <TableLink to={renderConfig.urlBase + id}>
                          {text}
                        </TableLink>
                      ) : (
                        text
                      )}
                      {!text && renderConfig.icons && !!Icons && (
                        <Icons element={element} />
                      )}
                    </Cell>
                  );
                }

                return <td key={id + 'empty'} className="empty" />;
              })}
            </DataRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
}
