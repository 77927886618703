import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';

import { TextTypeOption } from './styles';
import { Options } from './types';
import CommonTooltip from 'components/CommonTooltip';
import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';

export interface OptionCheckboxProps {
  checked: boolean;
  element: Options;
  onChange: (event: ChangeEvent<{ value?: string }>, checked: boolean) => void;
  controlProps?: Record<string, string | boolean>;
  showDeleteIcon?: boolean;
  deleteText?: (text: string) => void;
  deletable?: boolean;
}

export function OptionCheckbox({
  checked,
  element,
  onChange,
  controlProps,
  showDeleteIcon,
  deleteText,
  deletable,
}: OptionCheckboxProps): JSX.Element {
  const { t } = useTranslation();

  const tooltipText = deletable
    ? t('capture.actions.tooltips.deleteRegex.canBeDeleted')
    : t('capture.actions.tooltips.deleteRegex.canNotBeDeleted');
  const tooltipType = deletable ? 'black' : 'yellow';

  return (
    <TextTypeOption
      container
      justifyContent="space-between"
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
    >
      <CommonCheckboxWithLabel
        label={
          <Typography
            variant="body2"
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {element.label}
          </Typography>
        }
        onChange={onChange}
        value={element.id}
        checked={checked}
        {...controlProps}
      />
      {showDeleteIcon && (
        <CommonTooltip
          title={tooltipText}
          type={tooltipType}
          placement="bottom-end"
        >
          <div>
            <IconButton
              color="primary"
              aria-label={t('capture.a11y.deleteRegex')}
              disabled={!deletable}
              onClick={() => {
                typeof deleteText === 'function' && deleteText(element.id);
              }}
              size="small"
              sx={(theme) => ({
                padding: theme.typography.pxToRem(4),
                '&:disabled': {
                  opacity: 0.4,
                  color: theme.palette.primary.main,
                },
              })}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>
        </CommonTooltip>
      )}
    </TextTypeOption>
  );
}

export default OptionCheckbox;
